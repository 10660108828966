<template>
    <div id="pre-registration-record">
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" @click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                        <cancel-schedule v-if="![constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER, constants.STATUS_CANCELED_MARY_BELEZA].includes(schedule.status) && has_permission('services-edit') && !edit.service && !edit.address"/>
                        <b-button v-if="![constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER, constants.STATUS_CANCELED_MARY_BELEZA].includes(schedule.status) && has_permission('services-edit') && !edit.service && !edit.address" variant="mary-beleza" class="ml-2" @click="CreateProblemSchedule()">
                            <b-icon icon="exclamation-triangle" class="mr-2" />Relatar Problema
                        </b-button>
                        <b-button v-if="![constants.STATUS_OPPORTUNITY].includes(schedule.status)" class="ml-2" variant="primary" @click="NewScheduleWorkerAndCustomer" v-b-tooltip="'Pega automaticamente o cliente o profissional do agendamento atual, para criar um novo.'">
                            <b-icon icon="plus" class="mr-2" />Novo agendamento
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">

            <b-overlay :show="loadingData" rounded="sm">
                <panel title="Serviço" icon='address-card' icon-lib="far">
                    <b-row class="form-group">
                        <b-col sm="12" md="3" lg="3">
                            <b-row>
                                <b-col sm="4" md="12" lg="12" class="form-group">
                                    <label>Numero:</label>
                                    <div>
                                        {{ schedule.number }}
                                    </div>
                                </b-col>
                                <b-col sm="4" md="12" lg="12" class="form-group">
                                    <img :src="img_calendar" width="16" v-b-tooltip="'Data final'" class="mr-2"/>
                                    <label>Data do serviço:</label>
                                    <div>
                                        <template v-if="!edit.service">
                                            <span v-if="schedule.service_date_end">
                                                {{ schedule.service_date | moment('DD/MM/YYYY HH:mm')}}h
                                            </span>
                                            <span v-else>
                                                {{ moment(schedule.service_date).format('DD/MM/YYYY') }}
                                            </span>                  
                                        </template>
                                        <template v-else>
                                            <v-date-picker v-if="schedule.service_date_end" :model-config="schedule.service_date_end ? modelConfigTime : modelConfig" v-model="service_date" :mode="schedule.service_date_end ? 'dateTime' : 'date'" is24hr>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div>
                                                        <input
                                                        class="form-control"
                                                        :value="inputValue"
                                                        v-on="inputEvents"/>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                            <v-date-picker v-else :model-config="modelConfig" v-model="schedule.service_date" mode="date" is24hr>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div>
                                                        <input
                                                        class="form-control"
                                                        :value="inputValue"
                                                        v-on="inputEvents"/>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </template>
                                    </div>
                                </b-col>
                                <b-col sm="4" md="12" lg="12" class="form-group">
                                    <label>Status:</label>
                                    <div>
                                        <b-badge pill v-schedule-status="schedule.status"></b-badge>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="12" md="4" lg="4">
                            <b-row>
                                <b-col sm="4" md="12" lg="12" class="form-group">
                                    <label>Serviço:</label>
                                    <div>
                                        {{ schedule.service.name }}
                                    </div>
                                </b-col>
                                <b-col v-if="schedule.service_date_end" sm="4" md="12" lg="12" class="form-group">
                                    <img :src="img_calendar" width="16" class="mr-2"/>
                                    <label>Data final do serviço:</label>
                                    <div>
                                        {{ schedule.service_date_end | moment('DD/MM/YYYY HH:mm')}}h
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="12" md="3" lg="3">
                            <b-card class="h-100 w-100">
                                <b-row>
                                    <b-col sm="4" md="12" lg="12" class="form-group">
                                        <template v-if="GetTransactionRecipe() && !IsTransactionPending(GetTransactionRecipe())">
                                            <img :src="img_receipt_ok" v-b-tooltip="'Data do recebimento: ' + moment(GetTransactionRecipe().payment_date).format('DD/MM/YYYY') + '\r\n'+GetTransactionObs(GetTransactionRecipe())" width="18" class="mr-2"/>
                                        </template>
                                        <template v-else>
                                            <img :src="img_payment_not_ok" class="mr-2" width="18" v-b-tooltip="'Não recebido.'"/>
                                        </template>
                                        <label>Valor cliente:</label>
                                        <div v-b-tooltip.hover.left="'Valor total'">
                                            <b>Total: </b>{{ schedule.customer_total | currency }}
                                        </div>
                                        <div v-if="schedule.customer_discount" v-b-tooltip.hover.left="'Valor desconto'" class="text-danger">
                                            <b>Desconto: </b>{{ schedule.customer_discount | currency }}
                                        </div>
                                        <template v-if="!edit.service">
                                            <div v-b-tooltip.hover.left="'Valor a receber'">
                                                <b>A receber: </b> {{ schedule.customer_value | currency }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <money class="form-control" v-model="customer_value" v-bind="money"></money>
                                        </template>
                                        <template v-if="schedule.customer_value_motive || (edit.service && backup && backup.customer_value != schedule.customer_value)">
                                            <label>Motivo do valor:</label>
                                            <div v-if="!edit.service"> <b v-if="schedule.customer_value_motive_user">{{schedule.customer_value_motive_user}}:</b> {{schedule.customer_value_motive}}</div>
                                            <textarea v-else class="form-control" v-model="schedule.customer_value_motive"></textarea>
                                        </template>
                                        <div v-if="TransactionRecipeFail() && has_permission('services-edit')" v-b-tooltip.hover.left="'Refazer cobrança'">
                                            <b>Refazer cobrança: </b><b-icon @click="RefreshTransaction" class="ml-3 zoom-1-2" :animation="loading_transaction ? 'spin' : ''" variant="danger" icon="arrow-repeat" scale="2"></b-icon>
                                        </div>
                                    </b-col>
                                    <b-col v-if="schedule.promotion" sm="4" md="12" lg="12" class="form-group">
                                        <img :src="img_coupon" width="20" v-b-tooltip="'Cupom'" class="mr-2"/>
                                        <label>Cupom:</label>
                                        <div>
                                            <b-badge id="coupon" variant="success"><h6 class="m-0 p-0 font-weight-bold">{{ schedule.promotion.coupon }}</h6></b-badge>
                                            <b-badge id="coupon" variant="danger" v-b-tooltip.hover.v-mary-beleza-light="'Valor do cupom'"><h6 class="m-0 p-0 font-weight-bold">
                                            <span v-if="schedule.promotion.value_type == 1">
                                                -{{schedule.promotion.value}}%
                                            </span>
                                            <span v-else>
                                                -{{schedule.promotion.value | currency}}
                                            </span>
                                            </h6></b-badge>
                                        </div>
                                        <b-tooltip target="coupon" variant="mary-beleza-light" placement="bottom">
                                            <strong>Descrição:</strong><br>
                                            <span>{{schedule.promotion.description}}</span>
                                        </b-tooltip>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col sm="12" md="3" lg="3">
                            <b-card class="h-100 w-100">
                                <b-row>
                                    <b-col sm="4" md="12" lg="12" class="form-group">
                                        <template v-if="GetTransactionExpense() && !IsTransactionPending(GetTransactionExpense())">
                                            <img :src="img_payment_ok" width="18" class="mr-2" v-b-tooltip="'Data do pagamento: ' + moment(GetTransactionExpense().payment_date).format('DD/MM/YYYY')+ '\r\n'+GetTransactionObs(GetTransactionExpense())">
                                        </template>
                                        <template v-else>
                                            <img :src="img_receipt_not_ok" width="18" class="mr-2" v-b-tooltip="'Não pago.'"/>
                                        </template>
                                        <label>Valor profissional:</label>
                                        <template v-if="!edit.service">
                                            <div v-b-tooltip.hover.left="'Valor a pagar'">
                                                <b>A pagar: </b>{{ schedule.worker_value | currency }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <money class="form-control" v-model="worker_value" v-bind="money"></money>
                                        </template>
                                        <template v-if="schedule.worker_value_motive || (edit.service && backup && backup.worker_value != schedule.worker_value)">
                                            <label>Motivo do valor:</label>
                                            <div v-if="!edit.service"> <b v-if="schedule.worker_value_motive_user">{{schedule.worker_value_motive_user}}:</b> {{schedule.worker_value_motive}}</div>
                                            <textarea v-else class="form-control" v-model="schedule.worker_value_motive"></textarea>
                                        </template>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row v-if="!schedule.service_date_end">
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <img :src="img_calendar" width="16" v-b-tooltip="'Data final'" class="mr-2"/>
                            <label>Horários escolhidos pelo cliente:</label>
                            <b-row class="mx-1 align-items-center">
                                <p v-if="schedule.hours.length == 0 && !edit.service">
                                    -
                                </p>
                                <b-col v-else class="m-0 my-2 px-1" sm="6" md="4" lg="3" v-for="(item, index) in schedule.hours" :key="index">
                                    <b-card class="border-hours">
                                        <template v-if="!edit.service || item.id">
                                            <img :src="img_time" width="20" v-b-tooltip="'Horário'" class="mr-2"/>
                                            <span>
                                                {{ ListDateTimeConcat(schedule.service_date, item.hour, schedule.service.duration) }}
                                            </span> 
                                            <b-icon v-if="edit.service && item.id" @click="$store.commit('service/SET_DELETE_HOUR', index)" icon="trash" class="ml-2 zoom-1-2" variant="danger" scale="1.3"></b-icon>                 
                                        </template>
                                        <template v-else>
                                            <v-date-picker style="z-index:9999" :model-config="modelConfigTime" :value="DateTimeConcat(schedule.service_date, item.hour)" @input="UpdateHour($event, index)" mode="time" :minute-increment="30" is24hr>
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <div class="d-flex align-items-center">
                                                        <img :src="img_time" width="20" height="20" v-b-tooltip="'Horário'" class="mr-2"/>
                                                        <input
                                                        class="form-control"
                                                        :value="inputValue"
                                                        v-on="inputEvents"/>
                                                        <b-icon @click="$store.commit('service/SET_DELETE_HOUR', index)" icon="trash" class="ml-2 zoom-1-2" variant="danger" scale="1.3"></b-icon>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </template>
                                    </b-card>
                                </b-col>
                                <div v-if="edit.service" class="zoom-1-2 align-self-center m-4">
                                    <b-icon @click="$store.commit('service/SET_ADD_HOUR')" icon="plus-circle-fill" variant="mary-beleza" scale="2.1"></b-icon>
                                </div>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row v-if="has_permission('services-edit')">
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <b-button v-if="!edit.service" variant="mary-beleza" size="sm" @click="EditSchedule">
                                <fa icon="pen" class="mr-2" /> Editar
                            </b-button>
                            <template v-else>
                                <b-button variant="outline-mary-beleza" size="sm" @click="EditSchedule" class="mr-2">
                                    <fa icon="ban" class="mr-2" /> Cancelar
                                </b-button>
                                <b-button variant="mary-beleza" size="sm" @click="UpdateSchedule">
                                    <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                                </b-button>
                            </template>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <b-overlay v-if="schedule.schedule_rating || schedule.schedule_rating_worker" :show="loadingData" rounded="sm">
                <panel title="Avaliações" icon='star' icon-lib="fas">
                    <b-row>
                        <b-col sm="12" md="6" lg="6">
                            <b-card class="mx-1 p-1 h-100 shadow">
                                <span v-if="schedule.schedule_rating_worker">
                                    <h5 class="d-flex justify-content-center">Avaliação do cliente</h5>
                                    <b-row>
                                        <b-col>
                                            <rating-emoji :value="schedule.schedule_rating_worker.evaluation" readonly only-actived emojiWidth="3rem" emojiHeight="3rem"/>          
                                        </b-col>
                                    </b-row>
                                </span>
                                <span v-else>
                                    <p class="d-flex justify-content-center align-items-center h-100 font-weight-bold">Não possui avaliação do cliente...</p>
                                </span>
                            </b-card>
                        </b-col>
                        <b-col sm="12" md="6" lg="6">
                            <b-card class="mx-1 p-1 h-100 shadow">
                                <span v-if="schedule.schedule_rating">
                                    <h5 class="d-flex justify-content-center">Avaliação do Profissional</h5>
                                    <b-row v-if="schedule.schedule_rating.obs">
                                        <b-col>
                                            <rating-emoji v-b-tooltip="schedule.schedule_rating.obs" :value="schedule.schedule_rating.evaluation" readonly only-actived emojiWidth="3rem" emojiHeight="3rem"/>          
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="schedule.schedule_rating.rating_values">
                                        <b-col>
                                            <label>Elogios</label>
                                            <p>
                                                <b-badge variant="mary-beleza" class="mr-1" pill v-for="(item, key) in schedule.schedule_rating.rating_values" :key="key">{{ item.name }}</b-badge>
                                            </p>
                                        </b-col>
                                    </b-row>
                                </span>
                                <span v-else>
                                    <p class="d-flex justify-content-center align-items-center h-100 font-weight-bold">Não possui avaliação do profissional...</p>
                                </span>
                            </b-card>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm">
                <div>
                    <b-row>
                        <b-col sm="12" md="12" lg="6" class="form-group mt-5">
                            <b-card style="height:100%;">
                                <template #header>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="mb-0 font-weight-bold">Cliente</h6>
                                    </div>
                                </template>
                                <div v-if="schedule.customer">
                                    <b-row class="justify-content-center">
                                        <b-avatar size="140" class="bg-mary-beleza-gradient" style="margin-top:-120px">
                                            <b-avatar :src="schedule.customer.person.image" size="96%">
                                            </b-avatar>
                                        </b-avatar>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="12" md="12" lg="12" class="form-group">
                                            <label>Nome:</label>
                                            <div class="link-open" @click="$router.push({ path: '/clientes/cadastro/' + schedule.customer_id })">
                                                {{ schedule.customer.person.name }}
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col sm="12" md="12" lg="12">
                                            <b-icon icon="at" variant="danger" scale="1.2" class="mr-1"></b-icon>
                                            <label>E-mail:</label>
                                            <div>
                                                <p v-if="!schedule.customer.person.email">
                                                    <strong class="text-danger">-</strong>
                                                </p>
                                                <p v-else>{{ schedule.customer.person.email }}</p>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                    <b-row>
                                        <b-col sm="12" md="12" lg="12" class="form-group">
                                            <img :src="img_service_phone" width="18" :id="'worker-phone'" class="mr-2" />
                                            <label>Telefones:</label>
                                            <div v-if="schedule.customer.person.phones.length > 0">
                                                <b-row>
                                                   <b-col sm="4" md="4" lg="6" xl="4" v-for="(phone, index) in schedule.customer.person.phones" :key="index" class="mb-1">
                                                        {{ phone.phone_number | formatPhones }}
                                                        <b-badge v-if="phone.is_personal" variant="primary" class="mr-1" style="font-size:11px;">Pessoal</b-badge>
                                                        <b-badge v-if="phone.is_whatsapp" variant="success" style="font-size:11px;">WhatsApp</b-badge>
                                                   </b-col>
                                                </b-row>
                                            </div>
                                            <div v-else>
                                               <strong class="text-danger">-</strong>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else>
                                    <h1 class="text-center" style="margin-top:30%;">Sem cliente</h1>
                                </div>
                            </b-card>
                        </b-col>

                        <b-col sm="12" md="12" lg="6" class="form-group mt-5">
                            <b-card style="height:100%;">
                                <template #header>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="mb-0 font-weight-bold">Profissional</h6>
                                        <div v-if="schedule.worker && has_permission('services-edit') && ![constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER, constants.STATUS_CANCELED_MARY_BELEZA].includes(schedule.status)">
                                            <span>
                                                <b-icon id="button_punishment_worker" class="zoom-1-2 mr-2" variant="mary-beleza" scale="1.4" icon="exclamation-octagon" @click="Punishment"></b-icon>
                                                <b-tooltip target="button_punishment_worker" placement="top">
                                                    Penalidade
                                                </b-tooltip>
                                            </span>
                                            <span>
                                                <b-icon id="button_alter_worker" class="zoom-1-2 mr-2 ml-2" variant="mary-beleza" scale="1.4" icon="arrow-left-right" @click="AlterWorker"></b-icon>
                                                <b-tooltip target="button_alter_worker" placement="top">
                                                    Alterar profissional
                                                </b-tooltip>
                                            </span>
                                            <span>
                                                <b-icon id="button_remove_worker" class="zoom-1-2 ml-2" variant="mary-beleza" scale="2.1" icon="x" @click="RemoveScheduleWorker(schedule.worker)"></b-icon>
                                                <b-tooltip target="button_remove_worker" placement="top">
                                                    Remover profissional
                                                </b-tooltip>
                                            </span>
                                        </div>
                                        <div v-else-if="schedule.worker">
                                            <div>
                                                <b-icon id="button_permission_worker" variant="secondary" scale="1.4" icon="arrow-left-right"></b-icon>
                                                <b-tooltip target="button_permission_worker" placement="top">
                                                    Não possível alterar profissional
                                                </b-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="schedule.worker">
                                    <b-row class="justify-content-center">
                                        <b-avatar size="140" class="bg-mary-beleza-gradient" style="margin-top:-120px">
                                            <b-avatar :src="schedule.worker.person.image" size="96%">
                                            </b-avatar>
                                        </b-avatar>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="12" md="12" lg="12" class="form-group">
                                            <label>Nome:</label>
                                            <div class="link-open" @click="$router.push({ path: '/profissionais/historico/' + schedule.worker_id })">
                                                {{ schedule.worker.person.name }}
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col sm="12" md="12" lg="12">
                                            <b-icon icon="at" variant="danger" scale="1.2" class="mr-1"></b-icon>
                                            <label>E-mail:</label>
                                            <div>
                                                <p v-if="!schedule.worker.person.email">
                                                    <strong class="text-danger">-</strong>
                                                </p>
                                                <p v-else>{{ schedule.worker.person.email }}</p>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                    <b-row>
                                        <b-col sm="12" md="12" lg="12" class="form-group">
                                            <img :src="img_service_phone" width="18" :id="'worker-phone'" class="mr-2" />
                                            <label>Telefones:</label>
                                            <div v-if="schedule.worker.person.phones.length > 0">
                                                <b-row>
                                                   <b-col sm="4" md="4" lg="6" xl="4" v-for="(phone, index) in schedule.worker.person.phones" :key="index" class="mb-1">
                                                        {{ phone.phone_number | formatPhones }}
                                                        <b-badge v-if="phone.is_personal" variant="primary" class="mr-1" style="font-size:11px;">Pessoal</b-badge>
                                                        <b-badge v-if="phone.is_whatsapp" variant="success" style="font-size:11px;">WhatsApp</b-badge>
                                                   </b-col>
                                                </b-row>
                                            </div>
                                            <div v-else>
                                                <strong class="text-danger">-</strong>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else class="text-center">
                                    <div v-if="schedule.preference_worker_id" class="alert alert-warning">
                                        Atenção serviço preferencial para o(a) profissional <strong>{{schedule.preference_worker.person.name}}</strong>
                                    </div>
                                    <h2 style="margin-top:10%;" class="font-italic">Sem profissional</h2>
                                    <div v-if="has_permission('services-edit') && ![constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER, constants.STATUS_CANCELED_MARY_BELEZA].includes(schedule.status)">
                                        <b-icon icon="plus-circle-fill" scale="4" variant="mary-beleza" id="add_worker" class="m-5 zoom-1-2" @click="AlterWorker"></b-icon>
                                        <b-tooltip target="add_worker" placement="top">
                                            Adicionar profissional
                                        </b-tooltip>
                                    </div>
                                    <div v-else>
                                        <b-icon icon="plus-circle-fill" scale="4" variant="secondary" class="m-5" id="permission_worker"></b-icon>
                                        <b-tooltip target="permission_worker" placement="top">
                                            Não é possível adicionar profissional!
                                        </b-tooltip>
                                    </div>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>

            <b-overlay :show="loadingData">
                <schedule-customer-preferences :edit="edit.preferences" @edit="EditPreferences()" @save="UpdateSchedulePreferences()" @cancel="EditPreferences()"></schedule-customer-preferences>
            </b-overlay>

            <b-modal id="modal-alter-worker-2" hide-footer centered size="lg" :title="schedule.worker ? 'Alterar profissional' : 'Adicionar profissional'" ref="modal-alter-worker-2" no-stacking>
                <rich-table class="m-4" ref="workerRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers" :loading="loading_reachtable" :cols="table_cols">
                    <template v-if="items && items.length > 0">
                        <tr v-for="(item, key) in items" :key="key" @click="selected = item.id">
                            <td width="40%">{{item.name}}</td>
                            <td width="40%">{{item.phone | formatPhones}}</td>
                            <td width="15%" class="text-center">
                                <b-button v-if="item.phone" v-b-tooltip="'Enviar Mensagem de Contato'" @click="WhatsAppLink(item.phone, item.name)" size="sm" variant="success"><fa :icon="['fab', 'whatsapp']" size="lg" /></b-button>
                            </td>
                            <td width="5%" class="text-center">
                                <b-iconstack v-if="selected === item.id" font-scale="1.2">
                                    <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                    <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                </b-iconstack>
                                <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="table_cols.length" class="text-center py-3">Nenhuma profissional livre no momento!</td>
                        </tr>
                    </template>
                </rich-table>
                <div>
                    <b-button  v-if="!schedule.service_date_end" variant="outline-mary-beleza" size="md" class="float-left" v-b-modal.modal-alter-worker-1>
                        Voltar
                    </b-button>
                    <b-button v-if="selected != null" variant="primary" size="md" class="float-right" @click="ConfirmWorker">
                        Confirmar
                    </b-button>
                </div>
            </b-modal>
            <b-modal id="modal-alter-worker-1" hide-footer centered size="lg" title="Selecione um horário" ref="modal-alter-worker-1" ok-only no-stacking>
                <b-row v-if="!schedule.service_date_end">
                    <b-col sm="12" md="12" lg="12" class="m-0 form-group">
                        <b-row class="justify-content-center">
                            <h5 class="text-muted my-4" v-if="schedule.hours.length == 0">
                                Agendamento não possui nenhum horário!
                            </h5>
                            <b-col v-else class="mb-2 p-2" sm="12" md="12" lg="4" v-for="(item, index) in schedule.hours" :key="index">
                                <b-card :class="item.id == selected_hour_id ? 'hours border-hours bg-secondary' : 'hours border-hours'" @click="SelectedHour(item.id)">
                                    <template>
                                        <img :src="img_time" width="20" v-b-tooltip="'Horário'" class="mr-2"/>
                                        <span>
                                            {{ ListDateTimeConcat(schedule.service_date, item.hour, schedule.service.duration) }}
                                        </span>                  
                                    </template>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row v-if="selected_hour_id != null">
                            <b-col class="text-right">
                                <b-button variant="mary-beleza" @click="ListWorkerSelectHour">Continuar</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-modal>

            <b-overlay :show="loadingData || search_zipcode" rounded="sm" v-if="schedule.address">
                <panel title="Endereço" icon='map-marked-alt' icon-lib="fa">
                    <b-row>
                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label class="mr-1">CEP:</label>
                            <template v-if="!edit.address">
                                <p v-if="!schedule.address.zipcode">
                                    -
                                </p>
                                <p v-else>{{ schedule.address.zipcode | formatCep }}</p>
                            </template>
                            <template v-else>
                                <the-mask :mask="'#####-###'" class="form-control" v-model="zipcode" placeholder="CEP" @blur.native="SearchZipcode(schedule.address)" />
                                <b-form-invalid-feedback :state="!errors.zipcode">
                                    <div v-for="error in errors.zipcode" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label class="mr-1">Rua:</label>
                            <template v-if="!edit.address">
                                <p v-if="!schedule.address.street">
                                    -
                                </p>
                                <p v-else>{{ schedule.address.street }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="street" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.street">
                                    <div v-for="error in errors.street" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </b-col>

                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label class="mr-1">Número:</label>
                            <template v-if="!edit.address">
                                <p v-if="!schedule.address.number">
                                    -
                                </p>
                                <p v-else>{{ schedule.address.number }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="number" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.number">
                                    <div v-for="error in errors.number" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="5" lg="5" class="form-group">
                            <label>Bairro:</label>
                            <template v-if="!edit.address">
                                <p v-if="!schedule.address.district">
                                    -
                                </p>
                                <p v-else>{{ schedule.address.district }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="district" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.district">
                                    <div v-for="error in errors.district" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </b-col>
                        
                        <b-col sm="12" md="4" lg="4" class="form-group">
                            <label>Complemento:</label>
                            <template v-if="!edit.address">
                                <p v-if="!schedule.address.complement">
                                    -
                                </p>
                                <p v-else>{{ schedule.address.complement }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="complement"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.complement">
                                    <div v-for="error in errors.complement" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </b-col>
                        <b-col sm="12" md="3" lg="3">
                            <div role="group">
                                <label>Ponto de referência:</label>
                                <template v-if="!edit.address">
                                    <p v-if="!schedule.address.reference">
                                        -
                                    </p>
                                    <p v-else>{{schedule.address.reference}}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="reference"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors.reference">
                                        <div v-for="error in errors.reference" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="form-group">
                        <b-col sm="12" md="4" lg="4">
                            <div role="group">
                                <label>Zona:</label>
                                <template v-if="!edit.address">
                                    <p v-if="!zone_name(schedule.address.zone)">
                                        -
                                    </p>
                                    <p v-else>{{ zone_name(schedule.address.zone) }}</p>
                                </template>
                                <template v-else>
                                    <b-form-select v-model="zone" :options="zone_options">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="!errors.zone">
                                        <div v-for="error in errors.zone" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                        <b-col sm="12" md="4" lg="4">
                            <div role="group">
                                <label>Estado:</label>
                                <template v-if="! edit.address">
                                    <p>{{schedule.address.city.state.name}}</p>
                                </template>
                                <template v-else>
                                    <b-form-select v-model="state_selected" :options="states" @input="UpdateState($event)">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </template>
                            </div>
                        </b-col>
                        <b-col v-if="schedule.address.city.state || !edit.address" sm="12" md="4" lg="4">
                            <div role="group">
                                <label>Cidade:</label>
                                <template v-if="! edit.address">
                                    <p>{{schedule.address.city.name}}</p>
                                </template>
                                <template v-else>
                                    <b-form-select v-model="city_id" :options="cities">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="!errors.city_id">
                                        <div v-for="error in errors.city_id" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="has_permission('services-edit')">
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <b-button v-if="!edit.address" variant="mary-beleza" size="sm" @click="EditAddress">
                                <fa icon="pen" class="mr-2" /> Editar
                            </b-button>
                            <template v-else>
                                <b-button variant="outline-mary-beleza" size="sm" @click="EditAddress" class="mr-2">
                                    <fa icon="ban" class="mr-2" /> Cancelar
                                </b-button>
                                <b-button variant="mary-beleza" size="sm" @click="UpdateScheduleAddress">
                                    <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                                </b-button>
                            </template>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <panel title="Pagamento" icon='credit-card' icon-lib="fa" v-if="schedule && schedule.customer && !loadingData">
                <b-row class="form-group">
                    <b-col>
                        <b-form-group label="Forma de Pagamento">
                            <select :disabled="!edit.prepaidcard" class="form-control" v-model="payment_type">
                                <option :value="0">Cartão de Crédito</option>
                                <option :value="1">PIX</option>
                            </select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="6" md="4" lg="4" v-for="(prepaidcard, key) in schedule.customer.person.prepaidcards" :key="key" :style="{ opacity: !edit.prepaidcard ? 0.5 : 1 }">
                        <b-card @click="() => { if(edit.prepaidcard) schedule.customer_prepaidcard_id = prepaidcard.id }">
                            <div>
                                <b-iconstack v-if="schedule.customer_prepaidcard_id == prepaidcard.id" font-scale="1.2">
                                    <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                    <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                </b-iconstack>
                                <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                            </div>
                            <div class="justify-content-between d-flex">
                                <div>
                                    **** **** **** {{prepaidcard.number}}
                                </div>
                                <div>
                                    {{prepaidcard.due_date | moment("MM/YYYY")}}
                                </div>
                            </div>
                            <p class="font-weight-bold m-0">{{prepaidcard.flag | cardFlag}}</p>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="!edit.prepaidcard && transaction_customer" class="form-group">
                    <b-col>
                        <template v-if="transaction_customer.status == 1">
                            <div class="alert alert-warning">
                                <i class="fa fa-clock-o"></i>
                                AGUARDANDO PAGAMENTO
                            </div>
                            <template v-if="transaction_customer.gateway_id && payment_type == 1">
                                <b-button variant="primary" type="button" v-b-modal.PixKey>Visualizar chave PIX Copia e Cola</b-button>
                                <b-modal size="lg" ok-only ok-title="Fechar" id="PixKey" title="PIX Copia e Cola" @show="LoadPixCode()">
                                    <b-overlay :show="loadingPixKey" rounded="sm">
                                        <div class="row" v-if="pixData">
                                            <div class="col-md-12">
                                                <table style="width: 100%; font-size: 12px;">
                                                    <tr>
                                                        <td>
                                                            <ol>
                                                                <li>Abra o aplicativo do seu celular que utilizará para o pagamento.</li>
                                                                <li>Selecione a chave pix do tipo COPIA E COLA</li>
                                                                <li>Copie o código abaixo</li>
                                                                <li>Cole no Aplicativo utilizado</li>
                                                                <li>Finalize o pagamento</li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <small>Código</small>
                                                            <textarea ref="pixData" class="form-control form-group" readonly v-model="pixData.payload"></textarea>
                                                            <p class="text-right" v-if="pixData.expirationDate">Valido até: {{pixData.expirationDate | moment("DD/MM/YYYY HH:mm")}}</p>
                                                            <b-button type="button" @click="CopyPixData" block variant="mary-beleza">Copiar</b-button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </b-overlay>
                                </b-modal>
                            </template>
                        </template>
                        <template v-else-if="transaction_customer.status == 4">
                            <div class="alert alert-warning">
                                <i class="fa fa-calendar-times-o"></i>
                                VENCIDO
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 5">
                            <div class="alert alert-success">
                                <i class="fa fa-check"></i>
                                <span>CONFIRMADO</span>
                                <div v-if="transaction_customer.confirmed_date"><i class="fa fa-calendar-o"></i> {{transaction_customer.payment_date | moment("DD/MM/YYYY")}}</div>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 2">
                            <div class="alert alert-success">
                                <i class="fa fa-check"></i>
                                <span>RECEBIDO</span>
                                <div v-if="transaction_customer.confirmed_date"><i class="fa fa-calendar-o"></i> {{transaction_customer.payment_date | moment("DD/MM/YYYY")}}</div>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 3">
                            <div class="alert alert-success">
                                <i class="fa fa-money"></i>
                                <span>RECEBIDO EM DINHEIRO</span>
                                <div v-if="transaction_customer.confirmed_date"><i class="fa fa-calendar-o"></i> {{transaction_customer.payment_date | moment("DD/MM/YYYY")}}</div>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 8">
                            <div class="alert alert-warning">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO REQUISITADA</span>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 9">
                            <div class="alert alert-warning">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>EM DISPUTA PARA DEVOLUÇÃO</span>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 10">
                            <div class="alert alert-info">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO NEGADA AGUARDANDO SALDO</span>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 11">
                            <div class="alert alert-danger">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO REALIZADA</span>
                            </div>
                        </template>
                        <template v-else-if="transaction_customer.status == 7">
                            <div class="alert alert-danger">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>FALHA</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="alert alert-danger">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>STATUS DESCONHECIDO</span>
                            </div>
                        </template>
                    </b-col>
                </b-row>
                <b-row v-if="has_permission('services-edit')">
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <b-button v-if="!edit.prepaidcard" variant="mary-beleza" size="sm" @click="EditPrepaidcard()">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="EditPrepaidcard()">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button variant="mary-beleza" size="sm" @click="UpdateSchedulePrepaidcard()">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </panel>

            <b-modal id="addcheckin" title="Realizar Checkin Manual" @ok="SaveCheckin()" ok-variant="mary-beleza" ok-title="Confirmar" cancel-variant="outline-mary-beleza" cancel-title="Cancelar">
                <template v-if="addcheckin">
                    <b-row>
                        <b-col>
                            <b-form-group 
                                label="Foto para o checkin" 
                                label-size="md"
                                label-class="font-weight-bold pt-0">
                                <input type="file" accept="image/*" @change="(e) => UpdateCheckinPhoto(e)" style="font-size: 12px;">
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <b-overlay v-if="schedule.worker_id" :show="loadingData || (addcheckin && addcheckin.loading)" rounded="sm">
                <panel title="Check-ins" icon='check-double' icon-lib="fa">
                    <div class="container">
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="main-timeline8">
                                    <div class="timeline" v-for="(item, key) in schedule_checkins" :key="key">
                                        <div>
                                            <span class="timeline-icon"></span>
                                            <span class="year"><strong>{{ CheckinStatus(item.type) }} {{ item.system ? '- Sistema' : '' }}</strong> <a target="_blank" v-if="item.type == 3 && schedule_receipt && schedule_receipt.url" :href="schedule_receipt.url" class='btn btn-primary btn-sm' style='font-weight: bold; color: #fff; text-decoration: underline;'>Detalhes ASAAS</a></span>
                                            <div class="timeline-content" :style="([constants.TYPE_I_GOT_TO_THE_SERVICE, constants.TYPE_STERILIZATION_OF_MATERIALS, constants.TYPE_COMPLETED_SERVICE].includes(item.type) ? 'background-size: cover; height:550px; background-image: url('+(item.photo ? item.photo : not_image)+');' : '')">
                                                <strong class="rounded p-1" style="margin-top:-5%; background: rgba(255, 255, 255, 0.5)">{{ item.created_at | moment("DD/MM/YYYY HH:mm") }}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="(!addcheckin || !addcheckin.loading) && schedule.next_checkin_type !== '' && schedule.next_checkin_type !== null && schedule.next_checkin_type !== undefined">
                            <div class="col-md-12 text-center">
                                <b-button type="button" variant="mary-beleza" @click="NewCheckin()" v-b-modal.addcheckin>
                                    REALIZAR CHECKIN - {{ CheckinStatus(schedule.next_checkin_type) }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </panel>
            </b-overlay>

            <b-overlay v-if="schedule.schedule_history.length > 0" :show="loadingData" rounded="sm">
                <panel title="Histórico" icon='check-double' icon-lib="fa">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="main-timeline8">
                                    <div class="timeline" v-for="(item, key) in schedule_history" :key="key">
                                        <div>
                                            <span class="timeline-icon"></span>
                                            <span class="year"><strong>{{ item.updated_at | moment("DD/MM/YYYY HH:mm") }}</strong></span>
                                            <div class="timeline-content border-history">
                                                <p v-if="item.worker">Profissional:
                                                    <strong class="rounded p-1 link-open" @click="$router.push({ path: '/profissionais/historico/' + item.worker_id })">{{ item.worker.person.name }}</strong>
                                                </p>
                                                <p v-if="item.customer_value">Valor Cliente: 
                                                    <strong class="rounded p-1">{{ item.customer_value | currency}}</strong>
                                                </p>
                                                <p v-if="item.worker_value">Valor Profissional: 
                                                    <strong class="rounded p-1">{{ item.worker_value | currency}}</strong> 
                                                </p>
                                                <p>Tolerancia: 
                                                    <strong class="rounded p-1">{{ item.tolerance }}</strong>
                                                </p>
                                                <p v-if="item.gender_preference">Genero de Preferencia: 
                                                    <strong class="rounded p-1">{{ item.gender_preference == 'male' ? 'Masculino' : 'Feminino' }}</strong>
                                                </p>
                                                <p v-if="item.obs">Observação: 
                                                    <strong class="rounded p-1">{{ item.obs }}</strong>
                                                </p>
                                                <p>Status: 
                                                    <b-badge pill v-schedule-status="item.status"></b-badge>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm" v-if="schedule.schedule_problems.length > 0">
                <panel title="Problemas" icon='exclamation-triangle' icon-lib="fa">
                    <b-row>
                        <b-col class="mb-4" v-for="(item, index) in schedule.schedule_problems" :key="index" sm="12" md="12" lg="6">
                            <b-card class="mb-2 shadow-lg h-100">
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="justify-content-between d-flex">
                                        <div>
                                            <label>Tipo: </label>
                                            <span class="ml-2 text-muted">{{ ProblemsType(item.type) }}</span>
                                        </div>
                                        <div>
                                            <b-button @click="ResolvedProblem(index)" variant="primary" size="sm" v-if="item.status">Dar continuidade</b-button>
                                        </div>
                                    </b-col>
                                    <b-col sm="8" md="8" lg="8">
                                        <label>Informado em: </label>
                                        <span class="ml-2 text-muted">{{ item.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
                                    </b-col>
                                    <b-col sm="4" md="4" lg="4">
                                        <label>Resolvido: </label>
                                        <span class="ml-2 text-muted" v-if="!item.status">SIM</span>
                                        <span class="ml-2 text-danger" v-else>NÃO</span>
                                    </b-col>
                                    <b-col sm="10" md="10" lg="10">
                                        <label class="mr-2">Observação: </label>
                                        <p>{{ item.obs }}</p>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm" v-if="[constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER, constants.STATUS_CANCELED_MARY_BELEZA].includes(schedule.status)">
                <panel title="Cancelamento" icon='ban' icon-lib="fa">
                    <b-row>
                        <b-col sm="12" md="12" lg="3">
                            <label class="mr-2">Por parte do(a):</label>
                            <b-badge pill v-schedule-status="schedule.status"></b-badge>
                        </b-col>
                        <b-col sm="12" md="12" lg="9">
                            <label>Motivo:</label>
                            <span class="ml-2 text-muted">{{ schedule.canceled_info }}</span>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import ScheduleCustomerPreferences from "@/components/ScheduleCustomerPreferences"
import UtilBar from '@/components/UtilBar'
import RichTable from '@/components/RichTable'
import _clone from 'lodash/clone'
import _cloneDeep from 'lodash/cloneDeep'
import _merge from 'lodash/merge'
import _orderBy from 'lodash/orderBy'
import Constants from '@/constants'
import ImageLoader from '@/mixins/ImageLoader'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Http from '@/api/http';
import CancelSchedule from './cancel'

export default {
    mixins: [ ImageLoader ],

    data() {
        return {
            loadingPixKey: false,
            pixData: null,
            state_selected: '',
            states: [],
            cities: [],
            loadingData: false,
            search_zipcode: false,
            addcheckin: null,
            edit: {
                transaction_customer: false,
                transaction_worker: false,
                service: false,
                preferences: false,
                address: false,
                prepaidcard: false
            },
            items: [],
            table_numbers: {
                size: 1,
                total: 0,
                found: 0,
                footer: null
            },
            filters: {
                status: 6,
                hability: null,
                service_date: null,
                service_date_end: null,
                limit: 10,
                offset: 0,
                search: null,
                sort_column: "name",
                sort_order: "asc",
                schedule_id: null,
            },
            loading_reachtable: true,
            selected: null,
            selected_hour_id: null,
            status_options: [
                { value: Constants.STATUS_OPPORTUNITY, text: "Oportunidades" },
                { value: Constants.STATUS_ACCEPT, text: "Aceitos" },
                { value: Constants.STATUS_IN_PREPARATION, text: "Em preparação" },
                { value: Constants.STATUS_ARRIVED, text: "Profissional Está no Local" },
                { value: Constants.STATUS_FINISHED, text: "Finalizados" },
                { value: Constants.STATUS_CANCELED_WORKER, text: "Cancelados Profissional" },
                { value: Constants.STATUS_CANCELED_CUSTOMER, text: "Cancelados Cliente" },
                { value: Constants.STATUS_CANCELED_MARY_BELEZA, text: "Cancelados MB" },
                { value: Constants.STATUS_STERILIZATION_OF_MATERIALS, text: "Esterilização" },
                { value: Constants.STATUS_PAYMENT_IN_PROCESS, text: "Pagamento em processamento" },
                { value: Constants.STATUS_LOST, text: "Oportunidade Perdida" },
                { value: Constants.STATUS_QUIT, text: "Desistiu" },
                { value: Constants.STATUS_EN_ROUTE, text: "Profissional a Caminho" }
            ],
            transactions_status_options: [
                { value: Constants.STATUS_DRAFT, text: 'Previsto' },
                { value: Constants.STATUS_UNKNOW, text: "Desconhecido" },
                { value: Constants.STATUS_PENDING, text: "Pendente" },
                { value: Constants.STATUS_RECEIVED, text: "Recebido" },
                { value: Constants.STATUS_RECEIVED_IN_CASH, text: "Recebido em dinheiro" },
                { value: Constants.STATUS_OVERDUE, text: "Vencido" },
                { value: Constants.STATUS_CONFIRMED, text: "Confirmação" },
                { value: Constants.STATUS_PROCESSING, text: "processamento" },
                { value: Constants.STATUS_FAIL, text: "Falha" },
            ],
            payment_type_options: [
                { value: Constants.PAYMENT_TYPE_MONEY, text: "Dinheiro" },
                { value: Constants.PAYMENT_TYPE_BOLETO, text: "Boleto Bancario" },
                { value: Constants.PAYMENT_TYPE_CREDITCARD, text: "Cartão de Crédito" },
                { value: Constants.PAYMENT_TYPE_DEBITCARD, text: "Cartão de Debito" },
                { value: Constants.PAYMENT_TYPE_PREDATED_CHECK, text: "Cheque pré-datado" },
                { value: Constants.PAYMENT_TYPE_DEPOSIT_CHECKING_ACCOUNT, text: "Deposito Bancario" },
                { value: Constants.PAYMENT_TYPE_PAYCHECK, text: "À Vista" },
            ],
            options_canceled_type: [
                { id: Constants.STATUS_CANCELED_CUSTOMER, text: 'Cliente' },
                { id: Constants.STATUS_CANCELED_WORKER, text: 'Profissional' },
                { id: Constants.STATUS_CANCELED_MARY_BELEZA, text: 'Mary Beleza' },
            ],
            options_problems_type: [
                { value: Constants.TYPE_WORKER_DELAY, text: 'Atraso da Profissional' },
                { value: Constants.TYPE_CUSTOMER_NOT_FOUND, text: 'Cliente não está' },
                { value: Constants.TYPE_ADDRESS_NOT_FOUND, text: 'Endereço não encontrado' },
                { value: Constants.TYPE_OTHERS, text: 'Outros' },
                { value: Constants.TYPE_CUSTOMER_DIFFERENT_SERVICE, text: 'Cliente quer serviço diferente do contratado' },
                { value: Constants.TYPE_CUSTOMER_NOT_SERVICE, text: 'Cliente não quer o serviço' },
                { value: Constants.TYPE_LOCATION_NOT_CONDITIONS, text: 'Local não apresenta condições de realização do trabalho' },
            ],
            zone_options: [
                { text: 'Centro', value: 0 },
                { text: 'Norte', value: 1 },
                { text: 'Sul', value: 2 }, 
                { text: 'Leste', value: 3 },
                { text: 'Oeste', value: 4 }
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            modelConfigTime: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm:ss',
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            },
            backup: null,
            checkins_status_options: [
                { value: Constants.TYPE_IN_PREPARATION, text: 'EM PREPARAÇÃO' },
                { value: Constants.TYPE_I_GOT_TO_THE_SERVICE, text: 'PROFISSIONAL ESTÁ NO LOCAL' },
                { value: Constants.TYPE_STERILIZATION_OF_MATERIALS, text: 'ESTERILIZAÇÃO DOS MATERIAIS' },
                { value: Constants.TYPE_COMPLETED_SERVICE, text: 'SERVIÇO CONCLUÍDO' },
                { value: Constants.TYPE_ON_THE_WAY, text: 'PROFISSIONAL A CAMINHO' }
            ],
            loading_transaction: false,
        }
    },

    components: {
        Panel,
        UtilBar,
        RichTable,
        CancelSchedule,
        ScheduleCustomerPreferences
    },

    mounted() {
        this.$store.commit('service/SET_CLEAN_SCHEDULE');
        this.$store.dispatch('states/getStates').then(data => {
            data.forEach(state => {
                this.states = [...this.states, {
                    text: state.name,
                    value: state.id
                }]
            });
        });
        this.LoadData();
    },
    
    methods: {
        CopyPixData() {
            const textarea = this.$refs.pixData;
            textarea.select();
            document.execCommand('copy');
        },
        LoadPixCode() {
            this.loadingPixKey = true;
            this.$store.dispatch("service/pix", this.$route.params.id).then((response) => {
                this.pixData = response.data;
				this.loadingPixKey = false;
			}).catch(() => {
				this.loadingPixKey = false;
			});
        },
        UpdateCheckinPhoto(e) {
            this.addcheckin.photo = e && e.target && e.target.files && e.target.files[0] ? e.target.files[0] : null;
        },
        SaveCheckin() {
            this.addcheckin.loading = true;
            this.$store.dispatch('service/checkin', this.addcheckin).then(() => {
                this.addcheckin.loading = false;
                this.$swal({ title: "Checkin realizado com sucesso.", icon: "success" });
                this.LoadData();
            }).catch(() => {
                this.addcheckin.loading = false;
                this.LoadData();
            });
        },
        NewCheckin() {
            this.addcheckin = {
                other_payment_type: false,
                worker_id: this.schedule.worker_id,
                schedule_id: this.schedule.id,
                type: this.schedule.next_checkin_type,
                photo: null,
                loading: false
            };
        },
        moment(...args){
            return moment(...args);
        },

        GetTransactionExpense() {
            return this.schedule.transactions.find(t => t.type == Constants.TYPE_EXPENSE);
        },

        GetTransactionRecipe() {
            return this.schedule.transactions.find(t => t.type == Constants.TYPE_RECIPE);
        },

        GetTransactionObs(transaction) {
            return transaction.status_obs ? transaction.status_obs : '';
        },

        IsTransactionPending(transaction) {
            return [Constants.STATUS_PROCESSING, Constants.STATUS_FAIL, Constants.STATUS_DRAFT, Constants.STATUS_PENDING, Constants.STATUS_OVERDUE].includes(transaction.status);
        },

        TransactionRecipeFail() {
            if(!this.schedule.transactions){
                return false;
            }
            var transaction = this.schedule.transactions.filter(t => t.type == Constants.TYPE_RECIPE)[0];
            if (transaction) {
                return transaction.status == Constants.STATUS_FAIL ? true : false;
            }else{
                return false;
            }
        },

        CheckinStatus(value){
            let status = this.checkins_status_options.find(status => status.value === value);
            return status?.text
        },

        ProblemsType(value){
            let type = this.options_problems_type.find(type => type.value === value);
            return type?.text
        },

        DateTimeConcat(date, time){
            var dateTime = moment(date).format("YYYY-MM-DD")+' '+time;
            return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        },

        ListDateTimeConcat(date, time, duration){
            var dateTime = moment(date).format("YYYY-MM-DD")+' '+time;
            return moment(dateTime).format("HH:mm") +'h às '+ moment(dateTime).add(duration, "minutes").format("HH:mm")+'h';
        },

        EditSchedule(){
            if(this.edit.address){
                this.EditAddress()
            }
            this.edit.service = !this.edit.service;
            if(this.edit.service){
                this.backup = _cloneDeep(this.schedule)
            }else{
                this.schedule = this.backup
                this.schedule.hours = this.backup.hours
            }
        },

        EditPreferences() {
            if(this.edit.address){
                this.EditAddress()
            }
            this.edit.preferences = !this.edit.preferences;
            if(this.edit.preferences){
                this.backup = _cloneDeep(this.schedule)
            }else{
                this.schedule = this.backup
            }
        },

        EditAddress(){
            if(this.edit.service){
                this.EditSchedule()
            }
            this.state_selected = this.schedule.address.city.state.id
            this.getCities(this.state_selected);
            this.edit.address = !this.edit.address;
            if(this.edit.address){
                this.backup = _cloneDeep(this.schedule.address)
            }else{
                this.schedule.address = this.backup
            }
        },

        EditPrepaidcard(){
            if(this.edit.service){
                this.EditSchedule()
            }
            
            this.edit.prepaidcard = !this.edit.prepaidcard;
            if(this.edit.prepaidcard){
                this.backup = this.schedule.customer_prepaidcard_id;
            }else{
                this.schedule.customer_prepaidcard_id = this.backup
            }
        },

        UpdateSchedulePrepaidcard() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterar`,
                html: `Deseja realmente alterar o pagamento do serviço?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/updateSchedulePaymentType', { customer_prepaidcard_id: this.schedule.customer_prepaidcard_id, payment_type: this.schedule.payment_type }).then(data => {
                        this.edit.prepaidcard = false;
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        getCities(state_id) {
            this.$store.dispatch('cities/getCities', state_id).then(data => {
                this.cities = [];
                data.forEach(citie => {
                    this.cities = [...this.cities, {
                        text: citie.name,
                        value: citie.id
                    }]
                });
            });

        },
        
        SelectedHour(hour_id){
            this.selected_hour_id == hour_id ?
                this.selected_hour_id = null
            :
                this.selected_hour_id = hour_id
        },

        ConfirmWorker(){
            if(this.selected_hour_id != null || this.schedule.service_date_end){
                this.schedule.worker_id ?
                    this.ScheduleUpdateWorker()
                :
                    this.ScheduleAddWorker() 
            } 
        },

        ListWorkerSelectHour(){
            let item = this.schedule.hours.find(hour => hour.id == this.selected_hour_id);
            this.filters.service_date = this.DateTimeConcat(this.schedule.service_date, item.hour)
            this.$refs['modal-alter-worker-2'].show()
        },

        UpdateHour(value, index) {
            var data = {
                value: moment(value).format("HH:mm:ss"),
                index
            }
            this.$store.commit('service/SET_HOUR', data)
        },

        LoadData() {
            this.loadingData = true;
            this.$store.dispatch('service/getSchedule', this.$route.params.id).then(() => {
                this.loadingData = false;
            });
            this.$store.dispatch('service/getPunishmentRules')
        },

        ChangeTableConfig(value) {
            this.table_config = value
            this.WorkersLoadData();
        },

        WorkersLoadData() {
            this.loading_reachtable = true;
            this.selected = null
            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('service/getWorkersNotSchedule', params).then(data => {
                this.loading_reachtable = false;

                this.items = data.items ? data.items : [];

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;

            });
        },

        AlterWorker(){
            if(this.edit.service){
                this.EditSchedule()
            }
            if(this.edit.address){
                this.EditAddress()
            }

            this.selected_hour_id = null;
            this.filters.schedule_id = this.schedule.id
            this.filters.hability = this.schedule.service.hability_id
            this.filters.service_date = this.schedule.service_date
            this.filters.service_date_end = this.schedule.service_date_end
            if(this.filters.service_date_end){
                this.$refs['modal-alter-worker-2'].show()
            }else{
                this.$refs['modal-alter-worker-1'].show()
            }
        },

        SearchStatus(id){
            let status = this.status_options.find(status => status.value === id);
            return status?.text
        },

        SearchStatusTransaction(id){
            let status = this.transactions_status_options.find(status => status.value === id);
            return status?.text
        },

        SearchPaymentType(id){
            let payment = this.payment_type_options.find(type => type.value === id);
            return payment?.text
        },

        ResolvedProblem(index) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Problema`,
                html: `Problema resolvido? Deseja dar continuidade ao serviço?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/resolvedProblem', index).then(data => {
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        Punishment() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Avançar',
                width: '900px',
                title: `Penalidade`,
                html: `Deseja penalizar a(o) cliente <b>${this.schedule.customer?.person.name}</b>?`
                    + `<hr>`
                    + `<select class="form-control form-control-md" id="swal-select-update-schedule">
                        <option value="" selected>Selecionar</option>
                        ${
                            this.punishment_rules_filter([null,1,2,5,6]).map(item =>
                                `<option value="${item.id}">${item.parent.description} ${item.customer_tax ? '- '+item.customer_tax.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''} </option>`
                            ).join('')
                        }
                    </select>`
                    + `<hr>`
                    + `<p style="font-size:14px" class="text-muted"><strong style="font-size:14px">Obs:</strong> Não é obrigátorio escolher penalidade, caso queira adicionar, escolha uma acima e click em 'OK' para prosseguir.</p>`,
                icon: 'warning',
                padding: 20,
                preConfirm: () => {
                    var value = document.getElementById("swal-select-update-schedule").value;
                    if(value === null || value === ""){
                        this.$swal.showValidationMessage(`Por favor, escolha uma penalidade para avançar!`)
                    } 
                    return value;
                },
            }).then((result) => {
                if(result.isConfirmed){
                    this.$swal({
                        showConfirmButton: true,
                        showCancelButton: true,
                        input: 'textarea',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        title: `Penalidade`,
                        html: `Deseja realmente adicionar a penalidade de <b>"${this.punishment_rules.find(item => item.id == result.value).parent.description}"</b> para o profissional?<hr/><p>Observação: </p>`,
                        icon: 'warning',
                        padding: 20,
                        showLoaderOnConfirm: true,
                        allowOutsideClick: () => !this.$swal.isLoading()
                    }).then((result2) => {
                        if (result2.isConfirmed) {
                            var data = {
                                id: result.value,
                                obs: result2.value
                            }
                            this.$store.dispatch('service/newPunishment', data).then(data => {
                            this.Sweealert2('success', data.success)
                            }).catch(error => {
                                return this.$swal.showValidationMessage(`${error.response.data.error}`)
                            });
                        }
                    })
                }
            })
        },

        CreateProblemSchedule() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                title: `Relatar Problema`,
                html: `Deseja informar um problema com o agendamento de Nº <b>"${this.schedule.number}"</b>?<hr/>`
                    + `<p class="mt-3">Tipo de problema: </p>`
                    + `<select class="form-control form-control-lg" name="select" id="swal-select">
                            <option value="select" selected>Selecione</option>
                            ${
                                this.options_problems_type.map(item =>
                                    `<option value="${item.value}">${item.text}</option>`
                                ).join('')
                            }
                        </select>`
                    + `<p class="mt-3">Observação: </p>`,
                icon: 'warning',
                padding: 20,
                showLoaderOnConfirm: true,
                preConfirm: (obs) => {
                    var select = document.getElementById("swal-select");

                    if(select.value === "select"){
                        this.$swal.showValidationMessage(`Por favor, selecione o tipo do problema!`)
                        return false;
                    }

                    if(obs === null || obs === ""){
                        this.$swal.showValidationMessage(`Por favor, informe uma observação para o problema!`)
                    } 
                    return [
                        obs,
                        select.value,
                    ]
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                   var data = {
                        obs: result.value[0],
                        type: result.value[1],
                    }
                    this.$store.dispatch('service/createProblemSchedule', data).then(data => {
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        return this.$swal.showValidationMessage(`${error.response.data.error}`)
                    });
                }
            })
        },
        ValidateSchedule() {
            if (this.edit.service && this.backup && this.backup.customer_value != this.schedule.customer_value && !this.schedule.customer_value_motive) {
                this.$swal("", "Motivo do valor de recebimento do(a) cliente é obrigatório.", "warning");
                return false;
            }

            if (this.edit.service && this.backup && this.backup.worker_value != this.schedule.worker_value && !this.schedule.worker_value_motive) {
                this.$swal("", "Motivo do valor de pagamento do(a) profissional é obrigatório.", "warning");
                return false;
            }

            return true;
        },
        UpdateSchedule() {
            if (!this.ValidateSchedule()) {
                return;
            }

            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterar`,
                html: `Deseja realmente alterar os dados do serviço?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        confirmButtonText: 'OK',
                        width: '900px',
                        title: `Penalidade`,
                        html: `Deseja penalizar a(o) cliente <b>${this.schedule.customer?.person.name}</b>?`
                            + `<hr>`
                            + `<select class="form-control form-control-md" id="swal-select-update-schedule">
                                <option value="" selected>Não aplicar penalidade</option>
                                ${
                                    this.punishment_rules_filter([4]).map(item =>
                                        `<option value="${item.id}">${item.parent.description} ${item.customer_tax ? '- '+item.customer_tax.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''} </option>`
                                    ).join('')
                                }
                            </select>`
                            + `<hr>`
                            + `<p style="font-size:14px" class="text-muted"><strong style="font-size:14px">Obs:</strong> Não é obrigátorio escolher penalidade, caso queira adicionar, escolha uma acima e click em 'OK' para prosseguir.</p>`,
                        icon: 'warning',
                        padding: 20,
                        preConfirm: () => {
                           return document.getElementById("swal-select-update-schedule").value;
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('service/updateSchedule', result.value).then(data => {
                                this.edit.service = false;
                                this.Sweealert2('success', data.success)
                            }).catch(error => {
                                this.$swal({
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    title: 'Atenção!',
                                    text: error.response.data.error,
                                    icon: 'error',
                                    padding: 20,
                                })
                            });
                        }
                    })
                }
            })
        },

        UpdateSchedulePreferences() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterar`,
                html: `Deseja realmente alterar os dados?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/updatePreferences', this.schedule).then(data => {
                        this.edit.preferences = false;
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        UpdateScheduleAddress() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterar`,
                html: `Deseja realmente alterar os endereço do serviço?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/updateAddress').then(data => {
                        this.edit.address = false;
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        ScheduleUpdateWorker() {
            this.$refs['modal-alter-worker-2'].hide()
            var worker = this.items.filter(item => item.id = this.selected);
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterar`,
                html: `Deseja realmente alterar a(o) profissional <strong>${this.schedule.worker?.person.name}</strong> pela(o) <strong>${worker[0].name}</strong>?
                <hr>`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        confirmButtonText: 'OK',
                        width: '900px',
                        title: `Penalidade`,
                        html: `Deseja penalizar a(o) profissional <b>${this.schedule.worker?.person.name}</b>?`
                            + `<hr>`
                            + `<select class="form-control form-control-md" id="swal-select-update-worker">
                                <option value="" selected>Não aplicar penalidade</option>
                                ${
                                    this.punishment_rules_filter([1,2]).map(item =>
                                        `<option value="${item.id}">${item.parent.description} ${item.recurrence > 1 ? ' -'+item.recurrence : ''} - (${item.points ? item.points+' Pontos' : '-'})</option>`
                                    ).join('')
                                }
                            </select>`
                            + `<hr>`
                            + `<p style="font-size:14px" class="text-muted"><strong style="font-size:14px">Obs:</strong> Não é obrigátorio escolher penalidade, caso queira adicionar, escolha uma acima e click em 'OK' para prosseguir.</p>`,
                        icon: 'warning',
                        padding: 20,
                        preConfirm: () => {
                           return document.getElementById("swal-select-update-worker").value;
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var data = {
                                worker_id: this.selected,
                                punishment_opt: result.value
                            }
                            this.$store.dispatch('service/updateScheduleWorker', data).then(data => {
                                this.Sweealert2('success', data.success)
                                }).catch(error => {
                                    this.$swal({
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        title: 'Atenção!',
                                        text: error.response.data.error,
                                        icon: 'error',
                                        padding: 20,
                                    })
                            });
                        }
                    })
                }
            })
        },

        ScheduleAddWorker() {
            var worker = this.items.find(item => item.id === this.selected);
            
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Adicionar`,
                html: `Deseja realmente adicionar a(o) profissional <strong>${worker.name}</strong> ao agendamento?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$refs['modal-alter-worker-2'].hide()
                    var data = {}
                    if(this.schedule.service_date_end){
                        data = { worker_id: this.selected }
                    }else{
                        let item = this.schedule.hours.find(hour => hour.id == this.selected_hour_id);
                        data = { worker_id: this.selected, hour: item.hour }
                    }
                    this.$store.dispatch('service/addScheduleWorker', data).then(data => {
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        RemoveScheduleWorker(worker){
            if(this.edit.service){
                this.EditSchedule()
            }
            if(this.edit.address){
                this.EditAddress()
            }

            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: 
                    `Deseja realmente remover o(a) profissional <strong>${worker.person.name}</strong>?`
                    + `<hr>`
                    + `<p style="font-size:14px" class="text-muted"><strong style="font-size:14px">Obs:</strong> O agendamento voltara a ser uma oportunidade</p>`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        confirmButtonText: 'OK',
                        width: '900px',
                        title: `Penalidade`,
                        html:`Deseja penalizar a(o) profissional <b>${worker.person.name}</b>?`
                            + `<hr>`
                            + `<select class="form-control form-control-md" id="swal-select-remove-worker">
                                <option value="" selected>Não aplicar penalidade</option>
                                ${
                                    this.punishment_rules_filter([2]).map(item =>
                                        `<option value="${item.id}">${item.parent.description} ${item.recurrence > 1 ? ' -'+item.recurrence : ''} - (${item.points ? item.points+' Pontos' : '-'})</option>`
                                    ).join('')
                                }
                            </select>`
                            + `<hr>`
                            + `<p style="font-size:14px" class="text-muted"><strong style="font-size:14px">Obs:</strong> Não é obrigátorio escolher penalidade, caso queira adicionar, escolha uma acima e click em 'OK' para prosseguir.</p>`,
                        icon: 'warning',
                        padding: 20,
                        preConfirm: () => {
                           return document.getElementById("swal-select-remove-worker").value;
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('service/removeScheduleWorker', result.value).then(data => {
                                this.Sweealert2('success', data.success)
                            }).catch(error => {
                                this.$swal({
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    title: 'Atenção!',
                                    text: error.response.data.error,
                                    icon: 'error',
                                    padding: 20,
                                })
                            });
                        }
                    })
                }
            })
        },

        UpdateState(value) {
            this.getCities(value);
            this.state_selected = value;
        },

        SearchZipcode(item) {
            this.search_zipcode = true
            var zipcode = item.zipcode.replace(/[^\d]+/g,'');
            
            Http.get(`/zipcode/${zipcode}`).then(response => {
                this.state_selected = response.data.state_id
                item.street = response.data.street
                item.complement = response.data.complement
                item.district = response.data.district
                item.city_id = response.data.city_id
                item.city_name = response.data.city_name
                this.search_zipcode = false
            }).catch(() => {
                this.search_zipcode = false
            });
        },

        zone_name(value) {
            var data = this.zone_options.find(zone => zone.value === value);
            return data?.text;
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        },

        punishment_rules_filter(types = []){
            if(Array.isArray(types) && types.length > 0){
                return this.punishment_rules.filter(item => types.includes(item.parent.type));
            }else{
                return this.punishment_rules;
            }
        },

        WhatsAppLink(phones, name) {
            let array_phones = phones.split(" | ");
            let inputOptions = [];

            array_phones.forEach(phone => {
                if (phone.length >= 10 && phone.length <= 11) { // Com DDD
                    inputOptions.push(phone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3"));
                }else if (phone.length >= 8 && phone.length <= 9) { // Sem DDD
                    inputOptions.push(phone.replace(/(\d{4,5})(\d{4})/, "$1-$2"));
                }
            });

            this.$swal.fire({
                title: 'Selecione o telefone',
                input: 'select',
                inputOptions: inputOptions,
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                inputValidator: (index) => {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = 'https://api.whatsapp.com/send?phone=55'+inputOptions[index].replace(/[^\d]/g, "")+'&text=Olá, '+name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
        },

        RefreshTransaction(){     
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Refazer`,
                html: `Deseja realmente refazer a cobrança da(o) cliente <strong>${this.schedule.customer.person.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {  
                    this.loading_transaction = true;
                    this.$store.dispatch('service/repeatTransaction').then(data => {
                        this.loading_transaction = false;
                        this.Sweealert2('success', data.success)
                    }).catch(error => {
                        this.loading_transaction = false;
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        });
                    });
                }
            })
        },

        NewScheduleWorkerAndCustomer() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Novo Agendamento`,
                html: `Deseja realmente criar um agendamento com o cliente e profissional atual deste agendamento?`,
                icon: 'question',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ path:'/servicos/cadastro', query: {customer_id: this.schedule.customer_id, worker_id: this.schedule.worker_id}});
                }
            })
        },
    },

    computed: {
        ...mapGetters('auth', [ 
            'has_permission' 
        ]),

        constants(){
            return Constants
        },

        schedule_receipt() {
            if (this.schedule && this.schedule.transactions) {
                for (var t of this.schedule.transactions) {
                    if (t.type == 1) {
                        return t;
                    }
                }
            }
            
            return null;
        },

        schedule_checkins: function() {
            return _orderBy(this.schedule.schedule_checkins, 'created_at', 'ASC')
        },

        schedule_history: function() {
            return _orderBy(this.schedule.schedule_history, 'created_at', 'ASC')
        },

        punishment_rules: {
            get() {
                return this.$store.state.service.punishment_rules;
            }
        },

        schedule: {
            get() {
                return this.$store.state.service.schedule;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE', value)
            }
        },

        transaction_customer: {
            get() {
                var transactions = this.$store.state.service.schedule && this.$store.state.service.schedule.transactions ? this.$store.state.service.schedule.transactions.filter(t => t.type == 1) : [];
                return transactions.length > 0 ? transactions[0] : null;
            }
        },

        payment_type: {
            get() {
                return this.$store.state.service.schedule.payment_type;
            },
            set(value) {
                this.$store.commit('service/SET_PAYMENT_TYPE', value)
            }
        },

        customer_value: {
            get() {
                return this.$store.state.service.schedule.customer_value
            },
            set(value) {
                this.$store.commit('service/SET_CUSTOMER_VALUE', value)
            }
        },

        worker_value: {
            get() {
                return this.$store.state.service.schedule.worker_value
            },
            set(value) {
                this.$store.commit('service/SET_WORKER_VALUE', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.service.errors
            },
            set(value) {
                this.$store.commit('service/SET_ERRORS', value)
            }
        },

        status: {
            get() {
                return this.$store.state.service.schedule.status
            },
            set(value) {
                this.$store.commit('service/SET_STATUS', value)
            }
        },

        service_date: {
            get() {
                return this.$store.state.service.schedule.service_date
            },
            set(value) {
                this.$store.commit('service/SET_SERVICE_DATE', value)
            }
        },

        zipcode: {
            get() {
                return this.$store.state.service.schedule.address.zipcode
            },
            set(value) {
                this.$store.commit('service/SET_ZIPCODE', value)
            }
        },

        street: {
            get() {
                return this.$store.state.service.schedule.address.street
            },
            set(value) {
                this.$store.commit('service/SET_STREET', value)
            }
        },

        complement: {
            get() {
                return this.$store.state.service.schedule.address.complement
            },
            set(value) {
                this.$store.commit('service/SET_COMPLEMENT', value)
            }
        },

        number: {
            get() {
                return this.$store.state.service.schedule.address.number
            },
            set(value) {
                this.$store.commit('service/SET_NUMBER', value)
            }
        },

        reference: {
            get() {
                return this.$store.state.service.schedule.address.reference
            },
            set(value) {
                this.$store.commit('service/SET_REFERENCE', value)
            }
        },

        zone: {
            get() {
                return this.$store.state.service.schedule.address.zone
            },
            set(value) {
                this.$store.commit('service/SET_ZONE', value)
            }
        },

        district: {
            get() {
                return this.$store.state.service.schedule.address.district
            },
            set(value) {
                this.$store.commit('service/SET_DISTRICT', value)
            }
        },

        city_id: {
            get() {
                return this.$store.state.service.schedule.address.city_id
            },
            set(value) {
                this.$store.commit('service/SET_CITY_ID', value)
            }
        },

        table_cols() {
            let cols = [
                {
                    id: 'name',
                    text: 'Nome',
                    class: 'cols_name'
                },
                {
                    id: null,
                    text: 'Telefone(s)',
                    class: 'cols_phones'
                },
                { text: '', class: '' },
                { text: '', class: '' },
            ];
            return cols;
        },
    },

}
</script>

<style>
.zoom-1-2:hover {
    transform: scale(1.2);
    cursor:pointer;
}
.border-hours {
    border-radius: 40px;
    cursor:pointer;
}
.hours:hover {
    background-color: #d3d9dd;
}
.card-body {
    padding: 15px;
}
.border-history {
    background-color: #fff !important;
    border-right: 1px dashed #d3d3d3;
    border-left: 1px dashed #d3d3d3;
    border-bottom: 1px dashed #d3d3d3;
}

.link-open:hover {
    color: var(--primary);
    cursor: pointer;
}

/******************* Timeline *****************/
.main-timeline8{overflow:hidden;position:relative}
.main-timeline8:after,.main-timeline8:before{content:"";display:block;width:100%;clear:both}
.main-timeline8:before{content:"";width:3px;height:100%;background:#d6d5d5;position:absolute;top:30px;left:50%}
.main-timeline8 .timeline{width:50%;float:left;padding-right:30px;position:relative}
.main-timeline8 .timeline-icon{width:32px;height:32px;border-radius:50%;background:#fff;border:3px solid #fe6847;position:absolute;top:5.5%;right:-17.5px}
.main-timeline8 .year{display:block;padding:8px;margin:0;font-size:15px;color:#fff;border-radius:0 50px 50px 0;background:#fe6847;text-align:center;position:relative}
.main-timeline8 .year:before{content:"";border-top:35px solid #f59c8b;border-left:35px solid transparent;position:absolute;bottom:-35px;left:0}
.main-timeline8 .timeline-content{padding:20px 10px;margin:0 20px 0 35px;background:#adadad}
.main-timeline8 .title{font-size:19px;font-weight:700;color:#504f54;margin:0 0 10px}
.main-timeline8 .description{font-size:14px;color:#7d7b7b;margin:0}
.main-timeline8 .timeline:nth-child(2n){padding:0 0 0 30px}
.main-timeline8 .timeline:nth-child(2n) .timeline-icon{right:auto;left:-14.5px}
.main-timeline8 .timeline:nth-child(2n) .year{border-radius:50px 0 0 50px;background:#7eda99}
.main-timeline8 .timeline:nth-child(2n) .year:before{border-left:none;border-right:35px solid transparent;left:auto;right:0}
.main-timeline8 .timeline:nth-child(2n) .timeline-content{text-align:right;margin:0 35px 0 20px}
.main-timeline8 .timeline:nth-child(2){margin-top:170px}
.main-timeline8 .timeline:nth-child(odd){margin:-175px 0 0}
.main-timeline8 .timeline:nth-child(even){margin-bottom:80px}
.main-timeline8 .timeline:first-child,.main-timeline8 .timeline:last-child:nth-child(even){margin:0}
.main-timeline8 .timeline:nth-child(2n) .timeline-icon{border-color:#7eda99}
.main-timeline8 .timeline:nth-child(2n) .year:before{border-top-color:#92efad}
.main-timeline8 .timeline:nth-child(3n) .timeline-icon{border-color:#8a5ec1}
.main-timeline8 .timeline:nth-child(3n) .year{background:#8a5ec1}
.main-timeline8 .timeline:nth-child(3n) .year:before{border-top-color:#a381cf}
.main-timeline8 .timeline:nth-child(4n) .timeline-icon{border-color:#f98d9c}
.main-timeline8 .timeline:nth-child(4n) .year{background:#f98d9c}
.main-timeline8 .timeline:nth-child(4n) .year:before{border-top-color:#f2aab3}
@media only screen and (max-width:992px){.main-timeline8{overflow:visible}
.main-timeline8:before{top:0;left:0}
.main-timeline8 .timeline:nth-child(2),.main-timeline8 .timeline:nth-child(even),.main-timeline8 .timeline:nth-child(odd){margin:0}
.main-timeline8 .timeline{width:90%;float:none;padding:0 0 0 30px;margin-bottom:20px!important}
.main-timeline8 .timeline:last-child{margin:0!important}
.main-timeline8 .timeline-icon{right:auto;left:-14.5px}
.main-timeline8 .year{border-radius:50px 0 0 50px}
.main-timeline8 .year:before{border-left:none;border-right:35px solid transparent;left:auto;right:0}
.main-timeline8 .timeline-content{margin:0 35px 0 45px}
}
</style>

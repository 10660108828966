<template>
  <div>
    <util-bar></util-bar>
    <div class="pr-3 pl-3 pt-3">
      <panel title="Filtros" icon="filter">
        <b-row class="form-group" align-h="between">
          <b-col sm="12" md="5" lg="5">
            <label>Atende a domicílio: </label>
            <b-form-select
              v-model="filters.accept_home_care"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="5" lg="5">
            <label>Possui veículo: </label>
            <b-form-select
              v-model="filters.has_vehicle"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <label></label>
            <p>
              <b-button
                class="mt-2 ml-2"
                variant="outline-danger"
                @click="CleanFilters"
                >Limpar Filtros</b-button
              >
            </p>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="2" lg="2">
            <label>Com MEI: </label>
            <b-form-select
              v-model="filters.has_mei"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <label>Já trabalha com aplicativo de beleza: </label>
            <b-form-select
              v-model="filters.another_beauty_app"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="6" lg="6">
            <label>Data de cadastro: </label>
            <typeable-date v-model="filters.created_at" />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="9" lg="9">
            <label>Habilidades:</label>
            <multiselect
              v-model="filters.habilities"
              :options="habilities_all"
              :multiple="true"
              placeholder="Selecione..."
              selectedLabel="Selecionado"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para remover"
              track-by="id"
              label="name"
            >
              <template slot="noResult">
                Nenhum resultado encontrado
              </template>
              <template slot="noOptions">
                Nenhum resultado encontrado
              </template>
            </multiselect>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Importação: </label>
            <b-form-select
              v-model="filters.imported_from"
              :options="options_imported_from"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col>
            <div role="group">
              <label>Como ficou sabendo da existência da Mary Beleza:</label>
              <template>
                <b-form-select
                  v-model="existence"
                  :options="existence_options"
                ></b-form-select>
              </template>
            </div>
          </b-col>
          <b-col sm="4" v-if="existence == 15">
            <div role="group">
              <label>Qual outro:</label>
              <template>
                <b-form-input
                  v-model="filters.who_existence"
                  placeholder="Qual outro"
                ></b-form-input>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="3" lg="2">
            <label>Agendado: </label>
            <b-form-select
              v-model="filters.scheduling_training_hability"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="2">
            <label>Bloqueado(a): </label>
            <b-form-select
              v-model="filters.blocked"
              :options="options"
            ></b-form-select>
          </b-col>  
          <b-col sm="12" md="3" lg="3">
            <div role="group">
              <label>Zona:</label>
              <b-form-select v-model="filters.zone" :options="zone_options">
                  <template #first>
                      <b-form-select-option :value="null">Selecione...</b-form-select-option>
                  </template>
              </b-form-select>
            </div>  
          </b-col>
        </b-row>
      </panel>

      <panel title="Profissionais MB">
        <b-row class="form-group">
          <b-col md="12 text-center">
            <!-- INSIRA O CONTEÚDO DO PAINEL AQUI -->
            <b-row class="form-group">
              <b-col sm="12" md="12" lg="12">
                <rich-table
                  ref="mb_registrationRichTable"
                  v-on:input="ChangeTableConfig"
                  :numbers="table_numbers"
                  :loading="loadingData"
                  :cols="table_cols"
                  :vueComponent="vue_module"
                >
                  <template v-if="items && items.length > 0">
                    <tr
                      v-for="(item, key) in items"
                      v-bind:key="key"
                      :class="item.page + (item.selected ? ' selected' : '')"
                    >
                      <td width="20%">{{ item.name }}</td>
                      <td width="20%">{{ item.email }}</td>
                      <td width="15%">
                        {{ item.national_document | formatDocuments }}
                      </td>
                      <td width="20%">{{ item.phone | formatPhones }}</td>
                      <td>
                        <b-button
                          v-if="item.restriction"
                          variant="danger"
                          size="sm"
                          v-b-tooltip
                          :title="item.restrict_reason"
                        >
                          <b-icon
                            icon="hand-thumbs-down"
                            style="color: #D5F5E3;"
                            font-scale="1.5"
                          />
                        </b-button>

                        <b-button v-else variant="success" size="sm">
                          <b-icon
                            icon="hand-thumbs-up"
                            style="color: #F2D7D5;"
                            font-scale="1.5"
                          />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <worker-contract-print
                          v-b-tooltip="'Imprimir Contrato'"
                          class="form-group"
                          :worker_id="item.id"
                        ></worker-contract-print>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          variant="success"
                          size="sm"
                          @click="
                            $router.push({
                              path: '/profissionais/historico/' + item.id
                            })
                          "
                          v-b-tooltip
                          title="Histórico do Profissional"
                        >
                          <b-icon icon="calendar4-week" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="
                            item.blocked == 0 &&
                              has_permission('worker-blocked')
                          "
                          variant="warning"
                          size="sm"
                          @click="blockedWorker(item)"
                          v-b-tooltip
                          title="Bloquear"
                        >
                          <b-icon icon="unlock" font-scale="1" />
                        </b-button>

                        <b-button
                          v-else-if="
                            item.blocked == 1 &&
                              has_permission('worker-blocked')
                          "
                          variant="danger"
                          size="sm"
                          @click="blockedWorker(item)"
                          v-b-tooltip
                          :title="item.blocked_reason"
                        >
                          <b-icon icon="lock" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          variant="info"
                          size="sm"
                          @click="changePassword(item)"
                          v-b-tooltip
                          title="Solicitar Criação de Senha Para o App"
                        >
                          <b-icon icon="key-fill" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="
                            $router.push({
                              path: '/profissionais/cadastro/' + item.id
                            })
                          "
                          v-b-tooltip
                          :title="
                            has_permission('worker-edit')
                              ? 'Editar'
                              : 'Visualizar'
                          "
                        >
                          <b-icon
                            v-if="has_permission('worker-edit')"
                            icon="pen"
                            font-scale="1"
                          />
                          <b-icon
                            v-else
                            icon="journal-medical"
                            font-scale="1"
                          />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="has_permission('worker-delete')"
                          variant="danger"
                          size="sm"
                          @click="deleteWorker(item)"
                          v-b-tooltip
                          title="Apagar Ficha"
                        >
                          <b-icon icon="trash" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <div
                          v-if="
                            has_permission('worker-edit') &&
                              item.behavioral_grade > 2
                          "
                        >
                          <hability-scheduling
                            :worker="item"
                            @update:reload_rich_table="LoadData"
                          ></hability-scheduling>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td :colspan="table_cols.length" class="text-center py-3">
                        Nenhum registro encontrado
                      </td>
                    </tr>
                  </template>
                </rich-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </panel>
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel";
import RichTable from "@/components/RichTable";
import store from "@/store";
import _clone from "lodash/clone";
import _merge from "lodash/merge";
import UtilBar from "@/components/UtilBar";
import Constants from "@/constants";
import Multiselect from "vue-multiselect";
import TypeableDate from "@/components/TypeableDate";
import WorkerContractPrint from "@/components/WorkerContractPrint";
import HabilityScheduling from "./hability-scheduling.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      vue_module: 'mb_registration',
      show: true,
      items: [],
      table_numbers: {
        size: 1,
        total: 0,
        found: 0,
        footer: null
      },
      loadingData: false,
      options: [
        { value: 0, text: "Não" },
        { value: 1, text: "Sim" },
        { value: null, text: "Todos" }
      ],
      options_imported_from: [
         // { value: Constants.STATUS_MARYBELEZASITE, text: "Site Mary Beleza" },
        { value: Constants.STATUS_RECRUTAMENTOBELEZA, text: "Recrutamento Beleza" },
        { value: Constants.STATUS_INFOJOBS, text: "InfoJobs" },
        { value: Constants.STATUS_SISTEMA, text: "Sistema" },
        { value: "facebook", text: "Facebook" },
        { value: null, text: "Todos" }
      ],
      existence_options: [
        { value: null, text: "Selecione", disabled: true },
        { value: 1, text: "Bloguerias" },
        { value: 2, text: "Cartão de Visita" },
        { value: 3, text: "E-mail Marketing" },
        { value: 4, text: "Facebook" },
        { value: 5, text: "Google" },
        { value: 6, text: "Instagram" },
        { value: 7, text: "Indicação de Amigos" },
        { value: 8, text: "Jornal" },
        { value: 9, text: "Outdoor" },
        { value: 10, text: "Panfletos de Propaganda" },
        { value: 11, text: "Revistas" },
        { value: 12, text: "SMS" },
        { value: 13, text: "WhatsApp" },
        { value: 14, text: "", disabled: true },
        { value: 15, text: "Outros" }
      ],
      zone_options: [
          {
            text: 'Sem Nenhuma',
            value: -1
          },
          {
            text: 'Centro',
            value: 0
          },
          {
            text: 'Norte',
            value: 1
          },
          {
            text: 'Sul',
            value: 2
          }, 
          {
            text: 'Leste',
            value: 3
          },
          {
            text: 'Oeste',
            value: 4
          }
      ],
    };
  },
  components: {
    Panel,
    RichTable,
    UtilBar,
    Multiselect,
    TypeableDate,
    WorkerContractPrint,
    HabilityScheduling
  },

  mounted() {
    this.LoadData();
    this.$store.dispatch("worker/getHabilitiesAll");
  },
  computed: {
    ...mapGetters("auth", ["has_permission"]),

    habilities_all: {
      get() {
        return this.$store.state.worker.habilities_all;
      }
    },

    filters: {
      get() {
        return this.$store.state.worker.filters.mb_registration;
      }
    },

    existence: {
      get() {
        return this.filters.existence;
      },
      set(value) {
        this.filters.existence = value;
        this.filters.who_existence = null;
      }
    },

    table_cols() {
      let cols = [
        {
          id: "name",
          text: "Nome",
          class: "cols_name"
        },
        {
          id: "email",
          text: "E-mail",
          class: "cols_email"
        },
        {
          id: "national_document",
          text: "CPF",
          class: "cols_national_document"
        },
        {
          id: "phone",
          text: "Telefones",
          class: "cols_phone"
        },
         {
          id: "restriction",
          text: "Restrição",
          class: "cols_restriction"
        },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" }
      ];
      return cols;
    }
  },
  watch: {
    $route() {
      this.LoadData();
    },

    filters: {
      handler: function() {
        this.filterProfessionals();
      },
      deep: true
    }
  },
  methods: {
    LoadData() {
      this.loadingData = true;

      var params = _clone(_merge(this.filters, this.table_config));
      store.dispatch("worker/getList", params).then(data => {
        this.loadingData = false;

        this.items = data.items ? data.items : [];

        this.table_numbers.size = data.size ? data.size : 0;
        this.table_numbers.found = data.found ? data.found : 0;
        this.table_numbers.total = data.total ? data.total : 0;
        this.table_numbers.footer = data.footer ? data.footer : 0;

        /* this.items.association_date = moment(this.items.association_date).format("DD/MM/YYYY"); */
      });

      /* patientsActions.GetList(params).then(response => {

            }); */
    },

    deleteWorker(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Apagar`,
        html: `Deseja realmente apagar o(a) profissional <strong>${worker.name}</strong>?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("worker/deleteWorkerById", worker.id)
            .then(response => {
              this.sweealert2("success", response.success);
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    },

    changePassword(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Criação de Senha`,
        html: `Deseja realmente enviar um e-mail para o(a) cliente <strong>${worker.name}</strong> criar ou alterar sua senha de acesso ao App?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            title: "Processando...",
            icon: "info",
            padding: 20
          });

          this.$store
            .dispatch("worker/changePasswordWorkerById", worker.id)
            .then(response => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: response.success,
                icon: "success",
                padding: 20
              });
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    },

    CleanFilters() {
      this.$store.commit("worker/SET_CLEAN_FILTERS", {
        module: this.vue_module,
        status: [Constants.STATUS_PROFESSIONAL_MB]
      });
      this.$refs["mb_registrationRichTable"].CleanFilters();
    },
    filterProfessionals() {
      this.LoadData();
    },

    clearFilters() {
      this.filters.professionals_type = 1;
      this.filters.show = 10;
      this.filters.name = "";
    },
    ChangeTableConfig(value) {
      this.table_config = value;
      this.LoadData();
    },

    sweealert2(icon, text) {
      this.$swal({
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        text: text,
        icon: icon,
        padding: 20
      });
    },

    blockedWorker(worker) {
      var input = worker.blocked == 0 ? "textarea" : null;
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        input: input,
        title: `${
          worker.blocked == 0 ? "Bloquear" : "Desbloquear"
        } Profissinal`,
        html: `Deseja realmente ${
          worker.blocked == 0 ? "BLOQUEAR" : "DESBLOQUEAR"
        } o(a) profissional <strong>${worker.name}</strong>? ${
          worker.blocked == 0
            ? `<hr/><p class="mt-3">Motivo do bloqueio: </p>`
            : ""
        }`,

        icon: "warning",
        padding: 20,
        preConfirm: reason => {
          if (reason === null || reason === "") {
            this.$swal.showValidationMessage(
              `Por favor, informe um motivo para o bloqueio do(a) profissional!`
            );
          }
          return [reason];
        }
      }).then(result => {
        if (result.isConfirmed) {
          var data = {
            worker_id: worker.id,
            reason: result.value[0]
          };

          this.$swal({
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            title: "Processando...",
            icon: "info",
            padding: 20
          });

          this.$store
            .dispatch("worker/blockedWorkerById", data)
            .then(response => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: response.success,
                icon: "success",
                padding: 20
              });

              this.LoadData();
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });

        
        }
      });
    }

    // blockedWorker(worker) {
    //   this.$swal({
    //     showConfirmButton: true,
    //     showCancelButton: true,
    //     confirmButtonText: "Sim",
    //     cancelButtonText: "Não",
    //     title: `${
    //       worker.blocked == 0 ? "Bloquear" : "Desbloquear"
    //     } Profissinal`,
    //     html: `Deseja realmente ${
    //       worker.blocked == 0 ? "BLOQUEAR" : "DESBLOQUEAR"
    //     } o(a) profissional <strong>${worker.name}</strong>?`,
    //     icon: "warning",
    //     padding: 20
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       this.$swal({
    //         showConfirmButton: false,
    //         timer: 10000,
    //         timerProgressBar: true,
    //         title: "Processando...",
    //         icon: "info",
    //         padding: 20
    //       });

    //       this.$store
    //         .dispatch("worker/blockedWorkerById", worker.id)
    //         .then(response => {
    //           this.$swal({
    //             showConfirmButton: true,
    //             timer: 3000,
    //             timerProgressBar: true,
    //             title: "Atenção!",
    //             text: response.success,
    //             icon: "success",
    //             padding: 20
    //           });

    //           this.LoadData();
    //         })
    //         .catch(error => {
    //           this.$swal({
    //             showConfirmButton: true,
    //             timer: 3000,
    //             timerProgressBar: true,
    //             title: "Atenção!",
    //             text: error.response.data.error,
    //             icon: "error",
    //             padding: 20
    //           });
    //         });
    //     }
    //   });
    // }
  }
};
</script>


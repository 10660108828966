<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.push({ path: '/utilitarios/notificacoes'})">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel>
                <b-row>
                    <b-col md="12">
                        <form-wizard color="#f868a9" title="" subtitle="" @on-complete="finishForm" finishButtonText="Enviar" nextButtonText="Próximo" backButtonText="Anterior">
                            <tab-content title="Regras para envio" :before-change="() => { return getPersons(false, null, true) }">
                                <b-row>
                                    <b-col sm="12" md="6" lg="6" class="form-group">
                                        <div role="group">
                                            <label>Tipo: </label>
                                            <b-form-select v-model="person_type" :options="options_person_type"></b-form-select>
                                        </div>
                                    </b-col>

                                    <b-col sm="12" md="3" lg="3" class="form-group">
                                        <div role="group">
                                            <label>Idade De: </label>
                                            <b-form-input v-model="filters.age_from" type="number" placeholder="0"></b-form-input>
                                        </div>
                                    </b-col>

                                    <b-col sm="12" md="3" lg="3" class="form-group">
                                        <div role="group">
                                            <label>Idade Até: </label>
                                            <b-form-input v-model="filters.age_to" type="number" placeholder="0"></b-form-input>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <div role="group">
                                            <label>Cidade: </label>
                                            <multiselect 
                                                v-model="filters.cities"
                                                :options="cities"
                                                :multiple="true"
                                                placeholder="Todas"
                                                selectedLabel="Selecionado"
                                                selectLabel="Pressione enter para selecionar"
                                                deselectLabel="Pressione enter para remover"
                                                track-by="value"
                                                label="text"
                                            >
                                                <template slot="noResult">
                                                    Nenhum resultado encontrado
                                                </template>
                                                <template slot="noOptions">
                                                    Nenhum resultado encontrado
                                                </template>
                                            </multiselect>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="form-group" sm="12" md="12" lg="5">
                                        <div role="group">
                                            <label>Sexo: </label>
                                            <b-form-radio-group v-model="filters.gender" button-variant="outline-primary" name="radio-btn-outline">
                                                <b-form-radio value="female">Feminino</b-form-radio>
                                                <b-form-radio value="male">Masculino</b-form-radio>
                                                <b-form-radio :value="null">Todos</b-form-radio>
                                            </b-form-radio-group>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="form-group" sm="12" md="12" lg="5">
                                        <div role="group">
                                            <label>Nome: </label>
                                            <b-form-input class="form-control" v-model="filters.name" placeholder="Nome" />
                                        </div>
                                    </b-col>
                                    <b-col class="form-group" sm="12" md="12" lg="3">
                                        <div role="group">
                                            <label>CPF: </label>
                                            <the-mask :mask="'###.###.###-##'" class="form-control" v-model="filters.national_document" placeholder="CPF" maxlength="14" />
                                        </div>
                                    </b-col>
                                    <b-col class="form-group" sm="12" md="12" lg="4">
                                        <div role="group">
                                            <label>E-mail: </label>
                                            <b-form-input v-model="filters.email"  placeholder="E-mail" trim></b-form-input>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="filters.type == constants.PERSON_TYPE_CUSTOMER">
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <div role="group">
                                            <label>Como ficou sabendo da existência da Mary Beleza: </label>
                                            <multiselect 
                                                v-model="filters.existences"
                                                :options="existence_options"
                                                :multiple="true"
                                                placeholder="Todas"
                                                selectedLabel="Selecionado"
                                                selectLabel="Pressione enter para selecionar"
                                                deselectLabel="Pressione enter para remover"
                                                track-by="value"
                                                label="text"
                                            >
                                                <template slot="noResult">
                                                    Nenhum resultado encontrado
                                                </template>
                                                <template slot="noOptions">
                                                    Nenhum resultado encontrado
                                                </template>
                                            </multiselect>
                                        </div>
                                    </b-col>
                                    <b-col sm="12" md="6" lg="6" class="form-group">
                                        <label>Período sem agendamentos:</label>
                                        <b-row>
                                            <b-col>
                                                <typeable-date v-model="filters.not_service_date.start"/>
                                            </b-col>
                                            <span class="align-self-center">à</span>
                                            <b-col>
                                                <typeable-date v-model="filters.not_service_date.end"/>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row v-if="filters.type == constants.PERSON_TYPE_WORKER">
                                    <b-col sm="12" md="9" lg="9" class="form-group">
                                        <div role="group">
                                            <label>Status: </label>
                                            <multiselect 
                                                v-model="filters.status"
                                                :options="options_status"
                                                :multiple="true"
                                                placeholder="Todas"
                                                selectedLabel="Selecionado"
                                                selectLabel="Pressione enter para selecionar"
                                                deselectLabel="Pressione enter para remover"
                                                track-by="value"
                                                label="text"
                                            >
                                                <template slot="noResult">
                                                    Nenhum resultado encontrado
                                                </template>
                                                <template slot="noOptions">
                                                    Nenhum resultado encontrado
                                                </template>
                                            </multiselect>
                                        </div>
                                    </b-col>
                                    <b-col v-if="filters.type == constants.PERSON_TYPE_WORKER" sm="12" md="3" lg="3" class="form-group">
                                        <label>Importação: </label>
                                        <b-form-select v-model="filters.imported_from" :options="options_imported_from"></b-form-select>
                                    </b-col>
                                </b-row>
                            </tab-content>
                            
                            <tab-content title="Mensagem" :before-change="checkForm">
                                <b-overlay :show="loadingWizard" rounded="lg">
                                    <b-alert v-if="persons && !loadingWizard" variant="warning" class="text-center" show>
                                        <b-icon icon="exclamation-triangle" class="mr-2" /> Foram encontradas <strong>{{ totalRows }} pessoas</strong>. <b-button variant="link" v-b-modal.list-persons>Ver Lista</b-button>
                                    </b-alert>
                                    
                                    <b-alert v-else-if="!loadingWizard" variant="danger" class="text-center" show>
                                        <b-icon icon="x-octagon" class="mr-2" /> Nenhuma pessoa encontrada com os filtros selecionados.
                                    </b-alert>

                                    <b-row>
                                        <b-col sm="12" md="12" lg="6">
                                            <div class="form-group">
                                                <label class="required">Resumo: </label>
                                                <vue-editor @selection-change="onSelectionChangeTitle" class="editor-title" v-model="title" :state="validationTitle" :editorToolbar="customToolbar"></vue-editor>
                                                <span style="margin-top:-28px; margin-left:5px; position:absolute;" class="zoom-1-2" @click="emojis.title = !emojis.title">😎</span>
                                                <VEmojiPicker style="margin-top:-34px; margin-left:30px; position:absolute; z-index:9999" v-show="emojis.title" @select="emojiTitle" />

                                                <b-form-invalid-feedback :state="validationTitle">
                                                    Maximo 190 caracteres.
                                                </b-form-invalid-feedback>
                                            </div>

                                            <div class="form-group">
                                                <label class="required">Mensagem: </label>
                                                <vue-editor @selection-change="onSelectionChangeMessage" class="editor-message" v-model="message" :state="validationMessage" :editorToolbar="customToolbar"></vue-editor>
                                                <span style="margin-top:-28px; margin-left:5px; position:absolute;" class="zoom-1-2" @click="emojis.message = !emojis.message">😎</span>
                                                <VEmojiPicker style="margin-top:-34px; margin-left:30px; position:absolute; z-index:9999" v-show="emojis.message" @select="emojiMessage" />
                                                <b-form-invalid-feedback :state="validationMessage">
                                                    Maximo 190 caracteres.
                                                </b-form-invalid-feedback>
                                            </div>
                                        </b-col>
                                        <b-col sm="12" md="12" lg="6">
                                            <input ref="image_ref" type="file" style="display: none;" @change="onPhotoFileSelect" accept="image/jpeg, image/png, image/jpg" />
                                            <div class="smartphone mt-5 mb-3">
                                                <div class="content">
                                                    <b-card style="width:328px; border-radius:10px" class="shadow-lg bg-white">
                                                        <b-row>
                                                            <b-col class="mb-2">
                                                                <b-icon class="mr-2" icon="patch-check-fill"></b-icon>
                                                                <span style="font-size:12px">Mary Beleza . now</span>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row style="line-height: 1.0;">
                                                            <b-col>
                                                                <span style="font-size:12px" v-html="title"></span>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row style="line-height: 1.0;">
                                                            <b-col>
                                                                <span style="font-size:12px" v-html="message"></span>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="image" class="mt-2">
                                                            <b-col class="container text-center">
                                                                <img style="width:auto; height:120px" :src="image"/>
                                                                <div class="overlay">
                                                                    <b-icon v-b-modal.modal-cropper class="icon-overlay" variant="primary" icon="pen-fill" font-scale="2"></b-icon>
                                                                    <b-icon @click="resetImage()" class="icon-overlay" variant="danger" icon="trash-fill" font-scale="2"></b-icon>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card>
                                                    <b-button v-if="!image" @click="$refs.image_ref.click()" class="btn-custom" variant="mary-beleza"><b-icon icon="image" font-scale="1.5"/></b-button>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-modal hide-header hide-footer id="modal-cropper" ref="modal-cropper" size="lg" centered no-close-on-backdrop>
                                         <b-row class="mb-2">
                                            <b-col md="1">
                                                <b-icon icon="mouse2" v-b-tooltip="'Utilize o mouse para dar zoom na imagem!'" font-scale="1.5"/>
                                            </b-col>
                                            <b-col md="1">
                                                <b-icon icon="arrows-fullscreen" v-b-tooltip="'Tamanho máximo da imagem é de 1MB, é possivel recortar para diminuir o tamanh!'" font-scale="1.5"/>
                                            </b-col>
                                         </b-row>
                                        <b-row class="text-center">
                                            <b-col>
                                                <VueCropper :aspect-ratio="8/8" class="cropped-image" v-show="selectPhotoFile" ref="cropper" :src="selectPhotoFile" alt="Source Image"></VueCropper>
                                            </b-col>
                                        </b-row>
                                        <b-row class="justify-content-center mt-3">
                                            <b-col>
                                                <b-button v-if="selectPhotoFile" block variant="outline-mary-beleza" @click="cropedImagePreview()">
                                                    <div v-if="cropedLoading">
                                                        <b-icon icon="arrow-clockwise" animation="spin" font-scale="1.3" class="mr-2"/>
                                                        Cortando...
                                                    </div>
                                                    <div v-else>
                                                        <b-icon icon="scissors" class="mr-2"/>
                                                        Cortar
                                                    </div>
                                                </b-button>
                                            </b-col>
                                            <b-col>
                                                <b-button variant="danger" block @click="cancelImage()">
                                                    Cancelar
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-modal>

                                    <b-modal hide-header hide-footer ref="modal-cropper-save" size="md" centered no-close-on-backdrop>
                                        <b-row>
                                            <b-col>
                                                <img style="width:100%; height:100%" :src="cropedImage"/>           
                                            </b-col>
                                        </b-row>
                                        <b-row class="justify-content-center mt-3">
                                            <b-col>
                                                <b-button @click="$refs['modal-cropper-save'].hide()" block variant="outline-mary-beleza">
                                                    <b-icon icon="arrow90deg-left" class="mr-2"></b-icon>
                                                    Voltar
                                                </b-button>
                                            </b-col>
                                            <b-col v-if="cropedImage">
                                                <b-button variant="success" block @click="setImage()">
                                                    Salvar
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-modal>
                                </b-overlay>
                            </tab-content>
                            
                            <tab-content title="Envio">
                                <div class="form-group">
                                        <b-row>
                                            <b-col sm="12" md="6" lg="6">
                                                <label>Deseja agendar as notificações?</label>
                                                <b-form-group>
                                                    <button-group :options="options_scheduling" v-model="scheduling_selected"></button-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col v-if="scheduling == true" sm="12" md="6" lg="6">
                                                <label>Agendar para?</label>
                                                <v-date-picker :model-config="modelConfig" v-model="min_sent_date" mode="dateTime" is24hr>
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <div >
                                                            <input
                                                            placeholder="dd/mm/aaaa h:m"
                                                            class="form-control"
                                                            :value="inputValue"
                                                            v-on="inputEvents"/>
                                                        </div>
                                                    </template>
                                                </v-date-picker>
                                            </b-col>
                                        </b-row>
                                    </div>
                            </tab-content>

                            <div v-if="errorMsg">
                                <span class="error">{{errorMsg}}</span>
                            </div>
                        </form-wizard>

                        <b-modal id="list-persons" hide-footer size="lg" title="Lista de pessoas para envio" ok-only cancelTitle="Fechar">
                            <template #default="{ ok }">
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="5" label-align-sm="right" class="mb-0">
                                            <b-input-group>
                                                <b-form-input id="filter-input" v-model="filters.search" @input="SearchPerson()" type="search" placeholder="Informe um nome para buscar"></b-form-input>

                                                <b-input-group-append>
                                                    <b-button :disabled="!filter_table" variant="danger" @click="filters.name = null, SearchPerson()">Limpar</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-overlay :show="loadingTable">
                                    <b-table :items="persons" :fields="fields" stacked="md" outlined striped fixed hover small>
                                        <template #cell(name)="row">
                                            <span>{{ row.item.name }}</span>
                                        </template>
                                        <template #cell(actions)="row">
                                            <b-button size="sm" variant="danger" @click="removePerson(row.item)" class="mr-1 text-right">
                                                Remover <b-icon icon="x" scale="1.3"></b-icon>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-overlay>

                                <b-row>
                                    <b-col sm="12" md="6" lg="7" offset-md="2" class="form-group">
                                        <b-pagination :disabled="loadingTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getPersons(true)" align="fill" size="sm" class="my-0"></b-pagination>
                                    </b-col>
                                    <b-col sm="12" md="3" lg="2" offset-md="1" class="form-group">
                                        <b-button class="mr-4" block size="sm" variant="primary" @click="ok()">OK</b-button>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-modal>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {FormWizard, TabContent} from 'vue-form-wizard'
import Panel from "@/components/Panel"
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import Multiselect from 'vue-multiselect'
import ButtonGroup from '@/components/ButtonGroup'
import _clone from 'lodash/clone'
import { VueEditor } from "vue2-editor";
import { VEmojiPicker } from 'v-emoji-picker';
import VueCropper from 'vue-cropperjs'
import TypeableDate from '@/components/TypeableDate'
import swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'

export default {
    components: {
        Panel,
        UtilBar,
        FormWizard,
        TabContent,
        Multiselect,
        ButtonGroup,
        VueEditor,
        VEmojiPicker,
        VueCropper,
        TypeableDate
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline", "strike"], 
                ["clean"],
            ],
            emojis: {
                title: false, 
                message: false,
            },
            handler: null,
            filters: {
                name: null,
                search: null,
                type: null,
                age_from: null,
                age_to: null,
                gender: null,
                existences: null,
                cities: null,
                imported_from: null,
                status: null,
                national_document: null,
                email: null,
                ignore_ids: [],
                not_service_date: {
                    start: null,
                    end: null
                }
            },
            scheduling: false,
            options_person_type: [
                { value: null, text: 'Todos' },
                { value: Constants.PERSON_TYPE_CUSTOMER, text: 'Clientes' },
                { value: Constants.PERSON_TYPE_WORKER, text: 'Profissionais' },
            ],
            options_status: [
                { value: Constants.STATUS_PRE_REGISTRATION, text: 'Pré-cadastro' },
                { value: Constants.STATUS_APPROVED, text: 'Aprovados' },
                { value: Constants.STATUS_DISAPPROVED, text: 'Reprovados' },
                { value: Constants.STATUS_NOT_SCHEDULED_TECHNICAL, text: 'Não agendado avaliação técnica' },
                { value: Constants.STATUS_SCHEDULED_TECHNICAL, text: 'Agendado avaliação técnica' },
                { value: Constants.STATUS_NOT_SCHEDULED_TRAINING, text: 'Não agendado treinamento' },
                { value: Constants.STATUS_SCHEDULED_TRAINING, text: 'Agendado treinamento' },
                { value: Constants.STATUS_PROFESSIONAL_MB, text: 'Profissional MB' },
            ],
            options_scheduling: [
                { id: false, text: "Não"},
                { id: true, text: "Sim"},
            ],
            options_imported_from: [
                { value: "recrutamentobeleza", text: "Site"},
                { value: "infojobs", text: "InfoJobs"},
                { value: "", text: "Sistema"},
                { value: "facebook", text: "Facebook" },
                { value: null, text: "Todos"},
            ],
            cities: [],
            existence_options: [
                { value: null, text: 'Selecione', disabled: true },
                { value: 1, text: 'Bloguerias' },
                { value: 2, text: 'Cartão de Visita' },
                { value: 3, text: 'E-mail Marketing' },
                { value: 4, text: 'Facebook' },
                { value: 5, text: 'Google' },
                { value: 6, text: 'Instagram' },
                { value: 7, text: 'Indicação de Amigos' },
                { value: 8, text: 'Jornal' },
                { value: 9, text: 'Outdoor' },
                { value: 10, text: 'Panfletos de Propaganda' },
                { value: 11, text: 'Revistas' },
                { value: 12, text: 'SMS' },
                { value: 13, text: 'WhatsApp' },
                { value: 14, text: '', disabled: true },
                { value: 15, text: 'Outros' }
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm:ss',
            },
            loadingWizard: false,
            loadingTable: false,
            errorMsg: null,
            fields: [
                { key: 'name', label: 'Nome', class: 'th-table', sortable: false },
                { key: 'actions', label: 'Remover da Lista' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            filter_table: null,
            index_editor_title: null,
            index_editor_message: null,
            imagePreview: null,
            selectPhotoFile: null,
            cropedImage: null,
            cropedLoading: false,
            image: null,
            blob: null
        }
    },
    created() {
        this.LoadCities();
    },
    methods: {
        SearchPerson() {
            clearTimeout(this.handler);
            this.handler = setTimeout(() => {
                this.getPersons(true);
            }, 500);
        },
        onSelectionChangeTitle(range) {
            if(range != null){
                this.index_editor_title = range.index
            }
        },

        onSelectionChangeMessage(range) {
            if(range != null){
                this.index_editor_message = range.index
            }
        },

        LoadCities() {
            this.$store.dispatch('cities/getCitiesByCustomers').then(data => {
                this.cities = [];
                data.forEach(citie => {
                    this.cities = [...this.cities, {
                        text: citie.name,
                        value: citie.id
                    }]
                });
            });
        },

        getPersons(table = false, action = null, reset = false) {
            if (!table) {
                this.loadingWizard = true;
            }
            if (reset) {
                this.filters.search = "";
                this.currentPage = 1;
                this.totalRows = 0;
            }

            this.loadingTable = true;
            var page = this.currentPage;
            var params = _clone(this.filters);
            params.offset = Math.max((page ? page - 1 : 0), 0) * this.perPage;
            params.limit = this.perPage;
            params.sort_column = "name";
            params.sort_order = "asc";
            this.$store.dispatch('notification/getPersons', params).then((response) => {
                this.totalRows = response.total;
                if (!table) {
                    this.loadingWizard = false;
                }
                this.loadingTable = false;

                if (action) {
                    action();
                }
            });

            return true;
        },

        setLoading: function(value) {
            this.loadingWizard = value
            this.image = null,
            this.blob = null
        },

        checkForm(){
            if(!this.title) {
                swal.fire("Atenção", "Por favor, insira um resumo para a notificação!", "error");
                return false;
            } else if(this.title.length > 190){
                    swal.fire("Atenção", "Por favor, insira um resumo com menos de 200 caracteres!", "error");
                    return false;
            } else if(!this.message){
                    swal.fire("Atenção", "Por favor, insira uma mensagem para a notificação!", "error");
                    return false;
            } else if(this.message.length > 190){
                    swal.fire("Atenção", "Por favor, insira uma mensagem com menos de 200 caracteres!", "error");
                    return false;
            } else if(this.persons.length == 0){
                    swal.fire("Atenção", "Para enviar notificação deve haver pelo menos uma pessoa na lista!", "error");
                    return false; 
            } else {
                return true
            }
        },

        finishForm() {
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Enviar`,
                html: `Deseja realmente enviar ${this.persons.length > 1 ? 'as' : 'a'} ${this.persons.length > 1 ? 'notificações' : 'notificação'}?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    swal.fire({
                        title: "Enviando...",
                        didOpen() {
                            swal.showLoading();
                        }
                    })

                    var page = this.currentPage;
                    var params = _clone(this.filters);
                    params.offset = Math.max((page ? page - 1 : 0), 0) * this.perPage;
                    params.limit = this.perPage;
                    params.sort_column = "name";
                    params.sort_order = "asc";

                    this.$store.dispatch('notification/createPushNotifications', { image: this.blob, filters: params }).then(data => {
                        this.$router.push({ path: '/utilitarios/notificacoes'})
                        swal.fire("Successo ao enviar!", data.success, "success")
                    });
                }
            })
        },

        removePerson(person){
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover a(o) ${person.name} da lista?<br><p style="font-size:14px" class="text-muted">Essa pessoa não ira receber a notificação.</p>`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    var ids = this.filters.ignore_ids ? this.filters.ignore_ids : [];
                    if (ids.filter(i => i == person.id).length == 0) {
                        ids.push(person.id);
                    }
                    
                    this.filters.ignore_ids = ids;
                    this.getPersons(true, () => swal.fire("Sucesso!", "Pessoa removida da lista!", "success"));
                }
            })
        },

        resetImage(){
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover a imagem?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.image = null
                    this.blob = null
                }
            })
        },

        setImage() {
            var cropper = this.$refs.cropper.getCroppedCanvas({imageSmoothingEnabled: false, imageSmoothingQuality: 'high'});
            cropper.toBlob((blob) => {
                var sizeImage = (blob.size/1024/1024).toFixed(2)
                if(sizeImage < 1){
                    this.image = cropper.toDataURL()
                    this.blob = blob
                    this.$refs['modal-cropper'].hide()
                    this.$refs['modal-cropper-save'].hide()
                    this.$refs.image_ref.value = null;
                }else{
                    swal.fire("Máximo atingido", "O tamanho da imagem é "+sizeImage+"MB e o tamanho máximo é 1MB", "error")
                }
            }, this.mime_type)
        },

        cancelImage() {
            this.$refs['modal-cropper'].hide()
            this.$refs.image_ref.value = null;
            this.selectPhotoFile = '';
            this.cropedImage = '';
        },

        cropedImagePreview() {
            this.cropedLoading = true;
            var cropper = this.$refs.cropper.getCroppedCanvas({imageSmoothingEnabled: false, imageSmoothingQuality: 'high'});
            cropper.toBlob((blob) => {
                var sizeImage = (blob.size/1024/1024).toFixed(2)
                if(sizeImage < 1){
                    this.cropedImage = cropper.toDataURL()
                    this.$refs['modal-cropper-save'].show();
                    this.cropedLoading = false;
                }else{
                    swal.fire("Máximo atingido", "O tamanho da imagem é "+sizeImage+"MB e o tamanho máximo é 1MB", "error")
                    this.cropedLoading = false;
                }
            }, this.mime_type)
        },

        onPhotoFileSelect(e) {
            const file = e.target.files[0]
            if(file){
                this.mime_type = file.type
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        this.$refs['modal-cropper'].show();
                        this.selectPhotoFile = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    swal.fire("Não suportado", "Somente é possivel importar arquivos JPG/JPEG", "error")
                }
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        emojiTitle(emoji) {
            this.title = this.title ? 
                this.title.substr(0,this.index_editor_title)+emoji.data+this.title.substr(this.index_editor_title)
            : 
                emoji.data
        },

        emojiMessage(emoji) {
            this.message = this.message ? 
                this.message.substr(0,this.index_editor_message)+emoji.data+this.message.substr(this.index_editor_message) 
            : 
                emoji.data
        }
    },

    computed: { 

        validationTitle() {
            return this.title != null && this.title != "" ? this.title.length > 0 && this.title.length < 200 ? null : false : null;
        },

        validationMessage() {
            return this.message != null && this.message != "" ? this.message.length > 0 && this.message.length < 200 ? null : false : null;
        },

        scheduling_selected: {
            get() {
                return this.scheduling
            },
            set(value) {
                this.min_sent_date = null;
                this.scheduling = value
            }
        },

        person_type: {
            get() {
                return this.filters.type
            },
            set(value) {
                this.filters.existences = null
                this.filters.imported_from = null
                this.filters.status = null
                this.filters.not_service_date = {start: null, end: null}
                this.filters.type = value
            }
        },

        persons: {
            get() {
                return this.$store.state.notification.push_notification.persons;
            },
        },

        title: {
            get() {
                return this.$store.state.notification.push_notification.title;
            },
            set(value) {
                var valueReplace = value.replace(/<br>/g , "")
                .replace(/<em>/g , "<i>")
                .replace(/<\/em>/g , "</i>")
                .replace(/<h1>/g , "")
                .replace(/<\/h1>/g , "")
                .replace(/<p>/g , "")
                .replace(/<\/p>/g , "")
                this.$store.commit('notification/SET_TITLE', valueReplace)
            }
        },

        message: {
            get() {
                return this.$store.state.notification.push_notification.message;
            },
            set(value) {
                var valueReplace = value.replace(/<br>/g , "")
                .replace(/<em>/g , "<i>")
                .replace(/<\/em>/g , "</i>")
                .replace(/<h1>/g , "")
                .replace(/<\/h1>/g , "")
                .replace(/<p>/g , "")
                .replace(/<\/p>/g , "")
                this.$store.commit('notification/SET_MESSAGE', valueReplace)
            }
        },

        min_sent_date: {
            get() {
                return this.$store.state.notification.push_notification.min_sent_date;
            },
            set(value) {
                this.$store.commit('notification/SET_MIN_SENT_DATE', value)
            }
        },

        type: {
            get() {
                return this.$store.state.notification.push_notification.type;
            },
            set(value) {
                this.$store.commit('notification/SET_TYPE', value)
            }
        },

        constants(){
            return Constants
        },
    },
}
</script>

<style lang="scss">
.vue-form-wizard .wizard-icon-circle {
    border: 4px solid #f3f2ee;
}

.th-table { 
    width: 80% !important; 
}

.editor-title .ql-editor {
    min-height: 80px;
}

.editor-message .ql-editor {
    min-height: 140px;
}


/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 340px;
  margin: auto;
  border: black solid;
  border-width: 16px 16px 0px 16px;
  border-top-width: 60px;
  border-radius: 36px 36px 0px 0px;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 0px;
  background: white;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2;
  right: 2;
  height: 100%;
  width: 92.5%;
  opacity: 0;
  transition: .3s ease;
  background-color: gray;
}

.icon-overlay {
  opacity: 1;
  margin-top: 12%;
  margin-left: 26%;
  text-align: center;
}

.icon-overlay:hover {
  transform: scale(1.2);
  cursor:pointer;
}

.smartphone .content .container:hover .overlay {
  opacity: 0.8;
}

.btn-custom {
    margin-top: 5%;
	height: 50px !important;
	width: 50px !important;
	border-radius: 100% !important;
    margin-left: 40%;
}
</style>

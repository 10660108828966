<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>
        
        <b-overlay :show="loading" no-wrap rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <img :src="RandomImages()" class="vibrate-1" width="40%" />
                    <h6 class="blink-2"><small>Carregando</small></h6>
                </div>
            </template>
        </b-overlay>
        <div class="pr-3 pl-3 pt-3">
            <panel title="Resumo Pós-Vendas">
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-center">
                        <h4 class="mt-2 mb-4">Quantidade de Pós-Venda por Nota</h4>

                        <b-table-simple responsive striped small hover>
                            <b-tbody>
                                <b-tr>
                                    <b-td class="text-center">
                                        <rating-emoji :value="1" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #c23334">{{ customer.rating.qt_evaluation_ratings['1'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="2" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #ff8500">{{ customer.rating.qt_evaluation_ratings['2'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="3" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #fecb02">{{ customer.rating.qt_evaluation_ratings['3'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="4" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #d2de02">{{ customer.rating.qt_evaluation_ratings['4'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="5" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #99d501">{{ customer.rating.qt_evaluation_ratings['5'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-center">
                        <h4 class="mt-2 mb-4">Últimos Registros em Todo o Período</h4>

                        <b-table-simple responsive small>
                            <b-tbody>
                                <b-tr>
                                    <b-td style="width:4%;" v-for="(item, index) in CheckLastRatings(customer.rating.last_ratings)" :key="index" :class="'border border-dark border-1 text-center '+RatingsColors(item.evaluation)+' text-white'" v-b-tooltip="item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm') : '-'">{{ item.evaluation }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="text-left" colspan="10">
                                        Mais Antigas
                                    </b-td>
                                    <b-td class="text-right" colspan="10">
                                        Mais Recentes
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import Panel from '@/components/Panel'
import Http from '@/api/http'
import moment from 'moment'

export default {
    components: {
        UtilBar,
        Panel,
    },

    data() {
        return {
            loading: false,
            loaderImages: [
                'icon-nails', 
                'icon-hair-stylist', 
                'icon-massage', 
                'icon-lipstick'
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            customer: null,
            ratings: {1: 'hate', 2: 'disappointed', 3: 'natural', 4: 'good', 5: 'excellent'},
            ratings_desc: ['Péssimo (1)', 'Ruim (2)', 'Regular (3)', 'Bom (4)', 'Ótimo (5)'],
        }
    },

    created() {
        this.LoadData();
    },

    methods: {

        moment(...args){
            return moment(...args);
        },

        LoadData() {
            this.loading = true;
            Http.get(`customer/${this.$route.params.id}/history`, {params: this.filters}).then(response => {
                this.customer = response.data
                this.loading = false;
            }).catch(error => {
                this.Sweealert2('error', error.response.data.error)
            })
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20
            })
        },

        RatingsDesc(value) {
            let rating = Math.floor(value)
            switch(rating){
                case rating = 1:
                    return `Péssimo (${value})`
                case rating = 2:
                    return `Ruim (${value})`
                case rating = 3:
                    return `Regular (${value})`
                case rating = 4:
                    return `Bom (${value})`
                case rating = 5:
                    return `Ótimo (${value})`
            }
        },

        RatingsColors(rating) {
            switch(rating){
                case rating = 1:
                    return `bg-danger`
                case rating = 2:
                    return `bg-danger`
                case rating = 3:
                    return `bg-warning`
                case rating = 4:
                    return `bg-success`
                case rating = 5:
                    return `bg-success`
                case rating = '-':
                    return `bg-secondary`
            }
        },

        CheckLastRatings(value){
            var count = (20 - value.length)
            for (var i = 0; i < count; i++){
                value.unshift({evaluation: "-", created_at: null})
            }
            return value
        },

        RandomImages() {
            return require('@/assets/images/icons/' + this.loaderImages[Math.floor(Math.random() * this.loaderImages.length)] + '.png');
        },

        CalculatePercent(qt_ratings, qt_values_ratings){
            if(!qt_ratings){
                return 0+'%';
            }
            return Math.abs((((qt_ratings - (qt_values_ratings ? qt_values_ratings : 0)) - qt_ratings)/qt_ratings)*100)+'%'
        },
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        filters: {
            get(){
                return this.$store.state.worker.historical.filters;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.icon-mb {
    vertical-align: bottom;
}

.vue-reaction {
    width: 100% !important;
}

.schedule-open:hover {
    color: var(--primary);
    cursor: pointer;
}

.delete-punishment:hover {
    transform: scale(1.2);
    cursor: pointer;
}
</style>

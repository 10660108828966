<template>
    <div id="customer-new">
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">

            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <label>Buscar Por:</label>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <b-form-select v-model="filters.type" :options="type_options"></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col sm="12" md="8" lg="8" class="form-group">
                        <b-form-input v-model="filters.value" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
            </panel>

            <b-row class="lazy">
                <b-col :sm="customers.lenght > 1 ? '6' : '12'" :md="customers.lenght > 1 ? '4' : '12'" :lg="customers.lenght > 1 ? '4' : '12'" v-for="(customer, index) in customers" v-bind:key="index">
                    <div class="card card-custom card-stretch customer mb-2 d-flex align-content-center align-items-center">
                        <div class="card-body text-center pt-4">
                            <h6>{{ customer.name }}</h6>
                            <p class="pb-0 mb-1 mt-3"><b-icon icon="map" class="mr-2" /> {{ customer.city }} / {{ customer.state }}</p>
                            <p class="pb-0 mb-1 mt-2"><b-icon icon="person-badge" class="mr-2" /> {{ customer.national_document | formatDocuments }}</p>
                            <p class="pb-0 mb-1 mt-2"><fa icon="birthday-cake" class="mr-2" /> {{ customer.birth | moment("DD/MM/YYYY") }}</p>
                            <p class="pb-0 mb-1 mt-2"><b-icon icon="envelope" class="mr-2" /> <small>{{ customer.email }}</small></p>
                            <p class="pb-0 mb-1 mt-2"><b-icon icon="telephone" class="mr-2" /> {{ customer.phone | formatPhones }}</p>
                            <p class="pb-0 mb-1 mt-2"><b-icon icon="phone" class="mr-2" /> {{ customer.mobile_phone | formatPhones }}</p>
                            <p class="pb-0 mb-1 mt-2">
                                <b-badge v-if="customer.situation == 1" variant="success">Nada Consta</b-badge>
                                <b-badge v-if="customer.situation == 2" variant="danger">Erro</b-badge>
                                <b-badge v-if="customer.situation == 3" variant="warning">Atenção</b-badge>
                            </p>
                            <p class="mt-2 font-weight-bold">{{ customer.situation_description }}&nbsp;</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import UtilBar from "@/components/UtilBar"
import moment from 'moment' // eslint-disable-line

export default {
    data() {
        return {
            show: true,
            loadingData: false,
            filters: {
                type: 0,
                value: ''
            },
            type_options: [
                { value: 0, text: 'Nome' },
                { value: 1, text: 'CPF' },
                { value: 2, text: 'CNPJ' },
            ],
            customers: [{
                "name": "Isis Luna Pires",
                "national_document": "54328961411",
                "state_document": "36784510",
                "birth": "1993-12-25",
                "gender": "Feminino",
                "email": "isislunapires@afujita.com.br",
                "zipcode": "77823450",
                "street": "Rua 12",
                "street_number": 267,
                "neighborhood": "Loteamento São Pedro",
                "city": "Araguaína",
                "state": "TO",
                "phone": "6336474219",
                "mobile_phone": "63992429683",
                "situation": 1,
                "situation_description": '',
            }]

        }
    },
    components: {
        Panel, UtilBar
    },
    computed: {
        
    },
    methods: {
        filterProfessionals() {
            // this.LoadData();
        },
        ChangeTableConfig(value) {
            this.table_config = value
            // this.LoadData();
        }
    }
}
</script>

<style>

</style>
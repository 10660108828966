export default class Customer {

    constructor() {
        this.after_sales = false,
        this.partner = false,
        this.observations = null,
        this.person = { 
            name: '',
            email: '',
            national_document: null,
            state_document: null,
            birth: null,
            restriction: false,
            restrict_reason: '',
            restriction_level: null,
            existence: null,
            who_existence: null,
            phones: [{
                phone_number: null, 
                description: '', 
                sms: false,
                is_whatsapp: false,
            }],
            address: [{
                name: '',
                zipcode: '',
                street: '',
                number: null,
                complement: '',
                district: '',
                reference: '',
                zone: null,
                latitude: null,
                longitude: null,
                default: true,
                city_id: null,
                city: {
                    id: null,
                    state_id: null,
                    name: '',
                    enabled: 0,
                    state: {
                      id: null,
                      name: '',
                      acronym: ''
                    }
                }
            }],
        }
    }
}
import Http from '@/api/http'

const state = {
    menu_collapsed: true,
    menu: [],
    mailing_url: null
};

const getters = {
    menu_collapsed: state => {
        return state.menu_collapsed
    },
    menu: state => {
        return state.menu
    }
};

const actions = {
    toggle_menu({ commit }) {
        commit('TOGGLE_MENU')
    },
    load_menu({ commit }) {
        Http.get("menu").then((response) => {
            commit('SET_MENU', response.data)
        })
    },
    mailing_url({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('mailing/url').then(response => {
                commit('SET_MAILING_URL', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    }
};

const mutations = {
    TOGGLE_MENU(state)  {
        state.menu_collapsed = ! state.menu_collapsed
    },
    SET_MENU(state, data) {
        state.menu = data
    },
    SET_MAILING_URL(state, data) {
        state.mailing_url = data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


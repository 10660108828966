<template>
    <div id="customer-inactive">
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <label>Clientes que não compram a:</label>
                    </b-col>
                </b-row>
                <b-row class="form-group filter-period">
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <div role="group">
                            <button-group :options="period_options" v-model="filters.period"></button-group>
                        </div>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Painel">
                <b-row class="form-group">
                    <b-col md="12 text-center">
                        
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table ref="patientsRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers"
                                    :loading="loadingData" :cols="table_cols">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, key) in items" v-bind:key="key"
                                            :class="item.page + (item.selected ? ' selected' : '')">
                                            <td width="25%">{{item.name}}</td>
                                            <td width="20%">{{item.email}}</td>
                                            <td width="10%" class="text-center">{{item.driverlicensetype}}</td>
                                            <td width="5%">{{item.facebook}}</td>
                                            <td width="5%">{{item.mother_name}}</td>
                                            <td width="15%">{{item.national_document}}</td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="primary" size="sm"
                                                    @click="$router.push({ path: '' + item.id + '/prontuario/'})"
                                                    title="Prontuário">
                                                    <!-- <fa :icon="['far', 'clipboard']" /> -->
                                                    <b-icon icon="clipboard" font-scale="1" />
                                                </b-button>
                                            <td width="5%" class="text-center">
                                                <b-button variant="info" size="sm" @click="$router.push({ path: '' + item.id })"
                                                    title="Procedimentos">
                                                    <b-icon icon="list-check" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="success" size="sm" @click="$router.push({ path: '' + item.id })"
                                                    title="Tabela de Preços">
                                                    <b-icon icon="table" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="warning" size="sm" @click="$router.push({ path: '' + item.id })"
                                                    title="Exceções">
                                                    <b-icon icon="exclamation-triangle" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="primary" size="sm" @click="$router.push({ path: '' + item.id })"
                                                    title="Editar Paciente">
                                                    <b-icon icon="pen" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="danger" size="sm" @click="Delete(item)">
                                                    <b-icon icon="trash" font-scale="1" />
                                                </b-button>
                                            </td>
                                        </tr>
                                    </template>
                                </rich-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable';
import ButtonGroup from '@/components/ButtonGroup';
import UtilBar from '@/components/UtilBar';

export default {
    data() {
        return {
            show: true,
            table_numbers: {
                size: 1,
                total: 3,
                found: 3,
                footer: null
            },
            loadingData: false,
            filters: {
                professionals_type: 1,
                show: 2,
                name: '',
                period: null
            },
            items: [{
                name: 'Paulo Salinas',
                email: 'paulo@rebuut.com.br',
                driverlicensetype: 'A/B',
                facebook: '/paulosalinas',
                mother_name: 'Sueli',
                national_document: '254.257.258-56'
            }, {
                name: 'Leticia',
                email: 'leticia@rebuut.com.br',
                driverlicensetype: 'A/B',
                facebook: '/leticia',
                mother_name: 'Linez',
                national_document: '356.987.652-55'
            }, {
                name: 'Miguel',
                email: 'Miguel@rebuut.com.br',
                driverlicensetype: '',
                facebook: '/Miguel',
                mother_name: 'Leticia',
                national_document: '487.985.652-33'
            }],
            period_options: [
                { id: "30", text: "30 dias", icon: 'calendar2-week' },
                { id: "60", text: "60 dias", icon: 'calendar2-week' },
                { id: "90", text: "90 dias", icon: 'calendar2-week' },
                { id: "120", text: "120 dias", icon: 'calendar2-week' },
                { id: -2, text: "Outro", icon: 'calendar2-event' },
            ],
        }
    },
    components: {
        Panel, RichTable, ButtonGroup, UtilBar
    },
    computed: {
        table_cols() {
            let cols = [{
                    id: 'name',
                    text: 'Nome',
                    class: 'cols_name'
                },
                {
                    id: 'email',
                    text: 'E-mail',
                    class: 'cols_mail'
                },
                {
                    id: 'driverlicensetype',
                    text: 'Carteira',
                    class: 'cols_driverlicense'
                },
                {
                    id: 'facebook',
                    text: 'Facebook',
                    class: 'cols_facebook'
                },
                {
                    id: 'mother_name',
                    text: 'Mãe',
                    class: 'cols_mother_name'
                },
                {
                    id: 'national_document',
                    text: 'RG',
                    class: 'cols_national_document'
                }, {
                    id: 'button1',
                    text: '',
                    class: 'cols_button_1'
                }, {
                    id: 'button2',
                    text: '',
                    class: 'cols_button_2'
                }, {
                    id: 'button3',
                    text: '',
                    class: 'cols_button_3'
                }, {
                    id: 'button4',
                    text: '',
                    class: 'cols_button_4'
                }, {
                    id: 'button5',
                    text: '',
                    class: 'cols_button_5'
                }, {
                    id: 'button6',
                    text: '',
                    class: 'cols_button_6'
                }
            ];
            return cols;
        },
    },
    methods: {
        filterProfessionals() {
            // this.LoadData();
        },
        ChangeTableConfig(value) {
            this.table_config = value
            // this.LoadData();
        }
    }
}
</script>

<style>

</style>
<template>
    <div :style="{ width: width, height: height }">
        <canvas ref="graphic" width="100%" height="100%"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'
export default {
    props: {
        width: {
            type: [String,Number],
            required: true
        },
        height: {
            type: [String,Number],
            required: true
        },
        typeGraphic: {
            type: String,
            required: false,
            default: "bar"
        },
        data: {
            type: Object,
            required: true
        },
        grid: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    updated() {
        this.buildGraphic();
    },
    mounted() {
        this.buildGraphic();
    },
    data() {
        return {
            chart: null,
        }
    },
    methods: {
        buildGraphic() {
            let graphic = this.$refs.graphic.getContext('2d');
            let init = {
                type: this.typeGraphic,
                data: this.data,
                options: {
                    responsive:true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            },
                            gridLines: {
                                drawOnChartArea: this.grid
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: this.data.tooltip ? this.data.tooltip : () => { return ""; },
                        }
                    }
                }
            };

            if (!this.chart) {
                this.chart = new Chart(graphic, init);
            }
            else {
                this.chart.type = init.type;
                this.chart.data = init.data;
                this.chart.options = init.options;

                this.chart.update();
            }
        }
    }
}
</script>

<template>
<div>
    <panel title="TREINAMENTO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-row class="form-group">
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label>Participou do treinamento?</label>
                            <template v-if="! OpenForm.training">
                                <p>
                                    <span v-if="training == null">-</span>
                                    <b-badge v-else-if="training" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="training" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.training">
                                    <div v-for="error in errors.training" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col v-if="training" sm="12" md="6" lg="6">
                        <div role="group">
                            <label>Data:</label>
                            <template v-if="! OpenForm.training">
                                <p>{{training_dt | moment("DD/MM/YYYY") }}</p>
                            </template>
                            <template v-else>
                                <typeable-date v-model="training_dt"/>
                                <b-form-invalid-feedback :state="!errors.training_dt">
                                    <div v-for="error in errors.training_dt" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="IsUpdate && has_permission('worker-edit')">
                    <b-col sm="12" v-if="!IsStage">
                        <b-button v-if="!OpenForm.training" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'training')">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button variant="mary-beleza" size="sm" @click="update()">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>

    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import TypeableDate from '@/components/TypeableDate'
import { mapGetters } from 'vuex'
import Constants from '@/constants'

export default {

    components: {
        Panel,
        TypeableDate
    },

    data() {
        return {

            options: [{
                    text: 'Sim',
                    value: 1
                },
                {
                    text: 'Não',
                    value: 0
                },
            ],
        }

    },

    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        update() {
            this.$store.dispatch('worker/updateWorker').then(response => {
                if (this.IsStage) {
                    this.$store.commit('worker/STAGE_NEXT')
                }
                this.sweealert2('success', response.success)
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },
    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        training: {
            get() {
                return this.$store.state.worker.worker.training;
            },
            set(value) {
                this.$store.commit('worker/SET_TRAINING', value)
            }
        },

        training_dt: {
            get() {
                return this.$store.state.worker.worker.training_dt;
            },
            set(value) {
                this.$store.commit('worker/SET_TRAINING_DT', value)
            }
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            },
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    }
}
</script>

import moment from 'moment'
export default class Promotion {

    constructor() {
        
        this.name = '' // String
        this.description = '' // String
        this.type = 1 // Integer
        this.coupon = '' // String
        this.number_of_uses = 0 // Integer
        this.number_of_uses_per_customer = 0 // Integer
        this.start_date = moment().format("YYYY-MM-DD HH:mm:ss") // String
        this.end_date = moment().format("YYYY-MM-DD HH:mm:ss") // String
        this.value_type = 0 // Integer
        this.value = 0 // Float
        this.single_rule = 1 // Integer
        this.status = 1 // Integer
        this.services = [] // Array
    }
}
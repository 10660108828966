<template>
    <div class="fab-wrapper" v-b-hover="showFab"> 
        <p class="fab-button">
            <fa :icon="[iconLib, icon]" :color="iconColor" :size="iconSize+'x'" />
        </p> 
        <ul v-if="show" class="fab-item-list">
            <li class="fab-item" v-for="(item, key) in items" :key="key" :style="'background:'+item.background" v-b-tooltip.hover.left="item.title">
                <router-link :to="item.route" :target="item.blank ? '_blank' : ''">
                    <fa :icon="[item.iconLib, item.icon]" :color="item.iconColor" :size="item.iconSize+'x'" />
                </router-link>
            </li>
        </ul>
    </div>
</template>


<script>

export default {
    computed: {
    },
    data() {
        return {
            show: false
        }
    },
    props: {
        iconLib: {
            type: String,
            default: 'fas'
        },
        icon: {
            type: String,
            default: 'info'
        },
        iconColor: {
            type: String,
            default: 'white'
        },
        iconSize: {
            type: Number,
            default: 2
        },
        items: {
            type: Array
        }
    },
    methods: {
        showFab(){
            this.show = !this.show;
        }
    }
}

</script>


<style>
.fab-wrapper{
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  display: flex;
  flex-direction: center;
  align-items: center;
}

.fab-button{
  width: 50px;
  height: 50px;
  background-color: #EA60A7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
}

.fab-button:hover{
    transform: scale(1.25);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.fab-button p {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 70px;
}

.fab-item-list{
  animation: go-back 1s;
  bottom: 0px;
  transition: 0.5s ease-in;
  opacity: 1;
  width: 100%;
  position: absolute;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 70px;
  padding-top: 10px;
  list-style: none;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
}

.fab-item{
  background: #0D9DDB;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align:center;
  color: #fff;
  margin-bottom: 12px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
}
.fab-item:hover{
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
}

@keyframes go-back {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

</style>
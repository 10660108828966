export default class Employee {

    constructor() {
        
        this.blocked = 0 // Integer
        this.module_workers = 0 // Integer
        this.module_customers = 0 // Integer
        this.module_services = 0 // Integer
        this.module_financial = 0 // Integer
        this.module_users = 0 // Integer
        this.module_utils = 0 // Integer
        this.phone = '' // String
        this.cell_phone = '' // String
        this.person = { 
            name: '', //string 
            email: '', //string
            image: null, //string
            birth: '', //date
            password: '', //string
            national_document: '', //string 
            state_document: '', //string
            restriction: 0, // ? back-end? | boolean
            restriction_level: 5, // ? back-end? | integer
            restrict_reason: '', // ? back-end? | text
            type: 'employee', // enum['worker', 'customer', 'franchise', 'employee']
            hide_financial: false,
            gender: '', // enum['male', 'female']
            address: {
                name: '', //string
                street: '', //string
                number: '', //integer
                district: '', //string
                zipcode: null, //string
                zone: null, //integer
                reference: '', //string
                default: 0, //boolean
                latitude: 0, //float
                longitude: 0, //float
                complement: '', //string
                city_id: null, //integer
                city: {
                    id: null,
                    state_id: null,
                    name: '',
                    enabled: 0,
                    state: {
                        id: null,
                        name: '',
                        acronym: ''
                    }
                }
            },
            phones: [
                {
                    phone_number: '', 
                    description: '', 
                    is_personal: 0, 
                    is_whatsapp: 0
                }
            ],
        }
    }
}
<template>
    <div>
        <util-bar>
            <div v-if="has_permission('promotions-edit')">
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <router-link to="/utilitarios/promocoes/cadastro" class="btn btn-mary-beleza">
                            <fa icon="plus-circle" class="mr-2" /> Adicionar
                        </router-link> 
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="3" lg="3" class="form-group">
                        <label for="">Ativos:</label>
                        <b-form-group>
                            <button-group :options="options" v-model="filters.status"></button-group>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Tipo do valor:</label>
                        <b-form-group>
                            <button-group :options="promotions_value_type" v-model="filters.value_type"></button-group>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="filters.value_type != null" sm="12" md="3" lg="3" class="form-group">
                        <label>Valor:</label>
                        <b-row>
                            <b-col>
                                <money v-if="filters.value_type == constants.PROMOTION_VALUE_TYPE_FIXED" class="form-control" v-model="filters.value.start" v-bind="money" ></money>
                                <money v-else-if="filters.value_type == constants.PROMOTION_VALUE_TYPE_PERCENTAGE" class="form-control" v-model="filters.value.start" v-bind="percentage"></money>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <money v-if="filters.value_type == constants.PROMOTION_VALUE_TYPE_FIXED" class="form-control" v-model="filters.value.end" v-bind="money" ></money>
                                <money v-else-if="filters.value_type == constants.PROMOTION_VALUE_TYPE_PERCENTAGE" class="form-control" v-model="filters.value.end" v-bind="percentage"></money>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right">
                        <label></label>
                        <p>
                            <b-button class="mt-2 ml-2" variant="outline-danger" @click="CleanFilters">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="6" lg="6" class="form-group">
                        <label>Válidade:</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.start_date"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.end_date"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" class="form-group">
                        <label>Total de uso:</label>
                        <b-row>
                            <b-col>
                                <b-form-input v-model="filters.number_of_uses.start" type="number"></b-form-input>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <b-form-input v-model="filters.number_of_uses.end" type="number"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" class="form-group">
                        <label>Uso por pessoa:</label>
                        <b-row>
                            <b-col>
                                <b-form-input v-model="filters.number_of_uses_per_customer.start" type="number"></b-form-input>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <b-form-input v-model="filters.number_of_uses_per_customer.end" type="number"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
            <panel>
                <b-row class="form-group">
                    <b-col md="12 text-center">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table ref="promotionRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers"
                                    :loading="loadingData" :cols="table_cols">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, key) in items" v-bind:key="key"
                                            :class="item.page + (item.selected ? ' selected' : '')">
                                            <td>{{item.name}}</td>
                                            <td>
                                                <span v-if="item.type == 1">Cupom</span>
                                            </td>
                                            <td>
                                                <span v-if="item.coupon">{{item.coupon}}</span>
                                            </td>
                                            <td>
                                                {{ item.start_date | moment("DD/MM/YYYY HH:mm")}}
                                            </td>
                                            <td>
                                                {{ item.end_date | moment("DD/MM/YYYY HH:mm")}}
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button v-if="item.status == 1" variant="success" size="sm" @click="AlterStatus(item)" v-b-tooltip title="Promoção Ativa">
                                                    <b-icon icon="toggle-on" font-scale="1" />
                                                </b-button>
                                                <b-button v-if="item.status == 0" variant="warning" size="sm" @click="AlterStatus(item)" v-b-tooltip title="Promoção Inativa">
                                                    <b-icon icon="toggle-off" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button v-if="has_permission('promotions-edit')" variant="primary" size="sm" @click="$router.push({ path: '/utilitarios/promocoes/cadastro/' + item.id })" v-b-tooltip title="Editar">
                                                    <b-icon icon="pen" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button v-if="has_permission('promotions-delete')" variant="danger" size="sm" @click="Delete(item)" v-b-tooltip title="Excluir">
                                                    <b-icon icon="trash" font-scale="1" />
                                                </b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="8" class="text-center py-3">Nenhum registro encontrado</td>
                                        </tr>
                                    </template>
                                </rich-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable'
import Constants from '@/constants'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'
import ButtonGroup from '@/components/ButtonGroup'
import TypeableDate from '@/components/TypeableDate'

export default {

    components: {
        Panel, 
        RichTable,
        UtilBar,
        ButtonGroup,
        TypeableDate
    },

    data() {
        return {
            show: true,
            items: [],
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            loadingData: false,
            promotions_value_type: [
                { id: Constants.PROMOTION_VALUE_TYPE_PERCENTAGE, text: 'Porcentagem' },
                { id: Constants.PROMOTION_VALUE_TYPE_FIXED, text: 'Valor Fixo' },
                { id: null, text: 'Todos' },
            ],
            options: [
                {id: 1, text: 'Sim'},
                {id: 0, text: 'Não'},
                {id: null, text: 'Todos'},
            ],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            },
        }
    },

    watch: {
        'filters': {
            handler: function () {
                if(this.filters.value_type == null){
                    this.filters.value.start = ""
                    this.filters.value.end = ""
                }
                this.LoadData()
            },
            deep: true
        }
    },
    
    computed: {

        ...mapGetters('auth', [
            'has_permission'
        ]),

        filters: {
            get() {
                return this.$store.state.promotion.filters
            }
        },

        constants(){
            return Constants
        },

        table_cols() {
            let cols = [{
                    id: 'name',
                    text: 'Nome',
                    class: 'cols_name'
                }, {
                    id: 'type',
                    text: 'Tipo',
                    class: 'cols_type'
                }, {
                    id: 'coupon',
                    text: 'Cupom',
                    class: 'cols_coupon'
                }, {
                    id: 'start_date',
                    text: 'Data Inicial',
                    class: 'cols_start_date'
                }, {
                    id: 'end_date',
                    text: 'Data Final',
                    class: 'cols_end_date'
                }, {
                    id: null,
                    text: '',
                    class: 'cols_button_1'
                }, {
                    id: null,
                    text: '',
                    class: 'cols_button_2'
                }, {
                    id: null,
                    text: '',
                    class: 'cols_button_3'
                },
            ];
            return cols;
        },
    },


    mounted() {
        this.LoadData();
    },

    methods: {

        CleanFilters() {
            this.$store.commit('promotion/SET_CLEAN_FILTERS')
            this.$refs["promotionRichTable"].CleanFilters();
        },

        filterPromotions() {
            this.LoadData();
        },
        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },

        LoadData() {
            this.loadingData = true;

            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('promotion/getPromotions', params).then(data => {
                this.loadingData = false;
                this.items = data.items ? data.items : [];
                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;
            });
        },

        Delete(promotion) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: 'Excluir Promoção',
                html: `Deseja realmente excluir a promoção <strong>${promotion.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    this.$store.dispatch('promotion/deletePromotion', promotion).then(response => {
                        this.LoadData();
                        this.Sweealert2('success', response.success);
                    }).catch(() => {});
                }
            })
        },

        AlterStatus(promotion) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: promotion.status == 1 ? 'Desativar Promoção' : 'Ativar Promoção',
                html: `Deseja realmente ${promotion.status == 1 ? 'desativar' : 'ativar'} a promoção <strong>${promotion.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    this.$store.dispatch('promotion/updateStatusPromotion', promotion.id).then(response => {
                        this.LoadData();
                        this.Sweealert2('success', response.success);
                    }).catch(() => {});
                }
            })
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    }
}
</script>

<style>

</style>
<template>
    <div>
        <b-button @click="OpenCreateScheduling()" variant="warning" size="sm" v-b-tooltip title="Agendar">
            <b-iconstack font-scale="1.5">
                <b-icon stacked icon="calendar"></b-icon>
                <b-icon stacked shift-v="-1" scale="0.5" icon="telephone-forward"></b-icon>
            </b-iconstack>
        </b-button>
        <b-modal id="modal-scheduling" centered size="lg" ref="modal-scheduling" no-close-on-backdrop no-close-on-esc no-enforce-focus hide-footer>
            <template #modal-header>
                <span>
                    Agendar data da avaliação técnica para <b>{{ worker.name }}</b>.
                </span>
                <span>
                    <b-icon icon="x" scale="1.5" @click="CancelScheduling" class="zoom-1-2"></b-icon>
                </span>
            </template>

            <loader-mary-beleza :show="loading"/>
            <div>
                <b-row v-if="!scheduling && !history">
                    <b-col sm="12" md="12" lg="6">
                        <span class="text-center d-flex justify-content-around">
                            <h5>Não Agendados</h5>
                        </span>
                        <b-card v-for="(hability, key) in has_not_scheduling_habilities" :key="key" class="my-2">
                            <b-row>
                                <b-col sm="12" md="12">
                                    <span><b>{{hability.name}}</b></span>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card v-if="has_not_scheduling_habilities.length == 0" class="my-2">
                            <b-row>
                                <b-col sm="12" md="12">
                                    <span>Não encontramos nenhuma habilidade!</span>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col>
                        <span class="text-center d-flex justify-content-around">
                            <h5>Agendados</h5>
                        </span>
                        <b-card class="my-2" v-for="(hability, key) in has_scheduling_habilities" :key="key">
                            <b-row>
                                <b-col sm="12" md="12">
                                    <span><b>{{ hability.name }}</b></span>
                                </b-col>
                                <b-col sm="10" md="10" lg="10" v-if="hability.pivot.scheduling_technical">
                                    <span class="text-muted"><b-icon icon="clock-history" scale="1.1" class="mr-1" v-b-tooltip="'Data do agendamento.'"></b-icon> {{ hability.pivot.scheduling_technical | moment("DD/MM/YYYY HH:mm") }}</span>
                                </b-col>
                                <b-col sm="2" md="2" lg="2">
                                    <b-icon icon="exclamation-triangle-fill" class="zoom-1-2" scale="1.5" variant="danger" @click="AbsenceScheduling(hability)" v-b-tooltip="'Informar não comparecimento!'"></b-icon>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card v-if="has_scheduling_habilities.length == 0" class="my-2">
                            <b-row>
                                <b-col sm="12" md="12">
                                    <span>Não encontramos nenhum agendamento!</span>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col sm="12" md="12" lg="12">
                        <b-row>
                            <b-col sm="6" md="6" lg="6">
                                <b-button v-if="has_not_scheduling_habilities.length > 0" block @click="ShowScheduling" variant="outline-mary-beleza"><b-icon icon="calendar-event-fill" scale="1.2" class="mr-3"></b-icon>Agendar avaliações</b-button>
                            </b-col>
                            <b-col sm="6" md="6" lg="6">
                                <b-button v-if="worker_habilities_scheduling.length > 0" block @click="ShowHistory" variant="outline-mary-beleza"><b-icon icon="file-text-fill" scale="1.2" class="mr-3"></b-icon>Ver histórico de agendamentos</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="scheduling">
                    <b-col sm="12" md="12" lg="12" class="text-center d-flex justify-content-around">
                        <h5>Agendamento</h5>
                    </b-col>
                    <b-col v-for="(hability, key) in has_not_scheduling_habilities" :key="key" sm="12" md="6" xl="6" class="my-2">
                        <b-card :class="errors.find(item => item == key) != null ? 'h-100 border-danger' : 'h-100'">
                            <b-row>
                                <b-col sm="1" md="2">
                                    <b-iconstack v-if="SelectedHability(hability.id)" @click="SetHability(hability)" style="cursor: pointer;" font-scale="1.2">
                                        <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                        <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                    </b-iconstack>
                                    <b-icon v-else variant="mary-beleza" icon="circle" @click="SetHability(hability)" style="cursor: pointer;" font-scale="1.2" />
                                </b-col>
                                <b-col sm="11" md="10">
                                    <span><b>{{hability.name}}</b></span>
                                </b-col>
                            </b-row>
                            <b-row v-if="SelectedHability(hability.id)">
                                <b-col sm="12" md="6" lg="6">
                                    <label>Data de contato: </label>
                                    <v-date-picker :value="GetHabilityScheduling(hability).contact_scheduling_technical" @input="SetHabilityContactTechnical(hability, $event)" :model-config="modelConfig" mode="dateTime" is24hr>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                            class="py-1 px-2 w-100 border rounded focus:outline-none"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            />
                                        </template>
                                    </v-date-picker>
                                </b-col>
                                <b-col sm="12" md="6" lg="6">
                                    <label>Data agendada: </label>
                                    <v-date-picker :value="GetHabilityScheduling(hability).scheduling_technical" @input="SetHabilitySchedulingTechnical(hability, $event)" :model-config="modelConfig" mode="dateTime" is24hr>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                            class="py-1 px-2 w-100 border rounded focus:outline-none"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            />
                                        </template>
                                    </v-date-picker>
                                </b-col>
                                <b-col v-if="GetHabilityScheduling(hability).count_scheduling">
                                    <p class="mb-0 mt-2 text-warning">Possui <b>{{GetHabilityScheduling(hability).count_scheduling}}</b> agendamento(s) na data escolhida!</p>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col sm="12" md="12" lg="12" class="justify-content-between d-flex mt-3">
                        <b-button variant="outline-mary-beleza" @click="BackScheduling">Voltar</b-button>
                        <b-button variant="mary-beleza" @click="SaveScheduling">Salvar</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="history">
                    <b-col sm="12" md="12" lg="12" class="text-center d-flex justify-content-around">
                        <h5>Histórico</h5>
                    </b-col>
                    <b-col v-for="(hability, key) in worker_habilities_scheduling.filter(item => item.id != null)" :key="key" sm="12" md="6" xl="6" class="my-2">
                        <b-card>
                            <b-row>
                                <b-col sm="12" md="12" lg="12">
                                    <span><b>{{ hability.name }}</b></span>
                                </b-col>
                                <b-col sm="6" md="6" lg="6" v-if="hability.pivot.scheduling_technical">
                                    <span class="text-muted"><b-icon icon="clock-history" scale="1.1" class="mr-1" v-b-tooltip="'Data do agendamento.'"></b-icon> {{ hability.pivot.scheduling_technical | moment("DD/MM/YYYY HH:mm") }}</span>
                                </b-col>
                                <b-col sm="2" md="2" lg="2" v-if="hability.pivot.absence">
                                    <b-icon icon="dash-circle-fill" scale="1.5" variant="warning" v-b-tooltip="'Não comparecido!'"></b-icon>
                                </b-col>
                                <b-col sm="2" md="2" lg="2" v-if="hability.pivot.absence_reason">
                                    <b-icon icon="chat-left-text-fill" scale="1.5" variant="info" v-b-tooltip="'Motivo: '+hability.pivot.absence_reason"></b-icon>
                                </b-col>
                                <b-col sm="2" md="2" lg="2">
                                    <b-icon @click="DeleteScheduling(hability)" icon="trash" scale="1.5" variant="danger" class="zoom-1-2" v-b-tooltip="'Apagar agendamento'"></b-icon>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col sm="12" md="12" lg="12" class="justify-content-between d-flex mt-3">
                        <b-button variant="outline-mary-beleza" @click="BackHistory">Voltar</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Http from '@/api/http'
import Constants from '@/constants'
import swal from 'sweetalert2'
import moment from 'moment'

export default {
    props: {
        worker: {
            type: Object,
            required: true,
        }
    },

    data() {
       return {
            scheduling: false,
            history: false,
            loading: false,
            habilities: [],
            errors: [],
            worker_habilities: [],
            worker_habilities_scheduling: [],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm',
            },
       } 
    },

    methods: {
        LoadData() {
            this.loading = true;
            this.scheduling = false;
            this.history = false;
            Http.get(`worker/${this.worker.id}/scheduling/habilities`).then(response => {
                this.worker_habilities = response.data.worker_habilities ? response.data.worker_habilities : [];
                this.worker_habilities_scheduling = response.data.worker_habilities_scheduling ? response.data.worker_habilities_scheduling : [];
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },

        SaveScheduling(){
            if(this.habilities.length <= 0){
                swal.fire("", "Não possui nenhuma habilidade agendada para salvar!", "error");
                return;
            }

            this.errors = [];
            this.habilities.forEach(item => {
                if(!item.contact_scheduling_technical || !item.scheduling_technical){
                    let index = this.has_not_scheduling_habilities.findIndex(item2 => item2.id == item.hability_id);
                    this.errors.push(index);
                }
            })

            if(this.errors.length > 0){
               swal.fire("", "Por favor preencha todos os campos!", "error");
               return;
            }

            var data = {
                worker_id: this.worker.worker.id,
                status: Constants.STATUS_SCHEDULED_TECHNICAL,
                scheduling_technical: this.habilities
            }
            this.loading = true
            Http.put(`worker/${this.worker.worker.id}/scheduling`, data).then(response => {
                swal.fire("", response.data.success, "success");
                this.LoadData();
                this.loading = false;
            }).catch(error => {
                swal.fire("", error.response.data.error, "error");
                this.loading = false;
            })
        },

        ShowScheduling(){
            this.errors = [];
            this.habilities = [];
            this.scheduling = true;
        },

        BackScheduling(){
            this.scheduling = false;
        },

        ShowHistory(){
            this.history = true;
        },

        BackHistory(){
            this.history = false;
        },

        CancelScheduling(){
            this.$refs['modal-scheduling'].hide();
            this.$emit("update:reload_rich_table");
        },

        OpenCreateScheduling(){
            this.LoadData();
            this.scheduling = false;
            this.history = false;
            this.habilities = [];
            this.$refs['modal-scheduling'].show();
        },

        SchedulingCount(date, hability_id){
            var params = { 
                offset: 0, 
                limit: 1, 
                sort_order: "asc", 
                sort_column: "name", 
                status: 3,
                scheduling_technical: moment(date).format('YYYY-MM-DD'),
            }
            Http.get('worker',{params}).then(response => {
                let index = this.habilities.findIndex(item => item.hability_id == hability_id);
                this.habilities[index].count_scheduling = response.data.total ? response.data.total : null;
            });
        },

        GetHabilityAndSubHability(hability_id){
            var hability = this.worker_habilities.find(item => item.id == hability_id);
            var hability_parent = this.worker_habilities.find(item => item.id == hability.parent_id);
            return {
                hability_name: hability?.name,
                parent_name: hability_parent?.name,
            }
        },

        HabilityAsScheduling(hability_id){
            var hability = this.worker_habilities_scheduling.find(item => item.id == hability_id);
            return hability ? true : false;
        },

        SetHability(hability){
            let index = this.habilities.findIndex(item => item.hability_id == hability.id);

            if(index < 0) {
                this.habilities.push({
                    hability_id: hability.id,
                    contact_scheduling_technical: moment().format("YYYY-MM-DD HH:mm:ss"),
                    scheduling_technical: null,
                    count_scheduling: 0
                });
            } else {
                this.habilities.splice(index, 1);
            }
        },

        SelectedHability(hability_id){
            var hability = this.habilities.find(item => item.hability_id == hability_id);
            return hability ? true : false;
        },

        SetHabilityContactTechnical(hability, value){
            let index = this.habilities.findIndex(item => item.hability_id == hability.id);
            this.habilities[index].contact_scheduling_technical = value;
        },

        GetHabilityScheduling(hability){
            return this.habilities.find(item => item.hability_id == hability.id);
        },

        SetHabilitySchedulingTechnical(hability, value){
            let index = this.habilities.findIndex(item => item.hability_id == hability.id);
            this.habilities[index].scheduling_technical = value;
            this.SchedulingCount(value, hability.id);
        },

        AbsenceScheduling(hability){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                title: `Não comparecimento`,
                html: `Deseja realmente informar o não comparecimento da(o) profissional <strong>${this.worker.name}</strong> na avaliação de <strong>${hability.name}</strong>? Por favor informe o motivo:`,
                icon: 'question',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    Http.post(`/worker/scheduling/${hability.pivot.id}/absence`, {absence_reason: result.value}).then(response => {
                        swal.fire("", response.data.success, "success");
                        this.LoadData();
                        this.loading = false;
                    }).catch(error => {
                        swal.fire("", error.response.data.error, "error");
                        this.loading = false;
                    });
                }
            })
        },

        DeleteScheduling(hability){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Apagar`,
                html: `Deseja realmente apagar o agendamento da(o) profissional <strong>${this.worker.name}</strong> na avaliação de <strong>${hability.name}</strong>?`,
                icon: 'question',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    Http.delete(`/worker/scheduling/${hability.pivot.id}`).then(response => {
                        swal.fire("", response.data.success, "success");
                        this.LoadData();
                        this.loading = false;
                    }).catch(error => {
                        swal.fire("", error.response.data.error, "error");
                        this.loading = false;
                    });
                }
            })
        },
    },

    computed: {

        has_not_scheduling_habilities: {
            get(){
                return this.worker_habilities.filter(item => item.parent_id == null && !this.has_scheduling_habilities.find(item2 => item2.id == item.id));
            }
        },

        has_scheduling_habilities: {
            get(){
                return this.worker_habilities_scheduling.filter(item => item.id != null && !item.pivot.absence);
            }
        }
    }

}
</script>
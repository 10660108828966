export default class Filters {

    constructor() {

        this.created_at = {
            start: null,
            end: null
        },
        this.min_sent_date = {
            start: null,
            end: null
        },
        this.deleted = null
        this.created = null
    }
}
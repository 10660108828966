<template>
    <div v-if="!inline" class="calendar-wrapper">
        <b-row>
            <b-col sm="12" md="12" lg="12" class="d-flex justify-content-center py-2">
                <div class="navigator-year mr-3" @click="selectYear('left')"><b-icon icon="chevron-left" /></div>
                <div class="current-year"><strong>{{reference_year}}</strong></div>
                <div class="navigator-year ml-3" @click="selectYear('right')"><b-icon icon="chevron-right" /></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="months" :class="valueIsEqual(reference_year+'-'+month.number) ? 'selected' : ''" sm="4" md="4" lg="4" v-for="(month, i) in months" :key="i" :selected="valueIsEqual(reference_year+'-'+month.number)" :disabled="monthIsDisabled(reference_year+'-'+month.number)" @click="selectMonth(reference_year+'-'+month.number)">
                {{month.name}}                 
            </b-col>
        </b-row>
    </div>

    <div v-else class="calendar-wrapper-inline noselect">
        <b-row>
            <b-col sm="12" md="3" lg="3" class="d-flex align-content-center justify-content-between align-middle py-2">
                <div class="navigator-year" @click="selectYear('left')"><b-icon icon="chevron-left" /></div>
                <div class="current-year align-middle"><strong>{{reference_year}}</strong></div>
                <div class="navigator-year" @click="selectYear('right')"><b-icon icon="chevron-right" /></div>
            </b-col>
            <b-col sm="12" md="9" lg="9" class="months-list">
                <div class="months inline" :class="valueIsEqual(reference_year+'-'+month.number) ? 'selected' : ''" v-for="(month, i) in months" :key="i" :selected="valueIsEqual(reference_year+'-'+month.number)" :disabled="monthIsDisabled(reference_year+'-'+month.number)" @click="selectMonth(reference_year+'-'+month.number)">{{month.name}}</div>   
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        disabledDates: {
            required: false,
            type: Array
        },
        minMonthEqual: {
            required: false,
            type: Boolean,
            default: true
        },
        minMonth: {
            required: false,
            type: String
        },
        maxMonth: {
            required: false,
            type: String
        },
        maxMonthEqual: {
            required: false,
            type: Boolean,
            default: true
        },
        value: {
            required: false,
            type: [String, Array]
        },
        block: {
            required: false,
            type: Boolean,
            default: false
        },
        inline: {
            required: false,
            type: Boolean,
            default: false
        },
        multiple: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        months() {
            return [
                { name: 'jan', number: '01' },
                { name: 'fev', number: '02' },
                { name: 'mar', number: '03' },
                { name: 'abr', number: '04' },
                { name: 'mai', number: '05' },
                { name: 'jun', number: '06' },
                { name: 'jul', number: '07' },
                { name: 'ago', number: '08' },
                { name: 'set', number: '09' },
                { name: 'out', number: '10' },
                { name: 'nov', number: '11' },
                { name: 'dez', number: '12' }
            ];
        },
        currentMonth() {
            return moment(this.value).format("MM");
        }
    },
    data() {
        return {
            reference_year: null,
            valid_month: moment().format("YYYY-MM")
        }
    },
    beforeMount() {
        var values = this.multiple ? (Array.isArray(this.value) ? this.value : []) : [this.value];
        for (var i = 0; i < values.length; i++) {
            var value = values[i];
            if (!value || !moment(value, "YYYY-MM").isValid() || this.value.length != 7)
            {
                if (!this.multiple) {
                    var default_month = this.valid_month;
                    if (!this.monthIsDisabled(default_month)) {
                        default_month = this.valid_month;
                    }

                    this.reference_year = Number.parseInt(moment(default_month).format("YYYY"));
                    values[i] = default_month;
                }
                else {
                    values[i] = "invalid";
                }
            }
        }

        values = values.filter(i => i != 'invalid');
        if (values.length == 0) {
            this.reference_year = Number.parseInt(moment().format("YYYY"));
        }
        else {
            var reference = values.sort((a, b) => {
                return a < b ? -1 : 1;
            })[0];

            this.reference_year = Number.parseInt(moment(reference).format("YYYY"));
        }

        values = this.multiple ? values : values[0];
        
        this.$emit("input", values);
    },
    methods: {
        monthIsDisabled(date) {
            var disabled = false;

            if (this.minMonth && (this.minMonthEqual ? moment(date, "YYYY-MM").isBefore(moment(this.minMonth, "YYYY-MM")) : moment(date, "YYYY-MM").isSameOrBefore(moment(this.minMonth, "YYYY-MM"))) ) {
                if (!this.multiple) {
                    this.valid_month = this.minMonthEqual ? this.minMonth : moment(this.minMonth, "YYYY-MM").add(1, "month").format("YYYY-MM");
                }
                disabled = true;
            } 
            else if (this.maxMonth && (this.maxMonthEqual ? moment(date, "YYYY-MM").isAfter(moment(this.maxMonth, "YYYY-MM")) : moment(date, "YYYY-MM").isSameOrAfter(moment(this.maxMonth, "YYYY-MM")) ) ) {
                if (!this.multiple) {
                    this.valid_month = this.maxMonthEqual ? this.maxMonth : moment(this.maxMonth, "YYYY-MM").subtract(1, "month").format("YYYY-MM");
                }
                disabled = true;
            } 
            else if (this.disabledDates && this.disabledDates.filter(d => d == date).length > 0)
            {
                if (!this.multiple) {
                    this.valid_month = "";
                }

                disabled = true;
            }

            if (!this.multiple) {
                this.valid_month = ""+this.valid_month;

                if (this.value == date && disabled) {
                    this.$emit("input", this.valid_month);
                }
            }

            return disabled;
        },
        selectMonth(date) {
            if (this.monthIsDisabled(date))
                return;

            var value = null;
            if (!this.multiple) {
                value = date;
            }
            else {
                value = Array.isArray(this.value) ? this.value : [];
                if (this.valueIsEqual(date)) {
                    value = value.filter(v => v != date);
                    this.$emit("input", value);
                    return;
                }

                value.push(date);
                var distinct = [];
                for (var i = 0; i < value.length; i++) {
                    var exists = false;
                    for (var item of distinct) {
                        if (item == value[i]) {
                            exists = true;
                            break;
                        }
                    }

                    if (!exists) {
                        distinct.push(value[i]);
                    }
                }

                value = distinct;
                value = value.sort((a, b) => {
                    return a < b ? -1 : 1;
                });
            }

            this.$emit("input", value);
            this.$emit("selectMonth", value);
        },
        selectYear(direction)
        {
            if (direction == "left")
            {
                this.reference_year--;
            }
            else
            {
                this.reference_year++;
            }

            this.reference_year = Math.max(this.reference_year, 1);
        },
        valueIsEqual(competence) {
            var equal = false;
            if (!this.multiple) {
                equal = this.value == competence;
            }
            else if (Array.isArray(this.value)) {
                for (var i = 0; i < this.value.length; i++) {
                    if (this.value[i] == competence) {
                        equal = true;
                        break;
                    }
                }
            }

            return equal;
        }
    }
}
</script>

<style lang="scss" scoped>
    .months-list {
        display: flex;
        align-content: center;
        
    }

    .months {
        width: 8.333333%;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        text-align: center;
        padding: 0.575rem 0;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
            transition: all 0.4s ease;
            background-color: rgb(243, 243, 243);
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-right: 1px solid #ddd;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &.selected {
            transition: all 0.4s ease;
            background-color: #ddd;
        }

        &.inline {
            border-bottom: 1px solid #ddd;
            padding: 0.375rem 0 !important;
        }
    }

    .navigator-year
    {
        transition: all 0.4s ease;
        cursor: pointer;

        &:hover {
            color: #888;
        }
    }

    .calendar-wrapper {
        width: 100%;
        background-color: #fafafa;
        overflow: hidden;
        border: 1px solid #ddd;
        border-radius: 5px;

        .row {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    @media screen and (max-width: 767px) {
        .months-list {
            display: flex;
            flex-wrap: wrap;
        }

        .months {
            width: 33.333333%;
            border-right: solid 1px #ddd;
            
            &:first-child, &:last-child {
                border-radius: 0;
            }
        }
    }
</style>



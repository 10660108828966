<template>
    <div>
        <b-card style="height:100%;">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h6 class="mb-0 font-weight-bold">Cliente</h6>
                    <div v-if="customer">
                        <span>
                            <b-icon id="button_remove_customer" class="zoom-1-2 ml-2" variant="mary-beleza" scale="2.1" icon="x" @click="RemoveCustomer"></b-icon>
                            <b-tooltip target="button_remove_customer" placement="top">
                                Remover cliente
                            </b-tooltip>
                        </span>
                    </div>
                </div>
            </template>
            <div v-if="customer">
                <b-row class="justify-content-center">
                    <b-avatar size="140" class="bg-mary-beleza-gradient" style="margin-top:-120px">
                        <b-avatar :src="customer.image" size="96%">
                        </b-avatar>
                    </b-avatar>
                </b-row>
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <label>Nome:</label>
                        <div class="link-open" @click="$router.push({ path: '/clientes/cadastro/' + customer_id })">
                            {{ customer.name }}
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <b-icon icon="at" variant="danger" scale="1.2" class="mr-1"></b-icon>
                        <label>E-mail:</label>
                        <div>
                            <p v-if="!customer.email">
                                <strong class="text-danger">-</strong>
                            </p>
                            <p v-else>{{ customer.email }}</p>
                        </div>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <!-- <img :src="img_service_phone" width="18" :id="'worker-phone'" class="mr-2" /> -->
                        <label>Telefones:</label>
                        <div v-if="customer.phones.length > 0">
                            <b-row>
                                <b-col sm="4" md="4" lg="6" xl="4" v-for="(phone, index) in customer.phones" :key="index" class="mb-1">
                                    {{ phone.phone_number | formatPhones }}
                                    <b-badge v-if="phone.is_personal" variant="primary" class="mr-1" style="font-size:11px;">Pessoal</b-badge>
                                    <b-badge v-if="phone.is_whatsapp" variant="success" style="font-size:11px;">WhatsApp</b-badge>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else>
                            <strong class="text-danger">-</strong>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-else class="text-center">
                <div>
                    <multiselect placeholder="Selecione" :internal-search="false" :loading="loading" @search-change="Search" hide-selected selectedLabel="Selecionado" track-by="id" label="name" selectLabel="Pressione enter para selecionar" deselectLabel="Pressione enter para remover" v-model="customer" :options="customers" >
                        <template slot="noResult">
                            Nenhum resultado encontrado
                        </template>
                        <template slot="noOptions">
                            Nenhum resultado encontrado
                        </template>
                    </multiselect>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    props: {
        value: {
            required: true
        }
    },
    components: {
        Multiselect
    },
    computed: {
        customer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    data() {
        return {
            loading: false,
            customers: []
        }
    },
    created() {
        this.Search("");
    },
    methods: {
        Search(query) {
            var params = {
                offset: 0,
                limit: 100,
                sort_column: "name",
                sort_order: "asc",
                search: query
            };
            this.loading = true;
            this.$store.dispatch('service/getCustomers', params).then(data => {
                this.customers = data.items ? data.items : [];
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        RemoveCustomer() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover a(o) cliente?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.customer = null;
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div>
        <footer class="d-flex justify-content-between p-4">
            <ul class="list-group list-group-horizontal help">
                <li class="list-group-item py-0 pr-3 border-left-0 border-top-0 border-bottom-0 border-right-0">
                    <router-link to="/faq">
                        <fa :icon="['far', 'life-ring']" class="mr-2" />
                        <span>Central de Ajuda</span>
                    </router-link>
                </li>

                <!-- <li class="list-group-item py-0 pr-3 border-left-0 border-top-0 border-bottom-0 border-right">
                    <router-link to="/">
                        <fa :icon="['far', 'comments']" class="mr-2" />
                        <span>Central de Relacionamento</span>
                    </router-link>
                </li> -->

                <!-- <li class="list-group-item py-0 pr-3 border-left-0 border-top-0 border-bottom-0 border-right">
                    <router-link to="/">
                        <fa :icon="['fa', 'phone-volume']" class="mr-2" />
                        <span>Script Atendimento Telefônico</span>
                    </router-link>
                </li>

                <li class="list-group-item py-0 pr-3 border-left-0 border-top-0 border-bottom-0 border-right">
                    <router-link to="/">
                        <fa :icon="['fa', 'mail-bulk']" class="mr-2" />
                        <span>Script Envio de E-mails</span>
                    </router-link>
                </li> -->
            </ul>
            
            <ul class="list-group list-group-horizontal font-weight-light developed">
                <li class="list-group-item py-0 pr-3 border-left-0 border-top-0 border-bottom-0 border-right">
                    Mary Beleza &copy; {{ moment().format('YYYY') }}
                </li>
                <li class="list-group-item py-0 pl-3 border-left border-top-0 border-bottom-0 border-right-0">
                    <a href="https://www.rebuut.com.br" target="_blank">Desenvolvido por Rebuut</a>
                </li>
            </ul>
        </footer>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    methods: {
        moment(...args){
            return moment(...args);
        },
    }
}
</script>

<style scoped>

</style>
const io = require('socket.io-client').default;

var conn = io();
Object.defineProperty(window, "io", {
    configurable: false,
    writable: false,
    enumerable: false,
    value: conn
})

export default {
    listen(channel, callback) {
        if (!channel) {
            console.error("Canal de conexão socket io é necessário.")
        }
        else if (!callback) {
            console.error("Callback socket io é necessário.");
        }
        else {
            window.io.on(channel, callback);
        }
    },
    stop(channel) {
        window.io.removeListener(channel);
    }
}
<template>
    <div id="change-password">
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button @click="ChangePassword()" class="btn btn-mary-beleza">
                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel>
                <b-row>
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <div role="group">
                                    <label>Nova senha:</label>
                                    <b-form-input type="password" v-model="model.newpassword" aria-describedby="input-live-help input-live-feedback" placeholder="Nova senha"></b-form-input>
                                    <b-form-invalid-feedback id="input-live-feedback">
                                        Mostra erros
                                    </b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <div role="group">
                                    <label>Confirma nova senha:</label>
                                    <b-form-input type="password" :state="validation" v-model="model.confirmpassword" aria-describedby="input-live-help input-live-feedback" placeholder="Confirma nova senha"></b-form-input>
                                    <b-form-invalid-feedback :state="validation" id="input-live-feedback">
                                        Senha diferente.
                                    </b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </panel>
        </div>


    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import Http from '@/api/http'
import swal from 'sweetalert2'

export default {
    data() {
        return {
            model: {
                newpassword:'',
                confirmpassword:''
            },
        }
    },
    components: {
        Panel, UtilBar
    },
    methods: {
        ChangePassword(){
            if(this.model.newpassword != this.model.confirmpassword){
                swal.fire("Falha", "A senha deve ser igual nos dois campos!", "error");
                return 
            } 
            
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                icon: "warning",
                title: "Nova senha",
                text: "Deseja reamente salvar as alterações a senha?"
            }).then((result) => {
                if (result.value) {
                    swal.fire({
                        title: "Salvando",
                        didOpen() {
                            swal.showLoading();
                        }
                    })
                    Http.post("/changePassword", {password: this.model.newpassword}).then(response => {
                        swal.fire("Sucesso", response.data.success, "success");
                        this.model.newpassword = null;
                        this.model.confirmpassword = null;
                    }).catch(error => {
                        swal.fire("Falha", error.response.data.error, "error");
                    })
                }
            });
        },
    },
    computed: {
        validation() {
            return this.model.confirmpassword ? this.model.newpassword != this.model.confirmpassword ? false : true : null
        }
    }
}
</script>

<style>

</style>
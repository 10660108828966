<template>
    <div class="quiz-medal text-center" :class="size" v-if="!image_mode">
        <div :class="`quiz-medal__circle quiz-medal__circle--${type}`">
            <slot></slot>
        </div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
    </div>
    <div v-else class="quiz-medal-img text-center" :class="size">
      <img :src="image[type]" />
    </div>
</template>

<script>
import ImageLoader from '@/mixins/ImageLoader'

export default {
    mixins: [ ImageLoader ],
    props: {
        image_mode: {
          default: true,
          type: Boolean
        },
        type: {
            required: false,
            default: 'gold'
        },
        size: {
            required: false,
            default: 'md'
        }
    },
    computed: {
      image() {
        switch(this.size) {
          case "lg":
            return {
              bronze: this.bronze_lg,
              silver: this.silver_lg,
              gold: this.gold_lg,
              ruby: this.ruby_lg
            }
          case "md":
            return {
              bronze: this.bronze_md,
              silver: this.silver_md,
              gold: this.gold_md,
              ruby: this.ruby_md
            }
          case "sm":
            return {
              bronze: this.bronze_sm,
              silver: this.silver_sm,
              gold: this.gold_sm,
              ruby: this.ruby_sm
            }
          default:
            return {}
        }
      }
    }
}
</script>

<style scoped>
html {
  box-sizing: border-box;
}

body {
  display: flex;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.quiz-medal {
  margin: 0;
}

.quiz-medal {
  position: relative;
  margin-bottom: 16px;
}

.quiz-medal__circle {
    text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 500;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 46px;
  vertical-align: middle;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 2px 2px 0 #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle.quiz-medal__circle--ruby {
    box-shadow: inset 0 0 0 #ff649e, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #ff649e;
    text-shadow: 0 0 4px #e31e63;
    background: linear-gradient(to bottom right, #ff649e 50%, #e31e63 50%);
}
.quiz-medal__circle.quiz-medal__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.quiz-medal__circle.quiz-medal__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}

.quiz-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 10px;
  width: 0;
  height: 20px;
  top: 50px;
}

.quiz-medal__ribbon--left {
  border-color: #fc402d #fc402d transparent #fc402d;
  left: 8px;
  transform: rotate(20deg) translateZ(-32px);
}

.quiz-medal__ribbon--right {
  left: 28px;
  border-color: #f31903 #f31903 transparent #f31903;
  transform: rotate(-20deg) translateZ(-48px);
}

.quiz-medal.sm * {
    font-size: 11px;
}

.quiz-medal.sm .quiz-medal__circle {
    width: 32px;
    height: 32px;
    line-height: 30px;
}

.quiz-medal.sm .quiz-medal__ribbon {
    border-width: 3px 4px;
    width: 0;
    height: 10px;
    top: 30px;
}

.quiz-medal.sm .quiz-medal__ribbon--left {
    left: 8px;
}

.quiz-medal.sm .quiz-medal__ribbon--right {
    left: 16px;
}

.quiz-medal.md * {
    font-size: 18px;
}

.quiz-medal.md .quiz-medal__circle {
    width: 64px;
    height: 64px;
    line-height: 60px;
}

.quiz-medal.md .quiz-medal__ribbon {
    border-width: 6px 10px;
    width: 0;
    height: 20px;
    top: 55px;
}

.quiz-medal.md .quiz-medal__ribbon--left {
    left: 13px;
}

.quiz-medal.md .quiz-medal__ribbon--right {
    left: 32px;
}

.quiz-medal.lg * {
    font-size: 30px;
}

.quiz-medal.lg .quiz-medal__circle {
    width: 128px;
    height: 128px;
    line-height: 118px;
}

.quiz-medal.lg .quiz-medal__ribbon {
    border-width: 6px 20px;
    width: 0;
    height: 50px;
    top: 100px;
}

.quiz-medal.lg .quiz-medal__ribbon--left {
    left: 26px;
}

.quiz-medal.lg .quiz-medal__ribbon--right {
  left: 60px;
}

.quiz-medal-img {
  margin-bottom: 0px !important;
}

.quiz-medal-img.lg {
  text-align: center;
}

.quiz-medal-img.lg > img {
  width: auto;
  height: 160px;
}

.quiz-medal-img.md > img {
  width: auto;
  height: 66px;
}

.quiz-medal-img.md > img {
  width: auto;
  height: 34px;
}
</style>
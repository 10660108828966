<template>
    <div>
        <app-header></app-header>

        <div class="ml-0 mr-0" id="body-row">
            <app-nav></app-nav>

            <div class="col pl-0 pr-0 pb-3" id="mb-content">
                
                <div>
                    <slot></slot>
                    <fab-button v-if="has_permission('faq-list')" :items="items"/>
                </div>
            </div>
        </div>
        <div class="row mr-0">
            <div class="col">
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</template>

<script>
    import AppHeader from '@/components/AppHeader'
    import AppNav from '@/components/AppNav'
    import AppFooter from '@/components/AppFooter'
    import FabButton from './FabButton'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            AppHeader,
            AppNav,
            AppFooter,
            FabButton
        },
        data(){
            return {
                items: [
                    {
                        title: 'FAQ',
                        iconLib: 'fas',
                        icon: 'question',
                        iconColor: 'white',
                        iconSize: '2',
                        background: '#EA60A7',
                        blank: false,
                        route: '/faq'
                    },
                    /* {
                        title: 'Whatsapp',
                        iconLib: 'fab',
                        icon: 'whatsapp',
                        iconColor: 'white',
                        iconSize: '2',
                        background: 'green',
                        blank: true,
                        route: 'https://web.whatsapp.com/'
                    }, */
                ]
            }
        },
		created() {
			this.LoadMenu();
		},
		methods: {
			LoadMenu() {
				this.$store.dispatch("system/load_menu")
			},
            cache(){
                alert('Cache Cleared');
            },
            alert(){
                alert('Clicked on alert icon');
            }
		},
        computed: {
            ...mapGetters('auth', [
                'has_permission'
            ]),
        }
    }
</script>

<style scoped>
    #body-row {
        display: grid;
        grid-template-columns: auto 1fr;
    }
</style>
export default class Filters {

    constructor() {
        this.status = null //Oportunidade / Aceito / A caminho / Cheguei / Finalizei / Cancelado
        this.service_date = {
            start: null,
            end: null
        }, //Periodo de datas para a busca
        this.payment = null // Realizado SIM - NÃO
        this.payment_date = {
            start: null,
            end: null
        }, // Data de pagamentos realizados
        this.received = null // Recebido SIM - NÃO
        this.received_date = {
            start: null,
            end: null
        }, // Data do recebimento
        this.received_type = null // Tipo de pagamento - Dinheiro / Cartão / ect...
        this.service_type = null // Tipo de serviço - Unha - Mãos - Pés
        this.status_assas = null // Status do pagamento do assas - VER
        this.worker_zone = null
        this.collection_assas = null
        this.payment_assas = null
        this.payment_status = null
        this.received_status = null
        this.services = null
        this.time_remaining = null
    }
}
<template>
<div>
    <panel title="ENTREVISTA" icon="address-card" icon-lib="far">
        <b-row>
            <b-col :class="!OpenForm.interview ? 'text-center col-sm-4 col-md-4 col-lg-4 form-group' : 'col-sm-12 col-md-12 col-lg-12 form-group'">
                <label for="input-live">Avaliação Pessoal:</label>
                <span>
                    <b-icon icon="patch-question-fill" scale="1.5" class="mx-2 text-mary-beleza" id="behavioral_grade"></b-icon>
                    <b-popover target="behavioral_grade" triggers="hover" placement="rightbottom">
                    <template #title>Observação</template>
                        <p>
                            Essa avaliação é referente a avaliação pessoal da(o) profissional.
                        </p>
                        <p>
                            A nota minima para a(o) profissional avançar com o cadastro é <strong>4 a 5</strong>.
                        </p>
                    </b-popover>
                </span>
                <template v-if="! OpenForm.interview">
                    <div class="mt-2">
                        <b-icon v-if="!behavioral_grade" icon="x-circle-fill" scale="1.50" variant="danger"></b-icon>
                        <template v-else>
                            <rating-emoji :emoji-remove-hidden="!OpenForm.interview" :title="(behavioral_grade == null ? 'Sem Nota' : 'Nota ' + behavioral_grade)" :value="behavioral_grade" readonly only-actived>
                                <template #label="{ label }">{{`${label} (${behavioral_grade})`}}</template>
                            </rating-emoji>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">
                        <rating-emoji v-model="behavioral_grade" :labels="ratings_desc"></rating-emoji>
                    </div>
                    <b-form-invalid-feedback :state="!errors.behavioral_grade">
                        <div v-for="error in errors.behavioral_grade" :key="error">
                            {{ error }}
                        </div>
                    </b-form-invalid-feedback>
                </template>
            </b-col>

            <b-col v-if="status >= constants.STATUS_SCHEDULED_TECHNICAL && status <= constants.STATUS_PROFESSIONAL_MB" :class="!OpenForm.interview ? 'text-center col-sm-4 col-md-4 col-lg-4 form-group' : 'col-sm-12 col-md-12 col-lg-12 form-group'">
                <label>Avaliação Técnica:</label>
                <span>
                    <b-icon icon="patch-question-fill" scale="1.5" class="mx-2 text-mary-beleza" id="technical_grade"></b-icon>
                    <b-popover target="technical_grade" triggers="hover" placement="rightbottom">
                    <template #title>Observação</template>
                        <p>
                            Essa avaliação é referente a avaliação técnica realizada pela(o) profissional.
                        </p>

                        <p>
                            A nota minima para a(o) profissional avançar com o cadastro é <strong>4 a 5</strong>.
                        </p>
                    </b-popover>
                </span>
                <template v-if="! OpenForm.interview">
                    <div class="mt-2">
                        <b-icon v-if="!technical_grade" icon="x-circle-fill" scale="1.50" variant="danger"></b-icon>
                        <rating-emoji v-else :emoji-remove-hidden="!OpenForm.interview" :title="(technical_grade == null ? 'Sem Nota' : 'Nota ' + technical_grade)" :value="technical_grade" readonly only-actived>
                            <template #label="{ label }">{{`${label} (${technical_grade})`}}</template>
                        </rating-emoji>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">
                        <rating-emoji v-model="technical_grade" :labels="ratings_desc"></rating-emoji>
                    </div>
                    <b-form-invalid-feedback :state="!errors.technical_grade">
                        <div v-for="error in errors.technical_grade" :key="error">
                            {{ error }}
                        </div>
                    </b-form-invalid-feedback>
                </template>
            </b-col>
            <b-col :class="!OpenForm.interview ? 'text-center col-sm-4 col-md-4 col-lg-4 form-group' : 'col-12 col-sm-12 col-md-12 col-lg-12 form-group'" v-if="behavioral_grade && technical_grade">
                <label >Avaliação Geral:</label>
                <span>
                    <b-icon icon="patch-question-fill" scale="1.5" class="mx-2 text-mary-beleza" id="total_grade"></b-icon>
                    <b-popover target="total_grade" triggers="hover" placement="rightbottom">
                    <template #title>Observação</template>
                        <p>
                            <b>Média</b> da avaliação pessoal somada a avaliação técnica da(o) profissional.
                        </p>
                    </b-popover>
                </span>
                <template>
                    <div class="mt-2">
                        <b-icon v-if="!total_grade" icon="x-circle-fill" scale="1.50" variant="danger"></b-icon>
                        <rating-emoji v-else :emoji-remove-hidden="!OpenForm.interview" :title="(total_grade == null ? 'Sem Nota' : 'Nota ' + total_grade)" :value="Math.floor(total_grade)" readonly only-actived>
                            <template #label="{ label }">{{`${label} (${total_grade})`}}</template>
                        </rating-emoji>
                    </div>
                </template>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" md="12" lg="12" class="form-group">
                <div role="group">
                    <label for="input-live">Observação:</label>
                    <template v-if="! OpenForm.interview">
                        <p v-if="!interview_details">
                            -
                        </p>
                        <p v-else>
                            <span v-html="interview_details"></span>
                        </p>
                    </template>
                    <template v-else>
                        <b-form-textarea id="textarea-rows" v-model="interview_details" rows="4"></b-form-textarea>
                        <b-form-invalid-feedback :state="!errors.interview_details">
                            <div v-for="error in errors.interview_details" :key="error">
                                {{ error }}
                            </div>
                        </b-form-invalid-feedback>
                    </template>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="IsUpdate && has_permission('worker-edit')">
            <b-col sm="12" md="12" lg="12" class="form-group" v-if="!IsStage">
                <b-button v-if="!OpenForm.interview" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'interview')">
                    <fa icon="pen" class="mr-2" /> Editar
                </b-button>
                <template v-else>
                    <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                        <fa icon="ban" class="mr-2" /> Cancelar
                    </b-button>
                    <b-button variant="mary-beleza" size="sm" @click="update()">
                        <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                    </b-button>
                </template>
            </b-col>
        </b-row>
    </panel>

    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Finalizar
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import Constants from '@/constants'

import {
    mapGetters
} from 'vuex'

export default {

    components: {
        Panel
    },

    data() {
        return {
            ratings: {1: 'hate', 2: 'disappointed', 3: 'natural', 4: 'good', 5: 'excellent'},
            ratings_desc: ['Péssimo (1)', 'Ruim (2)', 'Regular (3)', 'Bom (4)', 'Ótimo (5)']
        }
    },

    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        update() {
            if(this.behavioral_grade == 0 || this.behavioral_grade == null){
                this.sweealert2('error', 'Obrigatório informar uma avaliação pessoal da profissional')
                return
            }
            this.$store.dispatch('worker/updateWorker').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        ActiveRating(item) {
            return (item != null && item >= 0)
        },

        RatingsDesc(value) {
            let rating = Math.floor(value)
            switch(rating){
                case rating = 1:
                    return `Péssimo (${value})`
                case rating = 2:
                    return `Ruim (${value})`
                case rating = 3:
                    return `Regular (${value})`
                case rating = 4:
                    return `Bom (${value})`
                case rating = 5:
                    return `Ótimo (${value})`
            }
        },
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage',
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        behavioral_grade: {
            get() {
                let behavioral = this.$store.state.worker.worker.behavioral_grade
                return behavioral?.toString()
            },
            set(value) {
                this.$store.commit('worker/SET_BEHAVIORAL_GRADE', value)
            }
        },

        technical_grade: {
            get() {
                let technical = this.$store.state.worker.worker.technical_grade
                return technical?.toString()
            },
            set(value) {
                this.$store.commit('worker/SET_TECHNICAL_GRADE', value)
            }
        },

        total_grade: {
            get() {
                return ((+this.technical_grade + +this.behavioral_grade)/2)
            }
        },

        interview_details: {
            get() {
                return this.$store.state.worker.worker.detail.interview_details;
            },
            set(value) {
                this.$store.commit('worker/SET_INTERVIEW_DETAILS', value)
            }
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            },
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },

    },

}
</script>

<style scoped>
.effect {
    width: 1.8rem !important;
    height: 1.8rem !important;
    right: 0 !important;
}

.vue-reaction {
    width: 1.8rem !important;
    height: 1.8rem !important;
    z-index: 0;
}

.vue-reaction img {
    width: 1.8rem !important;
    height: 1.8rem !important;
}

.vue-reaction img:hover {
    width: 2.3rem !important;
    height: 2.3rem !important;
}

.vue-reaction .minha {
    display: inline-block;
}
</style>
<template>
  <div id="dashboard" class="pr-3 pl-3 pt-3">
    <b-row>
      <b-col sm="12" lg="12" md="12">
        <b-overlay :show="showTotals && panel != 'schedule'">
          <panel>
            <div
              class="card-counter"
            >
              <div v-if="workersPerHabilities">
                <b-tabs>
                  <b-tab v-for="(hability, i) in workersPerHabilities" :key="i" :title="hability.name">
                    <bar-graphic :key="showTotals" class="mt-5" title="Profissionais - Quantidade por Habilidades" :grid="true" width="100%" height="400px" :data="hability.data"></bar-graphic>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </panel>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Panel from "@/components/Panel";
import Http from "@/api/http";
import BarGraphic from "@/components/BarGraphic";

export default {
  components: {
    Panel,
    BarGraphic
  },
  data() {
    return {
      workersPerHabilities: null,
    //   total_schedules_per_competences: null,
      items: []
    };
  },
  created() {
    this.LoadData();
    this.$store.dispatch("financial/getBalance");
  },
  methods: {
    LoadData() {
      this.LoadTotals();
    },
    LoadTotals(panel = null) {
        this.panel = panel;
        this.showTotals = true;
        Http.get("report/totals", { params: this.filters }).then(response => {
            var total_workers_per_habilities = response.data.total_workers_per_habilities;
            // var total_schedules_per_competences = response.data.total_schedules_per_competences;

            var tooltip = (item, data) => {
                var label = data.labels[item.index];
                //var total = data.datasets[item.datasetIndex].data.reduce((a, b) => a + b, 0);
                var value = data.datasets[item.datasetIndex].data[item.index];
                //var percentage = total > 0 ? (value / total) * 100 : 0;
                //percentage = percentage.toFixed(2);
                return label+": "+value;
            };

            var i = 0;
            var color = null;

            if (total_workers_per_habilities) {
                var workersPerHabilities = [];
                var grp2 = {};
                for (var h1 of total_workers_per_habilities) {
                  if (!grp2[h1.parent]) {
                    grp2[h1.parent] = {
                      name: h1.parent,
                      habilities: []
                    };
                  }

                  grp2[h1.parent].habilities.push(h1);
                }

                for (var kgrp2 in grp2) {
                  i = 0;
                  var dataset_hability = {
                    labels: [],
                    datasets: [
                        {
                            label: grp2[kgrp2].name,
                            backgroundColor: [],
                            data: [],
                            borderColor: "#888"
                        }
                    ],
                    tooltip: tooltip
                  };

                  for (var hability of grp2[kgrp2].habilities) {
                      color = this.getColor(null, i);
                      dataset_hability.labels.push(hability.name);
                      dataset_hability.datasets[0].data.push(hability.total);
                      dataset_hability.datasets[0].backgroundColor.push(color);
                      i++;
                  }

                  workersPerHabilities.push({
                    name: grp2[kgrp2].name,
                    data: dataset_hability
                  });
                }

                this.workersPerHabilities = workersPerHabilities;
            }

            // if (total_schedules_per_competences) {
            //     var dataset_schedules = {
            //         labels: total_schedules_per_competences.map(s => s.competence),
            //         datasets: [
            //             {
            //                 label: "Total",
            //                 backgroundColor: [],
            //                 data: [],
            //                 borderColor: "#888"
            //             }
            //         ],
            //         tooltip: tooltip
            //     };
            //      i = 0;
            //     for (var schedule of total_schedules_per_competences) {
            //       color = this.getColor(null, i);
            //       dataset_schedules.datasets[0].data.push(schedule.total);
            //       dataset_schedules.datasets[0].backgroundColor.push(color);
            //       i++;
            //     }

            //     this.total_schedules_per_competences = dataset_schedules;
            // }

            this.showTotals = false;
        });
    },
    LoadWorkers() {

      this.$store
        .dispatch("worker/getWorkers", this.table_config)
        .then(data => {

          this.items = data.items ? data.items : [];
        });
    },
    randomColor() {
        var dword = Math.random() * ~(1 << 31);
        return this.getColor(dword);
    },
    getColor(dword, id = null) {
        if (id != null) {
            var colorsfixed = [
                'rgba(98,189,80,1)',
                'rgba(242,214,0,1)',
                'rgba(255,159,26,40)',
                'rgba(0,121,191,1)',
                'rgba(195,119,224,51)',
                'rgba(235,90,70,67)',
                'rgba(0,194,224,1)',
                'rgba(81,232,152,1)',
                'rgba(255,120,203,69)',
                'rgba(53,82,99,1)',
                'rgba(179,190,196,1)',
                'rgba(139,69,0,1)'
            ];
            if (id < colorsfixed.length)
                return colorsfixed[id];
            else
                return this.randomColor();
        }

        var a = 255;
        var r = ((dword & 0x00ff0000) >> 16);
        var g = (dword & 0x0000ff00) >> 8;
        var b = (dword & 0x000000ff);
        var color = "rgba("+r+","+g+","+b+","+a+")";
        return color;
    },
  },
  computed: {

  }
};
</script>

<style scoped>
  .tabs >>> .nav-link {
    color: #A9A9A9;
  }
</style>
import Vue from 'vue' 
import Http from '@/api/http';
import PromotionDomain from '@/domain/promotion'
import FiltersDomain from '@/domain/filters/promotion'

const state = {
    promotions: [],
    promotion: new PromotionDomain(),
    permissions: null,
    isUpdate: false,
    is_update: false,
    filters: new FiltersDomain(),
    errors: {}
};

const getters = {
    IsUpdate: (state) => {
        return state.is_update
    },
};

const actions = {

    loadInsertForm({ commit, dispatch }) {
        commit('SET_PROMOTION_ERROR', {});
        dispatch("IsUpdate", false);
        commit('SET_PROMOTION', new PromotionDomain);
    },

    loadEditForm({ commit, dispatch }, id) {
        dispatch("IsUpdate", true);
        commit('SET_PROMOTION_ERROR', {});
        Http.get('promotion/' + id).then(response => {
            var promotion = response.data;
            if (promotion) {
                promotion.services = promotion.services ? promotion.services.map(s => s.service_id) : [];
            }
            commit('SET_PROMOTION', promotion)
        })
    },

    IsUpdate({ commit }, is_update) {
        commit("PROMOTION_IS_UPDATE", is_update)
    },

    getPromotions({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('promotion', {params: params}).then(response => {
                commit('SET_PROMOTIONS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    search({ commit }, coupon) {
        return new Promise((resolve, reject) => {
            Http.get('promotion/search', {params: { coupon }}).then(response => {
                commit('SET_PROMOTIONS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createPromotion({ commit, state }) {
        return new Promise((resolve, reject) => {
            Http.post('promotion', state.promotion).then(response => {
                commit('CREATE_PROMOTION', response.data)
                resolve(response.data)
            }).catch(error => {
                if(error.response.status == 422){
                    commit('SET_PROMOTION_ERROR', error.response.data.error)
                }
                reject(error)
            })
        })
    },
    
    deletePromotion({commit}, promotion) {
        return new Promise((resolve, reject) => {
            Http.delete('promotion/' + promotion.id).then(response => {
                commit('DELETE_PROMOTION', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateStatusPromotion({commit}, promotion_id) {
        return new Promise((resolve, reject) => {
            Http.put(`/promotion/${promotion_id}/status`).then(response => {
                commit('UPDATE_PROMOTION', response.data.promotion)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updatePromotion({commit, state}) {
        return new Promise((resolve, reject) => {
            Http.put('promotion/' + state.promotion.id, state.promotion).then(response => {
                commit('UPDATE_PROMOTION', response.data)
                resolve(response.data)
            }).catch(error => {
                if(error.response.status == 422){
                    commit('SET_PROMOTION_ERROR', error.response.data.error)
                }
                reject(error)
            })
        })
    },

};

const mutations = {
    SET_PROMOTION_SERVICES(state, services) {
        state.promotion.services = services
    },
    PROMOTION_IS_UPDATE(state, is_update) {
        state.is_update = is_update
    },

    SET_PROMOTIONS(state, promotion) {
        state.promotion = promotion;
    },

    SET_PROMOTION(state, promotion) {
        Vue.set(state, 'promotion', promotion);
    },

    CREATE_PROMOTION(state, promotion) {
        state.promotions.push({promotion});
    },

    UPDATE_PROMOTION(state, promotion) {
        Vue.set(state.promotion, promotion);
    },

    DELETE_PROMOTION(state, promotion) {
        state.promotion = promotion
    },

    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_PROMOTION_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        //console.log(err)
        state.errors = err
            
        // for(var i = 0; i < err.length; ++i) {
           
        // }

        // Object.keys(err).map((item,index) => {
        //     console.log(index)
        // })

        // var error_array = {}
        // Object.keys(err.error).map((item) => {
        //     error_array[`${err.index}.${item}`] = err.error[item]
        // });
        // console.log(error_array)
        // state.errors = error_array
    },
    
    SET_PROMOTION_NAME(state, value){
        state.promotion.name = value
    },

    SET_PROMOTION_DESCRIPTION(state, value){
        state.promotion.description = value
    },

    SET_PROMOTION_TYPE(state, value){
        state.promotion.type = value
    },

    SET_PROMOTION_COUPON(state, value){
        state.promotion.coupon = value
    },

    SET_PROMOTION_NUMBER_OF_USES(state, value){
        state.promotion.number_of_uses = value
    },

    SET_PROMOTION_NUMBER_OF_USES_PER_CUSTOMER(state, value){
        state.promotion.number_of_uses_per_customer = value
    },

    SET_PROMOTION_START_DATE(state, value){
        state.promotion.start_date = value
    },

    SET_PROMOTION_END_DATE(state, value){
        state.promotion.end_date = value
    },

    SET_PROMOTION_VALUE_TYPE(state, value){
        state.promotion.value_type = value
    },

    SET_PROMOTION_VALUE(state, value){
        state.promotion.value = value
    },

    SET_PROMOTION_SINGLE_RULE(state, value){
        state.promotion.single_rule = value
    },

    SET_PROMOTION_STATUS(state, value){
        state.promotion.status = value
    },

    SET_PROMOTION_ID(state, value){
        state.promotion.id = value
    },

    SET_CLEAN_FILTERS(state) {
        state.filters = new FiltersDomain();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel>
                <b-row>
                    <b-col md="12" lg="12" sm="12">
                        <b-row>
                            <b-col sm="12" md="4" lg="4" class="form-group">
                                <label class="required">Data:</label>
                                <v-date-picker :model-config="modelConfig" v-model="date" :mode="'date'">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div>
                                            <input
                                            class="form-control"
                                            :value="inputValue"
                                            v-on="inputEvents" placeholder="Data do Feriado"/>
                                        </div>
                                    </template>
                                </v-date-picker>
                                <b-form-invalid-feedback :state="!errors.date">
                                    <div v-for="error in errors.date" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </b-col>
                            
                            <b-col sm="12" md="4" lg="4" class="form-group">
                                <label class="required">Descrição:</label>
                                <b-form-input type="text" v-model="description" placeholder="Descrição"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.description">
                                    <div v-for="error in errors.description" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </b-col>
                            
                            <b-col sm="12" md="4" lg="4" class="form-group">
                                <label>Tipo do Feriado: </label>
                                <b-form-select v-model="type" :options="holidays_type"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.type">
                                    <div v-for="error in errors.type" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <!-- <b-row class="form-group" v-if="type == constants.LOCAL_HOLIDAY">
                            <b-col sm="12" md="6" lg="6">
                                <div>
                                    <label>Estado:</label>
                                    <b-form-select v-model="state_selected" :options="states" @input="UpdateState($event, false)">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </b-col>
                            <b-col v-if="state_selected" sm="12" md="6" lg="6">
                                <div>
                                    <label>Cidade:</label>
                                    <b-form-select v-model="city_id" :options="cities">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </b-col>
                        </b-row> -->

                        <b-row v-if="has_permission('holidays-edit')">
                            <b-col sm="12" md="4" lg="4" offset-md="4">
                                <b-button variant="mary-beleza" v-on:click="verifyForm" block>
                                    <fa :icon="['far', 'save']" class="mr-2" /> Adicionar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>

            <!-- <panel title="Filtros">
                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <div>
                            <label>Estado:</label>
                            <b-form-select v-model="state_filter_selected" :options="states" @input="UpdateState($event, true)">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </b-col>
                    <b-col v-if="state_filter_selected" sm="12" md="4" lg="4">
                        <div>
                            <label>Cidade:</label>
                            <b-form-select v-model="filters.city_id" :options="cities_filter">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" lg="4">
                        <label></label>
                        <p>
                            <b-button class="mt-2" block @click="CleanFilters" variant="outline-danger">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>
            </panel> -->

            <panel>
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <rich-table ref="holidaysRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers"
                            :loading="loadingData" :cols="table_cols">
                            <template v-if="items && items.length > 0">
                                <tr v-for="(item, key) in items" v-bind:key="key"
                                    :class="item.page + (item.selected ? ' selected' : '')">
                                    <td width="25%">
                                        {{ item.date | moment("DD/MM/YYYY")}}
                                    </td>
                                    <td>
                                        {{ item.description }}
                                    </td>
                                    <!-- <td>
                                        <span v-if="item.city_id" >{{ item.city.name }}</span>
                                        <span v-else class="font-weight-bold text-danger">-</span>
                                    </td> -->
                                    <td>
                                        <b-badge v-if="item.type == 0" variant="primary">Feriado Nacional</b-badge>
                                        <b-badge v-else variant="warning">Feriado Local</b-badge>
                                    </td>
                                    <td>
                                        <b-button v-if="has_permission('holidays-delete')" @click="DeleteHoliday(item)" variant="danger" size="sm">
                                            <b-icon icon="trash" font-scale="1" />
                                        </b-button>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td :colspan="table_cols.length" class="text-center py-3">Nenhum registro encontrado</td>
                                </tr>
                            </template>
                        </rich-table>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import { mapGetters } from 'vuex'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import RichTable from '@/components/RichTable'

export default {
    components: {
        Panel,
        UtilBar,
        RichTable
    },
    data() {
        return {
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm',
            },
            holidays_type: [
                { value: Constants.NATIONAL_HOLIDAY, text: 'Feriado Nacional' },
                { value: Constants.LOCAL_HOLIDAY, text: 'Feriado Local' },
            ],
            loadingData: false,
            items: [],
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            state_selected: null,
            state_filter_selected: null,
            states: [],
            cities: [],
            cities_filter: [],
        }
    },
    watch: {
        'filters': {
            handler: function () {
               this.LoadData();
            },
            deep: true
        },
    },
    created() {
        this.LoadData();

        /* this.$store.dispatch('states/getStates').then(data => {
            data.forEach(state => {
                this.states = [...this.states, {
                    text: state.name,
                    value: state.id
                }]
            });
        }); */
        
    },
    methods: {
        LoadData() {
            this.loadingData = true;
            this.$store.dispatch("holiday/loadInsertForm");

            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('holiday/getHolidays', params).then(data => {
                this.loadingData = false;
                this.items = data.items ? data.items : [];
                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;
            });
        },

        verifyForm(){
            var holiday = this.$store.state.holiday.holiday;
            
            var error = {};
            holiday.date === '' ? error = {date: ['Data é Obrigatória']} : null
            holiday.type === '' ? error = {type: ['Tipo do Feriado é Obrigatório']} : null
            holiday.description === '' ? error = {description: ['A descrição do Feriado é Obrigatória']} : null
  
            if(Object.keys(error).length === 0){
                this.addError({});
                this.Save()
            }else{
                this.addError(error);
            }
        },

        Save() {
            this.$store.dispatch('holiday/createHoliday').then(response => {
                this.sweealert2Toast('success', response.success);
                this.LoadData();
            }).catch(() => {});
        },

        DeleteHoliday(holiday){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                html: `Deseja realmente remover o feriado <strong>${holiday.description}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    this.$store.dispatch('holiday/deleteHoliday', holiday.id).then(data => {
                        this.sweealert2Toast('success', data.success)
                        this.LoadData();
                    }).catch(error => {
                        this.sweealert2Toast('error', error.response.data.error);
                    })
                }
            })
        },

        ChangeTableConfig(value) {
            this.table_config = value
                this.LoadData();
        },

        addError(error){
            this.$store.commit('holiday/SET_HOLIDAY_ERROR', error)
        },

        sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        },

        sweealert2Toast(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        /* GetCities(state_id, filter){
            this.$store.dispatch('cities/getCities', state_id).then(data => {
                if(filter){
                    this.cities_filter = [];
                    data.forEach(citie => {
                        console.log(citie);
                        this.cities_filter = [...this.cities_filter, {
                            text: citie.name,
                            value: citie.id
                        }]
                    });
                }else{
                    this.cities = [];
                    data.forEach(citie => {
                        this.cities = [...this.cities, {
                            text: citie.name,
                            value: citie.id
                        }]
                    });
                }
            });
        }, */

        /* UpdateState(value, filter = false) {
            if(value != null){
                this.GetCities(value, filter);
            }
            filter ? this.state_filter_selected = value : this.state_selected = value;
        },

        CleanFilters() {
            this.$store.commit('holiday/SET_CLEAN_FILTERS');
            this.$refs["holidaysRichTable"].CleanFilters();
        }, */
    },

    computed: {
        table_cols() {
            let cols = [
                {
                    id: 'date',
                    text: 'Data',
                    class: 'cols_date'
                }, {
                    id: 'description',
                    text: 'Descrição',
                    class: 'cols_description'
                },/*  {
                    id: null,
                    text: 'Cidade',
                    class: 'cols_city_id'
                },  */{
                    id: 'type',
                    text: 'Tipo',
                    class: 'cols_type'
                }, {
                    id: null,
                    text: '',
                    class: 'cols_delete'
                }
            ];
            return cols;
        },

        id: {
            get() {
                return this.$store.state.holiday.holiday.id;
            },
            set(value) {
                this.$store.commit('holiday/SET_HOLIDAY_ID', value)
            }
        },

        date: {
            get() {
                return this.$store.state.holiday.holiday.date;
            },
            set(value) {
                this.$store.commit('holiday/SET_HOLIDAY_DATE', value)
            }
        },

        description: {
            get() {
                return this.$store.state.holiday.holiday.description;
            },
            set(value) {
                this.$store.commit('holiday/SET_HOLIDAY_DESCRIPTION', value)
            }
        },

        type: {
            get() {
                return this.$store.state.holiday.holiday.type;
            },
            set(value) {
                this.state_selected = null;
                this.city_id = null;
                this.$store.commit('holiday/SET_HOLIDAY_TYPE', value)
            }
        },

        /* city_id: {
            get() {
                return this.$store.state.holiday.holiday.city_id;
            },
            set(value) {
                this.$store.commit('holiday/SET_HOLIDAY_CITY_ID', value)
            }
        }, */

        filters: {
            get(){
                return this.$store.state.holiday.filters;
            }
        },

        errors: {
            get() {
                return this.$store.state.holiday.errors;
            },
        },

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },
    },
}
</script>

<style>

</style>

import Vue from 'vue'
import App from './App.vue'
import Pusher from './api/pusher'
import VueRouter from 'vue-router';
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueApexCharts from 'vue-apexcharts';
import * as VueGoogleMaps from 'vue2-google-maps'
import VCalendar from 'v-calendar';
import money from 'v-money'
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask'
import LoadScript from 'vue-plugin-load-script';
import RatingEmoji from '@/components/RatingEmoji';
import LoaderMaryBeleza from '@/components/LoaderMaryBeleza';
import VeeValidate, {Validator} from 'vee-validate';
import ptBR from 'vee-validate/dist/locale/pt_BR';

var vee_validate_config = {
    events: 'submit|blur',
    fieldsBagName: 'formFields'
}
Vue.use(VeeValidate, vee_validate_config);
Validator.localize("ptBR", ptBR)

Validator.extend('nozero', {
	getMessage: field => 'O campo ' + field + ' é obrigatório',
	validate: value => value != 0
});

Vue.component("rating-emoji", RatingEmoji);
Vue.component("loader-mary-beleza", LoaderMaryBeleza);

Vue.prototype.$pusher = Pusher;

Vue.use(LoadScript);

import {
	faCoffee as fasCoffee,
	faKey,
	faTachometerAlt,
	faAngleRight,
	faCog,
	faTable,
	faDoorClosed,
	faPhoneVolume,
	faMailBulk,
	faCashRegister,
	faAngleDown,
	faAngleUp,
	faStream,
	faPlusCircle,
	faColumns,
	faUndo,
	faFemale,
	faListUl,
	faExclamationTriangle,
	faChevronLeft,
	faMapMarkedAlt,
	faTimesCircle,
	faAlignLeft,
	faFilter,
	faBirthdayCake,
	faBars,
	faLevelDownAlt,
	faSpinner,
	faInfoCircle,
	faPhoneAlt,
	faSignature,
	faPen,
	faTrash,
	faAngleDoubleDown,
	faAngleDoubleUp,
	faClock,
	faBan,
	faUpload,
	faBriefcase,
	faCheckDouble,
	faQuestionCircle,
	faQuestion,
	faInfo,
	faMobile,
	faStar,
	faCreditCard,
	faFile,
	faSearch
} from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
	faUser as farCoffee,
	faEye as farEye,
	faEyeSlash as farEyeSlash,
	faCopyright,
	faLifeRing,
	faComments,
	faUserCircle,
	faAddressCard,
	faSave,
	faIdBadge,
	faClipboard,
	faEdit,
	faTrashAlt,
	faHandshake,
	faAddressBook,
	faCalendarAlt,
	faEnvelope,
	faEnvelopeOpen,
	faWindowRestore,
	faCheckCircle,
	faThumbsDown,
	faBell,
	faThumbsUp,
	faBookmark,
	faPaperPlane,
	faChartBar
} from '@fortawesome/free-regular-svg-icons'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'

Vue.component('apexchart', VueApexCharts)
Vue.component('fa', FontAwesomeIcon)

library.add(fasCoffee, faChartBar, faQuestionCircle, faBell, farCoffee, farEye, farEyeSlash, faKey, faTachometerAlt, faAngleRight, faCog, faTable, faDoorClosed,
	faCopyright, faLifeRing, faComments, faPhoneVolume, faMailBulk, faUserCircle, faCashRegister, faAngleDown, faAngleUp,
	faStream, faPlusCircle, faColumns, faAddressCard, faSave, faUndo, faIdBadge, faFemale, faClipboard, faListUl, faExclamationTriangle,
	faEdit, faTrash, faTrashAlt, faChevronLeft, faHandshake, faAddressBook, faMapMarkedAlt, faTimesCircle, faAlignLeft, faFilter,
	faBirthdayCake, faCalendarAlt, faBars, faLevelDownAlt, faSpinner, faInfoCircle, faPhoneAlt, faEnvelope, faEnvelopeOpen,
	faWindowRestore, faSignature, faPen, faAngleDoubleDown, faAngleDoubleUp, faBan, faCheckCircle, faThumbsDown, faThumbsUp, 
	faWhatsapp, faUpload, faBookmark, faBriefcase, faPaperPlane, faClock, faCheckDouble, faQuestion, faInfo, faMobile, faStar, faCreditCard, faFile, faSearch)


// import 'v-calendar/lib/v-calendar.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.config.productionTip = false

const options = {
	confirmButtonColor: '#41b882',
	cancelButtonColor: '#ff7674',
};

Vue.prototype.router = router;

Vue.use(require('vue-moment'));
require('moment/locale/pt-br')

Vue.use(VueSweetalert2, options);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter);
Vue.use(VueApexCharts);
Vue.use(VueTheMask)
Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyCuHC26V5a-RydniFlpGsCkAA71rbRa1mU"
	}
});

Vue.use(VCalendar, {
	firstDayOfWeek: 1,
	datePickerTintColor: '#EF62A3',
	datePickerShowCaps: false,
	datePickerShowPopover: false,
	datePickerSelectColor: '#EF62A3',
	datePickerInputProps: {
		class: "form-control"
	},
	datePickerDisabledAttribute: {
		contentStyle: {
			color: 'grey',
			background: "none transparent",
			weight: 600,
			opacity: 0.7
		}
	},
	formats: {
		title: 'MMMM YYYY',
		weekdays: 'W',
		navMonths: 'MMM',
		input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
		dayPopover: 'L',
	},
	themeStyles: {
		dayCellNotInMonth: {
			opacity: 0.3,
		},
		dayContent: {
			background: 'none'
		},
		dayPopoverContent: {
			background: 'white'
		},
		dayContentHover: {
			backgroundColor: 'yellow'
		},
		wrapper: {
			background: '#FEFBFC',
			color: 'black',
			fontWeight: 'bold'
		},
		headerHorizontalDivider: {
			borderBottom: 'solid lightgrey 1px',
			width: '0%',
		},
		weekdayHorizontalDivider: {
			borderBottom: 'solid lightgrey 1px',
			width: '100%',
		},
		weekdays: {
			padding: `10px 0`,
		},
		header: {
			padding: `10px 0`,
		},
	}
});

Vue.use(money, {
	precision: 4
});


/**
 * FILTROS
 * */
Vue.filter('currency', function (value, currency_mark = true) {
    if(value == null)
        value = 0

    value = Number(value)
    return (currency_mark ? "R$ " : "") + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
});

Vue.filter('million', function(value) {
	value = value && !isNaN(value) ? parseFloat(value) : 0;
	value = value.toLocaleString();
	return value;
})

Vue.filter('percentage', function (value) {
    if(value == null)
        return "0,00%";
    value = Number(value);

    return value.toFixed(2).replace(",", "").replace(".", ",")+"%";
});

Vue.filter('cardFlag', function (value) {
    switch (value) {
		case 1:
			return "Visa";
			
		case 2:
			return "MasterCard";
			
		case 3:
			return "Diners";
			
		case 4:
			return "Discover";
		
		case 5:
			return "Elo";
			
		case 6:
			return "Amex";
			
		case 7:
			return "JCB";
			
		case 8:
			return "Aura";
			
		case 9:
			return "HiperCard";
		
		case 10:
			return "Maestro";
	
		default:
			return "-";
	}
});

Vue.filter('formatPhones', function (value) {
	if(!value )
		return ''
		
	var value_old = value;
	value = value.replace(/[^\d]/g, "");

	function formate(phone){
		if (phone.length >= 12 && phone.length <= 13) { // Com DDD com PAIS
			return phone.replace(/(\d{2})(\d{2})(\d{4,5})(\d{4})/, "+$1 ($2) $3-$4");
		}

		if (phone.length >= 10 && phone.length <= 11) { // Com DDD
			return phone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3");
		}

		if (phone.length >= 8 && phone.length <= 9) { // Sem DDD
			return phone.replace(/(\d{4,5})(\d{4})/, "$1-$2");
		}
	}

	if (value.length > 0) {
		if (value.length >= 8 && value.length <= 13) { // Com DDD
			return formate(value);
		}

		// Formata telefones das listagens.
		if (value.length >= 14) { // Dois numeros com DDD
			var result = value_old.split(" | ");
			var phones = [];
			result.forEach(phone => {
				phones.push(formate(phone))
			})
			return phones.join(' | ');
		}

		return value;
	} else {
		return false;
	}
});

Vue.filter('formatDocuments', function (value) {
	if(!value )
		return ''

	value = value.replace(/[^\d]/g, "");

	if (value.length > 0) {
		value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/i, "$1.$2.$3/$4-$5");
		value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/i, "$1.$2.$3-$4");
		
		return value;
	} else {
		return value;
	}
});

Vue.filter('formatCep', function (value) {
	if(!value )
		return ''

	value = value.replace(/[^\d]/g, "");

	if (value.length > 0) {
		if (value.length == 8) { // CEP
			return value.replace(/(\d{5})(\d{3})/, "$1-$2");
		}
		return value;
	} else {
		return value;
	}
});

Vue.filter('not_informed', function (value) {
	return value !== null && value !== undefined && value !== "" ? value : "Não Informado"
});

Vue.filter('zipcode', function(value) {
    value += "";

    value = value.replace(/[^\d]*/i, "");
    value = value.replace(/(\d{5})(\d{3})/i, "$1-$2");

    return value;
});

// Vue.directive('vpshow', {
// 	inViewport(el) {
// 		var rect = el.getBoundingClientRect()
// 		return !(rect.top > 70)
// 	},

// 	bind(el, binding) {
// 		el.classList.add('before-enter')
// 		el.$onScroll = function () {
// 			if (binding.def.inViewport(el)) {
// 				el.classList.add('enter')
// 				el.classList.remove('before-enter')
// 				binding.def.unbind(el, binding)
// 			}
// 		}
// 		document.addEventListener('scroll', el.$onScroll)
// 	},

// 	inserted(el) {
// 		el.$onScroll()
// 	},

// 	unbind(el) {
// 		document.removeEventListener('scroll', el.$onScroll)
// 		delete el.$onScroll
// 	}
// })

//Diretivas Uteis
Vue.directive('limit', {
    bind: function(el, binding, vnode) {
        var limit = binding.value;
        vnode.context.limiterDirective = function() {
            var value = this.value;
            if (value.length > limit) {
                this.value = value.substring(0, limit);
                this.dispatchEvent(new CustomEvent('input'));
            }
        }.bind(el);

        el.addEventListener('input', vnode.context.limiterDirective);
    },
    unbind: function(el, binding, vnode) {
        el.removeEventListener('input', vnode.context.limiterDirective);
    }
});

Vue.directive('vpshow', {
	bind: function (el, binding, vnode) {
		// var ini = binding.value ? binding.value : {};

		el.classList.add('vpshow-before-enter')
		el.classList.remove('vpshow-enter')

		vnode.context.alwaysVisible_OnWindowScroll = function () {
			var rect = el.getBoundingClientRect();

			if (rect.top <= 70) {
				el.classList.remove('vpshow-before-enter')
				el.classList.add('vpshow-enter')
			} else {
				el.classList.remove('vpshow-enter')
				el.classList.add('vpshow-before-enter')
			}
		}.bind(vnode.context);

		window.addEventListener('scroll', vnode.context.alwaysVisible_OnWindowScroll);
		window.addEventListener('resize', vnode.context.alwaysVisible_OnWindowResize);
	},
	unbind: function (el, binding, vnode) {
		el.removeAttribute('always-visible');
		el.style.removeProperty("top");
		el.style.removeProperty("left");
		el.style.removeProperty("position");
		el.style.removeProperty("z-index");
		el.style.removeProperty("width");
		el.style.removeProperty("height");

		window.removeEventListener('scroll', vnode.context.alwaysVisible_OnWindowScroll);
		window.removeEventListener('resize', vnode.context.alwaysVisible_OnWindowResize);
	}
})

Vue.directive('schedule-status', {
	bind: function (el, binding) {
		var color = ['bg-mary-beleza', 'bg-warning', 'bg-info', 'bg-primary', 'bg-success', 'bg-danger']
		var status = [
			{name: 'Oportunidade', color: color[0]}, 
			{name: 'Aceito', color: color[1]},
			{name: 'Em preparação', color: color[2]},
			{name: 'Profissional Está no Local', color: color[3]}, 
			{name: 'Finalizado', color: color[4]}, 
			{name: 'Cancelado - Profissional', color: color[5]},
			{name: 'Cancelado - Cliente', color: color[5]},
			{name: 'Cancelado - Mary Beleza', color: color[5]},
			{name: 'Esterilização dos materiais', color: color[1]},
			{name: 'Pagamento em processamento', color: color[2]},
			{name: 'Oportunidade Perdida', color: color[5]},
			{name: 'Desistiu', color: color[5]},
			{name: 'Profissional a Caminho', color: color[2]}
		]
		
		if(binding.value != null){
			el.classList.add(status[binding.value].color)
			el.innerHTML = `<span>${status[binding.value].name}</span>`
			el.style.color = "#fff"
		}
	},

	update: function (el, binding) {
		var color = ['bg-mary-beleza', 'bg-warning', 'bg-info', 'bg-primary', 'bg-success', 'bg-danger']
		var status = [
			{name: 'Oportunidade', color: color[0]}, 
			{name: 'Aceito', color: color[1]}, 
			{name: 'Em preparação', color: color[2]},
			{name: 'Profissional Está no Local', color: color[3]}, 
			{name: 'Finalizado', color: color[4]}, 
			{name: 'Cancelado - Profissional', color: color[5]},
			{name: 'Cancelado - Cliente', color: color[5]},
			{name: 'Cancelado - Mary Beleza', color: color[5]},
			{name: 'Esterilização dos materiais', color: color[1]},
			{name: 'Pagamento em processamento', color: color[2]},
			{name: 'Oportunidade Perdida', color: color[5]},
			{name: 'Desistiu', color: color[5]},
			{name: 'Profissional a Caminho', color: color[2]}
		]
		
		color.forEach(element => {
			el.classList.remove(element)
		});

		if(binding.value != null){
			el.classList.add(status[binding.value].color)
			el.innerHTML = `<span>${status[binding.value].name}</span>`
		}
	},
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
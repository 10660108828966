import Http from '@/api/http'

const state = {
    balance: null,
    cashflow: null,
    extract: null,
    asaas_extract: null,
    asaas_payments: [],
    asaas_receipts: [],
    asaas_receipt: null,
    expenses_fixed_value: null,
    expenses_fixed_values: null,
    expenses_fixed_categories: [],
    pricetable: null,
    filters_asaas_payment: {
        due_date_start: null,
        due_date_end: null,
        status: null
    },
    filters_asaas_receipts: {
        due_date_start: null,
        due_date_end: null,
        status: null
    },
    asaas_payment: null,
    schedule_invoice: null,
    invoices_show_select: false,
    invoices_selected: [],
    filters_asaas_invoices: {
        service_date: {
            start: null,
            end: null
        },
        invoice: null,
        invoice_status: null,
        invoice_number: null
    },

};

const getters = {
    
};

const actions = {
    getPriceTable({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('financial/pricetable').then(response => {
                commit('SET_PRICETABLE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    savePriceTable({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.post('financial/pricetable', params).then(response => {
                commit('SET_PRICETABLE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    generatePriceTable({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.post('financial/pricetable/generate', params).then(response => {
                commit('SET_PRICETABLE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getBalance({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('financial/balance').then(response => {
                commit('SET_BALANCE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getCashFlow({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/cashflow', {params: params}).then(response => {
                commit('SET_CASHFLOW', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getExtract({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/extract', {params: params}).then(response => {
                commit('SET_EXTRACT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    anticipateAsaasPayment({commit}, transaction_id) {
        return new Promise((resolve, reject) => {
            Http.post('financial/asaas/payments/anticipate', { transaction_id }).then(response => {
                commit('SET_ASAAS_PAYMENT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    cancelAsaasPayment({commit}, data) {
        return new Promise((resolve, reject) => {
            debugger
            Http.post('financial/asaas/payments/'+data.id+"/cancel", { motive: data.motive }).then(response => {
                commit('SET_ASAAS_PAYMENT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    reprocessAsaasPayment({commit}, transaction_id) {
        return new Promise((resolve, reject) => {
            Http.post('financial/asaas/payments/reprocess', { transaction_id }).then(response => {
                commit('SET_ASAAS_PAYMENT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getAsaasExtract({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/extract/asaas', {params: params}).then(response => {
                commit('SET_ASAAS_EXTRACT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getAsaasPayments({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/asaas/payments', {params: params}).then(response => {
                commit('SET_ASAAS_PAYMENTS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getAsaasReceipts({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/asaas/receipts', {params: params}).then(response => {
                commit('SET_ASAAS_RECEIPTS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    saveAsaasReceipts({ commit }, data) {
        return new Promise((resolve, reject) => {
            Http.post('financial/asaas/receipts', data).then(response => {
                commit('SET_ASAAS_RECEIPT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    deleteAsaasReceipts({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.delete('financial/asaas/receipts/'+id).then(response => {
                commit('SET_ASAAS_RECEIPT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    refundAsaasReceipts({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.post('financial/asaas/receipts/'+id+"/refund").then(response => {
                commit('SET_ASAAS_RECEIPT', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getExpensesFixedCategories({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/expenses_fixed_categories', {params: params}).then(response => {
                commit('SET_EXPENSES_FIXED_CATEGORIES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getExpensesFixedValues({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('financial/expenses_fixed_values', {params: params}).then(response => {
                commit('SET_EXPENSES_FIXED_VALUES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    saveExpensesFixedValue({ commit }, params) {
        return new Promise((resolve, reject) => {
            if (params.id) {
                Http.put('financial/expenses_fixed_values/'+params.id, params).then(response => {
                    commit('SET_EXPENSES_FIXED_VALUE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            }
            else {
                Http.post('financial/expenses_fixed_values', params).then(response => {
                    commit('SET_EXPENSES_FIXED_VALUE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            }
        });
    },
    getExpenseFixed({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.get('financial/expenses_fixed_values/'+id).then(response => {
                commit('SET_EXPENSES_FIXED_VALUE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    deleteExpenseFixed({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.delete('financial/expenses_fixed_values/'+id).then(response => {
                commit('SET_EXPENSES_FIXED_VALUE', null)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getScheduleInvoices({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('schedule/invoices', {params: params}).then(response => {
                commit('SET_SCHEDULE_INVOICES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    saveScheduleInvoices({ state }, item){
        var schedule_id = item ? item.id : state.invoices_selected.map(item => item.id);
        return new Promise((resolve, reject) => {
            Http.post('financial/invoices', {schedule_id}).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data.error)
            });
        });
    },
    cancelScheduleInvoices(_, id){
        return new Promise((resolve, reject) => {
            Http.post(`financial/invoices/${id}/cancel`).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data.error)
            });
        });
    },
};

const mutations = {
    SET_PRICETABLE(state, value) {
        state.pricetable = value;
    },
    SET_BALANCE(state, value) {
        state.balance = value;
    },
    SET_CASHFLOW(state, value) {
        state.cashflow = value;
    },
    SET_EXTRACT(state, value) {
        state.extract = value;
    },
    SET_ASAAS_EXTRACT(state, value) {
        state.asaas_extract = value;
    },
    SET_CLEAN_FILTERS_ASAAS_PAYMENTS(state) {
        state.filters_asaas_payment = {
            due_date_start: null,
            due_date_end: null,
            status: 1
        }
    },
    SET_ASAAS_PAYMENT(state, value) {
        state.asaas_payment = value;
    },
    SET_ASAAS_PAYMENTS(state, value) {
        state.asaas_payments = value;
    },
    SET_ASAAS_RECEIPTS(state, value) {
        state.asaas_receipts = value;
    },
    SET_ASAAS_RECEIPT(state, value) {
        state.asaas_receipt = value;
    },
    SET_EXPENSES_FIXED_CATEGORIES(state, value) {
        state.expenses_fixed_categories = value;
    },
    SET_EXPENSES_FIXED_VALUES(state, value) {
        state.expenses_fixed_values = value;
    },
    SET_EXPENSES_FIXED_VALUE(state, value) {
        state.expenses_fixed_value = value;
    },
    SET_SCHEDULE_INVOICES(state, value) {
        state.schedule_invoice = value;
    },
    SET_INVOICES_SELECTED(state, value) {
        let index = state.invoices_selected.findIndex(item => item.id == value.id);
        if(index < 0) {
            state.invoices_selected.push(value);
        } else {
            state.invoices_selected.splice(index, 1);
        }
    },
    SET_INVOICES_SHOW_SELECT(state) {
        state.invoices_selected = [];
        state.invoices_show_select = !state.invoices_show_select;
    },
    SET_CLEAN_FILTERS_ASAAS_INVOICES(state) {
        state.filters_asaas_invoices = {
            service_date: {
                start: null,
                end: null
            },
            invoice: null,
            invoice_status: null,
            invoice_number: null
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>
        
        <b-overlay :show="loading" no-wrap rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <img :src="RandomImages()" class="vibrate-1" width="40%" />
                    <h6 class="blink-2"><small>Carregando</small></h6>
                </div>
            </template>
        </b-overlay>
        <div class="pr-3 pl-3 pt-3" v-if="worker">
            <panel title="Filtro" icon="filter" icon-lib="fa" startCollapsed>

                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <label class="mr-1">Período:</label>
                        <v-date-picker v-model="filters.service_date" :columns="$screens({ default: 1, lg: 2 })" :model-config="modelConfig" mode="date" is-range is-expanded></v-date-picker>
                    </b-col>

                    <b-col sm="12" md="12" lg="12" class="text-center">
                        <b-button class="mt-2 ml-2" variant="primary" @click="LoadData()"> <b-icon icon="receipt" class="mr-2" /> Gerar Relatório</b-button>
                        <b-button class="mt-2 ml-2" variant="outline-primary" @click="CleanFilters()"> <b-icon icon="funnel" class="mr-2" /> Limpar Filtros</b-button>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Resumo">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="3" class="justify-content-center align-self-center d-flex">
                        <b-row>
                            <b-col sm="12" md="12" lg="12">
                                <b-avatar :src="worker.person.image" class="m-2" size="170px"></b-avatar>
                            </b-col>
                        </b-row>
                    </b-col>
                    
                    <b-col sm="12" md="12" lg="9">
                        <div class="text-center mb-4">
                            <h3>{{ worker.person.name }}</h3>
                            <h6><strong>Cadastrado(a) em: </strong>{{ worker.person.created_at | moment("DD/MM/YYYY") }}</h6>
                        </div>

                        <b-table-simple responsive striped small hover>
                            <b-tbody>
                                <b-tr>
                                    <b-td>
                                        <strong>Aceitos: </strong> {{ worker.historical.qt_schedules_accept }}
                                    </b-td>
                                    <b-td>
                                        <strong>A caminho: </strong> {{ worker.historical.qt_schedules_en_route }}
                                    </b-td>
                                    <b-td>
                                        <strong>No lugar: </strong> {{ worker.historical.qt_schedules_arrived }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">
                                        <strong>Realizados: </strong> {{ worker.historical.qt_schedules_finished }}
                                    </b-td>
                                    <b-td colspan="2">
                                        <strong>Não confirmado pela MB: </strong> {{ worker.historical.qt_schedules_canceled_mary_beleza }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">
                                        <strong>Não confirmado pelo Profissional: </strong> {{ worker.historical.qt_schedules_canceled_worker }}
                                    </b-td>
                                    <b-td colspan="2">
                                        <strong>Não confirmado pelo Cliente: </strong> {{ worker.historical.qt_schedules_canceled_customer }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">
                                        <strong>Quantidade de Serviços: </strong> {{ worker.historical.qt_schedules }}
                                    </b-td>
                                    <b-td colspan="2">
                                        <strong>Taxa de Confirmação (%): </strong> {{ worker.historical.avg_confirmation }}%
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">
                                        <strong>Serviços com Pós-Venda: </strong> {{ worker.historical.qt_ratings }}
                                    </b-td>
                                    <b-td colspan="2">
                                        <strong>Média de Nota: </strong> {{ worker.historical.avg_ratings }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Resumo Pós-Vendas">
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-center">
                        <h4 class="mt-2 mb-4">Quantidade de Pós-Venda por Nota</h4>

                        <b-table-simple responsive striped small hover>
                            <b-tbody>
                                <b-tr>
                                    <b-td class="text-center">
                                        <rating-emoji :value="1" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #c23334">{{ worker.after_sales.qt_evaluation_ratings['1'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="2" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #ff8500">{{ worker.after_sales.qt_evaluation_ratings['2'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="3" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #fecb02">{{ worker.after_sales.qt_evaluation_ratings['3'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="4" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #d2de02">{{ worker.after_sales.qt_evaluation_ratings['4'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                    <b-td class="text-center">
                                        <rating-emoji :value="5" readonly only-actived emojiWidth="3rem" emojiHeight="3rem">
                                            <template #label="">
                                                <h5>
                                                    <b-badge style="background-color: #99d501">{{ worker.after_sales.qt_evaluation_ratings['5'] || 0 }}</b-badge>
                                                </h5>
                                            </template>
                                        </rating-emoji>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-center">
                        <h4 class="mt-2 mb-4">Últimos Registros em Todo o Período</h4>

                        <b-table-simple responsive small>
                            <b-tbody>
                                <b-tr>
                                    <b-td style="width:4%;" v-for="(item, index) in CheckLastRatings(worker.after_sales.last_ratings)" :key="index" :class="'border border-dark border-1 text-center '+RatingsColors(item.evaluation)+' text-white'" v-b-tooltip="item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm') : '-'">{{ item.evaluation }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="text-left" colspan="10">
                                        Mais Antigas
                                    </b-td>
                                    <b-td class="text-right" colspan="10">
                                        Mais Recentes
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-table-simple stacked="md" outlined striped fixed hover small show-empty responsive>
                            <b-thead>
                                <b-tr>
                                    <b-th>Elogios</b-th>
                                    <b-th>Selecionado/Total</b-th>
                                    <b-th>Porcentagem</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in worker.after_sales.values_ratings" :key="index">
                                    <b-td>{{ item.name }}</b-td>
                                    <b-td>{{ (worker.after_sales.qt_values_ratings[item.id] ? worker.after_sales.qt_values_ratings[item.id] : 0)+'/'+worker.historical.qt_ratings }}</b-td>
                                    <b-td>{{ CalculatePercent(worker.historical.qt_ratings, worker.after_sales.qt_values_ratings[item.id]) }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>

            </panel>

            <panel title="Agendamentos">
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <b-table :items="worker.schedules" :fields="schedules_list.fields" :current-page="schedules_list.currentPage" :per-page="schedules_list.perPage" stacked="md" outlined striped fixed hover small show-empty :filter="schedules_list.filter_table" @filtered="OnFilteredSchedule">
                            <template #cell(number)="row">
                                <span class="font-weight-bold schedule-open" @click="$router.push({ path: '/servicos/servico/' + row.item.id })">{{ row.value }}</span>
                            </template>
                            <template #cell(created_at)="row">
                                <span>{{ row.value | moment("DD/MM/YYYY") }}</span>
                            </template>
                            <template #cell(customer)="row">
                                <span>{{ row.item.customer.person.name }}</span>
                            </template>
                            <template #cell(status)="row">
                                <span><b-badge pill v-schedule-status="row.item.status"></b-badge></span>
                            </template>
                            <template #cell(schedule_rating)="row">
                                <span v-if="!row.value.evaluation">
                                    -
                                </span>
                                <b-badge v-else style="color:#fff" :class="RatingsColors(row.value.evaluation)">{{ row.value.evaluation }}</b-badge>
                                <b-icon icon="chat-text-fill" font-scale="1.2" class="ml-2 schedule-open" v-if="row.value.obs" variant="primary" :id="'tooltip-rating-'+row.value.id">{{ row.value.evaluation }}</b-icon>
                                <b-tooltip v-if="row.value.obs" :target="'tooltip-rating-'+row.value.id">
                                    {{ row.value.obs }}
                                </b-tooltip>
                            </template>
                            <template #empty="">
                                <p class="text-center pt-3">Nenhum registro encontrado</p>
                            </template>
                            <template #emptyfiltered="">
                                <p class="text-center pt-3">Nenhum registro encontrado</p>
                            </template>
                        </b-table>
                        <b-row class="justify-content-center" v-if="worker.schedules.length > 10">
                            <b-col md="8" class="form-group">
                                <b-pagination v-model="schedules_list.currentPage" :total-rows="schedules_list.totalRows" :per-page="schedules_list.perPage" align="fill" size="sm" class="my-0"></b-pagination>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Penalidades">
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <b-table :items="worker.person.punishment" :fields="punishments_list.fields" :current-page="punishments_list.currentPage" :per-page="punishments_list.perPage" stacked="md" outlined striped hover small show-empty :filter="punishments_list.filter_table" @filtered="OnFilteredPunishment">
                            <template #thead-top="">
                                <b-tr>
                                    <b-th colspan="2"></b-th>
                                    <b-th colspan="2" variant="danger" class="text-center py-2">
                                        <h4><b>Pontos: </b>{{ worker.person.punishment.reduce(function(total, item){
                                                return total + item.punishment_rules.points
                                            }, 0) }}/31
                                        </h4>
                                    </b-th>
                                    <b-th colspan="2" variant="primary" class="text-center py-2" >
                                        <h4>
                                            <b>Valor: </b>{{ worker.person.punishment.reduce(function(total, item){
                                                return total + item.value
                                            }, 0) | currency }}
                                        </h4>
                                    </b-th>
                                </b-tr>
                            </template>
                            <template #cell(description)="row">
                                <span>{{ row.item.punishment_rules.parent.description }}</span>
                            </template>
                            <template #cell(punishment_rules)="row">
                                <span v-if="row.value.points" >{{ row.value.points }}</span>
                                <b v-else class="text-danger">-</b>
                            </template>
                            <template #cell(worker_value)="row">
                                <span v-if="row.item.value">{{ row.item.value | currency }}</span>
                                <b v-else class="text-danger">-</b>
                            </template>
                            <template #cell(suspension)="row">
                                <span v-if="row.item.punishment_rules.suspension > 0">{{ row.item.punishment_rules.suspension }}</span>
                                <b v-else-if="row.item.punishment_rules.suspension < 0" class="text-danger">Indeterminado</b>
                                <b v-else class="text-danger">-</b>
                            </template>
                            <template #cell(created_at)="row">
                                <span v-if="row.item.created_at">{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
                                <b v-else class="text-danger">-</b>
                            </template>
                            <template #cell(remove)="row">
                                <b-icon icon="x" scale="1.5" variant="danger" class="delete-punishment" @click="DeletePunishment(row.item)"/>
                            </template>
                            <template #empty="">
                                <p class="text-center pt-3">Nenhum registro encontrado</p>
                            </template>
                            <template #emptyfiltered="">
                                <p class="text-center pt-3">Nenhum registro encontrado</p>
                            </template>
                        </b-table>
                        <b-row class="justify-content-center" v-if="worker.person.punishment.length > 10">
                            <b-col md="8" class="form-group">
                                <b-pagination v-model="punishments_list.currentPage" :total-rows="punishments_list.totalRows" :per-page="punishments_list.perPage" align="fill" size="sm" class="my-0"></b-pagination>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import Panel from '@/components/Panel'
import Http from '@/api/http'
import moment from 'moment'

export default {
    components: {
        UtilBar,
        Panel,
    },

    data() {
        return {
            loading: false,
            loaderImages: [
                'icon-nails', 
                'icon-hair-stylist', 
                'icon-massage', 
                'icon-lipstick'
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            worker: null,
            ratings: {1: 'hate', 2: 'disappointed', 3: 'natural', 4: 'good', 5: 'excellent'},
            ratings_desc: ['Péssimo (1)', 'Ruim (2)', 'Regular (3)', 'Bom (4)', 'Ótimo (5)'],
            schedules_list: {
                fields: [
                    { key: 'number', label: 'Número', sortable: true, sortDirection: 'asc' },
                    { key: 'created_at', label: 'Data', sortable: true, sortDirection: 'asc' },
                    { key: 'customer', label: 'Cliente', sortable: true, sortDirection: 'asc'},
                    { key: 'status', label: 'Situação', sortable: true, sortDirection: 'asc' },
                    { key: 'schedule_rating', label: 'Nota / Motivo' },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                filter_table: null,
            },
            punishments_list: {
                fields: [
                    { key: 'description', class: 'w-50', label: 'Descrição'},
                    { key: 'punishment_rules', label: 'Pontos', sortable: true, sortDirection: 'asc'},
                    { key: 'worker_value', label: 'Valor' },
                    { key: 'suspension', label: 'Suspenção' },
                    { key: 'created_at', label: 'Data', sortable: true, sortDirection: 'asc' },
                    { key: 'remove', label: 'Remover', class: 'text-center'},
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                filter_table: null,
            }
        }
    },

    created() {
        this.LoadData();
    },

    methods: {

        moment(...args){
            return moment(...args);
        },

        DeletePunishment(punishment) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Apagar`,
                html: `Tem certeza que deseja apagar a penalidade <b>${punishment.punishment_rules.parent.description}</b> da profissional <b>${this.worker.person.name}</b>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    Http.delete(`punishment/${punishment.id}`).then(response => {
                        var punishment_index = this.worker.person.punishment.findIndex(item => item.id === punishment.id);
                        this.worker.person.punishment.splice(punishment_index, 1);
                        this.Sweealert2('success', response.data.success);
                    }).catch(error => {
                        this.Sweealert2('error', error.response.data.error);
                    })
                }
            })
        },

        OnFilteredSchedule(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.schedules_list.totalRows = filteredItems.length
            this.schedules_list.currentPage = 1
        },

        OnFilteredPunishment(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.punishments_list.totalRows = filteredItems.length
            this.punishments_list.currentPage = 1
        },

        LoadData() {
            this.loading = true;
            Http.get(`worker/${this.$route.params.id}/history`, {params: this.filters}).then(response => {
                this.worker = response.data
                this.schedules_list.totalRows = this.worker.schedules.length
                this.punishments_list.totalRows = this.worker.person.punishment.length
                this.loading = false;
            }).catch(error => {
                this.Sweealert2('error', error.response.data.error)
            })
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20
            })
        },

        RatingsDesc(value) {
            let rating = Math.floor(value)
            switch(rating){
                case rating = 1:
                    return `Péssimo (${value})`
                case rating = 2:
                    return `Ruim (${value})`
                case rating = 3:
                    return `Regular (${value})`
                case rating = 4:
                    return `Bom (${value})`
                case rating = 5:
                    return `Ótimo (${value})`
            }
        },

        RatingsColors(rating) {
            switch(rating){
                case rating = 1:
                    return `bg-danger`
                case rating = 2:
                    return `bg-danger`
                case rating = 3:
                    return `bg-warning`
                case rating = 4:
                    return `bg-success`
                case rating = 5:
                    return `bg-success`
                case rating = '-':
                    return `bg-secondary`
            }
        },

        CheckLastRatings(value){
            var count = (20 - value.length)
            for (var i = 0; i < count; i++){
                value.unshift({evaluation: "-", created_at: null})
            }
            return value
        },

        RandomImages() {
            return require('@/assets/images/icons/' + this.loaderImages[Math.floor(Math.random() * this.loaderImages.length)] + '.png');
        },

        CalculatePercent(qt_ratings, qt_values_ratings){
            if(!qt_ratings){
                return 0+'%';
            }
            return Math.abs((((qt_ratings - (qt_values_ratings ? qt_values_ratings : 0)) - qt_ratings)/qt_ratings)*100)+'%'
        },

        CleanFilters() {
            this.$store.commit('worker/SET_CLEAN_FILTERS_HISTORICAL');
            this.LoadData();
        }
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        filters: {
            get(){
                return this.$store.state.worker.historical.filters;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.icon-mb {
    vertical-align: bottom;
}

.vue-reaction {
    width: 100% !important;
}

.schedule-open:hover {
    color: var(--primary);
    cursor: pointer;
}

.delete-punishment:hover {
    transform: scale(1.2);
    cursor: pointer;
}
</style>

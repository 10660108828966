<template>
    <div id="cash-flow">
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button v-if="has_permission('cashflow-edit')" v-b-modal.new-expenses-fixed variant="mary-beleza" class="mr-2">
                            <b-icon icon="plus-circle" class="mr-2" />
                            Cadastrar Despesa de Custo Fixo
                        </b-button>
                        <b-button v-if="has_permission('extract-list')" class="btn btn-outline-mary-beleza" @click="$router.push(extract_url)">
                            <b-icon icon="receipt-cutoff" class="mr-2" />
                            Ir para o Extrato
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Método de Visualização</label>
                        <button-group :options="method_options" v-model="filters.method" @input="Reload(true)"></button-group>
                    </b-col>
                    <b-col sm="12" md="8" lg="8" class="form-group">
                        <label>Mês Final <help-icon name="last-month">O fluxo de caixa apresentará 12 meses como resultado terminando no mês selecionado.</help-icon></label>
                        <month-picker inline block v-model="filters.last_month" @input="ResetFirstMonthAcc(), Reload(true)"></month-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="3" lg="2" class="form-group">
                        <label>Maximizado</label>
                        <b-form-checkbox switch v-model="filters.uncollapsed" @input="Reload(true)" size="md">Sim</b-form-checkbox >
                    </b-col>
                    <b-col sm="12" md="3" lg="2" class="form-group">
                        <label>Exibir Acumulado</label>
                        <b-form-checkbox switch v-model="filters.show_accumulate" size="md" @input="Reload(true)">{{filters.show_accumulate ? 'Sim' : 'Não'}}</b-form-checkbox >
                    </b-col>
                    <b-col v-if="filters.show_accumulate" sm="12" md="3" lg="8" class="form-group">
                        <label>Mês Inicial para Acumulação <help-icon name="last-month">O fluxo de caixa apresentará o acumulado do mês selecionado até o mês inicial do fluxo de caixa.</help-icon></label>
                        <month-picker inline block v-model="filters.first_month_acc" :maxMonthEqual="false" :maxMonth="filters.first_month" @input="Reload(true)"></month-picker>
                    </b-col>
                </b-row>
            </panel>
            
            <panel title="Fluxo de Caixa">
                <div class="cashflow" v-if="cashflow">
                    <table>
                        <cash-flow-line header-line :item="cashflow.months"></cash-flow-line>
                        <cash-flow-line v-on:LoadData="LoadData" :item="cashflow.receipts"></cash-flow-line>
                        <cash-flow-line v-on:LoadData="LoadData" :item="cashflow.expenses_variable"></cash-flow-line>
                        <cash-flow-line totalizer :item="gross_profit"></cash-flow-line>
                        <cash-flow-line v-on:OpenEditPopup="OpenEditPopup" v-on:LoadData="LoadData" :item="cashflow.expenses_fixed"></cash-flow-line>
                        <cash-flow-line totalizer :item="net_profit"></cash-flow-line>
                        <cash-flow-line totalizer :item="net_profit_sum"></cash-flow-line>
                    </table>
                </div>
                <div v-if="!cashflow" class="py-5">
                    <loader-mary-beleza :show="!cashflow" :noWrap="false"/>
                </div>
            </panel>
            
            <b-modal id="new-expenses-fixed" size="lg" title="Nova Despesa de Custo Fixo" button-size="sm" hide-footer>
                <expenses-fixed-value :key="add_item.key" v-on:AddSuccessful="AddExpenseSuccessful" v-if="add_item" v-model="add_item"></expenses-fixed-value>
            </b-modal>
            
            <b-modal id="edit-expenses-fixed" size="lg" title="Editar Despesa de Custo Fixo" button-size="sm" hide-footer>
                <div class="row">
                    <div class="col-md-12">
                        <label>Categoria</label>
                        <p>{{add_item_list.category_name}}</p>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12">
                        <button @click="OpenEditPopup(add_item_list.new_item)" class="btn btn-primary">ADICIONAR</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p v-if="add_item_list.loading" class="loading">
                            <i style="font-size:20px; padding: 20px 10px;" class="fa fa-spin fa-spinner"></i>
                            <span>Carregando....</span>
                        </p>
                        <table class="table" v-else>
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Data Pagamento</th>
                                    <th>Competência</th>
                                    <th>Registrado em</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in add_item_list.items" :key="item.id">
                                    <td>
                                        <template v-if="item.description">{{item.description}}</template>
                                        <template v-else>-</template>
                                    </td>
                                    <td>{{item.value | currency}}</td>
                                    <td>{{item.payment_date | moment("DD/MM/YYYY")}}</td>
                                    <td>{{item.competence | moment("MM/YYYY")}}</td>
                                    <td>
                                        {{item.updated_at | moment("DD/MM/YYYY HH:mm:SS")}}
                                    </td>
                                    <td>
                                        <b-button @click="EditExpenseFixed(item.id)" variant="primary">
                                            <fa icon="pen" />
                                        </b-button>
                                        <b-button @click="DeleteExpenseFixed(item.id)" variant="danger">
                                            <fa icon="trash" />
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import ExpensesFixedValue from '@/components/ExpensesFixedValue'
import ButtonGroup from '@/components/ButtonGroup'
import CashFlowLine from '@/components/CashFlowLine'
import MonthPicker from "@/components/MonthPicker"
import HelpIcon from "@/components/HelpIcon"
import UtilBar from "@/components/UtilBar"
import _cloneDeep from 'lodash/cloneDeep'
import swal from 'sweetalert2'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    components: { Panel, ButtonGroup, CashFlowLine, MonthPicker, HelpIcon, ExpensesFixedValue, UtilBar },
    data() {
        return {
            filters: {
                method: 'co',
                uncollapsed: false,
                first_month: moment().subtract(11, 'months').format("YYYY-MM"),
                last_month: moment().format("YYYY-MM"),
                show_accumulate: false,
                first_month_acc: moment().subtract(6, "months").format("YYYY-MM"),
            },
            cashflow: null,
            cashflow_clone: null,
            form_modal_open: false,
            list_modal_open: false,
            add_item: {
                id_category: null,
                competence: moment().subtract(1, "month").format("YYYY-MM"),
                payment_date: moment().format("YYYY-MM-DD")
            },
            add_item_list: {
                category_name: null,
                items: [],
                loading: false,
            }
        }
    },
    computed: {
        ...mapGetters('auth', [
            'has_permission'
        ]),

        method_options() {
            return [
                { id: 'ca', text: 'CAIXA' },
                { id: 'co', text: 'COMPETÊNCIA' },
            ]
        },
        gross_profit() {
            let values = {}
            Object.keys(this.cashflow.months.values).forEach((item) => {
                values[item] = this.cashflow.receipts.values[item] - this.cashflow.expenses_variable.values[item]
            });

            return {
                key: "",
                name: "LUCRO BRUTO",
                level: 0,
                values,
                show_children: false,
                has_children: false,
                editable: false,
                children: []
            }
        },
        net_profit() {
            let values = {}
            Object.keys(this.cashflow.months.values).forEach((item) => {
                values[item] = this.gross_profit.values[item] - this.cashflow.expenses_fixed.values[item]
            });

            return {
                key: "",
                name: "LUCRO LÍQUIDO",
                level: 0,
                values,
                show_children: false,
                has_children: false,
                editable: false,
                children: []
            }
        },
        net_profit_sum(){
            let values = {
                sum: 0
            }
            Object.keys(this.cashflow.months.values).forEach((item, index) => {
                if(index == 0) {
                    values[item] = this.net_profit.values[item];
                } else {
                    values[item] = this.net_profit.values[item] + values.sum
                }
                values.sum = values[item]
            })
            delete values.sum
            return {
                name: "LUCRO LÍQUIDO ACUMULADO",
                level: 0,
                values,
                children: null
            }
        },
        form_modal_title() {
            return this.add_item.id ? "Editar despesa de custo fixo" : "Adicionar despesa de custo fixo"
        },
        extract_url() {
            return "/financeiro/extrato";
        }
    },
    created() {
        this.LoadData('');
    },
    methods: {
        ResetFirstMonthAcc() {
            this.filters.first_month = moment(this.filters.last_month).subtract(11, 'months').format("YYYY-MM");
            this.filters.first_month_acc = moment(this.filters.first_month).subtract(6, 'months').format("YYYY-MM");
        },
        Reload(restore_collapse_state = false) {
            this.cashflow_clone = _cloneDeep(this.cashflow);
            this.cashflow = null;
            this.LoadData(null, null, null, restore_collapse_state);
        },
        LoadData(parent, resolve, reject, restore_collapse_state = false) {
            this.filters.first_month = moment(this.filters.last_month).subtract(11, 'months').format("YYYY-MM");
            let filters = _cloneDeep(this.filters)
            filters.first_month = moment(filters.last_month).subtract(11, 'months').format("YYYY-MM");
            filters.last_month = moment(filters.last_month).format("YYYY-MM");
            filters.parent = parent ? parent.key : '';

            this.$store.dispatch("financial/getCashFlow", filters).then((response) => {
                if(parent) {
                    parent.children = response;
                    parent.show_children = true;
                } else {
                  var cashflow = response;
                  if (restore_collapse_state) {
                    //this.RestoreProps(this.cashflow_clone, cashflow, ["show_children"]);
                  }

                  this.cashflow = cashflow;
                }

                this.cashflow_clone = _cloneDeep(this.cashflow);
                if (resolve) {
                    resolve(parent);
                }
            }).catch((e) => {
                if (reject) {
                    reject(parent, e);
                }
            });
        },
        RestoreProps(oldObj, newObj, props) {
          if (!oldObj || !newObj || !props) {
            return;
          }

          for (var prop in oldObj) {
            if (props.filter(p => p == prop).length > 0) {
              this.$set(newObj, prop, oldObj[prop]);
            }

            if (typeof oldObj[prop] == "object") {
              this.RestoreProps(oldObj[prop], newObj[prop], props);
            }
          }
        },
        OpenEditPopup({ key, month, has_items, category_name }) {
            if(!this.has_permission('cashflow-edit')){
                swal.fire("Permissão", "Não possui permissão para essa ação!", "error");
                return;
            }
            var parent = null;
            if(has_items) {
                this.$bvModal.show("edit-expenses-fixed");
                this.add_item_list.category_name = category_name
                this.add_item_list.new_item = {
                    key, month, has_items: false, category_name: null
                }

                parent = key.trim().split("_");
                parent = parent[parent.length - 1];
                parent ? parent.toString().replace("#", "") : parent;

                let filters = {
                    parent,
                    method: this.filters.method,
                    period: month
                }
                
                this.add_item_list.items = [];
                this.add_item_list.loading = true;
                this.$store.dispatch("financial/getExpensesFixedValues", filters).then((response) => {
                    this.add_item_list.items = response;
                    this.add_item_list.loading = false;
                });
            } else {
                this.$bvModal.hide("edit-expenses-fixed");
                let item = {
                    key: moment().format("x")
                }
                if(key)
                {
                    parent = key.trim().split("_");
                    parent = parent[parent.length - 1];
                    item.id_category = parent ? parent.toString().replace("#", "") : parent;

                    if(this.filters.method == "ca") {
                        item.payment_date = moment(month).format("YYYY-MM-DD")
                        item.competence = moment(month).subtract(1, "month").format("YYYY-MM")
                    } else {
                        item.competence = month
                        item.payment_date = moment(month).add(1, "month").format("YYYY-MM-DD")
                    }
                } else {
                    item.competence = moment().subtract(1, "month").format("YYYY-MM");
                    item.payment_date = moment().format("YYYY-MM-DD")
                    item.id_category = null;
                }
                this.add_item = item
                this.$bvModal.show("new-expenses-fixed");
            }
        },
        AddExpenseSuccessful() {
            this.$bvModal.hide("new-expenses-fixed");
            this.Reload(true);
        },
        EditExpenseFixed(id) {
            this.$store.dispatch("financial/getExpenseFixed", id).then((response) => {
                this.add_item = response;
                this.add_item.key = moment().format("x");
                this.add_item.id_category = response.person_id ? response.parent_id+"@"+response.person_id : response.parent_id;
                this.$bvModal.hide("edit-expenses-fixed");
                this.$bvModal.show("new-expenses-fixed");
            });
        },
        DeleteExpenseFixed(id) {
            swal.fire({
                title: "Excluir",
                text: "Deseja realmente excluir esta despesa de custo fixo?",
                icon: "question",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("financial/deleteExpenseFixed", id).then(() => {
                        this.Reload(true);
                        this.$bvModal.hide('edit-expenses-fixed');
                        swal.fire("Sucesso", "Sucesso ao remover", "success");
                    })
                }
            });
        }
    }
}
</script>

<style scoped>
    .modal p.loading {
        text-align: center;
    }

    .cashflow {
        overflow-x: auto;
        height: auto;
        width: 100%;
    }

    .cashflow > table {
        width: 100%;
        height: auto;
    }
</style>

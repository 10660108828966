<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.push({ path: '/colaboradores'})" class="mr-2">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                        <b-button v-on:click="verifyForm" class="btn btn-mary-beleza">
                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">

            <personal-information></personal-information>

            <financial></financial>

            <access></access>

            <addresss :validate-region="false"></addresss>

            <panel v-if="!loading" title="PERMISSÕES" icon="address-card" icon-lib="far">
                <b-row>
                    <b-col md="4" v-for="(item, key) in $store.state.employee.permissions" :key="key">
                        <permission-list :group="item" :user_permissions="getPermissionModule(item.module)" @update:user_permissions="UpdateUserPermissions(item.module, $event)" />
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Panel from "@/components/Panel"
import PersonalInformation from "@/views/Employee/components/PersonalInformation"
import Access from "@/views/Employee/components/Access"
import Addresss from "@/views/Employee/components/Address"
import Financial from "@/views/Employee/components/Financial"
import PermissionList from '@/components/PermissionList'
import UtilBar from '@/components/UtilBar'

export default {
    components: {
        PersonalInformation,
        Access,
        Financial,
        Addresss,
        Panel,
        PermissionList,
        UtilBar
    },

    data() {
        return {
            loading: false
        }
    },

    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.loading = true
            if (this.$route.params.id) {
                this.$store.dispatch("employee/loadEditForm", this.$route.params.id).then(() =>{
                    this.loading = false
                });
            } else {
                this.$store.dispatch("employee/loadInsertForm").then(() => {
                    this.loading = false
                });
            }
        },
        UpdateUserPermissions(module, new_value) {
            this.$store.dispatch('employee/updatePermission', {module, new_value})
        },

        verifyForm(){
            var employee = this.$store.state.employee.employee;
            var error = {};
            employee.person.name === '' ? error = {name: ['Nome é Obrigatório']} : null
            employee.person.email === '' ? error = Object.assign(error, {email: ['E-mail Obrigatório']}) : null
            employee.person.password === '' ? error = Object.assign(error, {password: ['Senha Obrigatória']}) : null
            employee.person.national_document === '' ? error = Object.assign(error, {national_document: ['CPF Obrigatório']}) : null
            employee.person.gender === '' ? error = Object.assign(error, {gender: ['Gênero Obrigatório']}) : null
            employee.person.address.zipcode === null ? error = Object.assign(error, {zipcode: ['CEP Obrigatório']}) : null
            employee.person.address.street === '' ? error = Object.assign(error, {street: ['Logradouro Obrigatório']}) : null
            employee.person.address.number === '' ? error = Object.assign(error, {number: ['Número Obrigatório']}) : null
            employee.person.address.district === '' ? error = Object.assign(error, {district: ['Bairro Obrigatório']}) : null
            employee.person.address.city_id === null ? error = Object.assign(error, {city_id: ['Cidade Obrigatória']}) : null
  
            if(Object.keys(error).length === 0){
                this.addError({});
                this.Save()
            }else{
                this.addError(error);
                this.sweealert2('error', error[Object.keys(error)[0]])
            }
        },

        Save() {

            if(!this.$route.params.id) {
                this.$store.dispatch('employee/createEmployee').then(response => {
                    this.sweealert2('success', response.success)
                    this.$router.push('/colaboradores')
                }).catch(error => {
                    this.addError(error)
                    this.sweealert2('error', error[Object.keys(error)[0]])
                });
            } else {
                this.$store.dispatch('employee/updateEmployee').then(response => {
                    this.sweealert2('success', response.success)
                    this.$router.push('/colaboradores')
                }).catch(error => {
                    this.addError(error)
                    this.sweealert2('error', error[Object.keys(error)[0]])
                });
            }
        },

        addError(error){
            this.$store.commit('employee/SET_EMPLOYEE_ERROR', error)
        },

        sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    },

    computed: {
        ...mapGetters('employee', [
            'getPermissionModule'
        ]),
        id: {
            get() {
                return this.$store.state.employee.employee.id;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_ID', value)
            }
        },
    },
}
</script>

<style>

</style>


// STATUS QUE UM PROFISSIONAL PODE TER
const STATUS_DISAPPROVED = -1;
const STATUS_PRE_REGISTRATION = 0;
const STATUS_APPROVED = 1;
const STATUS_NOT_SCHEDULED_TECHNICAL = 2;
const STATUS_SCHEDULED_TECHNICAL = 3;
const STATUS_NOT_SCHEDULED_TRAINING = 4;
const STATUS_SCHEDULED_TRAINING = 5;
const STATUS_PROFESSIONAL_MB = 6;
const STATUS_WAITING_CHECKING = 7;
const STATUS_DROPOUT = 8;

// STATUS QUE UM SERVIÇO PODE TER
const STATUS_OPPORTUNITY = 0;
const STATUS_ACCEPT = 1;
const STATUS_IN_PREPARATION = 2;
const STATUS_ARRIVED = 3;
const STATUS_FINISHED = 4;
const STATUS_CANCELED_WORKER = 5;
const STATUS_CANCELED_CUSTOMER = 6;
const STATUS_CANCELED_MARY_BELEZA = 7;
const STATUS_STERILIZATION_OF_MATERIALS = 8;
const STATUS_PAYMENT_IN_PROCESS = 9;
const STATUS_LOST = 10;
const STATUS_QUIT = 11;
const STATUS_EN_ROUTE = 12;
const STATUS_VALID_SERVICES = 13

//SCHEDULE
const SCHEDULE_PAYMENT_TYPE_CREDITCARD = 0;
const SCHEDULE_PAYMENT_TYPE_PIX = 1;

// STATUS QUE UMA TRANSAÇÃO PODE TER
const STATUS_DRAFT = -1;
const STATUS_UNKNOW = 0;
const STATUS_PENDING = 1;
const STATUS_RECEIVED = 2;
const STATUS_RECEIVED_IN_CASH = 3;
const STATUS_OVERDUE = 4;
const STATUS_CONFIRMED = 5;
const STATUS_PROCESSING = 6;
const STATUS_FAIL = 7;
const STATUS_CANCELED_TRA = 12;

// TIPOS DE TRANSAÇÕES
const TYPE_EXPENSE = 0;
const TYPE_RECIPE = 1;

// TIPOS DE PAGAMENTO
const PAYMENT_TYPE_MONEY = 1;
const PAYMENT_TYPE_BOLETO = 2;
const PAYMENT_TYPE_CREDITCARD = 3;
const PAYMENT_TYPE_DEBITCARD = 4;
const PAYMENT_TYPE_PREDATED_CHECK = 5;
const PAYMENT_TYPE_DEPOSIT_CHECKING_ACCOUNT = 6;
const PAYMENT_TYPE_PAYCHECK = 7;

// TIPOS DE PROMOÇÕES E VALORES DAS PROMOÇÕES
const PROMOTION_TYPE_COUPON = 1;

// TIPOS DE VALORES DAS PROMOÇÕES
const PROMOTION_VALUE_TYPE_PERCENTAGE = 1;
const PROMOTION_VALUE_TYPE_FIXED = 0;

// TIPOS DE PESSOAS
const PERSON_TYPE_CUSTOMER = 'customer';
const PERSON_TYPE_WORKER = 'worker';
const PERSON_TYPE_EMPLOYEE = 'employee';
const PERSON_TYPE_FRANCHISE = 'franchise';

// TIPOS NOTIFICAÇÕES
const TYPE_TEXT_NOTIFICATION = 6;

// STATUS DE ENVIO DE NOTIFICAÇÃO
const SENT_NOT = 0;
const SENT_SUCCESS = 1;
const SENT_ERROR = 2;

// TIPOS DE FERIADOS
const NATIONAL_HOLIDAY = 0;
const LOCAL_HOLIDAY = 1;

// TIPOS DE CHECKINS
const TYPE_IN_PREPARATION = 0;
const TYPE_I_GOT_TO_THE_SERVICE = 1;
const TYPE_STERILIZATION_OF_MATERIALS = 2;
const TYPE_COMPLETED_SERVICE = 3;
const TYPE_ON_THE_WAY = 4;

// TIPOS DE PROLEMAS DOS AGENDAMENTOS
const TYPE_WORKER_DELAY = 0;
const TYPE_CUSTOMER_NOT_FOUND = 1;
const TYPE_ADDRESS_NOT_FOUND = 2;
const TYPE_OTHERS = 3;
const TYPE_CUSTOMER_DIFFERENT_SERVICE = 4;
const TYPE_CUSTOMER_NOT_SERVICE = 5;
const TYPE_LOCATION_NOT_CONDITIONS = 6;

// TIPOS DE PENALIDADES
const TYPE_DELAY = 1;
const TYPE_ABANDONMENT = 2;
const TYPE_CANCELLATION = 3;
const TYPE_EDITION = 4;

// STATUS NOTAS FISCAIS
const STATUS_INVOICE_UNKNOW = 0;
const STATUS_SCHEDULED = 1;
const STATUS_SYNCHRONIZED = 2;
const STATUS_AUTHORIZED = 3;
const STATUS_PROCESSING_CANCELLATION = 4;
const STATUS_CANCELED = 5;
const STATUS_CANCELLATION_DENIED = 6;
const STATUS_ERROR = 7;


//STATUS DE IMPORTAÇÃO DE PROFISSIONAIS
const STATUS_MARYBELEZASITE = 'marybelezasite';
const STATUS_RECRUTAMENTOBELEZA = 'recrutamentobeleza';
const STATUS_INFOJOBS = 'infojobs';
const STATUS_SISTEMA = 'system';

export default {
    // STATUS QUE UM PROFISSIONAL PODE TER
    STATUS_DISAPPROVED: STATUS_DISAPPROVED,
    STATUS_PRE_REGISTRATION: STATUS_PRE_REGISTRATION,
    STATUS_WAITING_CHECKING: STATUS_WAITING_CHECKING,
    STATUS_APPROVED: STATUS_APPROVED,
    STATUS_NOT_SCHEDULED_TECHNICAL: STATUS_NOT_SCHEDULED_TECHNICAL,
    STATUS_SCHEDULED_TECHNICAL: STATUS_SCHEDULED_TECHNICAL,
    STATUS_NOT_SCHEDULED_TRAINING: STATUS_NOT_SCHEDULED_TRAINING,
    STATUS_SCHEDULED_TRAINING: STATUS_SCHEDULED_TRAINING,
    STATUS_PROFESSIONAL_MB: STATUS_PROFESSIONAL_MB,
    STATUS_DROPOUT: STATUS_DROPOUT,

    // STATUS QUE UM SERVIÇO PODE TER
    STATUS_OPPORTUNITY: STATUS_OPPORTUNITY,
    STATUS_ACCEPT: STATUS_ACCEPT,
    STATUS_IN_PREPARATION: STATUS_IN_PREPARATION,
    STATUS_ARRIVED: STATUS_ARRIVED,
    STATUS_FINISHED: STATUS_FINISHED,
    STATUS_CANCELED_WORKER: STATUS_CANCELED_WORKER,
    STATUS_CANCELED_CUSTOMER: STATUS_CANCELED_CUSTOMER,
    STATUS_CANCELED_MARY_BELEZA: STATUS_CANCELED_MARY_BELEZA,
    STATUS_STERILIZATION_OF_MATERIALS: STATUS_STERILIZATION_OF_MATERIALS,
    STATUS_PAYMENT_IN_PROCESS: STATUS_PAYMENT_IN_PROCESS,
    STATUS_LOST: STATUS_LOST,
    STATUS_QUIT: STATUS_QUIT,
    STATUS_EN_ROUTE: STATUS_EN_ROUTE,
    STATUS_VALID_SERVICES: STATUS_VALID_SERVICES,

    //SCHEDULE
    SCHEDULE_PAYMENT_TYPE_CREDITCARD: SCHEDULE_PAYMENT_TYPE_CREDITCARD ,
    SCHEDULE_PAYMENT_TYPE_PIX: SCHEDULE_PAYMENT_TYPE_PIX ,

    // STATUS QUE UMA TRANSAÇÃO PODE TER
    STATUS_DRAFT: STATUS_DRAFT,
    STATUS_UNKNOW: STATUS_UNKNOW,
    STATUS_PENDING: STATUS_PENDING,
    STATUS_RECEIVED: STATUS_RECEIVED,
    STATUS_RECEIVED_IN_CASH: STATUS_RECEIVED_IN_CASH,
    STATUS_OVERDUE: STATUS_OVERDUE,
    STATUS_CONFIRMED: STATUS_CONFIRMED,
    STATUS_PROCESSING: STATUS_PROCESSING,
    STATUS_FAIL: STATUS_FAIL,
    STATUS_CANCELED_TRA: STATUS_CANCELED_TRA,

    // TIPOS DE TRANSAÇÕES
    TYPE_EXPENSE: TYPE_EXPENSE,
    TYPE_RECIPE: TYPE_RECIPE,
    
    // TIPOS DE PAGAMENTO
    PAYMENT_TYPE_MONEY: PAYMENT_TYPE_MONEY,
    PAYMENT_TYPE_BOLETO: PAYMENT_TYPE_BOLETO,
    PAYMENT_TYPE_CREDITCARD: PAYMENT_TYPE_CREDITCARD,
    PAYMENT_TYPE_DEBITCARD: PAYMENT_TYPE_DEBITCARD,
    PAYMENT_TYPE_PREDATED_CHECK: PAYMENT_TYPE_PREDATED_CHECK,
    PAYMENT_TYPE_DEPOSIT_CHECKING_ACCOUNT: PAYMENT_TYPE_DEPOSIT_CHECKING_ACCOUNT,
    PAYMENT_TYPE_PAYCHECK: PAYMENT_TYPE_PAYCHECK,

    // TIPOS DE PROMOÇÕES
    PROMOTION_TYPE_COUPON: PROMOTION_TYPE_COUPON,

    // TIPOS DE VALORES DE PROMOÇÕES
    PROMOTION_VALUE_TYPE_PERCENTAGE: PROMOTION_VALUE_TYPE_PERCENTAGE,
    PROMOTION_VALUE_TYPE_FIXED: PROMOTION_VALUE_TYPE_FIXED,

    // TIPOS DE PESSOAS
    PERSON_TYPE_CUSTOMER: PERSON_TYPE_CUSTOMER,
    PERSON_TYPE_WORKER: PERSON_TYPE_WORKER,
    PERSON_TYPE_EMPLOYEE: PERSON_TYPE_EMPLOYEE,
    PERSON_TYPE_FRANCHISE: PERSON_TYPE_FRANCHISE,

    // TIPOS DE NOTIFICAÇÕES
    TYPE_TEXT_NOTIFICATION: TYPE_TEXT_NOTIFICATION,

    // STATUS DE ENVIO DE NOTIFICAÇÃO
    SENT_NOT: SENT_NOT,
    SENT_SUCCESS: SENT_SUCCESS,
    SENT_ERROR: SENT_ERROR,

    // TIPOS DE FERIADOS
    NATIONAL_HOLIDAY: NATIONAL_HOLIDAY,
    LOCAL_HOLIDAY: LOCAL_HOLIDAY,

    // TIPOS DE CHECKINS
    TYPE_ON_THE_WAY: TYPE_ON_THE_WAY,
    TYPE_I_GOT_TO_THE_SERVICE: TYPE_I_GOT_TO_THE_SERVICE,
    TYPE_STERILIZATION_OF_MATERIALS: TYPE_STERILIZATION_OF_MATERIALS,
    TYPE_COMPLETED_SERVICE: TYPE_COMPLETED_SERVICE,
    TYPE_IN_PREPARATION: TYPE_IN_PREPARATION,

    // TIPOS DE PROLEMAS DOS AGENDAMENTOS
    TYPE_WORKER_DELAY: TYPE_WORKER_DELAY,
    TYPE_CUSTOMER_NOT_FOUND: TYPE_CUSTOMER_NOT_FOUND,
    TYPE_ADDRESS_NOT_FOUND: TYPE_ADDRESS_NOT_FOUND,
    TYPE_OTHERS: TYPE_OTHERS,
    TYPE_CUSTOMER_DIFFERENT_SERVICE: TYPE_CUSTOMER_DIFFERENT_SERVICE,
    TYPE_CUSTOMER_NOT_SERVICE: TYPE_CUSTOMER_NOT_SERVICE,
    TYPE_LOCATION_NOT_CONDITIONS: TYPE_LOCATION_NOT_CONDITIONS,

    // TIPOS DE PENALIDADES
    TYPE_DELAY: TYPE_DELAY,
    TYPE_ABANDONMENT: TYPE_ABANDONMENT,
    TYPE_CANCELLATION: TYPE_CANCELLATION,
    TYPE_EDITION: TYPE_EDITION,

    // STATUS NOTAS FISCAIS
    STATUS_INVOICE_UNKNOW: STATUS_INVOICE_UNKNOW,
    STATUS_SCHEDULED: STATUS_SCHEDULED,
    STATUS_SYNCHRONIZED: STATUS_SYNCHRONIZED,
    STATUS_AUTHORIZED: STATUS_AUTHORIZED,
    STATUS_PROCESSING_CANCELLATION: STATUS_PROCESSING_CANCELLATION,
    STATUS_CANCELED: STATUS_CANCELED,
    STATUS_CANCELLATION_DENIED: STATUS_CANCELLATION_DENIED,
    STATUS_ERROR: STATUS_ERROR,

    //STATUS DE IMPORTAÇÃO DE PROFISSIONAIS
    STATUS_MARYBELEZASITE: STATUS_MARYBELEZASITE,
    STATUS_RECRUTAMENTOBELEZA: STATUS_RECRUTAMENTOBELEZA,
    STATUS_INFOJOBS: STATUS_INFOJOBS,
    STATUS_SISTEMA: STATUS_SISTEMA
}
<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" v-on:click="$router.push({ path: '/utilitarios/promocoes'})">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                        <b-button v-on:click="verifyForm" class="btn btn-mary-beleza ml-2">
                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel>
                <b-row>
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Nome:</label>
                                    <b-form-input v-model="name" type="text" placeholder="Nome" name='name' v-validate="'required'" data-vv-as="Nome"></b-form-input>
                                    <span class="text-danger" v-if="errors.has('name')">{{errors.first('name')}}</span>
                                </div>
                            </b-col>
                            
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label>Descrição:</label>
                                    <b-form-input v-model="description" type="text" placeholder="Descrição"></b-form-input>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="6" lg="6" v-if="false">
                                <div role="group">
                                    <label>Tipo da Promoção: </label>
                                    <b-form-select v-model="type" :options="promotions_type"></b-form-select>
                                </div>
                            </b-col>

                            <b-col sm="12" md="12" lg="12">
                                <div role="group">
                                    <label class="required">Código do Cupom:</label>
                                    <b-form-input class="text-uppercase" v-model="coupon" type="text" placeholder="Ex.: MARYBELEZA10" name='coupon' v-validate="'required'" data-vv-as="Código do Cupom"></b-form-input>
                                    <span class="text-danger" v-if="errors.has('coupon')">{{errors.first('coupon')}}</span>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <div role="group">
                                    <label>Serviços: </label>
                                    <b-form-group>
                                        <services-select multiple v-model="services_selected"></services-select>
                                    </b-form-group>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Aplicar: </label>
                                    <b-form-group>
                                        <button-group :options="promotions_value_type" v-model="value_type"></button-group>
                                    </b-form-group>
                                </div>
                            </b-col>

                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Valor do Desconto:</label>
                                    <money name='value' v-validate="'required|nozero'" data-vv-as="Valor do Desconto" v-if="value_type == constants.PROMOTION_VALUE_TYPE_FIXED" class="form-control" v-model="value" v-bind="money" ></money>
                                    <money name='value' v-validate="'required|nozero'" data-vv-as="Valor do Desconto" v-else-if="value_type == constants.PROMOTION_VALUE_TYPE_PERCENTAGE" class="form-control" v-model="value" v-bind="percentage" ></money>
                                    <span class="text-danger" v-if="errors.has('value')">{{errors.first('value')}}</span>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Quantidade de Usos Geral:</label> <help-icon name="number-of-uses" icon="patch-question-fill">Quantidade de vezes em que o cupom poderá ser utilizado. Deixe <strong>0 (zero)</strong> para quantidade ilimitada.</help-icon>
                                    <b-form-input name='number' v-validate="'required|nozero'" data-vv-as="Quantidade de Usos Geral" v-model="number_of_uses" type="number"></b-form-input>
                                    <span class="text-danger" v-if="errors.has('number')">{{errors.first('number')}}</span>
                                </div>
                            </b-col>
                            
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Quantidade de Usos por Cliente: </label> <help-icon name="number-of-uses-per-customer" icon="patch-question-fill">Quantidade de vezes em que o cupom poderá ser utilizado por um mesmo cliente. Deixe <strong>0 (zero)</strong> para que o cliente possa utilizar o cupom ilimitadamente.</help-icon>
                                    <b-form-input name='number_of_uses_per_customer' v-validate="'required|nozero'" data-vv-as="Quantidade de Usos por Cliente" v-model="number_of_uses_per_customer" type="number"></b-form-input>
                                    <span class="text-danger" v-if="errors.has('number_of_uses_per_customer')">{{errors.first('number_of_uses_per_customer')}}</span>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label>Cupom válido DE:</label>
                                    <v-date-picker :min-date='$route.params.id ? null : new Date()' is-expanded :model-config="modelConfig" v-model="start_date" mode="dateTime" is24hr></v-date-picker>
                                </div>
                            </b-col>
                            
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label>Cupom válido ATÉ: </label>
                                    <v-date-picker :min-date='start_date' is-expanded :model-config="modelConfig" v-model="end_date" mode="dateTime" is24hr></v-date-picker>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group" v-if="false">
                            <b-col sm="12" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Aplicar somente esta promoção? </label> <help-icon name="single-rule" icon="patch-question-fill">Selecione <strong>NÃO</strong> caso queira que a promoção possa ser usada cumulativamente com outras promoções.</help-icon>
                                    <b-form-select v-model="single_rule" class="mb-3">
                                        <b-form-select-option value="0">Não</b-form-select-option>
                                        <b-form-select-option value="1">Sim</b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="!errors.single_rule">
                                        <div v-for="error in errors.single_rule" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import HelpIcon from '@/components/HelpIcon'
import ButtonGroup from '@/components/ButtonGroup'
import ServicesSelect from '@/components/ServicesSelect'

export default {
    components: {
        Panel,
        UtilBar,
        HelpIcon,
        ButtonGroup,
        ServicesSelect
    },
    data() {
        return {
            promotions_type: [
                { value: Constants.PROMOTION_TYPE_COUPON, text: 'Cupom' },
            ],
            promotions_value_type: [
                { id: Constants.PROMOTION_VALUE_TYPE_PERCENTAGE, text: 'Porcentagem' },
                { id: Constants.PROMOTION_VALUE_TYPE_FIXED, text: 'Valor Fixo' },
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm',
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            }
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            if (this.$route.params.id) {
                this.$store.dispatch("promotion/loadEditForm", this.$route.params.id);
            } else {
                this.$store.dispatch("promotion/loadInsertForm");
            }
        },

        verifyForm(){
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                this.Save();
            });
        },

        Save() {
            var promotion = this.$store.state.promotion.promotion;
            let message = `Deseja realmente criar o cupom <strong>${promotion.coupon.toUpperCase()}</strong> para ser utilizado `;

            if(promotion.number_of_uses == 0)
                message += `<strong>sem quantidade limite</strong> no App `
            else if(promotion.number_of_uses > 0)
                message += `<strong>${promotion.number_of_uses} vezes</strong> no App `

            if(promotion.number_of_uses_per_customer == 0)
                message += `e <strong>sem limite de uso</strong> por um mesmo cliente?`
            else if(promotion.number_of_uses_per_customer > 0)
                message += `e <strong>${promotion.number_of_uses_per_customer} vezes</strong> por um mesmo cliente?`
            
            
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: 'Atenção',
                html: message,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    if(!this.$route.params.id) {
                        this.$store.dispatch('promotion/createPromotion').then(response => {
                            this.sweealert2('success', response.success)
                            this.$router.push('/utilitarios/promocoes')
                        }).catch(() => {});
                    } else {
                        this.$store.dispatch('promotion/updatePromotion').then(response => {
                            this.sweealert2('success', response.success)
                            this.$router.push('/utilitarios/promocoes')
                        }).catch(() => {});
                    }
                }
            })
        },

        addError(error){

            this.$store.commit('promotion/SET_ADD_ERROR_IN_ARRAY', error)
        },

        sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    },

    computed: {
        
        id: {
            get() {
                return this.$store.state.promotion.promotion.id;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_ID', value)
            }
        },

        name: {
            get() {
                return this.$store.state.promotion.promotion.name;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_NAME', value)
            }
        },

        description: {
            get() {
                return this.$store.state.promotion.promotion.description;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_DESCRIPTION', value)
            }
        },

        type: {
            get() {
                return this.$store.state.promotion.promotion.type;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_TYPE', value)
            }
        },

        coupon: {
            get() {
                return this.$store.state.promotion.promotion.coupon;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_COUPON', value)
            }
        },

        number_of_uses: {
            get() {
                return this.$store.state.promotion.promotion.number_of_uses;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_NUMBER_OF_USES', value)
            }
        },
        
        number_of_uses_per_customer: {
            get() {
                return this.$store.state.promotion.promotion.number_of_uses_per_customer;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_NUMBER_OF_USES_PER_CUSTOMER', value)
            }
        },

        start_date: {
            get() {
                return this.$store.state.promotion.promotion.start_date;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_START_DATE', value)
                if(this.end_date < value){
                    this.$store.commit('promotion/SET_PROMOTION_END_DATE', value)
                }
            }
        },

        end_date: {
            get() {
                return this.$store.state.promotion.promotion.end_date;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_END_DATE', value)
            }
        },

        value: {
            get() {
                return this.$store.state.promotion.promotion.value;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_VALUE', value)
            }
        },

        value_type: {
            get() {
                return this.$store.state.promotion.promotion.value_type;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_VALUE_TYPE', value)
            }
        },

        services_selected: {
            get() {
                return this.$store.state.promotion.promotion.services;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_SERVICES', value)
            }
        },

        single_rule: {
            get() {
                return this.$store.state.promotion.promotion.single_rule;
            },
            set(value) {
                this.$store.commit('promotion/SET_PROMOTION_SINGLE_RULE', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.promotion.errors;
            },

        },

        constants(){
            return Constants
        },
    },
}
</script>

<style>

</style>

<template>
    <div class="mary-beleza-header">
        <nav class="navbar navbar-expand-md navbar-dark bg-mary-beleza-gradient">
            <button class="navbar-toggler navbar-toggler-right" type="button" v-b-toggle="'collapse-mobile-menu'">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand">
                <img src="@/assets/images/logo/small/logo-mb-branco-sistema.png" alt="Mary Beleza" title="Mary Beleza" height="40" class="d-inline-block align-top">
            </a>
            <ul class="navbar-nav toggle-menu">
                <!-- <li class="nav-item text-white d-flex align-items-center">
                    <label for="openSidebarMenu" class="sidebarIconToggle" @click="MenuToggle()">
                        <div class="spinner diagonal part-1" :class="{ 'checked': menu_collapsed }"></div>
                        <div class="spinner horizontal" :class="{ 'checked': menu_collapsed }"></div>
                        <div class="spinner diagonal part-2" :class="{ 'checked': menu_collapsed }"></div>
                    </label>
                </li> -->
                <li class="nav-item text-white d-flex align-items-center">
                    <label for="openSidebarMenu" class="sidebarIconToggle" @click="MenuToggle()">
                        <!-- <fa icon="chevron-left"  /> -->
                        <b-iconstack font-scale="1" :class="{ 'checked': menu_collapsed }">
                            <b-icon stacked icon="chevron-left" variant="white"></b-icon>
                            <b-icon stacked icon="chevron-left" shift-h="6" variant="mary-beleza-light"></b-icon>
                        </b-iconstack>
                    </label>
                </li>
            </ul>
            
            <b-collapse id="collapse-mobile-menu" class="navbar-collapse">
                <ul class="navbar-nav navbar-menu">
                    <li class="nav-item active">
                        <router-link to="/" class="nav-link" v-b-toggle="'collapse-mobile-menu'">
                            <fa icon="tachometer-alt" class="fa-fw mr-3" />
                            <span class="menu-collapsed">Dashboard</span>
                        </router-link>
                    </li>

                    <li class="nav-item active new" v-for="(menu_group, index) in menu.items" :key="index">
                        <template v-if="!menu_group.subitems || menu_group.subitems.length == 0">
                            <router-link v-if="!menu_group.external" :to="menu_group.route" class="nav-link"  v-b-toggle="'collapse-mobile-menu'">
                                <fa icon="tachometer-alt" class="fa-fw mr-3" />
                                <span class="menu-collapsed">{{menu_group.desc}}</span>
                            </router-link>
                            <a v-else :target="menu_group.target" :href="menu_item.route" class="nav-link" v-b-toggle="'collapse-mobile-menu'">
                                <fa icon="tachometer-alt" class="fa-fw mr-3" />
                                <span class="menu-collapsed">{{menu_group.desc}}</span>
                            </a>
                        </template>
                        <template v-else>
                            <a class="c-pointer nav-link d-flex align-content-between" v-b-toggle="'menu_' + index">
                                <fa :icon="menu_group.icons" class="fa-fw mr-3" />
                                <span class="menu-collapsed">{{menu_group.desc}}</span>
                                <span class="submenu-icon ml-auto">
                                    <fa icon="angle-right" class="fa-fw mr-3" />
                                </span>
                            </a>
                            <b-collapse :id="'menu_' + index" accordion="main-menu">
                                <ul class="submenu">
                                    <li class="text-white py-2" v-for="(menu_item, item_index) in menu_group.subitems" :key="item_index">
                                        <router-link v-if="!menu_item.external" :to="menu_item.route" class="my-2 list-group-item-action text-white text-sm-left" v-b-toggle="'collapse-mobile-menu'">
                                            <span class="menu-collapsed">{{menu_item.desc}}</span>
                                        </router-link>
                                        <a v-else :target="menu_item.target" :href="menu_item.route" class="my-2 list-group-item-action text-white text-sm-left" v-b-toggle="'collapse-mobile-menu'">
                                            <span class="menu-collapsed">{{menu_item.desc}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </b-collapse>
                        </template>
                    </li>
                </ul>

                <comemorative-dates></comemorative-dates>

                <ul class="navbar-nav navbar-services">
                    <li class="nav-item active border-right nav-welcome">
                        <a class="nav-link">
                            Bem-vindo, <strong>{{user.name}}</strong>
                        </a>
                    </li>
                    <li class="nav-item active">
                        <router-link to="/tabela-de-precos" v-b-tooltip title="Tabela de Preços" class="nav-link" v-if="has_permission('price-table')">
                            <b-icon icon="table" font-scale="1" />
                            <span class="menu-collapsed">Tabela de Preços</span>
                        </router-link>
                    </li>
                    <li class="nav-item active" v-if="has_permission('configs')">
                        <router-link to="/configuracoes" class="nav-link" v-b-tooltip title="Configurações">
                            <b-icon icon="gear" font-scale="1" />
                            <span class="menu-collapsed">Configurações</span>
                        </router-link>
                    </li>
                    <li class="nav-item active">
                        <router-link to="/alterar-senha" v-b-tooltip title="Alterar Senha" class="nav-link">
                            <b-icon icon="key" font-scale="1" rotate="45" />
                            <span class="menu-collapsed">Alterar Senha</span>
                        </router-link>
                    </li>
                    <li class="nav-item active">
                        <button @click="Logout" v-b-tooltip title="Sair" class="btn btn-link nav-link">
                            <b-icon icon="door-closed" font-scale="1" />
                            <span class="menu-collapsed">Sair</span>
                        </button>
                    </li>
                </ul>
            </b-collapse>
        </nav>
    </div>
</template>

<script>
import ComemorativeDates from '@/components/ComemorativeDates'
import { mapGetters } from 'vuex'

export default {
    components: {
        ComemorativeDates
    },
    computed: {
        ...mapGetters('auth', [
            'user',
            'has_permission'
        ]),
        ...mapGetters('system', [
            'menu_collapsed', 'menu'
        ]),
    },
    methods: {
        MenuToggle() {
            this.$store.dispatch('system/toggle_menu')
        },
        Logout() {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>

<style scoped>
    #collapse-mobile-menu >>> .submenu {
        list-style: none !important;
    }

    #collapse-mobile-menu >>> .submenu {
        margin-left: 10px;
    }
</style>
<template>
    <b-overlay :show="show" :no-wrap="noWrap" rounded="sm">
        <template #overlay>
            <div class="text-center">
                <img :src="RandomImages()" class="vibrate-1" width="40%" />
                <h6 class="blink-2"><small>Carregando</small></h6>
                <!-- <b-icon icon="stopwatch" animation="cylon" font-scale="2"></b-icon> -->
            </div>
        </template>
    </b-overlay>
</template>

<script>
    export default {
        name: 'ButtonGroup',
        props: {
            show: {
                type: Boolean,
                default: false
            },
            noWrap: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loaderImages: [
                    'icon-nails', 
                    'icon-hair-stylist', 
                    'icon-massage', 
                    'icon-lipstick'
                ]
            }
        },
        methods: {
            RandomImages() {
                return require('@/assets/images/icons/' + this.loaderImages[Math.floor(Math.random() * this.loaderImages.length)] + '.png');
            }
        },
    }
</script>

<style scoped>
</style>
<template>
    <div id="customer-media">
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">

                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <label>Data:</label>
                        <v-date-picker v-model="filters.date" is-range clas>
                            <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input v-if="inputValue.start || inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                                <b-form-input v-else v-on="inputEvents.start" />
                            </template>
                        </v-date-picker>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>
                    
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Opções:</label>
                        <b-form-select v-model="filters.option" :options="media_options"></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col sm="12" md="4" lg="4" class="form-group pt-3">
                        <b-button variant="primary" block class="mt-3"><b-icon class="mr-2" icon="receipt-cutoff" font-scale="1" /> Gerar Relatório</b-button>
                    </b-col>
                </b-row>
            </panel>

            <panel :title="'Dados no Período de ' + date_start + ' a ' + date_end" icon="calendar-alt" icon-lib="far">
                <b-row class="form-group">
                    <b-col sm="12" md="6" lg="6">
                        <b-table-simple responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>
                                        Tipo de Divulgação
                                    </b-th>
                                    <b-th>
                                        Quantidade
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="items.length">
                                <b-tr v-for="(item, index) in items" :key="index">
                                    <b-td>
                                        {{ item.description }}
                                    </b-td>
                                    <b-td>
                                        {{ item.qty }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td colspan="2" class="text-center"><small>Nenhum Resultado Encontrado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col sm="12" md="6" lg="6">
                        <apexchart type="pie" width="100%" :options="chart_options" :series="chart_series"></apexchart>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Opções de OUTROS escolhidos" icon="calendar-alt" icon-lib="far">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <b-table-simple responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr class="text-center">
                                    <b-th>
                                        Opções
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody class="text-center">
                                <b-tr>
                                    <b-td>
                                        Indicação
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Já conhecia
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import UtilBar from "@/components/UtilBar"
import moment from 'moment' // eslint-disable-line

export default {
    data() {
        return {
            show: true,
            loadingData: false,
            date_start: '',
            date_end: '',
            filters: {
                date: {
                    start: '',
                    end: ''
                },
                date_start: null,
                date_end: null,
                option: ''
            },
            media_options: [
                {value: '', text: 'Todas' },
                {value: 21, text: 'Blogueiras' },
                {value: 16, text: 'Cartão de visita' },
                {value: 14, text: 'Email Marketing' },
                {value: 10, text: 'Facebook' },
                {value: 9, text: 'oogle' },
                {value: 8, text: 'ndicação de Amigos' },
                {value: 17, text: 'Instagram' },
                {value: 15, text: 'Jornal' },
                {value: 19, text: 'Outdoor' },
                {value: 12, text: 'Panfletos de Propagandas' },
                {value: 11, text: 'Revistas' },
                {value: 20, text: 'SMS' },
                {value: 18, text: 'Whatsapp' },
                {value: 0, text: '', disabled: true },
                {value: 13, text: 'Outros' },
            ],
            items: [
                {id: 1, description: 'Indicação de Amigos', qty: 9 },
                {id: 2, description: 'Google', qty: 7 },
                {id: 3, description: 'Outros', qty: 3 },
                {id: 4, description: 'Blogueiras', qty: 2 },
                {id: 5, description: 'Facebook', qty: 1 },
                {id: 1, description: 'Revistas', qty: 1 },
            ],
            chart_series: [],
            chart_options: {
                labels: [],
                responsive: [{
                    breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
                }]
            },
        }
    },
    watch: {
        'filters.date': {
            handler: function () {
                this.date_start = moment(this.filters.date.start).format('DD/MM/YYYY')
                this.date_end = moment(this.filters.date.end).format('DD/MM/YYYY')
            },
            deep: true
        }
    },
    components: {
        Panel, UtilBar
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            for(var item in this.items) {
                this.chart_options.labels.push(this.items[item].description);
                this.chart_series.push(this.items[item].qty);
            }
        },
    }
}
</script>

<style>

</style>
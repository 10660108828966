<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row" v-if="title">
                <div class="col-md-12">
                    <h4 class="title">{{title}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <graphic ref="graphic" :grid="grid" :width="width" :height="height" typeGraphic="bar" :data="data"></graphic>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Graphic from './Graphic.vue'

export default {
    props: {
        width: {
            type: [String,Number],
            required: true
        },
        height: {
            type: [String,Number],
            required: true
        },
        title: {
            type: String,
            required: false
        },
        data: {
            type: Object,
            required: true
        },
        grid: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        Graphic
    },
    methods: {
        buildGraphic() {
            this.$refs.graphic.buildGraphic();
        }
    }
}
</script>

<style scoped>
    .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
</style>

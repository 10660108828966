export default class Schedule {

    constructor() {

        this.id = null,
        this.number = "",
        this.service_date = null,
        this.service_date_end = null,
        this.status = null,
        this.payment_type = 0,
        this.customer_value = null,
        this.worker_value = null,
        this.address_id = null,
        this.customer_id = null,
        this.worker_id = null,
        this.service_id = null,
        this.obs = null,
        this.deleted_at = null,
        this.created_at = "",
        this.updated_at = "",
        this.customer_prepaidcard_id = null,
        this.gender_preference = null,
        this.tolerance = null,
        this.customer = {
            id: null,
            person: {
                id: null,
                name: "",
                phones: []
            }
        },
        this.worker = {
            id: null,
            person: {
                id: null,
                name: "",
                phones: []
            }
        },
        this.address = {
            id: null,
            city_id: null,
            district: "",
            street: "",
            number: "",
            latitude: null,
            longitude: null,
            city: {
                id: null,
                state_id: null,
                name: "",
                enabled: null,
                state: {
                    id: null,
                    name: "",
                    acronym: ""
                }
            }
        },
        this.hours = [],
        this.service = {
            id: null,
            name: ""
        },
        this.transactions = [],
        this.schedule_problems = [],
        this.schedule_history = [],
        this.schedule_checkins = [],
        this.customer_prepaidcard = []
        
    }
}
<template>
	<div id="mary-beleza">
		<template v-if="!$route.meta.plainLayout">
			<app-layout>
				<router-view :key="$route.path"></router-view>
			</app-layout>
		</template>

		<template v-if="$route.meta.plainLayout">
			<router-view :key="$route.path"></router-view>
		</template>
	</div>
</template>

<script>
	import AppLayout from '@/components/AppLayout'
	import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.css'
	import '@/assets/scss/main.scss'

	export default {
		name: 'App',
		components: {
			AppLayout
		},
	}
</script>

<style>
.only-print {
	display: none !important;
}

@media print {
	.no-print, .no-print * {
		display: none !important;
	}

	.only-print {
		display: block !important;
	}
}
</style>
<template>
  <div
    :title="title"
    ref="WorkerRegisterPrint"
    class="WorkerRegisterPrint text-left"
  >
    <b-row class="no-print">
      <b-col md="12" class="text-right">
        <b-button
          class="btn-print"
          size="sm"
          :variant="variant"
          type="button"
          @click="print()"
          ><b-icon icon="printer" font-scale="1" />
          <slot></slot>
        </b-button>
      </b-col>
    </b-row>
    <div class="only-print">
      <b-row class="page" v-if="Workers.length > 1">
        <b-col md="12">
          <b-row class="form-group">
            <b-col md="4" class="text-left">
              <img
                src="@/assets/images/logo/medium/logo-mb-colorido.png"
                alt="Mary Beleza"
                title="Mary Beleza"
                class="logo d-inline-block align-top"
              />
            </b-col>
            <b-col md="8" class="text-left">
              <br />
              <br />
              <br />
              <h1>
                Entrevistas - Resumo de
                {{ moment(typeof dateSummary == 'object' ? dateSummary.start : dateSummary).format("DD/MM/YYYY") }}
              </h1>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col sm="4">
              <label>Nome</label>
            </b-col>
            <b-col sm="2">
              <label>CPF</label>
            </b-col>
            <b-col sm="4">
              <label>Telefone</label>
            </b-col>
            <hr />
          </b-row>
          <b-row class="form-group" v-for="(worker, i) in Workers" :key="i">
            <b-col sm="4">
              <b
                ><b-icon icon="clock-history" class="mr-1"></b-icon
                >{{ worker.scheduling_training | moment("HH:mm") }}:</b
              >
              <span class="ml-1">{{ worker.name }}</span>
              <b-row>
                <b-col>
                  <p
                    style="font-size: 12px!important"
                    v-for="(item,
                    key) in worker.worker.worker_habilities_scheduling.filter(
                      worker => !worker.pivot.absence
                    )"
                    :key="key"
                  >
                    - {{ item.name }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="2">
              <span>{{ worker.national_document | formatDocuments }}</span>
            </b-col>
            <b-col sm="4" class="mb-2">
              <span>{{ worker.phone | formatPhones }}</span>
            </b-col>
            <b-col
              sm="12"
              v-for="(hability, key) in WorkerHabilitiesScheduling(worker)"
              :key="key"
            >
              <b-icon icon="clock-history" class="mr-2"></b-icon>
              <label v-if="onlyScheduled">{{
                hability.pivot.scheduling_technical | moment("HH:mm")
              }}</label>
              <span class="ml-2">{{ hability.name }}</span>
            </b-col>
            <b-col sm="12">
              <hr />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-for="(worker, i) in Workers" :key="i" class="page">
        <b-col md="12">
          <b-row class="form-group">
            <b-col md="4" class="text-left">
              <img
                src="@/assets/images/logo/medium/logo-mb-colorido.png"
                alt="Mary Beleza"
                title="Mary Beleza"
                class="logo d-inline-block align-top"
              />
            </b-col>
            <b-col md="8" class="text-right">
              <br />
              <br />
              <br />
              <h1>
                Entrevista Profissional
              </h1>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="12">
              <h4>Informações Pessoais</h4>
              <hr />
            </b-col>
            <b-col md="6">
              <label>Nome:</label> {{ worker.name | not_informed }}
            </b-col>
            <b-col md="6">
              <label>E-mail:</label> {{ worker.email | not_informed }}
            </b-col>
            <b-col md="12">
              <label>Telefones:</label>
              {{ worker.phone | formatPhones | not_informed }}
            </b-col>
          </b-row>
          <b-row
            class="form-group"
            v-if="worker.address && worker.address.length > 0"
          >
            <b-col md="12">
              <h4>Endereço</h4>
              <hr />
            </b-col>
            <b-col md="12">
              <ul>
                <li v-for="(address, i) in worker.address" :key="i">
                  <span v-html="mountAddressLine1(address)"></span>
                  <span v-html="mountAddressLine2(address)"></span>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <template>
              <b-col md="12">
                <h4>Informações Adicionais</h4>
                <hr />
              </b-col>
              <b-col md="6" class="form-group">
                <label
                  >Estaria disposto a fazer atendimentos em domicílio?</label
                ><br />{{ worker.accept_home_care | options_detail }}
              </b-col>
              <b-col md="6" class="form-group">
                <label
                  >Você tem empresa como MEI (Micro Empreendedor
                  Individual)?</label
                ><br />{{ worker.has_mei | options_detail }}
              </b-col>
              <b-col md="6" class="form-group">
                <label>Possui veículo de locomoção próprio?</label><br />{{
                  worker.has_vehicle | options_detail
                }}
              </b-col>
              <b-col md="6" class="form-group">
                <label>Você já trabalha com algum aplicativo de beleza?</label
                ><br />{{ worker.another_beauty_app | options_detail }}
              </b-col>
              <b-col md="6" class="form-group">
                <label>Facebook:</label> {{ worker.facebookv | not_informed }}
              </b-col>
              <b-col md="6" class="form-group">
                <label>Instagram:</label> {{ worker.instagram | not_informed }}
              </b-col>
            </template>
            <b-col
              md="12"
              v-if="
                worker.worker &&
                  worker.worker.worker_habilities &&
                  worker.worker.worker_habilities.length > 0
              "
            >
              <label>Áreas de Atuação:</label>
              <table class="worker_habilities">
                <tr
                  v-for="(hability, j) in remapHabilities(
                    worker.worker.worker_habilities,
                    worker.worker.worker_habilities_scheduling
                  )"
                  :key="j"
                >
                  <th class="hability">
                    {{ hability.name }} -
                    <span v-if="hability.scheduling">{{
                      hability.scheduling | moment("DD/MM/YYYY HH:mm")
                    }}</span>
                    <ul v-if="hability.childreen.length > 0">
                      <li
                        class="form-group"
                        style="white-space: nowrap;"
                        v-for="(child, i) in hability.childreen"
                        :key="i"
                      >
                        {{ child.name }}<br /><strong
                          >Nota (Autoavaliação):</strong
                        >
                        {{ child.note }}<br /><strong>Experiência</strong>:
                        {{ timeExperienceText(child.experience)
                        }}<br />OBS:________________________________________________________________________________
                      </li>
                    </ul>
                  </th>
                </tr>
              </table>
            </b-col>
            <b-col
              md="12"
              v-if="
                worker.professional_references &&
                  worker.professional_references.length > 0
              "
            >
              <label>Referências profissionais:</label>
              <span v-html="worker.professional_references"></span>
            </b-col>
          </b-row>
          <br />
          <br />
          <br />
          <b-row>
            <b-col md="12" class="text-center">
              <h4>Observações Gerais</h4>
              <div class="general-obs"></div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import _sortBy from "lodash/sortBy";
import moment from "moment";

export default {
  computed: {
    Workers() {
      var workers = [];
      if (Array.isArray(this.workers)) {
        workers = this.workers;
      } else if (this.workers) {
        workers = [this.workers];
      }

      if (this.onlyScheduled) {
        workers = workers.filter(
          w => w.worker.worker_habilities_scheduling.length > 0
        );
      }
      return workers;
    }
  },
  filters: {
    options_detail: function(value) {
      return value == null ? "-" : value ? "SIM" : "NÃO";
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    dateSummary: {
      type: [String, Object],
      default: null
    },
    variant: {
      type: String,
      required: false,
      default: "primary"
    },
    workers: {
      type: [Array, Object],
      required: true
    },
    onlyScheduled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      experience_options: [
        { value: 1, text: "0 a 3 meses" },
        { value: 2, text: "3 a 6 meses" },
        { value: 3, text: "6 meses a 1 ano" },
        { value: 4, text: "1 a 2 anos" },
        { value: 5, text: "2 a 5 anos" },
        { value: 6, text: "Mais de 5 anos" }
      ]
    };
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },
    _sortBy(...args) {
      return _sortBy(...args);
    },
    timeExperienceText(value) {
      let experience = this.experience_options.filter(
        experience => experience.value == value
      )[0];
      return experience?.text;
    },
    remapHabilities(habilities, scheduling) {
      var h = [];
      var ignore = [];

      for (var hability of habilities) {
        if (
          scheduling.find(item => item.id == hability.hability_id) ||
          !this.onlyScheduled
        ) {
          var childreen = [];
          for (var child of habilities) {
            if (child.parent_id && child.parent_id == hability.hability_id) {
              childreen.push(child);
              ignore.push(child.hability_id);
            }
          }

          if (ignore.filter(id => id == hability.hability_id).length == 0)
            if (!hability.parent_id || childreen.length == 0) {
              if (this.onlyScheduled) {
                var hability_scheduling = scheduling.find(
                  item => item.id == hability.hability_id && !item.pivot.absence
                );
                if (hability_scheduling) {
                  h.push({
                    name: hability.name,
                    scheduling: hability_scheduling.pivot.scheduling_technical,
                    childreen: _sortBy(
                      childreen.map(c => {
                        return {
                          name: c.name,
                          note: c.self_evaluation_grade,
                          experience: c.time_experience
                        };
                      }),
                      "scheduling"
                    )
                  });
                }
              } else {
                h.push({
                  name: hability.name,
                  childreen: childreen.map(c => {
                    return {
                      name: c.name,
                      note: c.self_evaluation_grade,
                      experience: c.time_experience
                    };
                  })
                });
              }
            }
        }
      }

      habilities = h;
      return habilities;
    },
    WorkerHabilitiesScheduling(worker) {
      if (this.onlyScheduled) {
        let habilities = worker.worker.worker_habilities_scheduling.filter(
          item =>
            moment(item.pivot.scheduling_technical).format("YYYY-MM-DD") ==
              moment(typeof this.dateSummary == 'object' ? this.dateSummary.start : this.dateSummary).format("YYYY-MM-DD") &&
            !item.pivot.absence
        );
        return _sortBy(habilities, "pivot.scheduling_technical");
      } else {
        return [];
      }
    },
    print() {
      if (this.Workers.length == 0) {
        swal.fire("", "Nenhum profissional agendado.", "warning");
        return;
      }

      this.hide_elements();
      window.print();
      this.restore_elements();
    },
    hide_elements() {
      var elements = window.document.querySelectorAll("*");
      var el = null;
      for (el of elements) {
        el.classList.add("noprint-worker-register-print");
      }

      el = this.$refs.WorkerRegisterPrint;
      while (el) {
        el.classList.remove("noprint-worker-register-print");
        el = el.parentElement;
      }

      elements = this.$refs.WorkerRegisterPrint.querySelectorAll("*");
      for (el of elements) {
        el.classList.remove("noprint-worker-register-print");
      }
    },
    restore_elements() {
      var elements = window.document.querySelectorAll("*");
      for (var el of elements) {
        el.classList.remove("noprint-worker-register-print");
      }
    },
    mountAddressLine1(address) {
      if (address && address.street)
        return (
          (address.name ? `<strong>${address.name}</strong> ` : "") +
          address.street +
          (address.number ? ", " + address.number : "") +
          (address.complement ? " - " + address.complement : "") +
          (address.district ? " - " + address.district : "")
        );
      return "";
    },
    mountAddressLine2(address) {
      if (address)
        return (
          (address.zipcode ? "CEP: " + address.zipcode + " - " : "") +
          (address.city && address.city.name ? address.city.name : "") +
          (address.city && address.city.state
            ? ", " + address.city.state.name
            : "")
        );
      return "";
    }
  }
};
</script>

<style>
.WorkerRegisterPrint {
  display: inline;
}

@media print {
  .noprint-worker-register-print {
    display: none !important;
  }

  .WorkerRegisterPrint * {
    font-size: 14px;
  }

  *:not(hr):not(.general-obs) {
    border: none !important;
  }

  .WorkerRegisterPrint .logo {
    width: auto;
    height: 80px;
  }

  .WorkerRegisterPrint .page {
    page-break-after: always;
  }

  .WorkerRegisterPrint .page:last-child {
    page-break-after: auto;
  }

  .WorkerRegisterPrint h1 {
    font-size: 24px;
    font-weight: bold;
  }

  .WorkerRegisterPrint h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .WorkerRegisterPrint h1,
  .WorkerRegisterPrint h4,
  .WorkerRegisterPrint hr {
    margin: 0.1cm 0;
  }

  .WorkerRegisterPrint label {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  .WorkerRegisterPrint .worker_habilities {
    width: 100%;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .WorkerRegisterPrint .worker_habilities .hability {
    width: 20%;
  }

  .WorkerRegisterPrint .worker_habilities .obs {
    width: 80%;
    border-bottom: 1px solid #ddd !important;
  }

  .WorkerRegisterPrint .general-obs {
    display: block;
    width: 100%;
    height: 8cm;
    border: 1px solid #ddd;
  }

  .hability ul {
    margin: 0px;
    list-style: none;
    margin-left: 15px;
    padding: 0;
  }
}
</style>
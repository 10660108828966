import Http from "@/api/http"

const state = {
    states: []
};

const getters = {
    
};

const actions = {
    getStates({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('state').then(response => {
                commit('SET_STATE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

const mutations = {
    SET_STATE(state, states) {
        state.states = states;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


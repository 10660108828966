<template>
    <b-overlay :show="!loaded" style="width: 100%; height: 400px"></b-overlay>
</template>
<script>
export default {
    data() {
        return {
            loaded: false
        }
    },
    created() {
        this.Navigate();
    },
    methods: {
        Navigate() {
            this.loaded = false;
            this.$store.dispatch("system/mailing_url").then((response) => {
                window.location.href = response.url;
				this.loaded = true;
			}).catch(() => {
				this.loaded = true;
			});
        }
    }
}
</script>
<template>
    <span>
        <b-button variant="mary-beleza" class="ml-2" @click="show_modal">
            <fa icon="ban" class="mr-2" />Cancelar
        </b-button>
        <b-modal ref="modal-cancel-schedule" centered no-close-on-backdrop hide-footer hide-header>
            <div class="p-4">
                <b-row class="form-group">
                    <b-col>
                        <label>Qual das partes?</label>
                        <b-form-group>
                            <button-group :options="filter_canceled_types()" v-model="model.canceled_type"></button-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="model.canceled_type" class="form-group">
                    <b-col>
                        <label>Observação: </label>
                        <b-form-textarea v-model="model.canceled_info" placeholder="..." rows="4"></b-form-textarea>
                    </b-col>
                </b-row>
                <div v-if="[constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_CUSTOMER].includes(model.canceled_type)">
                    <b-row class="form-group">
                        <b-col>
                            <label>Penalidade</label>
                            <b-form-select v-model="model.punishment_opt" :options="punishment_rules_filter()">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div v-if="[constants.STATUS_CANCELED_WORKER, constants.STATUS_CANCELED_MARY_BELEZA].includes(model.canceled_type)">
                    <b-row class="form-group">
                        <b-col>
                            <label>Reagendar?</label>
                            <b-form-group>
                                <button-group :options="options" v-model="reschedule"></button-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="reschedule" class="form-group">
                        <b-col>
                            <label>Escolha data:</label>
                            <div>
                                <v-date-picker :model-config="modelConfig" v-model="model.reschedule_date" mode="dateTime" :minute-increment="30" is-expanded is24hr></v-date-picker>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row class="form-group mt-2">
                    <b-col>
                        <b-button variant="outline-danger" block @click="hide_modal()">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                    <b-col v-if="model.canceled_type">
                        <b-button variant="primary" block @click="cancel_schedule()">
                            <b-icon icon="check-all" scale="1.4" class="mr-2" />Confirmar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </span>
</template>

<script>
import Constants from '@/constants'
import ButtonGroup from '@/components/ButtonGroup'

export default {

    data() {
        return {
            model: {
                canceled_type: null,
                canceled_info: null,
                punishment_opt: null,
                reschedule_date: null,
            },
            reschedule: false,
            options_canceled_types: [
                { id: Constants.STATUS_CANCELED_CUSTOMER, text: 'Cliente', not_worker: true },
                { id: Constants.STATUS_CANCELED_WORKER, text: 'Profissional' },
                { id: Constants.STATUS_CANCELED_MARY_BELEZA, text: 'Mary Beleza', not_worker: true },
            ],
            options: [
                { id: true, text: 'Sim' },
                { id: false, text: 'Não' },
            ],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm',
            },
        }
    },

    components: {
        ButtonGroup
    },
    
    methods: {

        show_modal(){
            this.reschedule = false;
            this.model.reschedule_date = null;
            this.model.punishment_opt = null;
            this.model.canceled_info = null;
            this.model.canceled_type = null;
            this.$refs['modal-cancel-schedule'].show();
        },

        hide_modal(){
            this.model.canceled_type = null;
            this.$refs['modal-cancel-schedule'].hide();
        },

        filter_canceled_types(){
            if(!this.schedule.worker_id){
                return this.options_canceled_types.filter(item => item.not_worker);
            }
            return this.options_canceled_types;
        },

        punishment_rules_filter(types = []){
            
            if(types.length == 0){
                switch (this.model.canceled_type) {
                    case Constants.STATUS_CANCELED_CUSTOMER:
                        types = [Constants.TYPE_CANCELLATION]
                        break;

                    case Constants.STATUS_CANCELED_WORKER:
                        types = [Constants.TYPE_DELAY, Constants.TYPE_ABANDONMENT]
                        break;
                    
                    case Constants.STATUS_CANCELED_MARY_BELEZA:
                        types = [Constants.TYPE_DELAY, Constants.TYPE_ABANDONMENT]
                        break;
                }
            }

            if(Array.isArray(types) && types.length > 0){
                var filter = this.punishment_rules.filter(item => types.includes(item.parent.type));
                return filter.map(item => {
                    return {value: item.id, text: `${item.parent.description} ${item.recurrence > 1 ? ' - ' + item.recurrence : ''} ${item.points ? ' - ' + item.points + ' Pontos' : ''}` }
                })
            }else{
                var punishments = this.punishment_rules;
                return punishments.map(item => {
                    return {value: item.id, text: `${item.parent.description} ${item.recurrence > 1 ? ' - ' + item.recurrence : ''} ${item.points ? ' - ' + item.points + ' Pontos' : ''}` }
                })
            }
        },

        cancel_schedule() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Cancelar`,
                html: `Deseja realmente cancelar o agendamento?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/cancelSchedule', this.model).then(data => {
                        this.hide_modal();
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            text: data.success,
                            icon: 'success',
                            padding: 20,
                        });
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

    },

    computed: {
        
        constants(){
            return Constants
        },

        select_part_type: {
            get() {
                return this.model.canceled_type;
            },
            set(value) {
                if(value != this.model.canceled_type){
                    this.reschedule = false;
                    this.model.reschedule_date = null;
                    this.model.punishment_opt = null;
                    this.model.canceled_type = value;
                }
            }
        },

        punishment_rules: {
            get() {
                return this.$store.state.service.punishment_rules;
            }
        },

        schedule: {
            get() {
                return this.$store.state.service.schedule;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE', value)
            }
        },
    },

}
</script>

<style>

</style>
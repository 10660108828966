import Http from '@/api/http';
import moment from 'moment';
import HolidayDomain from '@/domain/holiday'
import FiltersDomain from '@/domain/filters/holiday'

const state = {
    holidays: [],
    holiday: new HolidayDomain(),
    filters: new FiltersDomain(),
    errors: {}
};

const actions = {

    loadInsertForm({ commit }) {
        commit('SET_CLEAR_ERRORS', {});
        commit('SET_HOLIDAY', new HolidayDomain);
    },

    getHolidays({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('holiday', {params: params}).then(response => {
                commit('SET_HOLIDAYS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getHoliday({ state, commit }, holiday_id) {
        return new Promise((resolve, reject) => {
            state.holiday
            Http.get(`holiday/${holiday_id}`).then(response => {
                commit('SET_HOLIDAY', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createHoliday({ state }) {
        return new Promise((resolve, reject) => {
            Http.post('holiday', state.holiday).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    deleteHoliday({ commit }, holiday_id) {
        return new Promise((resolve, reject) => {
            Http.delete(`holiday/${holiday_id}`).then(response => {
                commit('SET_REMOVE_HOLIDAY', holiday_id)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

};

const mutations = {

    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_HOLIDAY_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },

    SET_CLEAN_FILTERS(state) {
        state.filters = new FiltersDomain();
    },

    SET_HOLIDAYS(state, data) {
        state.holidays = data.items ? data.items : data;
    },
    SET_HOLIDAY(state, holiday) {
        state.holiday = holiday;
    },
    SET_HOLIDAY_DATE(state, value) {
        state.holiday.date = value;
    },
    SET_HOLIDAY_DESCRIPTION(state, value) {
        state.holiday.description = value;
    },
    SET_HOLIDAY_TYPE(state, value) {
        state.holiday.type = value;
    },
    SET_HOLIDAY_CITY_ID(state, value) {
        state.holiday.city_id = value;
    },

    SET_REMOVE_HOLIDAY(state, holiday_id) {
        let index = state.holidays.findIndex(holiday => holiday.id == holiday_id);
        state.holidays[index].deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
    },
    
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
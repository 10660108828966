<template>
<div>
    <panel title="BANCO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">

                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Banco:</label>
                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bankName()">
                                    <span>-</span>
                                </p>
                                <p v-else>{{ bankName() }}</p>
                            </template>
                            <template v-else>
                                <multiselect 
                                    placeholder="Selecione" 
                                    selectedLabel="Selecionado" 
                                    track-by="id" 
                                    label= "name" 
                                    selectLabel="Pressione enter para selecionar" 
                                    deselectLabel="Pressione enter para remover" 
                                    v-model="bank" 
                                    :options="bank_options"
                                >
                                    <template slot="noResult">
                                        Nenhum resultado encontrado
                                    </template>
                                    <template slot="noOptions">
                                        Nenhum resultado encontrado
                                    </template>
                                </multiselect>
                                <b-form-invalid-feedback :state="!errors.bank_id">
                                    <div v-for="error in errors.bank_id" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Agência:</label>   

                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_agency">
                                    <span>-</span>
                                </p>
                                <p v-else>{{bank_agency}}<template v-if="bank_agency_digit"> - {{bank_agency_digit}}</template></p>
                            </template>
                            <template v-else>
                                <b-row>
                                    <b-col cols="8">
                                        <b-form-input v-model="bank_agency" type="number" aria-describedby="input-live-help input-live-feedback" placeholder="Agência"></b-form-input>
                                    </b-col>
                                    <b-col cols="4">
                                        <b-form-input v-model="bank_agency_digit" type="number" aria-describedby="input-live-help input-live-feedback" placeholder="Dígito"></b-form-input>
                                    </b-col>
                                    <b-form-invalid-feedback :state="!errors.bank_agency">
                                        <div v-for="error in errors.bank_agency" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback :state="!errors.bank_agency_digit">
                                        <div v-for="error in errors.bank_agency_digit" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </b-row>
                            </template>
                        </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Conta:</label>

                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_account">
                                    -
                                </p>
                                <p v-else>{{bank_account}} - {{bank_account_digit}}</p>
                            </template>
                            <template v-else>
                                <b-row>
                                    <b-col sm="8" md="8" lg="8">
                                        <b-form-input v-model="bank_account" type="number" aria-describedby="input-live-help input-live-feedback" placeholder="Conta"></b-form-input>
                                    </b-col>
                                    <b-col sm="4" md="4" lg="4">
                                        <b-form-input v-model="bank_account_digit" type="number" aria-describedby="input-live-help input-live-feedback" placeholder="Dígito"></b-form-input>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-form-invalid-feedback :state="!errors.bank_account">
                                <div v-for="error in errors.bank_account" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback :state="!errors.bank_account_digit">
                                <div v-for="error in errors.bank_account_digit" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Tipo:</label>
                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_account_type">
                                    -
                                </p>
                                <p v-else>{{bank_account_type == 'C' ? 'Conta Corrente' : 'Conta Poupança'}}</p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="bank_account_type" :options="bank_type_options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.bank_account_type">
                                    <div v-for="error in errors.bank_account_type" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Conta de terceiro?</label>
                            <template v-if="!OpenForm.bankAccount">
                                <p>
                                    <span v-if="bank_third_party == null">-</span>
                                    <b-badge v-else-if="bank_third_party" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-checkbox v-model="bank_third_party" size="lg" name="check-button" switch></b-form-checkbox>
                                <b-form-invalid-feedback :state="!errors.bank_third_party">
                                    <div v-for="error in errors.bank_third_party" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="bank_third_party" class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Nome do titular:</label>
                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_third_party_name">
                                    -
                                </p>
                                <p v-else>{{bank_third_party_name}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="bank_third_party_name" placeholder="Nome"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.bank_third_party_name">
                                    <div v-for="error in errors.bank_third_party_name" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">CPF titular:</label>
                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_third_party_cpf">
                                    -
                                </p>
                                <p v-else>{{bank_third_party_cpf | formatDocuments }}</p>
                            </template>
                            <template v-else>
                                <the-mask :mask="'###.###.###-##'" class="form-control" v-model="bank_third_party_cpf" placeholder="CPF" maxlength="14" />
                                <b-form-invalid-feedback :state="!errors.bank_third_party_cpf">
                                    <div v-for="error in errors.bank_third_party_cpf" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="mr-1">Data de Nascimento Titular:</label>
                            <template v-if="! OpenForm.bankAccount">
                                <p v-if="!bank_third_party_birth">
                                    -
                                </p>
                                <p v-else>{{bank_third_party_birth | moment("DD/MM/YYYY") }}</p>
                            </template>
                            <template v-else>
                                <typeable-date placeholder="dd/mm/aaaa" v-model="bank_third_party_birth"/>
                                <b-form-invalid-feedback :state="!errors.bank_third_party_birth">
                                    <div v-for="error in errors.bank_third_party_birth" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="IsUpdate && has_permission('worker-edit')">
                    <b-col sm="12" v-if="!IsStage">
                        <b-button v-if="!OpenForm.bankAccount" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'bankAccount')">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button variant="mary-beleza" size="sm" @click="update()">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>

    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import TypeableDate from '@/components/TypeableDate'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import Constants from '@/constants'

export default {

    components: {
        Panel,
        TypeableDate,
        Multiselect
    },

    data() {
        return {
            account_third_options: [{
                    text: 'Sim',
                    value: 1
                },
                {
                    text: 'Não',
                    value: 0
                }
            ],
            bank_type_options: [{
                    text: 'Conta Corrente',
                    value: 'C'
                },
                {
                    text: 'Conta Poupança',
                    value: 'A'
                },
            ],
            bank_options: [],
        }
    },

    mounted() {
        this.$store.dispatch('bank/getBanks').then(data => {
            data.forEach(bank => {
                this.bank_options = [...this.bank_options, {
                    name:`${bank.code} - ${bank.name}`,
                    id: bank.id
                }]
            });
        });
    },

    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        update() {
            
            this.$store.dispatch('worker/updateWorker', 'bank').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        sweealert2(icon, text) {
            var bgColor = '#dbf6e9';

            if(icon == 'error')
                bgColor = '#f4c8c8'

            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: bgColor
            })
        },

        bankName(){
            if(this.bank){
                let bank = this.bank_options.find(bank => bank.id === this.bank.id);
                return bank?.name;
                   
            }else{    
                return null;
            }
        }
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        bank: {
            get() {
                return this.bank_options.find(bank => bank.id == this.$store.state.worker.worker.bank_id);
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_ID', value.id)
            }
        },

        bank_agency: {
            get() {
                return this.$store.state.worker.worker.bank_agency;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_AGENCY', value)
            }
        },

        bank_account: {
            get() {
                return this.$store.state.worker.worker.bank_account;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_ACCOUNT', value)
            }
        },

        bank_account_type: {
            get() {
                return this.$store.state.worker.worker.bank_account_type;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_ACCOUNT_TYPE', value)
            }
        },

        bank_third_party: {
            get() {
                return this.$store.state.worker.worker.bank_third_party;
            },
            set(value) {
                this.bank_third_party_name = null;
                this.bank_third_party_cpf = null;
                this.bank_third_party_birth = null;
                this.$store.commit('worker/SET_BANK_THIRD_PARTY', value)
            }
        },

        bank_third_party_name: {
            get() {
                return this.$store.state.worker.worker.bank_third_party_name;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_THIRD_PARTY_NAME', value)
            }
        },

        bank_third_party_cpf: {
            get() {
                return this.$store.state.worker.worker.bank_third_party_cpf;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_THIRD_PARTY_CPF', value)
            }
        },

        bank_third_party_birth: {
            get() {
                return this.$store.state.worker.worker.bank_third_party_birth;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_THIRD_PARTY_BIRTH', value)
            }
        },

        bank_account_digit: {
            get() {
                return this.$store.state.worker.worker.bank_account_digit;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_ACCOUNT_DIGIT', value)
            }
        },

        bank_agency_digit: {
            get() {
                return this.$store.state.worker.worker.bank_agency_digit;
            },
            set(value) {
                this.$store.commit('worker/SET_BANK_AGENCY_DIGIT', value)
            }
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            }
        },
        
        technical_grade: {
            get() {
                return this.$store.state.worker.worker.technical_grade;
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },

    },
}
</script>

<template>
    <div id="pre-registration-record">
        <util-bar>
            <div v-if="!loadingData">
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button v-if="has_permission('worker-edit') && [constants.STATUS_DISAPPROVED, constants.STATUS_PRE_REGISTRATION].includes($store.state.worker.worker.status)" variant="success" class="mr-2" @click="ApproveOrFail(constants.STATUS_APPROVED)">
                            <fa :icon="['far', 'thumbs-up']" class="mr-2" />Aprovar Pré-cadastro
                        </b-button>

                        <b-button v-if="has_permission('worker-edit') && [constants.STATUS_APPROVED, constants.STATUS_PRE_REGISTRATION].includes($store.state.worker.worker.status)" variant="danger" class="mr-2" @click="ApproveOrFail(constants.STATUS_DISAPPROVED)">
                            <fa :icon="['far', 'thumbs-down']" class="mr-2" />Reprovar Pré-cadastro
                        </b-button>

                        <b-button v-if="has_permission('worker-edit') && [constants.STATUS_APPROVED, constants.STATUS_DISAPPROVED].includes($store.state.worker.worker.status)" variant="primary" class="mr-2" @click="$router.push({ path: '/profissionais/cadastro/' + $store.state.worker.worker.id })">
                            <fa icon="pen" class="mr-2" />Editar
                        </b-button>

                        <b-button variant="outline-mary-beleza" v-on:click="$router.push({ path: '/profissionais/pre-cadastro'})">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <b-overlay :show="loadingData" rounded="sm">
                <panel title="Informações Pessoais" icon='user-circle' icon-lib="far">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Nome:</label>
                            <div>
                                {{ $store.state.worker.worker.person.name }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>E-mail:</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.person.email">
                                                -
                                </p>
                                <p v-else>{{ $store.state.worker.worker.person.email }}</p>
                            </div>
                        </b-col>

                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Telefones:</label>
                            
                            <div v-if="$store.state.worker.worker.person.phones.length > 0">
                                <div v-for="(phone, index) in $store.state.worker.worker.person.phones" :key="index" class="mb-1">
                                    - {{ phone.phone_number | formatPhones }} <small v-if="phone.description">({{ phone.description }})</small><br />
                                    <b-badge v-if="phone.is_personal" variant="primary" class="mr-2">Pessoal</b-badge>
                                    <b-badge v-if="phone.is_whatsapp" variant="success">WhatsApp</b-badge>
                                </div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </b-col>
                    </b-row>
                    <b-alert v-if="$store.state.worker.worker.status == constants.STATUS_DISAPPROVED" class="text-dark" variant="warning" show><strong class="text-danger">Reprovado: </strong>{{ $store.state.worker.worker.disapproval_reason }}</b-alert>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm" v-if="$store.state.worker.worker.person.address[0].zipcode">
                <panel title="Endereço" icon='map-marked-alt' icon-lib="fa">
                    <b-row>
                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label>CEP:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].zipcode | formatCep }}
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Endereço:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].street }}
                            </div>
                        </b-col>

                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label>Número:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].number }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Bairro:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].district }}
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Complemento:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].complement != null ? $store.state.worker.worker.person.address[0].complement : '-' }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Cidade:</label>
                            <div>
                                <template v-if="$store.state.worker.worker.person.address[0] && $store.state.worker.worker.person.address[0].city">{{ $store.state.worker.worker.person.address[0].city.name }}</template>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Estado:</label>
                            <div>
                                <template v-if="$store.state.worker.worker.person.address[0] && $store.state.worker.worker.person.address[0].city && $store.state.worker.worker.person.address[0].city.state">{{ $store.state.worker.worker.person.address[0].city.state.name }}</template>
                            </div>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm">
                <panel title="Informações Adicionais" icon='address-card' icon-lib="far">
                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Estaria disposto a fazer atendimentos em domicílio?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.accept_home_care">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.accept_home_care"><b-badge variant="success">Sim</b-badge></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Você tem empresa como MEI (Micro Empreendedor Individual)?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.has_mei">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.has_mei"><b-badge variant="success">Sim</b-badge></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Possui veículo de locomoção próprio?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.has_vehicle">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.has_vehicle"><b-badge variant="success">Sim</b-badge> <small v-if="$store.state.worker.worker.detail.has_vehicle_which">{{ $store.state.worker.worker.detail.has_vehicle_which }}</small></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>

                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Você já trabalha com algum aplicativo de beleza?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.another_beauty_app">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.another_beauty_app"><b-badge variant="success">Sim</b-badge> <small>{{ $store.state.worker.worker.detail.another_beauty_app }}</small></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Facebook:</label>
                            <div>
                                <span v-if="!$store.state.worker.worker.detail.facebook">-</span>
                                <span v-else-if="$store.state.worker.worker.detail.facebook">{{$store.state.worker.worker.detail.facebook}}</span>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Instagram:</label>
                            <div>
                                <span v-if="!$store.state.worker.worker.detail.instagram">-</span>
                                <span v-else-if="$store.state.worker.worker.detail.instagram">{{$store.state.worker.worker.detail.instagram}}</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Áreas de Atuação:</label>
                            <div>
                                <h5 v-if="$store.state.worker.worker.worker_habilities.length > 0">
                                    <b-badge v-for="(hability, index) in $store.state.worker.worker.worker_habilities" :key="index" variant="mary-beleza" class="mr-2">{{habilityName(hability.hability_id)}}</b-badge> 
                                </h5>
                                <span v-else>Nenhuma Área de Atuação Informada</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Referências profissionais</label>
                            <div>
                                <span v-if="$store.state.worker.worker.detail.professional_references" v-html="$store.state.worker.worker.detail.professional_references"></span>
                                <p v-else>Nenhuma referência informada</p>
                            </div>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import store from '@/store'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {
            loadingData: true
        }
    },
    components: {
        Panel,
        UtilBar
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        async LoadData() {
            await store.dispatch('worker/getWorkerById', this.$route.params.id).then(() => {
                this.loadingData = false;
            });
        },

        ApproveOrFail(status) {
            this.$swal(
                status == Constants.STATUS_APPROVED ? {
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Aprovar Pré-cadastro`,
                html: `Deseja realmente Aprovar o pré-cadastro do(a) profissional <strong>${store.state.worker.worker.person.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            } : {
                showConfirmButton: true,
                showCancelButton: true,
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Reprovar Pré-cadastro`,
                html: `Deseja realmente Reprovar o pré-cadastro do(a) profissional <strong>${store.state.worker.worker.person.name}</strong>?<hr/><p>Por favor informe o motivo:</p>`,
                icon: 'warning',
                padding: 20,
                showLoaderOnConfirm: true,
                preConfirm: (disapproval_reason) => {
                    // Validação do textarea.
                    if(disapproval_reason === null || disapproval_reason === ""){
                        this.$swal.showValidationMessage(`Obrigatório informar motivo da reprovação!`)
                    } 
                    return disapproval_reason
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                   var data = {
                        worker_id: this.$store.state.worker.worker.id,
                        disapproval_reason: result.value,
                        status,
                        from: 'status_worker'
                    }
                    this.$store.dispatch('worker/changeStatusWorker', data).then(response => {
                        this.Sweealert2('success', response.success)
                        
                        if(status == Constants.STATUS_APPROVED)
                            this.$router.push('/profissionais/cadastro/' + this.$route.params.id)
                        else
                            this.$router.push('/profissionais/pre-cadastro')

                    }).catch(error => {
                        return this.$swal.showValidationMessage(`${error.response.data.error}`)
                    });
                }
            })
        },

        habilityName(id) {
            let habilitie = this.habilities.find(hability => hability.id === id)
            return habilitie?.name
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    },

    computed: {
        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        loading: {
            get() {
                return this.$store.state.worker.loading;
            },
        },

        habilities: {
            get() {
                return this.$store.state.worker.habilities_all;
            },
        },
    }
}
</script>

<style lang="scss">
label {
    font-weight: bold;
}
</style>
<template>
    <div>
        <util-bar>
            <div v-if="has_permission('notifications-edit')">
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button v-on:click="$router.push({ path: '/utilitarios/notificacoes/cadastro'})" class="btn btn-mary-beleza">
                            <fa :icon="['far', 'paper-plane']" class="mr-2" /> Enviar notificações
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="6" lg="6" class="form-group">
                        <label>Data de criação:</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.created_at.start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.created_at.end"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Apagadas:</label>
                        <b-form-group>
                            <button-group :options="options" v-model="filters.deleted"></button-group>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="2" lg="2">
                        <label></label>
                        <p>
                            <b-button class="mt-2 ml-2" variant="outline-danger" @click="CleanFilters">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="6" lg="6" class="form-group">
                        <label>Data de agendamento:</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.min_sent_date.start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.min_sent_date.end"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Criadas:</label>
                        <b-form-group>
                            <button-group :options="options" v-model="filters.created"></button-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </panel>
            <panel>
                <b-row class="form-group">
                    <b-col md="12 text-center">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table ref="notificationRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers"
                                    :loading="loadingData" :cols="table_cols">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, key) in items" v-bind:key="key"
                                            :class="item.page + (item.selected ? ' selected' : '')">
                                            <td width="25%"><span v-html="item.title"></span></td>
                                            <td>
                                                <b-icon v-b-tooltip="'Ver Pessoas'" :variant="item.deleted_at ? 'danger' : 'primary'" icon="people-fill" scale="1.5" class="mr-2 cursor-pointer" @click="ListPersons(item)"></b-icon>{{item.quantity}}
                                            </td>
                                            <td>
                                                <span v-if="item.created_at">
                                                    {{ item.created_at | moment("DD/MM/YYYY HH:mm")}}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="item.min_sent_date">
                                                    {{ item.min_sent_date | moment("DD/MM/YYYY HH:mm")}}
                                                </span>
                                                <strong v-else class="text-danger">-</strong>
                                            </td>
                                            <td>
                                                <b-icon v-if="item.message" scale="1.2" variant="mary-beleza" icon="chat-left-dots" :id="'push-notification-message' + key"></b-icon>
                                                <b-icon v-if="item.image" class="ml-3" scale="1.2" variant="primary" icon="image" :id="'push-notification-image' + key"></b-icon>
                                                <b-icon v-if="item.quantity == item.has_sent" scale="1.2" variant="success" icon="check-circle-fill" v-b-tooltip="'Envio(s) Concluído(s)'" class="ml-3"></b-icon>
                                                <span v-else>
                                                    <b-icon v-if="item.has_sent > 0" scale="1.2" icon="reply-fill" variant="success" v-b-tooltip="'Possui '+item.has_sent+' envio(s).'" class="ml-3"></b-icon>
                                                    <b-icon v-if="item.has_sent_error > 0" scale="1.2" variant="warning" icon="exclamation-triangle-fill" v-b-tooltip="'Possui '+item.has_sent_error+' envio(s) em erro.'" class="ml-3"></b-icon>
                                                    <b-icon v-if="item.deleted_at" scale="1.2" variant="danger" icon="trash-fill" v-b-tooltip="'Apagada'" class="ml-3"></b-icon>
                                                </span>
                                                <b-tooltip v-if="item.image" :target="'push-notification-image' + key" placement="bottom">
                                                    <img style="width:180px; height:60px" :src="item.image"/>
                                                </b-tooltip>
                                                <b-tooltip v-if="item.message" :target="'push-notification-message' + key" placement="bottom">
                                                    <strong>Mensagem</strong><br>
                                                    <span v-html="item.message"></span>
                                                </b-tooltip>
                                            </td>
                                            <td width="8%" class="text-center">
                                                <b-button v-if="has_permission('notifications-delete') && !item.deleted_at && item.has_sent == 0" @click="DeletePushNotification(item)" variant="danger" size="sm" v-b-tooltip :title="item.quantity > 1 ? 'Apagar Notificações' : 'Apagar Notificação'">
                                                    <b-icon icon="trash" font-scale="1" />
                                                </b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="table_cols.length" class="text-center py-3">Nenhum registro encontrado</td>
                                        </tr>
                                    </template>
                                </rich-table>
                            </b-col>
                            <b-modal ref="list-persons" hide-footer size="lg" title="Lista de pessoas para envio" ok-only cancelTitle="Fechar">
                                <template #default="{ ok }">
                                    <b-row>
                                        <b-col sm="12" md="12" lg="12" class="form-group">
                                            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="5" label-align-sm="right" class="mb-0">
                                                <b-input-group>
                                                    <b-form-input id="filter-input" v-model="list_persons.filter_table" type="search" placeholder="Informe um nome para buscar"></b-form-input>

                                                    <b-input-group-append>
                                                        <b-button :disabled="!list_persons.filter_table" variant="danger" @click="list_persons.filter_table = ''">Limpar</b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-table :items="list_persons.persons" :fields="list_persons.fields" :current-page="list_persons.currentPage" :per-page="list_persons.perPage" stacked="md" outlined striped bordered fixed hover small :filter="list_persons.filter_table" @filtered="OnFiltered">
                                        <template #cell(name)="row">
                                            <span>{{ row.item.name }}</span>
                                        </template>
                                        <template #cell(sent_date)="row">
                                            <span v-if="row.item.push_notification.sent_date">
                                                {{ row.item.push_notification.sent_date | moment("DD/MM/YYYY HH:mm")}}
                                            </span>
                                            <strong v-else class="text-danger">-</strong>
                                        </template>
                                        <template #cell(sent)="row">
                                            <span v-if="row.item.push_notification.sent">
                                                <b-icon v-if="row.item.push_notification.sent != constants.SENT_ERROR" icon="check" v-b-tooltip="'Enviado'" scale="2" variant="warning"></b-icon>
                                                <b-icon v-else icon="x" v-b-tooltip="'Em erro'" scale="2" variant="danger"></b-icon>
                                            </span>
                                            <strong v-else class="text-danger">-</strong>
                                        </template>
                                        <template #cell(read)="row">
                                            <span v-if="row.item.push_notification.read">
                                                <b-icon v-if="row.item.push_notification.read" icon="check-all" v-b-tooltip="'Enviado e Visto'" scale="2" variant="success"></b-icon>
                                            </span>
                                            <strong v-else class="text-danger">-</strong>
                                        </template>
                                    </b-table>
                                    <b-row>
                                        <b-col sm="12" md="6" lg="7" offset-md="2" class="form-group">
                                            <b-pagination v-model="list_persons.currentPage" :total-rows="list_persons.totalRows" :per-page="list_persons.perPage" align="fill" size="sm" class="my-0"></b-pagination>
                                        </b-col>
                                        <b-col sm="12" md="3" lg="2" offset-md="1" class="form-group">
                                            <b-button class="mr-4" block size="sm" variant="primary" @click="ok()">OK</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-modal>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import ButtonGroup from '@/components/ButtonGroup'
import TypeableDate from '@/components/TypeableDate'
import { mapGetters } from 'vuex'

export default {
    components: {
        Panel, 
        RichTable,
        UtilBar,
        ButtonGroup,
        TypeableDate
    },

    data() {
        return {
            show: true,
            items: [],
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            loadingData: false,
            list_persons: {
                persons: [],
                fields: [
                    { key: 'name', label: 'Nome', class: 'w-50', sortable: true, sortDirection: 'asc' },
                    { key: 'sent_date', label: 'Data de envio', class: 'w-25 text-center'},
                    { key: 'sent', label: 'Enviado', class: 'text-center'},
                    { key: 'read', label: 'Visto', class: 'text-center'},
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                filter_table: null
            },
            options: [
                {id: 1, text: 'Sim'},
                {id: 0, text: 'Não'},
                {id: null, text: 'Todos'},
            ]
        }
    },

    watch: {
        'filters': {
            handler: function () {
                this.LoadData()
            },
            deep: true
        }
    },
    
    computed: {

        ...mapGetters('auth', [
            'has_permission'
        ]),
        
        constants(){
            return Constants
        },

        table_cols() {
            let cols = [
                {
                    id: 'title',
                    text: 'Resumo',
                    class: 'cols_title'
                }, {
                    id: null,
                    text: 'Pessoas',
                    class: 'cols_quantity'
                }, {
                    id: 'created_at',
                    text: 'Criado',
                    class: 'cols_created_at'
                }, {
                    id: null,
                    text: 'Agendamento',
                    class: 'cols_sent_date'
                }, {
                    id: null,
                    text: 'Informações',
                    class: 'cols_informations'
                }, {
                    id: null,
                    text: '',
                    class: 'cols_delete'
                }
            ];
            return cols;
        },

        filters: {
            get(){
                return this.$store.state.notification.filters;
            }
        }
    },

    mounted() {
        this.LoadData();
    },

    methods: {

        CleanFilters() {
            this.$store.commit('notification/SET_CLEAN_FILTERS')
            this.$refs["notificationRichTable"].CleanFilters();
        },

        DeletePushNotification(push_notification){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                html: `Deseja realmente apagar a notificação?<br><br><strong>Titulo: </strong><p>${push_notification.title}</p><strong>Descrição: </strong><p>${push_notification.message}</p>`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('notification/deletePushNotification', push_notification.id).then(data => {
                        this.Sweealert2('success', data.success);
                    }).catch(error => {
                        this.Sweealert2('error', error.response.data.error);
                    })
                }
            })
        },

        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },

        LoadData() {
            this.loadingData = true;
            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('notification/getPushNotifications', params).then(data => {
                this.loadingData = false;
                this.items = data.items ? data.items : [];
                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;
            });
        },

        ListPersons(push_notification) {
            this.$store.dispatch('notification/getPushNotification', push_notification.id).then(data => {
                this.list_persons.persons = data.persons
                this.list_persons.totalRows = data.persons.length
                this.$refs['list-persons'].show()
            }).catch(error => {
                this.Sweealert2('error', error.response.data.error);
            })
            return true;
        },

        OnFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.list_persons.totalRows = filteredItems.length
            this.list_persons.currentPage = 1
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    }
}
</script>

<style>
.cursor-pointer:hover {
    cursor:pointer;
}
</style>
import { mapGetters } from 'vuex'
export default {
    created() {
        this.LoadStorageTableConfig();
    },
    data() {
        return {
            table_config_loaded: false
        }
    },
    computed: {
        ...mapGetters('rich_table', [
            'loadStorageTableConfig'
        ]),
        storage_name() {
            return (this.$route.meta.shared_filter_storage ? this.$route.meta.shared_filter_storage : (this.$route.fullPath.endsWith("/") ? this.$route.fullPath.substring(0, this.$route.fullPath.length - 1) : this.$route.fullPath)) + "/_config"            
        }
    },
    methods: {
        LoadStorageTableConfig() {
            if(this.loadStorageTableConfig(this.storage_name)){
                this.table_config_loaded = true
                this.model = this.loadStorageTableConfig(this.storage_name);
            }
        },
        SaveTableConfig(table_config) {
            var data = {storage_name: this.storage_name, table_config: table_config}
            this.$store.dispatch('rich_table/saveTableConfig', data)
        }
    }
}

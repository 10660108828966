<template>
    <loader-mary-beleza v-if="!loaded" :show="!loaded"/>
    <div v-else>
        <div class="pr-3 pl-3 pt-3" id="scrollspy-nested">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col>
                        <b-form-group label="Período">
                            <b-row>
                              <b-col>
                                <typeable-date v-model="period_start" />
                              </b-col>
                              <span class="align-self-center">-</span>
                              <b-col>
                                <typeable-date v-model="period_end"/>
                              </b-col>
                          </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Serviço">
                            <services-select
                              @input="LoadData('schedule')"
                              v-model="filters.service_id"  :model-config="modelConfig" is-range>
                            </services-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </panel> 
            <panel>
                <div
                  class="card-counter"
                >
                  <div v-if="total_schedules_per_competences">
                    <bar-graphic :key="loaded" class="mt-5" title="Serviços" :grid="true" width="100%" height="400px" :data="total_schedules_per_competences"></bar-graphic>
                  </div>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import Http from "@/api/http";
import { mapGetters } from 'vuex'
import BarGraphic from "@/components/BarGraphic";
import TypeableDate from '@/components/TypeableDate';
import ServicesSelect from "@/components/ServicesSelect";
import moment from 'moment';

export default {
    data() {
        return {
            loaded: false,
            total_schedules_per_competences: null,
            handler: null,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            filters: {
                period: {
                  start: null, end: null
                },
                service_id: null
            }
        }
    },
    components: {
        Panel,
        BarGraphic,
        TypeableDate,
        ServicesSelect
    },
    computed: {
        services() {
            var services = this.services_flatten;
            if (this.selected) {
                services = services.filter(s => s == this.selected);
            }

            return services;
        },
        period_start: {
            get() {
                return this.filters.period.start;
            },
            set(value) {
                if (this.filters.period.start == value) {
                    return;
                }

                this.filters.period.start = value;
                clearTimeout(this.handler);
                this.handler = setTimeout(() => { this.LoadData('schedule') }, 500);
            }
        },
        period_end: {
            get() {
              return this.filters.period.end;
            },
            set(value) {
                if (this.filters.period.end == value) {
                    return;
                }

                this.filters.period.end = value;
                clearTimeout(this.handler);
                this.handler = setTimeout(() => { this.LoadData('schedule') }, 500);
            }
        },
        ...mapGetters('auth', [
            'has_permission'
        ]),
    },


    mounted() {
        this.LoadData();
    },

    methods: {
        LoadData() {
            this.loaded = false;
            Http.get("report/totals", { params: this.filters }).then(response => {
                var total_schedules_per_competences = response.data.total_schedules_per_competences;
    
                var tooltip = (item, data) => {
                    //var label = data.labels[item.index];
                    var value = data.datasets[item.datasetIndex].data[item.index];
                    return "Realizados: "+value;
                };
    
                if (total_schedules_per_competences) {
                    var dataset_schedules = {
                        labels: total_schedules_per_competences.map(s => moment(s.competence, "MM/YYYY").format("MMMM/YYYY").toUpperCase()),
                        datasets: [
                            {
                                label: "Quantidade de Serviços",
                                backgroundColor: [],
                                data: [],
                                borderColor: "#888"
                            }
                        ],
                        tooltip: tooltip
                    };
                    var i = 0;
                    for (var schedule of total_schedules_per_competences) {
                      var color = this.getColor(null, i);
                      dataset_schedules.datasets[0].data.push(schedule.total);
                      dataset_schedules.datasets[0].backgroundColor.push(color);
                      i++;
                    }
    
                    this.total_schedules_per_competences = dataset_schedules;
                }
    
                this.loaded = true;
            });
        },
        randomColor() {
            var dword = Math.random() * ~(1 << 31);
            return this.getColor(dword);
        },
        getColor(dword, id = null) {
            if (id != null) {
                var colorsfixed = [
                    'rgba(98,189,80,1)',
                    'rgba(242,214,0,1)',
                    'rgba(255,159,26,40)',
                    'rgba(0,121,191,1)',
                    'rgba(195,119,224,51)',
                    'rgba(235,90,70,67)',
                    'rgba(0,194,224,1)',
                    'rgba(81,232,152,1)',
                    'rgba(255,120,203,69)',
                    'rgba(53,82,99,1)',
                    'rgba(179,190,196,1)',
                    'rgba(139,69,0,1)'
                ];
                if (id < colorsfixed.length)
                    return colorsfixed[id];
                else
                    return this.randomColor();
            }

            var a = 255;
            var r = ((dword & 0x00ff0000) >> 16);
            var g = (dword & 0x0000ff00) >> 8;
            var b = (dword & 0x000000ff);
            var color = "rgba("+r+","+g+","+b+","+a+")";
            return color;
        }
    }
}
</script>

<style lang="scss" scoped>
    .increase, .discount {
        display: block;
        border-radius: 1000px;
        width: 12px;
        height: 12px;
        background-color: #000;
        border: 2px solid #fff;
        position: static;
        float: right;
        margin-top: 5px;
    }

    .increase {
        background-color: #007bff;
    }

    .discount {
        background-color: #f7bf4e;
    }

    .pricetable-edit {
        margin: 0px;
        thead {
            > tr:first-child {
                > th {
                    text-align: center;
                    border: none;
                    background: white;
                    background-color: white;
                    color: #000;
                }
            }
            
        }
    }

    .pricetable-nav {
        position: sticky;
        top: 120px;
        cursor: default;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

        * {
            color: #9051a0;
            cursor: inherit;
        }

        a {
            color: inherit;
            cursor: pointer;
        }

        .active, .active a {
            background-color: #9051a0;
            border-radius: 10px;
            color: #fff;
        }

        .root, .root > a {
            cursor: default;
        }
    }

    .pricetable-nav.changed {
        top: 180px;
    }

    .pricetable-view *, input {
        font-size: 12px;
    }

    .profit {
        font-size: 12px;
    }

    .price-table-content {
        overflow: auto;
    }

    @media screen and (min-width: 768px) {
        .price-table-content {
            max-width: 450px;
            min-width: 100%;
        }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (min-width: 1000px) {
        .price-table-content {
            max-width: 650px;
        }
    }

    .nav-item-service {
        border-bottom: 1px dotted; 
        border-left: 1px dotted;
        border-radius: 10px;
    }
</style>

<style scoped>
    .services-tabs >>> .nav-tabs {
        background-color: #f868a9;
    }

    .services-tabs >>> .nav-item {
        padding: 2px;
    }

    .services-tabs >>> a {
        color: #fff;
    }
</style>
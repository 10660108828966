<template>
    <multiselect :multiple="multiple" placeholder="Selecione" selectedLabel="Selecionado" track-by="id" label="name" selectLabel="Pressione enter para selecionar" deselectLabel="Pressione enter para remover" v-model="Value" :options="services" >
        <template slot="noResult">
            Nenhum resultado encontrado
        </template>
        <template slot="noOptions">
            Nenhum resultado encontrado
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: {
        value: {
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        Value: {
            get() {
                if (this.multiple) {
                    return this.services.filter(s => this.value.filter(v => v == s.id).length > 0 );
                }

                return this.services.filter(s => s.id == this.value)[0];
            },
            set(value) {
                if (this.multiple) {
                    if (value) {
                        this.$emit('input', value.map(v => v.id));
                    }
                    else {
                        this.$emit('input', []);
                    }

                    return;
                }

                if (value) {
                    this.$emit('input', value.id);
                }
                else {
                    this.$emit('input', null);
                }
            }
        }
    },
    data() {
        return {
            loaded: false,
            services: []
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.loaded = false;
            this.$store.dispatch('service/getServices', {}).then(data => {
                var services = data;
                var flatten = (items, prefix = "") => {
                    var result = [];
                    for (var item of items) {
                        if (item.children && item.children.length > 0) {
                            result = result.concat(flatten(item.children, (prefix ? prefix + " / " : "") + item.name));
                        }
                        else {
                            item.name = prefix+ " / " +item.name;
                            result.push(item);
                        }
                    }

                    return result;
                };
                services = flatten(services);
                this.services = services;
            });
        }
    }
}
</script>
import Vue from 'vue' 
import Http from '@/api/http';

const state = {
    address: {
        name: '', //string
        street: '', //string
        number: '', //integer
        district: '', //string
        zipcode: '', //string
        zone: null, //integer
        reference: '', //string
        default: 0, //boolean
        latitude: 0, //float
        longitude: 0, //float
        city_id: null,
    },
    state: ''
};

const getters = {
    
};

const actions = {
    updateAddress({commit, state}, address_id) {
        return new Promise((resolve, reject) => {
            Http.put('address/' + address_id, state.address).then(response => {
                commit('UPDATE_ADDRESS', response.data.address)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

const mutations = {

    UPDATE_ADDRESS(state, address) {
        Vue.set(state.address, address);
    },

    SET_ADDRESS_NAME(state, value) {
        state.address.name = value;
    },

    SET_ZIPCODE(state, value) {
        state.address.zipcode = value;
    },

    SET_STREET(state, value) {
        state.address.street = value;
    },

    SET_NUMBER(state, value) {
        state.address.number = value;
    },

    SET_COMPLEMENT(state, value) {
        state.address.complement = value;
    },

    SET_REFERENCE(state, value) {
        state.address.reference = value;
    },

    SET_ZONE(state, value) {
        state.address.zone = value;
    },

    SET_DISTRICT(state, value) {
        state.address.district = value;
    },

    SET_CITY_ID(state, value) {
        state.address.city_id = value;
    },

    SET_ADDRESS_STATE(state, value) {
        state.state = value;
    },

    SET_ADDRESS_EMPTY(state) {
        state.address = {
                name: '', //string
                street: '', //string
                number: '', //integer
                district: '', //string
                zipcode: '', //string
                zone: null, //integer
                reference: '', //string
                default: 0, //boolean
                latitude: 0, //float
                longitude: 0, //float
                city_id: null,
        };
        state.state = '';
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};


import Http from '@/api/http'

const state = {
    banks: []
};

const getters = {
    
};

const actions = {
    getBanks({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('bank').then(response => {
                commit('SET_BANKS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

const mutations = {
    SET_BANKS(state, banks) {
        state.banks = banks;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


<template>
<div>
    <panel title="ENDEREÇO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12" v-for="(item, index) in address" :key="index">
                <b-overlay :show="search_zipcode" rounded="sm">
                    <b-row class="form-group">
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>CEP:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.zipcode">
                                        -
                                    </p>
                                    <p v-else>{{item.zipcode | formatCep}}</p>
                                </template>
                                <template v-else>
                                    <the-mask :mask="'#####-###'" class="form-control" v-model="item.zipcode" placeholder="CEP" @input="UpdateZipcode($event, index)" @blur.native="SearchZipcode(item, index)" />
                                    <b-form-invalid-feedback :state="!errors[index+'.zipcode']">
                                        <div v-for="error in errors[index+'.zipcode']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="form-group">
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>Logradouro:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.street">
                                        -
                                    </p>
                                    <p v-else>{{item.street}}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="item.street" aria-describedby="input-live-help input-live-feedback" placeholder="Logradouro" @input="UpdateStreet($event, index)"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors[index+'.street']">
                                        <div v-for="error in errors[index+'.street']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>Número:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.number">
                                        -
                                    </p>
                                    <p v-else>{{item.number}}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="item.number" aria-describedby="input-live-help input-live-feedback" placeholder="Número" @input="UpdateNumber($event, index)"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors[index+'.number']">
                                        <div v-for="error in errors[index+'.number']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>Complemento:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.complement">
                                        -
                                    </p>
                                    <p v-else>{{ item.complement }}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="item.complement" aria-describedby="input-live-help input-live-feedback" placeholder="Complemento" @input="UpdateComplement($event, index)"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors[index+'.complement']">
                                        <div v-for="error in errors[index+'.complement']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="form-group">
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>Bairro:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.district">
                                        -
                                    </p>
                                    <p v-else>{{item.district}}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="item.district" aria-describedby="input-live-help input-live-feedback" placeholder="Bairro" @input="UpdateDistrict($event, index)"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors[index+'.district']">
                                        <div v-for="error in errors[index+'.district']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                        <b-col sm="12" md="12" lg="4">
                            <div role="group">
                                <label>Ponto de referência:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.reference">
                                        -
                                    </p>
                                    <p v-else>{{item.reference}}</p>
                                </template>
                                <template v-else>
                                    <b-form-input v-model="item.reference" aria-describedby="input-live-help input-live-feedback" placeholder="Ponto de referência" @input="UpdateReference($event, index)"></b-form-input>
                                    <b-form-invalid-feedback :state="!errors[index+'.reference']">
                                        <div v-for="error in errors[index+'.reference']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                        <b-col sm="12" md="4" lg="4">
                            <div role="group">
                                <label>Zona:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!zone_name(item.zone)">
                                        -
                                    </p>
                                    <p v-else>{{ zone_name(item.zone) }}</p>
                                </template>
                                <template v-else>
                                    <b-form-select v-model="item.zone" :options="zone_options" @input="UpdateZone($event, index)">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="!errors[index+'.zone']">
                                        <div v-for="error in errors[index+'.zone']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="form-group">
                        <b-col sm="12" md="6" lg="6">
                            <div role="group">
                                <label>Estado:</label>
                                <template v-if="!OpenForm.personAddress">
                                    <p v-if="!item.city">
                                        -
                                    </p>
                                    <p v-else>{{item.city.state.name}}</p>
                                </template>
                                <template v-else>
                                    <b-form-select v-model="state_selected" :options="states" @input="UpdateState($event, index)">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </template>
                            </div>
                        </b-col>
                        <b-col v-if="item.city && (item.city.state || !OpenForm.personAddress)" sm="12" md="6" lg="6">
                            <div role="group">
                                <label>Cidade:</label>
                                <template v-if="! OpenForm.personAddress">
                                    <p v-if="!item.city.name">
                                        -
                                    </p>
                                    <p v-else>{{item.city.name}}</p>
                                </template>
                                <template v-else>
                                    <b-form-select :disabled="!item.city.state.id" v-model="item.city_id" :options="cities" @input="UpdateCity_id($event, index)">
                                        <template #first>
                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="!errors[index+'.city_id']">
                                        <div v-for="error in errors[index+'.city_id']" :key="error">
                                            {{ error }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </template>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="IsUpdate && has_permission('worker-edit')">
                        <b-col sm="12" v-if="IsStage">
                            <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                                <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                            </b-button>
                            <b-button variant="mary-beleza" size="sm" @click="item.id ? update(index) : create(index)">
                                <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                            </b-button>
                            
                        </b-col>
                        <b-col sm="12" v-else>
                            <b-button v-if="!OpenForm.personAddress" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'personAddress')">
                                <fa icon="pen" class="mr-2" /> Editar
                            </b-button>
                            <template v-else>
                                <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                    <fa icon="ban" class="mr-2" /> Cancelar
                                </b-button>
                                <b-button variant="mary-beleza" size="sm" @click="item.id ? update(index) : create(index)">
                                    <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                                </b-button>
                            </template>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"
import Http from '@/api/http';
import {
    mapGetters
} from 'vuex'

export default {

    components: {
        Panel
    },
    props: {
        validateRegion: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            state_selected: '',
            zone_options: [
                {
                    text: 'Centro',
                    value: 0
                },
                {
                    text: 'Norte',
                    value: 1
                },
                {
                    text: 'Sul',
                    value: 2
                }, {
                    text: 'Leste',
                    value: 3
                },
                {
                    text: 'Oeste',
                    value: 4
                }
            ],

            search_zipcode: false,
            states: [],
            cities: []
        }
    },

    created() {
        this.$store.dispatch('states/getStates').then(data => {
            data.forEach(state => {
                this.states = [...this.states, {
                    text: state.name,
                    value: state.id
                }]
            });
        });
        if(this.IsUpdate){
            this.state_selected = this.address[0].city && this.address[0].city.state ? this.address[0].city.state.id : null;
            this.getCities(this.state_selected);
        }
    },

    methods: {
        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        async getCities(state_id) {
            await this.$store.dispatch('cities/getCities', state_id).then(data => {
                var cities = [];
                data.forEach(citie => {
                    cities = [...cities, {
                        text: citie.name,
                        value: citie.id
                    }]
                });
                this.cities = cities;
            });

        },

        create(index) {
            //console.log(index)
            this.$store.dispatch('worker/createAddress', index).then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                if(response.success){
                    this.sweealert2('success', response.success)
                } else if(response.info){
                    this.sweealert2('info', response.info)
                }
                
            }).catch(error => {
                this.sweealert2('error', error[Object.keys(error)[0]])

            });
        },

        update(index) {
            console.log(index)
            this.$store.dispatch('worker/updateAddress', index).then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
            }).catch(error => {
                this.sweealert2('error', error[Object.keys(error)[0]])

            });
        },

        UpdateZipcode(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_ZIPCODE', data)
        },

        UpdateState(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_STATE_ID', data)
            if(value != null){
                this.getCities(value);
            }
            this.state_selected = value;
        },

        UpdateStreet(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_STREET', data)
        },

        UpdateComplement(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_COMPLEMENT', data)
        },

        UpdateNumber(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_NUMBER', data)
        },

        UpdateReference(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_REFERENCE', data)
        },

        UpdateZone(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_ZONE', data)
        },

        UpdateDistrict(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_DISTRICT', data)
        },

        UpdateCity_id(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_CITY_ID', data)
        },

        zone_name(value) {
            var data = this.zone_options.find(zone => zone.value === value);
            return data?.text;
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },


        SearchZipcode(item, index) {
            if(item.zipcode){
                this.search_zipcode = true;
                var zipcode = item.zipcode.replace(/[^\d]+/g,'');
                
                Http.get(`/zipcode/${zipcode}`, { params: { validate_region: this.validateRegion } }).then(response => {
                    this.UpdateState(response.data.state_id, index);
                    this.UpdateStreet(response.data.street, index);
                    this.UpdateComplement(response.data.complement, index);
                    this.UpdateDistrict(response.data.district, index);
                    this.UpdateCity_id(response.data.city_id, index);
                    this.UpdateZone(response.data.zone, index);
                    this.search_zipcode = false;
                }).catch(() => {
                    this.search_zipcode = false;
                });
            }
        },
    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        address: {
            get() {
                return this.$store.state.worker.worker.person.address;
            },
        },

        person: {
            get() {
                return this.$store.state.worker.worker.person;
            },
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    },
}
</script>

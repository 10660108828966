<template>
    <panel :title="title" icon='credit-card' icon-lib="fa">
        <b-row v-if="prepaidcards.length > 0">
            <b-col sm="6" md="4" lg="4" v-for="(prepaidcard, key) in prepaidcards" :key="key">
                <b-card @click="prepaidcard_id = prepaidcard.id">
                    <div>
                        <b-iconstack v-if="prepaidcard_id == prepaidcard.id" font-scale="1.2">
                            <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                            <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                        </b-iconstack>
                        <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                    </div>
                    <div class="justify-content-between d-flex">
                        <div>
                            **** **** **** {{prepaidcard.number}}
                        </div>
                        <div>
                            {{prepaidcard.due_date | moment("MM/YYYY")}}
                        </div>
                    </div>
                    <p class="font-weight-bold m-0">{{prepaidcard.flag | cardFlag}}</p>
                </b-card>
            </b-col>
        </b-row>
        <div v-else class="text-center">
            Nenhum cartão registrado
        </div>
    </panel>
</template>

<script>
import Panel from '@/components/Panel'

export default {
    components: {
        Panel
    },
    props: {
        title: {
            type: String,
            required: false,
            default: "Pagamento"
        },
        prepaidcards: {
            type: Array,
            required: true
        },
        value: {
            required: true
        }
    },
    computed: {
        prepaidcard_id: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    }
}
</script>
<template>
  <div id="workerservice" class="pr-3 pl-3 pt-3">
    <b-row>
      <b-col sm="12" lg="12" md="12">
        <b-overlay :show="showTotals && panel != 'schedule'">
          <panel>
            <div
              class="card-counter"
            >
              <div v-if="workersPerService">
                <b-tabs>
                  <b-tab v-for="(service, i) in workersPerService" :key="i" :title="service.name">
                    <bar-graphic :key="showTotals" class="mt-5" title="Profissionais - Quantidade por Serviços" :grid="true" width="100%" height="400px" :data="service.data"></bar-graphic>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </panel>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Panel from "@/components/Panel";
import Http from "@/api/http";
import BarGraphic from "@/components/BarGraphic";

export default {
  components: {
    Panel,
    BarGraphic
  },
  data() {
    return {
      workersPerService: null,
      items: [],
    };
  },
  created() {
    this.LoadData();
    this.$store.dispatch("financial/getBalance");
  },
  methods: {
    LoadData() {
      this.LoadTotals();
    },
    LoadTotals(panel = null) {
        this.panel = panel;
        this.showTotals = true;
        Http.get("report/totals", { params: this.filters }).then(response => {
            var total_workers_per_service = response.data.total_workers_per_service;

            var tooltip = (item, data) => {
                var label = data.labels[item.index];
                //var total = data.datasets[item.datasetIndex].data.reduce((a, b) => a + b, 0);
                var value = data.datasets[item.datasetIndex].data[item.index];
                //var percentage = total > 0 ? (value / total) * 100 : 0;
                //percentage = percentage.toFixed(2);
                return label+": "+value;
            };

            var i = 0;
            var color = null;
            if (total_workers_per_service) {
                var workersPerService = [];

                var grp1 = {};
                for (var s1 of total_workers_per_service) {
                  if (!grp1[s1.parent]) {
                    grp1[s1.parent] = {
                      name: s1.parent,
                      services: []
                    };
                  }

                  grp1[s1.parent].services.push(s1);
                }

                for (var kgrp1 in grp1) {
                  i = 0;
                  var dataset_service = {
                    labels: [],
                    datasets: [
                        {
                            label: grp1[kgrp1].name,
                            backgroundColor: [],
                            data: [],
                            borderColor: "#888"
                        }
                    ],
                    tooltip: tooltip
                  };

                  for (var service of grp1[kgrp1].services) {
                      color = this.getColor(null, i);
                      dataset_service.labels.push(service.name);
                      dataset_service.datasets[0].data.push(service.total);
                      dataset_service.datasets[0].backgroundColor.push(color);
                      i++;
                  }

                  workersPerService.push({
                    name: grp1[kgrp1].name,
                    data: dataset_service
                  });
                }

                this.workersPerService = workersPerService;
            }

            this.showTotals = false;
        });
    },
    randomColor() {
        var dword = Math.random() * ~(1 << 31);
        return this.getColor(dword);
    },
    getColor(dword, id = null) {
        if (id != null) {
            var colorsfixed = [
                'rgba(98,189,80,1)',
                'rgba(242,214,0,1)',
                'rgba(255,159,26,40)',
                'rgba(0,121,191,1)',
                'rgba(195,119,224,51)',
                'rgba(235,90,70,67)',
                'rgba(0,194,224,1)',
                'rgba(81,232,152,1)',
                'rgba(255,120,203,69)',
                'rgba(53,82,99,1)',
                'rgba(179,190,196,1)',
                'rgba(139,69,0,1)'
            ];
            if (id < colorsfixed.length)
                return colorsfixed[id];
            else
                return this.randomColor();
        }

        var a = 255;
        var r = ((dword & 0x00ff0000) >> 16);
        var g = (dword & 0x0000ff00) >> 8;
        var b = (dword & 0x000000ff);
        var color = "rgba("+r+","+g+","+b+","+a+")";
        return color;
    },
  },
  computed: {
  }
};
</script>

<style scoped>
  .tabs >>> .nav-link {
    color: #A9A9A9;
  }
</style>
import VueRouter from 'vue-router';
import store from '@/store/index';
import swal from 'sweetalert2';

import Dashboard from '@/views/Dashboard/index'
import Login from '@/views/Login/index'
import Faq from '@/views/Faq/index'
import Worker_Ranking from '@/views/Worker/ranking'
import Worker_Bonus from '@/views/Worker/bonus'
import Worker_General_Registrations from '@/views/Worker/general-registrations'
import Worker_MB_Registration from '@/views/Worker/mb-registrations'
import Worker_Approved_Registration from '@/views/Worker/approved-registrations'
import Worker_Pre_Registration from '@/views/Worker/pre-registrations'
import Worker_Pre_Registration_Record from '@/views/Worker/pre-registrations-record'
import Worker_Dropouts from '@/views/Worker/dropouts'
import Worker_Dropouts_Record from '@/views/Worker/dropouts-record.vue'
import Worker_Import_InfoJobs from '@/views/Worker/import-infojobs'
import Worker_Historical from '@/views/Worker/historical'
import Worker_From from '@/views/Worker/form'
import Worker_Map from '@/views/Worker/map'
import Employee_List from '@/views/Employee/index'
import Employee_Form from '@/views/Employee/form'
import Customer_List from '@/views/Customer/index'
import Customer_Form from '@/views/Customer/form'
import Customer_Inactive from '@/views/Customer/inactive'
import Customer_Consult_Network from '@/views/Customer/consult-network'
import Customer_Media from '@/views/Customer/media'
import Customer_Mapa from '@/views/Customer/mapa'
import Customer_Historical from '@/views/Customer/historical'
import Services_List from '@/views/Services/index'
import Services_Problems from '@/views/Services/services_problems'
import Services_Preview from '@/views/Services/preview-edit'
import Services_Form from '@/views/Services/form'
import ChangePassword from '@/views/Util/ChangePassword'
import Utilities_Signatures from '@/views/Utilities/signatures'
import Utilities_Promotions from '@/views/Utilities/Promotions/index'
import Utilities_Promotions_Form from '@/views/Utilities/Promotions/form'
import Utilities_Notifications from '@/views/Utilities/Notifications/index'
import Utilities_Notifications_Form from '@/views/Utilities/Notifications/form'
import Utilities_Holidays from '@/views/Utilities/holidays'
import Utilities_Mailing from '@/views/Utilities/Mailing'
import Financial_Statement from '@/views/Financial/statement'
import Financial_Cash_Flow from '@/views/Financial/cash-flow'
import Financial_Fixed_Costs from '@/views/Financial/fixed-costs'
import Financial_AsaasReceipts from '@/views/Financial/asaas-receipts'
import Financial_AsaasPayments from '@/views/Financial/asaas-payments'
import Financial_AsaasExtract from '@/views/Financial/asaas-extract'
import Financial_AsaasInvoices from '@/views/Financial/asaas-invoices'
import PriceTable from '@/views/PriceTable/index'
import Configurations from '@/views/Configurations/index'
import ReportService from '@/views/Report/service'
import ReportWorkerService from '@/views/Report/worker-service'
import ReportWorkerHability from '@/views/Report/worker-hability'

const routes = [
    { path: '/', component: Dashboard, meta: { title: 'Dashboard' } },
    { path: '/login', component: Login, meta: { title: 'Login', plainLayout: true } },
    { path: '/relatorios/servicos', component: ReportService, meta: { permission: 'faq-list', breadcrumb: [
        { name: 'Relatório', link: '/' },
        { name: 'Quantidade de Serviços', active: true }
    ] } },
    { path: '/relatorios/profissionais-servicos', component: ReportWorkerService, meta: { permission: 'faq-list', breadcrumb: [
        { name: 'Relatório', link: '/' },
        { name: 'Profissionais/Serviços', active: true }
    ] } },
    { path: '/relatorios/profissionais-habilidades', component: ReportWorkerHability, meta: { permission: 'faq-list', breadcrumb: [
        { name: 'Relatório', link: '/' },
        { name: 'Profissionais/Habilidades', active: true }
    ] } },
    { path: '/faq', component: Faq, meta: { permission: 'faq-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'FAQ', active: true }
    ] } },
    { path: '/configuracoes', component: Configurations, meta: { permission: 'configs', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Configurações', active: true }
    ] } },
    { path: '/profissionais', component: Worker_General_Registrations, meta: { permission: 'worker-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais' },
        { name: 'Cadastro Geral', active: true },  
    ] } },
    { path: '/profissionais/pre-cadastro', component: Worker_Pre_Registration, meta: { permission: 'worker-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Pré Cadastros', active: true },
    ] } },
    { path: '/profissionais/pre-cadastro/:id', component: Worker_Pre_Registration_Record, meta: { permission: 'worker-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Pré-Cadastro', link: '/profissionais/pre-cadastro' },
        { name: 'Ficha Cadastral', active: true },
    ] } },
    { path: '/profissionais/cadastro/:id?', component: Worker_From, meta: { permission: 'worker-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Cadastro', active: true },
    ] } },
    { path: '/profissionais/aprovados', component: Worker_Approved_Registration, meta: { permission: 'worker-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Cadastros Aprovados', active: true },
    ] } },
    { path: '/importacao/infojobs', component: Worker_Import_InfoJobs, meta: { permission: 'worker-infojobs', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Importação InfoJobs', active: true },
    ] } },
    { path: '/profissionais/mb', component: Worker_MB_Registration, meta: { permission: 'worker-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Cadastros MB', active: true },
    ] } },
    { path: '/profissionais/desistencia', component: Worker_Dropouts, meta: { permission: 'worker-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Desistência', active: true },
    ] } },
    { path: '/profissionais/desistencia/:id', component: Worker_Dropouts_Record, meta: { permission: 'worker-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Desistência', link: '/profissionais/desistencia' },
        { name: 'Ficha Cadastral', active: true },
    ] } },
    { path: '/profissionais/mapa', component: Worker_Map, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Mapa de Profissionais', active: true },
    ] } },
    { path: '/profissionais/historico/:id', component: Worker_Historical, meta: { permission: 'worker-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais', link: '/profissionais' },
        { name: 'Histórico', active: true },
    ] } },
    { path: '/profissionais/ranking', component: Worker_Ranking, meta: { permission: 'worker-ranking', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais' },
        { name: 'Ranking', active: true },  
    ] } },
    { path: '/profissionais/bonus', component: Worker_Bonus, meta: { permission: 'worker-bonus', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Profissionais' },
        { name: 'Bonus', active: true },  
    ] } },
    { path: '/colaboradores', component: Employee_List, meta: { permission: 'employee-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Colaboradores', active: true },
    ] } },
    { path: '/colaboradores/cadastro/:id?', component: Employee_Form, meta: { permission: 'employee-edit', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Colaboradores', link: '/colaboradores' },
        { name: 'Colaboradores Cadastro', active: true },
    ] } },
    
    { path: '/clientes', name: 'customers', component: Customer_List, meta: { permission: 'customer-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', active: true },
    ] } },
    { path: '/clientes/cadastro/:id?', component: Customer_Form, meta: { permission: 'customer-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Clientes Cadastro', active: true },
    ] } },
    { path: '/clientes/inativos', component: Customer_Inactive, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Clientes Inativos', active: true },
    ] } },
    { path: '/clientes/consultar-rede', component: Customer_Consult_Network, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Consltar Rede', active: true },
    ] } },
    { path: '/clientes/midia', component: Customer_Media, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Clientes Mídia', active: true },
    ] } },
    { path: '/clientes/mapa', component: Customer_Mapa, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Mapa de Clientes', active: true },
    ] } },
    { path: '/clientes/historico/:id', component: Customer_Historical, meta: { permission: 'customer-view', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Clientes', link: '/clientes' },
        { name: 'Histórico', active: true },
    ] } },
    
    { path: '/servicos', component: Services_List, meta: { permission: 'services-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Lista de Serviços', active: true },
    ] } },
    { path: '/servicos-com-problema', component: Services_Problems, meta: { permission: 'services-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Serviços com problema', active: true },
    ] } },
    { path: '/servicos/cadastro', component: Services_Form, props: route => ({ ...route.query }), meta: { permission: 'services-edit', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Cadastrar Serviço', active: true },
    ] } },
    { path: '/servicos/servico/:id?', component: Services_Preview, meta: { permission: 'services-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Lista de Serviços', link: '/servicos' },
        { name: 'Serviço', active: true },
    ] } },

    { path: '/utilitarios/assinatura', component: Utilities_Signatures, meta: { permission: 'signature', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Assinaturas', active: true },
    ] } },
    { path: '/utilitarios/promocoes', component: Utilities_Promotions, meta: { permission: 'promotions-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Promoções', active: true },
    ] } },
    { path: '/utilitarios/promocoes/cadastro/:id?', component: Utilities_Promotions_Form, meta: { permission: 'promotions-edit', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Promoções', link: '/utilitarios/promocoes' },
        { name: 'Promoções Cadastro', active: true },
    ] } },
    { path: '/utilitarios/notificacoes', component: Utilities_Notifications, meta: { permission: 'notifications-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Notificações', link: '/utilitarios/notificacoes' },
        { name: 'Notificações Cadastro e Envio', active: true },
    ] } },
    { path: '/utilitarios/notificacoes/cadastro/:id?', component: Utilities_Notifications_Form, meta: { permission: 'notifications-edit', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Notificações', active: true },
    ] } },
    { path: '/utilitarios/feriados', component: Utilities_Holidays, meta: { permission: 'holidays-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Feriados', active: true },
    ] } },
    { path: '/utilitarios/mala-direta', component: Utilities_Mailing, meta: { permission: 'mailing', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Utilitários' },
        { name: 'Mala Direta', active: true },
    ] } },
    { path: '/alterar-senha', component: ChangePassword, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Alterar Senha', active: true }
    ] } },

    { path: '/tabela-de-precos', component: PriceTable, meta: { permission: 'price-table', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Tabela de Preços', active: true }
    ] } },

    { path: '/financeiro/extrato', component: Financial_Statement, meta: { permission: 'extract-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Extrato', active: true },
    ] } },
    { path: '/financeiro/fluxo-caixa', component: Financial_Cash_Flow, meta: { permission: 'cashflow-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Fluxo de Caixa', active: true },
    ] } },
    { path: '/financeiro/custos-fixos', component: Financial_Fixed_Costs, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Custos Fixos', active: true },
    ] } },
    { path: '/financeiro/asaas-recebimentos', component: Financial_AsaasReceipts, meta: { breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Asaas Recebimentos', active: true },
    ] } },
    { path: '/financeiro/asaas-pagamentos', component: Financial_AsaasPayments, meta: { permission: 'asaas-payments-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Asaas Pagamentos', active: true },
    ] } },
    { path: '/financeiro/asaas-notas-fiscais', component: Financial_AsaasInvoices, meta: { permission: 'asaas-invoices-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Asaas Notas Fiscais', active: true },
    ] } },
    { path: '/financeiro/asaas-extrato', component: Financial_AsaasExtract, meta: { permission: 'asaas-extract-list', breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Financeiro' },
        { name: 'Asaas Extrato', active: true },
    ] } },

]

const router = new VueRouter({
	routes: routes,
	mode: 'history',
});

router.beforeEach((to, from, next) => {
    if(to.path == "/login" || (store.getters['auth/user'] && store.getters['auth/user'].token)){
        if(to.meta.permission && !store.getters['auth/user'].employee.permissions.includes(to.meta.permission)){
            swal.fire({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                title: 'Atenção!',
                text: "Você não possui permissão para acesso!",
                icon: 'error',
                padding: 20,
            });
        } else {
            next();
        }
    }
    else
        store.dispatch("auth/logout")
})

export default router
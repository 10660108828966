export default class Filters {

    constructor() {

        this.status = null;
        this.value_type = null;
        this.value = {
            start: 0,
            end: 0
        }
        this.start_date = null;
        this.end_date = null;
        this.number_of_uses = {
            start: null,
            end: null
        }
        this.number_of_uses_per_customer = {
            start: null,
            end: null
        }
    }
}
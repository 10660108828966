<template>
<div>
    <panel title="ARQUIVOS" icon="address-card" icon-lib="far">
        <b-row class="text-center">
            <b-col md="6" class="d-flex justify-content-center">
                <b-row>
                    <b-col>
                        <label class="m-2">Foto</label>
                        <b-overlay :show="savePersonPhoto">
                            <div size="120" class="user">
                                <b-avatar variant="light" class="profile-img" :src="imagePreview == '' ? image : imagePreview" button v-b-modal.modal-1></b-avatar>
                                <input ref="PhotoRef" type="file" style="display: none;" @change="onPhotoFileSelect" accept="image/jpeg, image/png, image/jpg" />
                            </div>
                        </b-overlay>
                        <b-modal hide-header id="modal-1" size="lg" no-close-on-backdrop v-if="has_permission('worker-edit')" @hide="webcamStop()">
                            <b-row class="text-center">
                                <b-col v-if="webcamPreview">
                                    <div class="webcam-preview">
                                        <div ref="WebcamPreviewRef"></div>
                                    </div>
                                </b-col>
                                <b-col v-else-if="selectPhotoFile">
                                    <VueCropper :aspect-ratio="4/4" class="cropped-image" v-show="selectPhotoFile" ref="cropper" :src="selectPhotoFile" alt="Source Image"></VueCropper>
                                </b-col>
                                <b-col>
                                    <b-row class="justify-content-center m-3">
                                        <b-avatar size="11rem" variant="mary-beleza">
                                            <b-avatar variant="light" :src="cropedImage == '' ? image : cropedImage" size="10rem"></b-avatar>
                                        </b-avatar>
                                    </b-row>
                                    <b-row class="justify-content-center m-3">
                                        <template v-if="!webcamPreview">
                                            <b-button variant="primary" v-bind:class="[selectPhotoFile ? 'btn-block' : 'pr-3 pl-3']" @click="$refs.PhotoRef.click()">
                                                <b-icon icon="upload" class="mr-2"></b-icon> Selecionar foto
                                            </b-button>
                                            <b-button variant="dark" v-bind:class="[selectPhotoFile ? 'btn-block' : 'pr-3 pl-3']" @click="webcamStart()">
                                                <b-icon icon="camera" class="mr-2"></b-icon> {{selectPhotoFile ? 'Nova Foto com Webcam' : 'Foto com Webcam' }}
                                            </b-button>
                                        </template>
                                        <b-button variant="danger" v-else @click="webcamCapture()" :disabled="webcampCapturing">
                                            <b-icon icon="check" class="mr-2"></b-icon> Capturar Foto
                                        </b-button>
                                    </b-row>
                                    <b-row class="justify-content-center m-3">
                                        <b-button v-if="selectPhotoFile" block variant="outline-mary-beleza" @click="cropedImagePreview()">
                                            <b-icon icon="scissors" class="mr-2"></b-icon>
                                            Cortar
                                        </b-button>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <template #modal-footer>
                                <b-button v-if="cropedImage" variant="success" @click="saveImage()">
                                    Salvar
                                </b-button>
                                <b-button variant="danger" @click="cancelImage()">
                                    Cancelar
                                </b-button>
                            </template>
                        </b-modal>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6" class="d-flex justify-content-center mb-3">
                <b-row>
                    <b-col>
                        <label class="m-2">Currículo</label>
                        <b-row align-h="center">
                            <b-col cols="3" class="mr-4">
                                <div>
                                    <input ref="ResumeRef" type="file" style="display: none;" @change="onResumeFileSelect" />
                                    <b-icon v-if="resume || newResume" icon="file-earmark-check" font-scale="8" variant="success"></b-icon>
                                    <b-icon v-else icon="file-earmark-excel" font-scale="8" variant="danger"></b-icon>
                                </div>
                            </b-col>
                            <b-col cols="3">
                                <b-col class="ml-1">
                                    <b-button v-if="has_permission('worker-edit')" @click="$refs.ResumeRef.click()" variant="primary" class="mb-2" v-b-tooltip title="Selecionar...">
                                        <b-overlay :show="saveWorkerResume" opacity="0">
                                            <b-icon icon="file-earmark-arrow-up"></b-icon>
                                        </b-overlay>
                                    </b-button>

                                    <a :href="resume" v-if="resume && !newResume" class="btn btn-primary" v-b-tooltip title="Baixar">
                                        <b-icon icon="download"></b-icon>
                                    </a>
                                </b-col>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>
    <panel v-if="IsStage">
        <b-row>
            <b-col sm="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="next">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"
import { mapGetters } from 'vuex'
import Constants from '@/constants'
import Http from '@/api/http'
// import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

const Webcam = require('@/assets/js/webcam.min.js');

export default {

    components: {
        Panel,
        VueCropper
    },

    data() {
        return {
            webcampCapturing: false,
            webcamPreview: false,
            cropedImage: '',
            selectPhotoFile: '',
            imagePreview: '',
            imageName: '',
            files: '',
            resumeFile: {
                name: '',
                file: ''
            },
            saveWorkerResume: false,
            savePersonPhoto: false,
            newResume: false
        }

    },

    methods: {
        webcamStart() {
            this.cropedImage = '';
            this.selectPhotoFile = null;
            this.webcampCapturing = false;
            Webcam.set({
                width: 320,
                height: 240,
                image_format: 'jpeg',
                jpeg_quality: 90
            });

            this.webcamPreview = true;
            window.requestAnimationFrame(() => {
                Webcam.attach(this.$refs.WebcamPreviewRef);
            })
        },
        webcamCapture() {
            this.webcampCapturing = false;
            Webcam.snap((data_uri) => {
                this.webcamStop();
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = () => {
                    URL.revokeObjectURL(data_uri);
                    var blob = xhr.response;
                    var file = new File([blob], "photo.jpg", {
                        type: blob.type
                    });
                    this.webcampCapturing = false;
                    this.onPhotoFileSelect({
                        target: {
                            files: [file]
                        }
                    });
                };
                xhr.open("GET", data_uri);
                xhr.send();
            });
        },
        webcamStop() {
            Webcam.reset();
            this.webcamPreview = false;
        },
        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        next() {
            this.$store.commit('worker/STAGE_NEXT')
        },

        saveImage() {
            this.imagePreview = this.$refs.cropper.getCroppedCanvas().toDataURL()
            this.$bvModal.hide('modal-1')
            this.savePersonPhoto = true

            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData()
                formData.append('file', blob, this.imageName)
                Http.post('upload/' + this.person_id + '/person_photos', formData)
                    .then((response) => {
                        this.sweealert2('success', response.data.success)
                        this.savePersonPhoto = false
                    })
                    .catch(function (error) {
                        this.sweealert2('error', error.response.data.error)
                        this.savePersonPhoto = false
                    })
            }, this.mime_type)
        },

        cancelImage() {
            this.$bvModal.hide('modal-1')
            this.$refs.PhotoRef.value = null;
            this.selectPhotoFile = ''
            this.cropedImage = ''
        },

        cropedImagePreview() {
            this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        },

        onPhotoFileSelect(e) {
            const file = e.target.files[0]
            this.mime_type = file.type
            this.imageName = file.name
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.selectPhotoFile = event.target.result
                    this.$refs.cropper.replace(this.selectPhotoFile)
                }
                reader.readAsDataURL(file)
            } else {
                alert('Não suportado!')
            }
        },

        onResumeFileSelect(e) {
            const file = e.target.files[0]
            this.saveWorkerResume = true
            this.resumeFile.name = file.name
            this.resumeFile.file = file
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            Http.post('upload/' + this.person_id + '/worker_resumes', formData)
                .then(response => {
                    this.sweealert2('success', response.data.success)
                    this.saveWorkerResume = false
                    this.newResume = true
                    this.$refs.ResumeRef.value = null;
                })
                .catch(function (error) {
                    this.sweealert2('error', error.response.data.error)
                    this.saveWorkerResume = false
                })
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

    },

    computed: {
        ...mapGetters('worker', [
            'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        person_id: {
            get() {
                return this.$store.state.worker.worker.person.id;
            }
        },

        name: {
            get() {
                return this.$store.state.worker.worker.person.name;
            }
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            }
        },

        image: {
            get() {
                return this.$store.state.worker.worker.person.image;
            }
        },

        resume: {
            get() {
                return this.$store.state.worker.worker.detail.resume;
            }
        },

        technical_grade: {
            get() {
                return this.$store.state.worker.worker.technical_grade;
            }
        },
    }
}
</script>

<style scoped>
.user {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    border: 3px solid #BB2387;
    position: relative;
}

.profile-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.icon {
    position: absolute;
    top: 5px;
    right: -5px;
    background: #BB2387;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 7px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.icon2 {
    position: absolute;
    top: 50px;
    right: -20px;
    background: #BB2387;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.cropped-image, .webcam-preview {
    width: 400px;
    height: 400px;
}

.webcam-preview {
    background-color: #ddd;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}
</style>

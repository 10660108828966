// import Vue from 'vue';
import axios from 'axios';
import swal from "sweetalert2";
import store from '@/store';

const client = axios.create({
    baseURL: '/api/',
    timeout: 30000
});

client.interceptors.request.use(config => {
    if (store.getters['auth/user'] && store.getters['auth/user'].token) {
        config.headers.common.Authorization = `bearer ${store.getters['auth/user'].token}`;
    }
  
    return config;
  }, error => {
    return Promise.reject(error);
});


client.interceptors.response.use((response) => {
    if (response.headers.token != undefined && response.headers.token != '') {
        let token = response.headers.token;
        store.commit("auth/UPDATE_TOKEN", token);
    }   
    return response;
}, (error) => {

    function sweealert2(data){
        swal.fire({showConfirmButton: false, 
            timer: 3500, 
            timerProgressBar: true, 
            title: 'Atenção!', 
            text: `${data}`, 
            icon: 'error',
            padding: 20
        })
    }

    if (error.response){
        var status = error.response.status;
        var error_data = error.response.data;

        if(status == 401) {
            store.dispatch('auth/logout');
        } else if(status == 400) {
            if(typeof(error_data.error) === 'string'){
                sweealert2(error_data.error);
            }
            else {
                sweealert2("Ocorreu um erro!")
            } 
        } else if (status == 422) {
                if(typeof(error_data) != 'string'){
                    sweealert2(error_data[Object.keys(error_data)[0]]);
                }
        } else if(status == 500) {
            if(typeof(error_data.error) === 'string'){
                sweealert2(error_data.error);
            }
            else {
                sweealert2("Ocorreu um erro!")
            }
        } 
    } else {
        sweealert2("Ocorreu um erro!")
    }
    
    return Promise.reject(error);
});



export default client; 
<template>
    <div>
        <b-breadcrumb v-if="crumbsList" class="p-0 mb-0">
            <b-breadcrumb-item v-for="(crumbs, idx) in crumbsList" :active="crumbs.active" :key="idx" @click="RouteTo(idx)">
                {{ crumbs.name }}
            </b-breadcrumb-item>
        </b-breadcrumb>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crumbsList: null,
        }
    },
    mounted() {
        this.CreateCrumbs();
    },
    watch: {
        '$route' () { 
            this.CreateCrumbs() 
        }
    },
    methods: {
        RouteTo(routeTo) {
            if(this.crumbsList[routeTo].link)
                this.$router.push(this.crumbsList[routeTo].link)
        },
        CreateCrumbs() {
            this.crumbsList = this.$route.meta.breadcrumb
        }
    }
}
</script>
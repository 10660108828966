<template>
    <div>
        <util-bar></util-bar>

        <loader-mary-beleza :show="!loaded"></loader-mary-beleza>
        <div v-if="loaded" class="pr-3 pl-3 pt-3">
            <b-row class="no-print d-flex align-items-stretch">
                <b-col sm="12" md="8" lg="8">
                    <panel title="Filtros" icon="filter">
                        <b-row class="row">
                            <b-col sm="12" md="12" lg="12" class="form-group">
                                <label>Período</label>
                                <v-date-picker v-model="filter_period_date" is-range :model-config="modelConfig">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <b-form-input v-if="inputValue.start || inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                                        <b-form-input v-else v-on="inputEvents.start" />
                                    </template>
                                </v-date-picker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" md="12" lg="12" class="text-center form-group">
                                <b-button variant="outline-mary-beleza" @click="CleanFilters">
                                    <b-icon class="mr-2" icon="funnel" />
                                    <span>Limpar Filtros</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </panel>
                </b-col>

                <b-col sm="12" md="4" lg="4">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <b-card class="card-stats success">
                                <b-row>
                                    <b-col sm="12" md="5" lg="5" class="text-center d-flex align-items-center icon">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <b-icon icon="cash-stack" />
                                        </div>
                                    </b-col>
                                    <b-col sm="12" md="7" lg="7" class="d-flex flex-column text-right text">
                                        <h4>{{balance | currency}}</h4>
                                        <h6>Saldo</h6>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <b-card class="card-stats warning">
                                <b-row>
                                    <b-col sm="12" md="5" lg="5" class="text-center d-flex align-items-center icon">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <b-icon icon="credit-card" />
                                        </div>
                                    </b-col>
                                    <b-col sm="12" md="7" lg="7" class="d-flex flex-column text-right text">
                                        <h4>{{balance_pending | currency}}</h4>
                                        <h6>A Receber</h6>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <panel>
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <rich-table ref="asaasPaymentsRichTable"
                                    v-on:input="ChangeTableConfig"
                                    :numbers="table_numbers"
                                    :loading="table_loading"
                                    :cols="table_cols"
                                    :search-filter="false">
                            <template v-if="items.length > 0">
                                <tr v-for="(item, key) in items" :key="key">
                                    <td>{{item.date | moment("DD/MM/YYYY")}}</td>
                                    <td>{{item.description}}</td>
                                    <td :class="{'text-danger': item.value < 0, 'text-success': item.value >= 0 }">{{item.value | currency}}</td>
                                    <td :class="{'text-danger': item.balance < 0, 'text-success': item.balance >= 0 }">{{item.balance | currency}}</td>
                                </tr>
                            </template>
                            <tr v-else class="odd zero_records">
                                <td :colspan="table_cols.length">Nenhum registro encontrado</td>
                            </tr>
                        </rich-table>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import RichTable from '@/components/RichTable'
import FilterStorage from '@/mixins/FilterStorage'
import TodayVDatePickerHighlighter from '@/mixins/TodayVDatePickerHighlighter'
import moment from 'moment'
import _merge from 'lodash/merge'
import _clone from 'lodash/clone'

export default {
    mixins: [ 
        FilterStorage, 
        TodayVDatePickerHighlighter
    ],
    components: {
        Panel,
        RichTable,
        UtilBar
    },
    computed: {
        filter_period_date: {
            get() {
                return {
                    start: this.filters.period_start,
                    end: this.filters.period_end
                };
            },
            set(value) {
                if ((!value && !this.filters.period_start && !this.filters.period_end) || (this.filters.period_start == value.start && this.filters.period_end == value.end)) {
                    return;
                }

                if (!value) {
                    this.filters.period_start = null;
                    this.filters.period_end = null;
                }
                else {
                    this.filters.period_start = value.start;
                    this.filters.period_end = value.end;
                }

                this.LoadData();
            }
        }
    },
    data() {
        return {
            loaded: false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            table_loading: false,
            filters: {
                period_start: null,
                period_end: null
            },
            table_numbers: {
                size: 10,
                total: 0,
                found: 0,
                footer: null
            },
            table_cols: [
                { text: "Data", sort_order: "asc" },
                { text: 'Descrição' },
                { text: 'Valor' },
                { text: 'Saldo Atual' }
            ],
            table_config: {
                search: '',
                sort_column: "date",
                sort_order: "desc",
                limit: 10,
                offset: 0
            },
            initRichtable: false,
            balance_pending: 0,
            balance: 0,
            items: []
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.SaveFilters(this.filters);
            var params = _clone(_merge(this.filters, this.table_config));
            this.table_loading = true;

            this.$store.dispatch('financial/getAsaasExtract', params).then(data => {
                this.balance = data.balance.current;
                this.balance_pending = data.balance.recipe_pending;
                this.items = data.moviments.items;
                    
                this.$set(this, 'table_numbers', {
                    size: data.moviments.size,
                    found: data.moviments.found,
                    total: data.moviments.total
                });

                this.table_loading = false;
                this.loaded = true;
            }).catch(() => {
                this.table_loading = false;
                this.loaded = true;
            });
        },
        moment(...args){
            return moment(...args);
        },
        ChangeTableConfig(value) {
            this.table_config = value;

            if (!this.initRichtable) {
                this.initRichtable = true;
                return;
            }

            if (value && value.type == "numbers") {
                return;
            }

            this.LoadData();
        },
        CleanFilters() {
            this.$set(this, "filters", {
                period_start: null,
                period_end: null
            });
            
            this.$refs.asaasPaymentsRichTable.CleanFilters();
        }
    }
}
</script>

<style scoped>
    .extract-balances {
        width: 300px;
        float: right;
    }

    .extract-balances >>> .GroupBox .body {
        padding: 20px;
    }
    .extract-balances >>> table {
        width: 100%;
    }
</style>

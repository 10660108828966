<template>
    <div id="customer-media">
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">

                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group text-right">
                        <b-button variant="outline-primary" @click="ClearFilters()"><b-icon class="mr-2" icon="funnel" font-scale="1" /> Limpar Filtros</b-button>
                    </b-col>
                    
                    <b-col sm="12" md="6" lg="6" class="form-group">
                        <label>
                            Regime:
                        </label>
                        <button-group :options="regime_options" v-model="filters.regime"></button-group>
                    </b-col>
                    
                    <b-col sm="12" md="6" lg="6" class="form-group">
                        <label>
                            Data: 
                            <help-icon name="date">
                                Por padrão, ao entrar nessa página, o sistema trás os últimos 30 dias corridos, inclusive hoje.
                            </help-icon>
                        </label>
                        
                        <v-date-picker v-model="filters_period" is-range :model-config="modelConfig">
                            <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input v-if="inputValue.start && inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                                <b-form-input v-else v-on="inputEvents.start" />
                            </template>
                        </v-date-picker>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col sm="12" md="12" lg="12" class="form-group text-center">
                        <b-button variant="primary" class="mr-2" @click="GenerateExtract()"><b-icon class="mr-2" icon="receipt-cutoff" font-scale="1" /> Gerar Relatório</b-button>
                        <b-button variant="outline-primary" @click="$router.push('/financeiro/fluxo-caixa')"><b-icon class="mr-2" icon="bar-chart-steps" font-scale="1" /> Ir para Fluxo de Caixa</b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel v-if="extract && !loadingData" :title="'Dados no Período ' + (last_period ? ('de '+moment(last_period.start).format('DD/MM/YYYY') + ' a ' + moment(last_period.end).format('DD/MM/YYYY')) : 'todo')" icon="calendar-alt" icon-lib="far">
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        
                        <h3 class="mb-3">Resumo Financeiro (Regime de {{ filters.regime == 1 ? 'Competência' : 'Caixa' }})</h3>
                        
                        <b-table-simple class="extract" responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>
                                        Descrição
                                    </b-th>
                                    <b-th>
                                        Realizado
                                    </b-th>
                                    <b-th>
                                        Realizado<br>(Cancelamentos)
                                    </b-th>
                                    <b-th>
                                        A Realizar
                                    </b-th>
                                    <b-th>
                                        A Realizar<br>(Cancelamentos)
                                    </b-th>
                                    <b-th>
                                        Total
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="extract">
                                <b-tr @click="show_recipe = !show_recipe" style="cursor: pointer;">
                                    <b-td>
                                        <span style="font-weight: bold; line-height: 2px; font-size: 20px;">{{!show_recipe ? '+' : '-'}}</span> Receitas de Serviço
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.recipe.completed - extract.summary.schedule.recipe.completed_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.recipe.completed_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.recipe.pending - extract.summary.schedule.recipe.pending_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.recipe.pending_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.recipe.total | currency }}
                                    </b-td>
                                </b-tr>
                                <b-tr v-show="show_recipe">
                                    <b-td colspan="6">
                                        <b-table-simple class="p-2" responsive striped small>
                                            <colgroup>
                                                <col style="width: 40%;"><col>
                                            </colgroup>
                                            <b-tbody>
                                                <div style="display: contents" v-for="(detail, i) in extract.summary.schedule.recipe.details" :key="i">
                                                    <b-tr>
                                                        <b-th colspan="3" class="text-center">{{detail.name}}</b-th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>Serviço</b-td>
                                                        <b-td>Quantidade</b-td>
                                                        <b-td>Valor Recebido</b-td>
                                                    </b-tr>
                                                    <b-tr v-for="(sc, j) in detail.schedules" :key="j">
                                                        <b-td>{{sc.service.name}}</b-td>
                                                        <b-td>{{sc.qtd}}</b-td>
                                                        <b-td>{{sc.completed - sc.completed_cancel | currency}}</b-td>
                                                    </b-tr>
                                                </div>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-td>
                                </b-tr>
                                <b-tr @click="show_expense = !show_expense" style="cursor: pointer;">
                                    <b-td>
                                        <span style="font-weight: bold; line-height: 2px; font-size: 20px;">{{!show_expense ? '+' : '-'}}</span> Despesas de Serviço
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.expense.completed - extract.summary.schedule.expense.completed_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.expense.completed_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.expense.pending - extract.summary.schedule.expense.pending_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.expense.pending_cancel | currency }}
                                    </b-td>
                                    <b-td>
                                        {{ extract.summary.schedule.expense.total | currency }}
                                    </b-td>
                                </b-tr>
                                <b-tr v-show="show_expense">
                                    <b-td colspan="6">
                                        <b-table-simple class="p-2" responsive striped small>
                                            <colgroup>
                                                <col style="width: 40%;"><col>
                                            </colgroup>
                                            <b-tbody>
                                                <div style="display: contents" v-for="(detail, i) in extract.summary.schedule.expense.details" :key="i">
                                                    <b-tr>
                                                        <b-th colspan="3" class="text-center">{{detail.name}}</b-th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>Serviço</b-td>
                                                        <b-td>Quantidade</b-td>
                                                        <b-td>Valor Recebido</b-td>
                                                    </b-tr>
                                                    <b-tr v-for="(sc, j) in detail.schedules" :key="j">
                                                        <b-td>{{sc.service.name}}</b-td>
                                                        <b-td>{{sc.qtd}}</b-td>
                                                        <b-td>{{sc.completed - sc.completed_cancel | currency}}</b-td>
                                                    </b-tr>
                                                </div>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-td>
                                </b-tr>
                                <b-tr class="font-weight-bold">
                                    <b-td>
                                        Lucro Bruto
                                    </b-td>
                                    <b-td :class="(extract.summary.schedule.recipe.completed - extract.summary.schedule.expense.completed) > 0 ? 'text-success' : 'text-danger'">
                                        {{ ((extract.summary.schedule.recipe.completed - extract.summary.schedule.recipe.completed_cancel) - (extract.summary.schedule.expense.completed - extract.summary.schedule.expense.completed_cancel)) | currency }}
                                    </b-td>
                                    <b-td :class="(extract.summary.schedule.recipe.completed - extract.summary.schedule.expense.completed) > 0 ? 'text-success' : 'text-danger'">
                                        {{ (extract.summary.schedule.recipe.completed_cancel - extract.summary.schedule.expense.completed_cancel) | currency }}
                                    </b-td>
                                    <b-td :class="(extract.summary.schedule.recipe.pending - extract.summary.schedule.expense.pending) > 0 ? 'text-success' : 'text-danger'">
                                        {{ ((extract.summary.schedule.recipe.pending - extract.summary.schedule.recipe.pending_cancel) - (extract.summary.schedule.expense.pending - extract.summary.schedule.expense.pending_cancel)) | currency }}
                                    </b-td>
                                    <b-td :class="(extract.summary.schedule.recipe.pending - extract.summary.schedule.expense.pending) > 0 ? 'text-success' : 'text-danger'">
                                        {{ (extract.summary.schedule.recipe.pending_cancel - extract.summary.schedule.expense.pending_cancel) | currency }}
                                    </b-td>
                                    <b-td :class="(extract.summary.schedule.recipe.total - extract.summary.schedule.expense.total) > 0 ? 'text-success' : 'text-danger'">
                                        {{ (extract.summary.schedule.recipe.total - extract.summary.schedule.expense.total) | currency }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td colspan="4" class="text-center"><small>Nenhum Resultado Encontrado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        
                        <h5 class="mb-3">Serviços Realizados</h5>
                        
                        <b-table-simple responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr class="text-center">
                                    <b-th>
                                        Total
                                    </b-th>
                                    <b-th>
                                        <img :src="img_confirmed" width="30" v-b-tooltip.hover title="Serviços Realizados" />
                                    </b-th>
                                    <b-th>
                                        <img :src="img_notconfirmedworker" width="30" v-b-tooltip.hover title="Serviços sem Profissional" />
                                    </b-th>
                                    <b-th>
                                        A Realizar
                                    </b-th>
                                    <b-th>
                                        Total Recebido
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="extract">
                                <b-tr class="text-center">
                                    <td>{{ extract.summary.schedule.quantifiers.total }}</td>
                                    <td>{{ extract.summary.schedule.quantifiers.completed }}</td>
                                    <td>{{ extract.summary.schedule.quantifiers.without_worker }}</td>
                                    <td>{{ extract.summary.schedule.quantifiers.pending }}</td>
                                    <td>{{ extract.summary.schedule.recipe.completed | currency }}</td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td colspan="5" class="text-center"><small>Nenhum Resultado Encontrado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </panel>
            <div class="text-center" v-else>
                <b-spinner label="Spinning"></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import moment from 'moment'
import ButtonGroup from '@/components/ButtonGroup';
import HelpIcon from '@/components/HelpIcon';
import UtilBar from '@/components/UtilBar';
import ImageLoader from '@/mixins/ImageLoader'

export default {
    mixins: [ ImageLoader ],
    data() {
        return {
            show: true,
            show_recipe: false,
            show_expense: false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            loadingData: false,
            regime_options: [
                { id: 1, text: "Competência" },
                { id: 2, text: "Caixa" }
            ],
            filters: {
                regime: 1,
                period: {
                    start: moment().format("YYYY-MM-DD"),
                    end: moment().add(30, 'days').format("YYYY-MM-DD")
                }
            },
            last_period: null,
            extract: null
        }
    },
    computed: {
        filters_period: {
            get() {
                var value = this.filters.period && this.filters.period.start && this.filters.period.end ? this.filters.period : {
                    start: moment().format("YYYY-MM-DD"),
                    end: moment().add(30, 'days').format("YYYY-MM-DD")
                };
                
                return value;
            },
            set(value) {
                value = value && value.start && value.end ? value : {
                    start: moment().format("YYYY-MM-DD"),
                    end: moment().add(30, 'days').format("YYYY-MM-DD")
                };
                this.filters.period = value;
            }
        }
    },
    components: {
        Panel, ButtonGroup, HelpIcon, UtilBar
    },
    created() {
        this.LoadData();
    },
    methods: {
        moment(...args){
            return moment(...args);
        },
        LoadData() {
            this.GenerateExtract();
        },
        GenerateExtract() {
            this.loadingData = true;
            this.$store.dispatch('financial/getExtract', {
                method: this.filters.regime == 1 ? "co" : "ca",
                period_start: this.filters.period ? this.filters.period.start : null,
                period_end: this.filters.period ? this.filters.period.end : null
            }).then((response) => {
                this.last_period = this.filters.period && this.filters.period.start && this.filters.period.end ? this.filters.period : null;
                this.extract = response;
                this.loadingData = false;
            }).catch(() => {
                this.loadingData = false;
            });
        },
        ClearFilters() {
            this.filters = {
                regime: 1,
                date: {
                    start: new Date(moment()),
                    end: new Date(moment().add(30, 'days'))
                }
            }
        }
    }
}
</script>

<style scoped>
    table thead * {
        vertical-align: top !important;
    }
</style>
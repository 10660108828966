<template>
    <b-row>
        <b-col sm="12" :md="enableOthers ? '8' : '12'" :lg="enableOthers ? '8' : '12'" class="btn-group w-100">
            <button :disabled="readonly" :style="{backgroundColor: option.color ? option.color : ''}" :class="[selected == option.id ? 'btn-primary' : 'btn-outline-primary']" @click="Select(option.id)" v-for="(option, index) in options" :key="index" type="button" class="btn"><b-icon v-if="option.icon" :icon="option.icon" class="mr-2" />{{option.text}}</button>
        </b-col>
        <b-col sm="12" md="4" lg="4" v-if="enableOthers">
            <b-form-input :readonly="readonly" v-model="otherValue" aria-describedby="input-live-help input-live-feedback" @keyup="SetOtherValue" placeholder="Informe outro valor"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
                Mostra erros
            </b-form-invalid-feedback>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'ButtonGroup',
        props: {
            options: {
                type: Array,
                default: function() {
                    return [
                        { id: null, text: "Todos" },
                        { id: 1, text: "Sim" },
                        { id: 0, text: "Não" },
                    ]
                }
            },
            rounded: {
                type: Boolean,
                default: false
            },
            value: {
                default: -1
            },
            others: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selected: null,
                enableOthers: false,
                otherValue: ''
            }
        },
        methods: {
            Select(id) {
                if (this.readonly) {
                    return;
                }

                if(id == -2) {
                    this.enableOthers = true;
                    this.otherValue = this.value;
                } else {
                    this.enableOthers = false;
                }

                this.selected = id;

                this.$emit('input', this.selected);
            },
            SetOtherValue() {
                if (this.readonly) {
                    return;
                }

                this.$emit('input', this.otherValue);
            }
        },
        watch: {
            'value': function(to){
                if(typeof this.value != 'number' && this.others) {
                    this.selected = -2
                }else{
                    this.selected = to
                }
            }
        },
        created() {
            this.selected = this.value

            if(!parseInt(this.value) && this.others) {
                this.enableOthers = true;
                this.otherValue = this.value;
                this.selected = -2
            }
        }
    }
</script>

<style scoped>
</style>
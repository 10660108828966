<template>
    <div>
        <panel title="RESTRIÇÃO" icon="address-card" icon-lib="far">
            <b-row class="form-group">
                <b-col sm="12" md="4" lg="7">
                    <div role="group">
                        <label>
                            Profissional em restrição: 
                            <b-icon icon="patch-question-fll" class="text-mary-beleza" id="restriction_customer"></b-icon>
                        </label>
                        
                        <b-popover target="restriction_customer" triggers="hover" placement="rightbottom">
                            <template #title>AJUDA</template>
                            <div class="text-center">
                                Este campo serve para marcar um profissional em restrição, descrevendo que este teve algum problema durante 
                                algum serviço prestado. Por exemplo, aqueles casos em que a profissional tenta contratar o
                                cliente "por fora", sem ter mais contato com a Mary Beleza.
                            </div>
                        </b-popover>
                        <template v-if="OpenForm.restriction">
                            <b-form-checkbox v-model="restriction" switch size="lg"><small>{{!restriction ? 'Não' : 'Sim' }}</small></b-form-checkbox>
                            <b-form-invalid-feedback :state="!errors.restriction">
                                <div v-for="error in errors.restriction" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </template>
                        <template v-else>
                            <p>
                                <b v-if="restriction == null" class="text-danger">-</b>
                                <b-badge v-else-if="restriction" variant="success">Sim</b-badge>
                                <b-badge v-else variant="danger">Não</b-badge>
                            </p>
                        </template>
                        
                    </div>
                </b-col>
                <b-col sm="12" md="4" lg="5" v-if="restriction">
                    <div role="group">
                        <label> Gravidade: </label>   
                        <template v-if="OpenForm.restriction">                     
                            <b-form-select v-model="restriction_level" :options="restriction_level_options">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                </template>
                            </b-form-select>
                            <b-form-invalid-feedback :state="!errors.restriction_level">
                                <div v-for="error in errors.restriction_level" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </template>
                        <template v-else>
                            <p v-if="!restriction_level">
                                <b class="text-danger">-</b>
                            </p>
                            <p v-else>{{ restriction_level_name(restriction_level) }}</p>
                        </template>
                    </div>
                </b-col>
            </b-row>
            <b-row class="form-group" v-if="restriction">
                <b-col sm="12" md="12" lg="12">
                    <div role="group">
                        <label>
                            Motivo/Observações: 
                        </label> 
                        <template v-if="OpenForm.restriction">                      
                            <b-form-textarea ref="restrict_reason" v-model="restrict_reason" placeholder="Descreva o motivo/observações do problema" rows="3" max-rows="6" ></b-form-textarea>
                            <b-form-invalid-feedback :state="!errors.restrict_reason">
                                    <div v-for="error in errors.restrict_reason" :key="error">
                                        {{ error }}
                                    </div>
                            </b-form-invalid-feedback>
                        </template>
                        <template v-else>
                            <p v-if="!restrict_reason">
                                <b class="text-danger">-</b>
                            </p>
                            <p v-else>{{ restrict_reason }}</p>
                        </template>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="IsUpdate && has_permission('worker-edit')">
                <b-col sm="12" v-if="!IsStage">
                    <b-button v-if="!OpenForm.restriction" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'restriction')">
                        <fa icon="pen" class="mr-2" /> Editar
                    </b-button>
                    <template v-else>
                        <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                            <fa icon="ban" class="mr-2" /> Cancelar
                        </b-button>
                        <b-button variant="mary-beleza" size="sm" @click="update()">
                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                        </b-button>
                    </template>
                </b-col>
            </b-row>
        </panel>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import { mapGetters } from 'vuex'
import Constants from '@/constants'

export default {

    components: {
        Panel,
    },

    data() {
        return {
            restriction_level_options: [
                { value: 1, text: 'Leve' },
                { value: 2, text: 'Média' },
                { value: 3, text: 'Grave'}
            ],
        }

    },

    methods: {

        update() {
            this.$store.dispatch('worker/updateWorker').then(response => {
                if (this.IsStage) {
                    this.$store.commit('worker/STAGE_NEXT')
                }
                this.sweealert2('success', response.success)
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        restriction_level_name(id){
            var restriction_level = this.restriction_level_options.find(item => item.value == id)
            return restriction_level?.text
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },
    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        restriction: {
            get() {
                return this.$store.state.worker.worker.person.restriction;
            },
            set(value) {
                this.restriction_level = null;
                this.restrict_reason = null;
                this.$store.commit('worker/SET_RESTRICTION', value)
            }
        },

        restriction_level: {
            get() {
                return this.$store.state.worker.worker.person.restriction_level;
            },
            set(value) {
                this.$store.commit('worker/SET_RESTRICTION_LEVEL', value)
            }
        },

        restrict_reason: {
            get() {
                return this.$store.state.worker.worker.person.restrict_reason;
            },
            set(value) {
                this.$store.commit('worker/SET_RESTRICT_REASON', value)
            }
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            },
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    }
}
</script>

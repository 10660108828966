<template>
  <div>
    <b-row>
      <b-col>
        <fa class="profile-quality-rules" v-b-modal.profile-quality-rules-content v-b-tooltip.hover title="Visualizar critérios de bonificação de profissionais" icon="question-circle"></fa>
      </b-col>
    </b-row>
    <b-modal id="profile-quality-rules-content" ok-only ok-title="Fechar" class="profile-quality-rules-content" size="big" title="Critérios de bonificação de profissionais">
        <b-overlay :show="!loaded && rules">
          <div v-for="(medal, i) in medals" :key="i">
            <b-row align-v="center">
                <b-col md="2" class="text-center">
                    <medal :type='medal' size="md">Prata</medal>
                </b-col>
                <b-col md="10">
                    <p>{{rules[medal] && rules[medal].description}}</p>
                </b-col>
            </b-row>
            <hr>
          </div>
          <br>
          <br>
          <p style="white-space: initial; font-size: 12px; color: #555;">Os profissionais que estão ganhando prêmio em dinheiro por terem sido chancelados com bônus devem ser exemplo de operação. 
            Caso estes não se comportem dessa maneira, perderão direito ao prêmio relativo àquele dia em específico.</p>
        </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import Medal from '@/components/Medal'

export default {
  components: {
    Medal
  },
  data() {
    return {
      loaded: false,
      rules: [],
      medals: [
        'bronze',
        'silver',
        'gold',
        'ruby'
      ]
    }
  },
  created() {
    this.LoadData();
  },
  methods: {
    LoadData() {
      this.loaded = false;
      this.$store.dispatch("worker/bonusDetails").then((response) => {
        this.loaded = true;
        this.rules = response.rules;
      });
    }
  }
}
</script>

<style scoped>
  @keyframes attention-profile-quality-rules {
    0% { transform: rotate(0); }
    10% { transform: rotate(30deg); }
    20% { transform: rotate(0); }
    80% { transform: rotate(0); }
    90% { transform: rotate(-30deg); }
    100% { transform: rotate(0); color: #92003b; }
  }

  .profile-quality-rules {
      cursor: pointer;
      float: right;
      font-size: 30px;
      color: #EF62A3;
      margin-bottom: 8px;
      animation: attention-ranking-rules 1s 1s both infinite;
      -webkit-animation: attention-profile-quality-rules 1s 1s both infinite;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
  }

  .profile-quality-rules:focus {
    outline: none;
  }

  .profile-quality-rules-content {
      text-align: justify;
  }

  .profile-quality-rules-content h4 {
    margin-bottom: 30px;
  }

  .profile-quality-rules-content ul {
    margin: 0;
    padding: 0 30px;
  }

  .profile-quality-rules-content li {
      margin: 10px 0;
  }
</style>

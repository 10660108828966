import Http from '@/api/http'

const state = {
    cities: []
};

const getters = {
    getCitieName: (state) => (name) => {
        return state.cities.find(citie => citie.name === name)
    },
};

const actions = {
    getCities({ commit }, state_id) {
        return new Promise((resolve, reject) => {
            Http.get('state/'+ state_id +'/city').then(response => {
                commit('SET_CITIES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getCitiesByCustomers({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('address/customers/city').then(response => {
                commit('SET_CITIES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

const mutations = {
    SET_CITIES(state, cities) {
        state.cities = cities;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


<template>
    <div>
        <div class="panel">
            <div v-if="title" class="panel-heading bg-white d-flex justify-content-between align-items-center" :class="[visible ? 'panel-heading-visible' : '']" :aria-controls="`panel-collapse-${uid}`" @click="visible = !visible">
                <h3 class="panel-title">
                    <fa :icon="[iconLib, icon]" class="mr-3" /> {{title}}
                </h3>
                
                <span>
                    <fa icon="angle-down" class="direction" />
                    <!-- <fa v-else icon="angle-up" /> -->
                </span>
            </div>

            <b-collapse v-model="visible" :id="`panel-collapse-${uid}`" class="m-0">
                <div class="panel-body">
                    <slot></slot>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                uid: null,
                visible: false
            }
        },
        props: {
            iconLib: {
                type: String,
                default: 'fa'
            },
            icon: {
                type: String,
                default: 'columns'
            },
            title: {
                type: String,
                default: null
            },
            startCollapsed: {
                default: false,
                type: Boolean
            }
        },
        created() {
            this.visible = !this.startCollapsed;
            this.uid = Math.floor(Math.random() * 101);
        },
    }
</script>


<style lang="scss" scoped>
</style>
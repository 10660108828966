<template>
<div>
    <panel title="ENDEREÇO" icon="map-marked-alt">
        <b-row class="form-group">
            <b-col sm="12" md="12" lg="12">

                <b-overlay :show="search_zipcode" rounded="sm">
                    <b-row class="form-group">
                        <b-col sm="12" md="12" lg="12">
                            <b-row class="form-group">
                                <b-col sm="12" md="12" lg="12">
                                    <div role="group">
                                        <label class="required">CEP:</label>
                                        <the-mask :mask="'#####-###'" class="form-control" v-model="zipcode" @blur.native="SearchZipcode()" placeholder="CEP" />
                                        <b-form-invalid-feedback :state="!errors.zipcode">
                                            <div v-for="error in errors.zipcode" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="form-group">
                                <b-col sm="12" md="5" lg="5">
                                    <div role="group">
                                        <label class="required">Logradouro:</label>
                                        <b-form-input v-model="street" placeholder="Logradouro"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.street">
                                            <div v-for="error in errors.street" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="3" lg="3">
                                    <div role="group">
                                        <label class="required">Número:</label>
                                        <b-form-input v-model="number" placeholder="Número"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.number">
                                            <div v-for="error in errors.number" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="4" lg="4">
                                    <div role="group">
                                        <label>Complemento:</label>
                                        <b-form-input v-model="complement" placeholder="Complemento"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="form-group">
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label class="required">Bairro:</label>
                                        <b-form-input v-model="district" placeholder="Bairro"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.district">
                                            <div v-for="error in errors.district" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label>Zona:</label>
                                        <b-form-select v-model="zone" :options="zone_options">
                                            <template #first>
                                                <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback :state="!errors.zone">
                                            <div v-for="error in errors.zone" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="form-group">
                                <b-col sm="12" md="12" lg="12">
                                    <div role="group">
                                        <label>Ponto de Referência:</label>
                                        <b-form-input v-model="reference" placeholder="Ponto de Referência"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="form-group">
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label class="required">Estado:</label>
                                        <b-form-select v-model="state" :options="states"></b-form-select>
                                        <b-form-invalid-feedback :state="!errors.state">
                                            <div v-for="error in errors.state" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                                
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label class="required">Cidade:</label>
                                        <b-form-select :disabled="!state" v-model="city_id" :options="cities">
                                            <template #first>
                                                <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback :state="!errors.city_id">
                                            <div v-for="error in errors.city_id" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import Http from '@/api/http';

export default {

    components: {
        Panel,
    },
    props: {
        validateRegion: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {            
            zone_options: [
                {
                    text: 'Centro',
                    value: 0
                },
                {
                    text: 'Norte',
                    value: 1
                },
                {
                    text: 'Sul',
                    value: 2
                }, {
                    text: 'Leste',
                    value: 3
                },
                {
                    text: 'Oeste',
                    value: 4
                }
            ],
            search_zipcode: false,
            states: [],
            cities: []
        }
    },
    mounted() {
        this.$store.dispatch('states/getStates').then(data => {
            data.forEach(state => {
                this.states = [...this.states, {
                    text: state.name,
                    value: state.id
                }]
            });

            this.states.unshift({text: 'Selecione', value: null})
            
            if(this.state){
                this.getCities(this.state)
            }
        });
    },

    methods: {
        getCities(state_id) {
            this.$store.dispatch('cities/getCities', state_id).then(data => {
                this.cities = [];
                data.forEach(citie => {
                    this.cities = [...this.cities, {
                        text: citie.name,
                        value: citie.id
                    }]
                });
            });

        },

        SearchZipcode() {
            if(this.zipcode){
                this.search_zipcode = true
                var zipcode = this.zipcode.replace(/[^\d]+/g,'');
                
                Http.get(`/zipcode/${zipcode}`, { params: { validate_region: this.validateRegion } }).then(response => {
                    this.state = response.data.state_id
                    this.street = response.data.street
                    this.complement = response.data.complement
                    this.district = response.data.district
                    this.city_id = response.data.city_id
                    this.city_name = response.data.city_name
                    this.search_zipcode = false
                }).catch(() => {
                    this.search_zipcode = false
                });
            }
        },
    },

    computed: {

        state: {
            get() {
                return this.$store.state.employee.employee.person.address.city.state.id;
            },
            set(value) {
                if(value != null){
                    this.getCities(value);
                }
                this.$store.commit('employee/SET_STATE_ID', value)
            }
        },

        zipcode: {
            get() {
                return this.$store.state.employee.employee.person.address.zipcode;
            },
            set(value) {
                this.$store.commit('employee/SET_ZIPCODE', value)
            }
        },

        street: {
            get() {
                return this.$store.state.employee.employee.person.address.street;
            },
            set(value) {
                this.$store.commit('employee/SET_STREET', value)
            }
        },

        number: {
            get() {
                return this.$store.state.employee.employee.person.address.number;
            },
            set(value) {
                this.$store.commit('employee/SET_NUMBER', value)
            }
        },

        complement: {
            get() {
                return this.$store.state.employee.employee.person.address.complement;
            },
            set(value) {
                this.$store.commit('employee/SET_COMPLEMENT', value)
            }
        },

        reference: {
            get() {
                return this.$store.state.employee.employee.person.address.reference;
            },
            set(value) {
                this.$store.commit('employee/SET_REFERENCE', value)
            }
        },

        zone: {
            get() {
                return this.$store.state.employee.employee.person.address.zone;
            },
            set(value) {
                this.$store.commit('employee/SET_ZONE', value)
            }
        },
        
        district: {
            get() {
                return this.$store.state.employee.employee.person.address.district;
            },
            set(value) {
                this.$store.commit('employee/SET_DISTRICT', value)
            }
        },

        city_id: {
            get() {
                return this.$store.state.employee.employee.person.address.city_id;
            },
            set(value) {
                this.$store.commit('employee/SET_CITY_ID', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.employee.errors;
            },
        },
    }

}
</script>

<style>

</style>

import moment from 'moment'
export default {
    created() {
        this.LoadStorageFilters();
    },
    data() {
        return {
            filters_loaded: false
        }
    },
    computed: {
        storage_name() {
            return (this.$route.meta.shared_filter_storage ? this.$route.meta.shared_filter_storage : (this.$route.fullPath.endsWith("/") ? this.$route.fullPath.substring(0, this.$route.fullPath.length - 1) : this.$route.fullPath)) + "/_filters"
        }
    },
    methods: {
        LoadStorageFilters(storage_name = null) {
            if(localStorage.getItem(storage_name ? storage_name : this.storage_name)){
                this.filters_loaded = true
                var filters = JSON.parse(localStorage.getItem(storage_name ? storage_name : this.storage_name));
                Object.keys(filters).map(f => {
                    if(filters[f] instanceof Object && filters[f].start && filters[f].end){
                        filters[f].start = moment(filters[f].start).toDate();
                        filters[f].end = moment(filters[f].end).toDate();
                    }
                    else if(typeof filters[f] === "string" && /^\s*\d{4}\s*-\s*\d{2}\s*$/.test(filters[f])) {
                        // Do nothing
                    }
                    else if(typeof filters[f] === "string" && moment(filters[f]).isValid()){
                        filters[f] = moment(filters[f]).toDate();
                    }
                    return {f: filters[f]};
                })
                this.filters = filters;
            }
        },
        SaveFilters(filters, storage_name = null) {
            filters = JSON.stringify(filters)
            localStorage.setItem(storage_name ? storage_name : this.storage_name, filters)
        }
    }
}
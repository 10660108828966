<template>
    <!-- <div class="popup" :class="{'up': up, 'left': left}">
        <fa v-if="animation == 'spin'" :icon="icon" :size="size" @mouseover="show_title = true" @mouseout="show_title = false" :style="{'color': color}" spin />
        <fa v-else-if="animation == 'pulse'" :icon="icon" :size="size" @mouseover="show_title = true" @mouseout="show_title = false" :style="{'color': color}" pulse />
        <fa v-else :icon="icon" :size="size" @mouseover="show_title = true" @mouseout="show_title = false" :style="{'color': color}" />
        <p v-if="show_title" :style="{'background-color': color}"><slot></slot></p>
    </div> -->

    <div class="d-inline">
        <b-icon :icon="icon" :class="colorClass" :id="'help-icon-' + name" :scale="scale"></b-icon>

        <b-popover :target="'help-icon-' + name" triggers="hover" :placement="placement">
            <template #title>{{ title }}</template>
            <div class="text-center">
                <slot></slot>
            </div>
        </b-popover>
    </div>
</template>


<script>

export default {
    computed: {
    },
    data() {
        return {
            show_title: false
        }
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
            default: 'patch-question-fill'
        },
        placement: {
            type: String,
            default: 'top'
        },
        colorClass: {
            type: String,
            required: false,
            default: 'text-mary-beleza'
        },
        title: {
            type: String,
            required: false,
            default: 'AJUDA'
        },
        scale: {
            type: String,
            required: false,
            default: '1'
        },
        // up: {
        //     type: Boolean,
        //     default: false
        // },
        // left: {
        //     type: Boolean,
        //     default: false
        // },
        // animation: {
        //     type: String,
        //     required: false,
        //     default: ''
        // },
    }
}

</script>


<style scoped>
    .icon {
        color: purple;
        vertical-align: middle;;
    }
    .popup {
        display: inline;
        position: relative;
        cursor: pointer;
    }
    .popup p {
        color: white;
        padding: 4px 7px;
        position: absolute;
        width: 300px;
        z-index: 999;
        left: 0;
        text-align: justify;
        line-height: 18px;
    }

    .popup.up p {
        bottom: 5px;
    }

    .popup.left p {
        left: -305px;
    }
</style>
<template>
<div>
    <panel title="ACESSO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label class="required">Email:</label>
                            <b-form-input v-model="email" type="email" aria-describedby="input-live-help input-live-feedback" placeholder="Email"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.email">
                                <div v-for="error in errors.email" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label class="required">Senha:</label>
                            <b-input-group>
                                <b-form-input v-model="password" :type="password_eye ? 'text': 'password'" aria-describedby="input-live-help input-live-feedback" placeholder="****" trim></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-secondary" @click="passwordVisibility">
                                        <b-icon variant="dark" :icon="password_eye ? 'eye-slash': 'eye'" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-invalid-feedback :state="!errors.password">
                                <div v-for="error in errors.password" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"

export default {

    components: {
        Panel,
    },

    data() {
        return {
            password_eye: false,
        }
    },

    methods: {
        passwordVisibility() {
            this.password_eye = !this.password_eye;
        },
    },

    computed: {

        email: {
            get() {
                return this.$store.state.employee.employee.person.email;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_EMAIL', value)
            }
        },

        password: {
            get() {
                return this.$store.state.employee.employee.person.password;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_PASSWORD', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.employee.errors;
            },
        },
    }

}
</script>

<style>

</style>

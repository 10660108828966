<template>
<div>
    <panel title="INFORMAÇÕES PESSOAIS" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col sm="12" md="8" lg="8">
                        <div role="group">
                            <label class="required">Nome:</label>
                            <b-form-input v-model="name" placeholder="Nome"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.name">
                                <div v-for="error in errors.name" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>

                    <b-col sm="12" md="4" lg="4">
                        <div role="group">
                            <label>Data de Aniversário:</label>
                            <typeable-date placeholder="dd/mm/aaaa" v-model="birth"/>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <div role="group">
                            <label class="required">CPF:</label>
                            <the-mask :mask="'###.###.###-##'" class="form-control" v-model="national_document" placeholder="CPF" maxlength="14" />
                            <b-form-invalid-feedback :state="!errors.national_document">
                                <div v-for="error in errors.national_document" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>

                    <b-col sm="12" md="4" lg="4">
                        <div role="group">
                            <label>RG:</label>
                            <b-form-input v-model="state_document" placeholder="RG" maxlength="14"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.state_document">
                                <div v-for="error in errors.state_document" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>

                    <b-col sm="12" md="4" lg="4">
                        <div role="group">
                            <label class="required">Sexo:</label>
                            <b-form-radio-group v-model="gender" button-variant="outline-primary" name="radio-btn-outline">
                                <b-form-radio value="female">Feminino</b-form-radio>
                                <b-form-radio value="male">Masculino</b-form-radio>
                            </b-form-radio-group>
                            <b-form-invalid-feedback :state="!errors.gender">
                                <div v-for="error in errors.gender" :key="error">
                                    {{ error }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <b-button :disabled="new_phone || edit_index != null" variant="primary" size="sm" class="mb-2" @click="AddPhones()">
                            <fa icon="plus-circle" class="mr-2" /> Adicionar Telefone
                        </b-button>
                        
                        <b-table-simple responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th class="text-center"><label class="required">Telefone</label></b-th>
                                    <b-th class="text-center"><label>Descrição</label></b-th>
                                    <b-th class="text-center"><label>Pessoal</label></b-th>
                                    <b-th class="text-center"><label>Whatsapp</label></b-th>
                                    <b-th class="text-center"></b-th>
                                    <b-th class="text-center"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="phones && phones.length">
                                <b-tr v-for="(phone, index) in phones" :key="index">
                                    <b-td>
                                        <template v-if="index != edit_index && IsUpdate">
                                            <p>{{ phone.phone_number | formatPhones }}</p>
                                        </template>
                                        <template v-else>
                                            <the-mask :mask="['(##) #####-####', '(##) ####-####']" class="form-control" v-bind:class="[errors[index+'.phone_number'] ? 'is-invalid' : '']" v-model="phone.phone_number" @input="UpdateNumber($event, index)" placeholder="Informe um Telefone" />
                                        </template>
                                    </b-td>
                                    <b-td>
                                        <template v-if="index != edit_index && IsUpdate">
                                            <p>{{ phone.description }}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="phone.description" :state="errors[index+'.description'] ? false : null" @input="UpdateDescription($event, index)" placeholder="Descrição" maxlength="99" />
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="index != edit_index && IsUpdate">
                                            <b v-if="phone.is_personal == null" class="text-danger">-</b>
                                            <b-badge v-else-if="phone.is_personal" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                        <template v-else>
                                            <b-form-checkbox v-model="phone.is_personal" :state="errors[index+'.is_personal'] ? false : null" size="lg" name="is_personal" switch @change="CheckedIsPersonal(phone, index)"></b-form-checkbox>
                                        </template>
                                    </b-td>

                                    <b-td class="align-middle text-center">
                                        <template v-if="index != edit_index && IsUpdate">
                                            <b v-if="phone.is_whatsapp == null" class="text-danger">-</b>
                                            <b-badge v-else-if="phone.is_whatsapp" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                        <template v-else>
                                            <b-form-checkbox v-model="phone.is_whatsapp" :state="errors[index+'.is_whatsapp'] ? false : null" size="lg" name="is_whatsapp" switch @change="CheckedIsWhatsapp(phone, index)"></b-form-checkbox>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="IsUpdate">
                                            <b-button v-if="index === edit_index" class="btn btn-success" size="sm" @click="new_phone ? SavePhone(index) : UpdatePhone(index)">
                                                <b-icon icon="check2" font-scale="1" />
                                            </b-button>
                                            <b-button v-else variant="primary" size="sm" @click="EditPhone(index)" :disabled="new_phone || edit_index != null">
                                                <b-icon icon="pen" font-scale="1" />
                                            </b-button>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <b-button v-if="index === edit_index" variant="danger" size="sm" @click="new_phone ? RemovePhone(index) : CancelPhone(index)">
                                            <b-icon icon="x-circle" font-scale="1" />
                                        </b-button>
                                        <b-button v-else variant="danger" size="sm" @click="RemovePhone(index)" :disabled="new_phone || edit_index != null">
                                            <b-icon icon="trash" font-scale="1" />
                                        </b-button>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td colspan="4" class="text-center"><small>Nenhum Telefone Adicionado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import TypeableDate from '@/components/TypeableDate'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        Panel,
        TypeableDate
    },
    data() {
        return {
            edit_index: null, // Referencia o INDEX que está em edição, para liberar os inputs.
            new_phone: false, // Indica se está sendo criado um novo, se sim ele desabilita alguns botões.
            phoneDb: null
        }
    },
    methods: {
        AddPhones() {
            this.$store.commit('employee/SET_PHONE_ADD')

            if (this.IsUpdate) {
                this.edit_index = this.phones.length - 1
                this.new_phone = true
            }
        },
        SavePhone(index) {
            this.$store.dispatch('employee/createPhone', index).then(response => {

                this.new_phone = false
                this.edit_index = null

                this.sweealert2('success', response.success)

            }).catch(error => {
                this.sweealert2('error', error.response.data.error[Object.keys(error)[0]])
            });
        },
        UpdateNumber(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('employee/SET_PHONE_NUMBER', data)
        },
        UpdateDescription(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('employee/SET_PHONE_DESCRIPTION', data)
        },
        RemovePhone(index) {
            /* IF para ver se é edição
            Se sim ele deixa o 'new_phone' como falso 
            e adiciona o valor null para o 'edit_index' */
            if (this.IsUpdate && !this.new_phone) {
                this.$swal({
                    title: 'Excluir Telefone',
                    html: `Deseja realmente excluir o telefone?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('employee/deletePhone', index).then(response => {
                            this.sweealert2('success', response.success)
                        }).catch(error => {
                            this.sweealert2('error', error.response.data)
                        });
                    }
                })
            } else {
                this.$store.commit("employee/SET_PHONE_DELETE", index);
            }

            this.new_phone = false
            this.edit_index = null

        },
        EditPhone(index) {
            this.phoneDb = _cloneDeep(this.phones[index])
            this.edit_index = index
        },
        CancelPhone(index) {
            this.edit_index = null
            this.phones[index] = this.phoneDb
        },
        UpdatePhone(index) {
            this.$store.dispatch('employee/updatePhone', index).then(response => {

                this.edit_index = null

                this.sweealert2('success', response.success)

            }).catch(error => {
                this.sweealert2('error', error.response.data.error[Object.keys(error)[0]])
            });
        },
        CheckedIsPersonal(phone, index) {
            var data = {
                phone,
                index
            }
            this.$store.commit('employee/SET_PHONE_IS_PERSONAL', data)
        },
        CheckedIsWhatsapp(phone, index) {
            var data = {
                phone,
                index
            }
            this.$store.commit('employee/SET_PHONE_IS_WHATSAPP', data)
        },
        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        }
    },
    computed: {
        ...mapGetters('employee', [
            'IsUpdate'
        ]),

        name: {
            get() {
                return this.$store.state.employee.employee.person.name;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_NAME', value)
            }
        },

        birth: {
            get() {
                return this.$store.state.employee.employee.person.birth;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_BIRTH', value)
            }
        },

        national_document: {
            get() {
                return this.$store.state.employee.employee.person.national_document;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_NATIONAL_DOCUMENT', value)
            }
        },

        state_document: {
            get() {
                return this.$store.state.employee.employee.person.state_document;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_STATE_DOCUMENT', value)
            }
        },

        gender: {
            get() {
                return this.$store.state.employee.employee.person.gender;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_GENDER', value)
            }
        },

        phones: {
            get() {
                return this.$store.state.employee.employee.person.phones;
            }
        },

        errors: {
            get() {
                return this.$store.state.employee.errors;
            },
        },
    }
}
</script>


import { render, staticRenderFns } from "./ButtonGroup.vue?vue&type=template&id=20b755d9&scoped=true&"
import script from "./ButtonGroup.vue?vue&type=script&lang=js&"
export * from "./ButtonGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b755d9",
  null
  
)

export default component.exports
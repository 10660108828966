<template>
<div>
    <panel title="CONTATO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col md="12">
                        <b-button v-if="has_permission('worker-edit')" :disabled="new_phone || edit_index != null" variant="primary" size="sm" class="mb-2" @click="AddPhones()">
                            <fa icon="plus-circle" class="mr-2" /> Adicionar Telefone
                        </b-button>
                        <b-table-simple responsive striped small hover>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th class="text-center"><label>Telefone</label></b-th>
                                    <b-th class="text-center"><label>Descrição</label></b-th>
                                    <b-th class="text-center"><label>Pessoal</label></b-th>
                                    <b-th class="text-center"><label>Whatsapp</label></b-th>
                                    <b-th class="text-center" v-if="has_permission('worker-edit')"></b-th>
                                    <b-th class="text-center" v-if="has_permission('worker-edit') && phones.length > 0"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="phones && phones.length">
                                <b-tr v-for="(phone, index) in phones" :key="index">
                                    <b-td>
                                        <template v-if="index != edit_index && IsUpdate">
                                            <p>{{ phone.phone_number | formatPhones }}</p>
                                        </template>
                                        <template v-else>
                                            <the-mask :mask="['(##) #####-####', '(##) ####-####']" class="form-control" v-bind:class="[errors[index+'.phone_number'] ? 'is-invalid' : '']" v-model="phone.phone_number" @input="UpdateNumber($event, index)" placeholder="Informe um Telefone" />
                                        </template>
                                    </b-td>
                                    <b-td>
                                        <template v-if="index != edit_index && IsUpdate">
                                            <p v-if="!phone.description">
                                                -
                                            </p>
                                            <p v-else>{{ phone.description }}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="phone.description" :state="errors[index+'.description'] ? false : null" @input="UpdateDescription($event, index)" placeholder="Descrição" maxlength="99" />
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="index != edit_index && IsUpdate">
                                            <span v-if="phone.is_personal == null">-</span>
                                            <b-badge v-else-if="phone.is_personal" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                        <template v-else>
                                            <b-form-checkbox v-model="phone.is_personal" :state="errors[index+'.is_personal'] ? false : null" size="lg" name="is_personal" switch @change="CheckedIsPersonal(phone, index)"></b-form-checkbox>
                                        </template>
                                    </b-td>

                                    <b-td class="align-middle text-center">
                                        <template v-if="index != edit_index && IsUpdate">
                                            <span v-if="phone.is_whatsapp == null">-</span>
                                            <b-badge v-else-if="phone.is_whatsapp" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                        <template v-else>
                                            <b-form-checkbox v-model="phone.is_whatsapp" :state="errors[index+'.is_whatsapp'] ? false : null" size="lg" name="is_whatsapp" switch @change="CheckedIsWhatsapp(phone, index)"></b-form-checkbox>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center" v-if="has_permission('worker-edit')">
                                        <template v-if="IsUpdate">
                                            <b-button v-if="index === edit_index" class="btn btn-mary-beleza" size="sm" @click="new_phone ? SavePhone(index) : UpdatePhone(index)">
                                                <b-icon icon="check" font-scale="1" />
                                            </b-button>
                                            <b-button v-else variant="primary" size="sm" @click="EditPhone(index)" :disabled="new_phone || edit_index != null">
                                                <b-icon icon="pen" font-scale="1" />
                                            </b-button>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center" v-if="has_permission('worker-edit') && phones.length > 0">
                                        <b-button v-if="index === edit_index" variant="danger" size="sm" @click="new_phone ? RemovePhone(index) : CancelPhone(index)">
                                            <b-icon icon="arrow-counterclockwise" font-scale="1" />
                                        </b-button>
                                        <b-button v-else variant="danger" size="sm" @click="RemovePhone(index)" :disabled="new_phone || edit_index != null">
                                            <b-icon icon="trash" font-scale="1" />
                                        </b-button>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td :colspan="phones.length > 0 ? '4' : '5'" class="text-center"><small>Nenhum Telefone Adicionado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>
    
    <panel v-if="IsStage">
        <b-row>
            <b-col sm="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button class="btn btn-mary-beleza" size="sm" @click="next">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'

export default {

    components: {
        Panel
    },

    data() {
        return {
            edit_index: null, // Referencia o INDEX que está em edição, para liberar os inputs.
            new_phone: false, // Indica se está sendo criado um novo, se sim ele desabilita alguns botões.
            backup: {}
        }
    },

    methods: {

        next() {
            var bool = !!this.phones.find(item => item.id)
            if(bool){
                this.$store.commit('worker/STAGE_NEXT')
            }else{
                this.sweealert2('error', 'É obrigatório o cadastro de um telefone')
            }
        },

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        AddPhones() {
            this.$store.commit('worker/SET_PHONE_ADD')

            /* IF para ver se é edição
            Se sim ele adiciona o index do novo produto no 'edit_index'
            e deixa o 'new_phone' como verdadeiro */
            if (this.IsUpdate) {
                this.edit_index = this.phones.length - 1
                this.new_phone = true
            }
        },

        RemovePhone(index) {

            /* IF para ver se é edição
            Se sim ele deixa o 'new_phone' como falso 
            e adiciona o valor null para o 'edit_index' */
            if (this.IsUpdate && !this.new_phone) {
                this.$swal({
                    title: 'Tem certeza que deseja excluir?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('worker/deletePhone', index).then(response => {
                            this.sweealert2('success', response.success)
                        }).catch(error => {
                            this.sweealert2('error', error.response.data)
                        });
                    }
                })
            } else {
                this.$store.commit("worker/SET_PHONE_DELETE", index);
            }

            this.new_phone = false
            this.edit_index = null

        },

        SavePhone(index) {
            this.$store.dispatch('worker/createPhone', index).then(response => {

                this.new_phone = false
                this.edit_index = null

                this.sweealert2('success', response.success)

            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        UpdatePhone(index) {
            this.$store.dispatch('worker/updatePhone', index).then(response => {

                this.edit_index = null

                this.sweealert2('success', response.success)

            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
            });
        },

        EditPhone(index) {
            this.edit_index = index
            this.backup = _cloneDeep(this.phones[index])
        },

        CancelPhone(index) {
            this.edit_index = null
            this.phones[index] = this.backup
        },

        CheckedIsPersonal(phone, index) {
            var data = {
                phone,
                index
            }
            this.$store.commit('worker/SET_PHONE_IS_PERSONAL', data)
        },
        CheckedIsWhatsapp(phone, index) {
            var data = {
                phone,
                index
            }
            this.$store.commit('worker/SET_PHONE_IS_WHATSAPP', data)
        },

        UpdateNumber(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_PHONE_NUMBER', data)
        },
        UpdateDescription(value, index) {
            var data = {
                value,
                index
            }
            this.$store.commit('worker/SET_PHONE_DESCRIPTION', data)
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        phones: {
            get() {
                return this.$store.state.worker.worker.person.phones;
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            }
        },
    },
}
</script>

<template>
    <div>
        <v-date-picker :is-range="is_range" v-model="date" :update-on-input="false" :model-config="modelConfig">
            <template v-if="is_range" v-slot="{ inputValue, inputEvents }">
                <b-row>
                    <b-col>
                        <input 
                        :value="inputValue.start"
                        :placeholder="placeholder"
                        :class="[state || state == null ? 'form-control' : 'form-control is-invalid']"
                        v-on="inputEvents.start" 
                        class="form-control"/>
                    </b-col>
                    <span class="align-self-center">-</span>
                    <b-col>
                        <input 
                        :value="inputValue.end"
                        :placeholder="placeholder"
                        :class="[state || state == null ? 'form-control' : 'form-control is-invalid']"
                        v-on="inputEvents.end" 
                        class="form-control"/>
                    </b-col>
                </b-row>
            </template>
            <template v-else v-slot="{ inputValue, inputEvents }">
                <div >
                    <input
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :class="[state || state == null ? 'form-control' : 'form-control is-invalid']"
                    :value="inputValue"
                    v-on="inputEvents"/>
                </div>
            </template>
        </v-date-picker>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: false
        },
        placeholder: {
            type: String,
            default: "dd/mm/aaaa",
        },
        state: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        is_range: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            date: '',
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
        }
    },

    created() {
        this.date = this.value
    },

    watch: {
        'date': {
            handler: function () {
                if(this.date == null || this.date == ""){
                    this.$emit('input', null);
                }else if(this.date.length >= 10){
                    this.$emit('input', this.date);
                }else if(this.is_range && this.date.start.length >= 10 && this.date.end.length >= 10){
                    this.$emit('input', this.date);
                }
            }
        },
        'value': {
            handler: function () {
                this.date = this.value
            }
        },

    },

}
</script>
import Http from '@/api/http'

const state = {
    configurations: null,
    services_zipcodes: []
};

const getters = {
    
};

const actions = {
    all({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('configurations').then(response => {
                commit('SET_CONFIGURATIONS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    save({ commit }, data) {
        return new Promise((resolve, reject) => {
            Http.post('configurations', data).then(response => {
                commit('SET_CONFIGURATIONS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    getServicesZipCodes({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('configurations/services-zipcodes').then(response => {
                commit('SET_SERVICES_ZIPCODES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    addServiceZipCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            Http.post('configurations/services-zipcodes'+(data.id ? '/'+data.id : ""), data).then(response => {
                commit('SET_SERVICES_ZIPCODES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
    deleteServiceZipCode({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.delete('configurations/services-zipcodes/'+id).then(response => {
                commit('SET_SERVICES_ZIPCODES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        });
    }
};

const mutations = {
    SET_CONFIGURATIONS(state, value) {
        state.configurations = value;
    },
    SET_SERVICES_ZIPCODES(state, value) {
        state.services_zipcodes = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


export default {
    computed: {
        img_service_move() {
            return require("@/assets/images/icons/service-move.png");
        },
        img_service_note() {
            return require("@/assets/images/icons/service-note.png");
        },
        logo_asaas_green() {
            return require("@/assets/images/icons/logo-asaas-green.png");
        },
        logo_asaas_green_light() {
            return require("@/assets/images/icons/logo-asaas-green-light.png");
        },
        img_service_ticket() {
            return require("@/assets/images/icons/service-ticket.png");
        },
        img_toconfirm() {
            return require("@/assets/images/icons/service-to-confirm.png");
        },
        img_confirmed() {
            return require("@/assets/images/icons/service-confirmed.png");
        },
        img_notconfirmedworker() {
            return require("@/assets/images/icons/service-cancel-worker.png");
        },
        img_notconfirmedcustomer() {
            return require("@/assets/images/icons/service-cancel-customer.png");
        },
        img_notconfirmedmarybeleza() {
            return require("@/assets/images/icons/service-cancel-mary-beleza.png");
        },
        img_service_move_list() {
            return require("@/assets/images/icons/service-move-list.png");
        },
        img_service_phone() {
            return require("@/assets/images/icons/service-phone.png");
        },
        img_service_envelope_open() {
            return require("@/assets/images/icons/service-envelope-open.png");
        },
        img_service_envelope() {
            return require("@/assets/images/icons/service-envelope.png");
        },
        img_payment_ok() {
            return require("@/assets/images/icons/service-payment-ok.png");
        },
        img_payment_not_ok() {
            return require("@/assets/images/icons/service-payment-not-ok.png");
        },
        img_receipt_ok() {
            return require("@/assets/images/icons/service-receipt-ok.png");
        },
        img_receipt_not_ok() {
            return require("@/assets/images/icons/service-receipt-not-ok.png");
        },
        logo_asaas_blue() {
            return require("@/assets/images/icons/logo-asaas-blue.png");
        },
        logo_asaas_blue_light() {
            return require("@/assets/images/icons/logo-asaas-blue-light.png");
        },
        img_service_id_card() {
            return require("@/assets/images/icons/service-id-card.png");
        },
        img_conditions() {
            return require("@/assets/images/icons/service-conditions.png");
        },
        img_conditions_send() {
            return require("@/assets/images/icons/service-conditions-send.png");
        },
        img_not_email() {
            return require("@/assets/images/icons/service-not-email.png");
        },
        img_calendar() {
            return require("@/assets/images/icons/service-calendar.png");
        },
        img_time() {
            return require("@/assets/images/icons/service-time.png");
        },
        img_camera() {
            return require("@/assets/images/icons/service-camera.png");
        },
        img_camera_light() {
            return require("@/assets/images/icons/service-camera-light.png");
        },
        img_eye() {
            return require("@/assets/images/icons/img-eye.png");
        },
        img_coupon() {
            return require("@/assets/images/icons/coupon.png");
        },
        img_close() {
            return require("@/assets/images/icons/close.png");
        },
        not_image() {
            return require("@/assets/images/not-image.jpg");
        },
        bronze_lg() {
            return require("@/assets/images/bronze.png");
        },
        silver_lg() {
            return require("@/assets/images/silver.png");
        },
        gold_lg() {
            return require("@/assets/images/gold.png");
        },
        ruby_lg() {
            return require("@/assets/images/ruby.png");
        },
        bronze_md() {
            return require("@/assets/images/bronze_md.png");
        },
        silver_md() {
            return require("@/assets/images/silver_md.png");
        },
        gold_md() {
            return require("@/assets/images/gold_md.png");
        },
        ruby_md() {
            return require("@/assets/images/ruby_md.png");
        },
        bronze_sm() {
            return require("@/assets/images/bronze_sm.png");
        },
        silver_sm() {
            return require("@/assets/images/silver_sm.png");
        },
        gold_sm() {
            return require("@/assets/images/gold_sm.png");
        },
        ruby_sm() {
            return require("@/assets/images/ruby_sm.png");
        },
        emoji_horrivel() {
            return require("@/assets/images/emojis/emoji-horrivel.svg");
        },
        emoji_ruim() {
            return require("@/assets/images/emojis/emoji-ruim.svg");
        },
        emoji_medio() {
            return require("@/assets/images/emojis/emoji-medio.svg");
        },
        emoji_bom() {
            return require("@/assets/images/emojis/emoji-bom.svg");
        },
        emoji_otimo() {
            return require("@/assets/images/emojis/emoji-otimo.svg");
        },
        asaas_logo() {
            return require("@/assets/images/asaas/logo_asaas_pink_small.png");
        }
    }
}
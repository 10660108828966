import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import worker from './modules/worker';
import employee from './modules/employee';
import address from './modules/address';
import states from './modules/states';
import cities from './modules/cities';
import bank from './modules/bank';
import customer from './modules/customer';
import system from './modules/system';
import service from './modules/service';
import financial from './modules/financial';
import promotion from './modules/promotion';
import notification from './modules/notification';
import holiday from './modules/holiday';
import configurations from './modules/configurations';
import rich_table from './modules/rich_table';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

var ls = new SecureLS({encodingType: 'rc4', isCompression: false, encryptionSecret: 's3cr3tPa$$w0rd@123'});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        worker,
        address,
        states,
        cities,
        bank,
        customer,
        employee,
        system,
        service,
        financial,
        promotion,
        notification,
        holiday,
        configurations,
        rich_table
    },

    plugins: [
        createPersistedState({
            key: 'vuex',
            storage: {
                getItem: (key) => { 
                    try {
                        return ls.get(key);
                    } catch (_) {
                        ls.clear();
                    }
                },
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key)
            },
        })
    ],
})
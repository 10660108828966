<template>
    <div>
        <b-card style="height:100%;">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h6 class="mb-0 font-weight-bold">Serviço</h6>
                    <div v-if="schedule">
                        <span>
                            <b-icon id="button_remove_customer" class="zoom-1-2 ml-2" variant="mary-beleza" scale="2.1" icon="x" @click="RemoveSchedule"></b-icon>
                            <b-tooltip target="button_remove_customer" placement="top">
                                Remover serviço
                            </b-tooltip>
                        </span>
                    </div>
                </div>
            </template>
            <div v-if="schedule">
                <b-row>
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        {{schedule.name}}
                    </b-col>
                </b-row>
            </div>
            <div v-else class="text-center">
                <div>
                    <multiselect placeholder="Selecione" :internal-search="false" :loading="loading" @search-change="Search" hide-selected selectedLabel="Selecionado" track-by="id" label="name" selectLabel="Pressione enter para selecionar" deselectLabel="Pressione enter para remover" v-model="schedule" :options="schedules" >
                        <template slot="noResult">
                            Nenhum resultado encontrado
                        </template>
                        <template slot="noOptions">
                            Nenhum resultado encontrado
                        </template>
                    </multiselect>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    props: {
        value: {
            required: true
        },
        customer_id: {
            required: false
        }
    },
    components: {
        Multiselect
    },
    computed: {
        schedule: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    data() {
        return {
            loading: false,
            schedules: []
        }
    },
    created() {
        this.Search("");
    },
    methods: {
        Search(query) {
            var params = {
                customer_id: this.customer_id,
                search: query
            };
            var self = this;
            this.loading = true;
            this.$store.dispatch('service/searchSchedules', params).then(data => {
                var items = data ? data : [];
                self.schedules = items;
                self.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        RemoveSchedule() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover o serviço?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.schedule = null;
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div :title="title" ref="WorkerContractPrint" class="WorkerContractPrint text-left">
        <b-row class="no-print">
            <b-col md="12" class="text-right">
                <b-button class="btn-print" size="sm" :variant="variant" type="button" @click="print()">
                    <b-icon icon="file-text" font-scale="1" />
                    <slot></slot>
                </b-button>
            </b-col>
        </b-row>
        <div v-if="worker" class="only-print font-all">
            <p class="text-center mb-3">
                <u class="text-center">
                    <b>CONTRATO DE INTERMEDIAÇÃO DIGITAL</b>
                </u>
            </p>
            <div id="border">
                <table>
                    <tr>
                        <td>
                            <p class="text-center">
                                <b>QUADRO RESUMO</b>
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 1</b>
                                – QUALIFICAÇÃO INTERMEDIADO
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <b>PROFISSIONAL PARCEIRO (INTERMEDIADO): {{worker.person.name}}</b>, {{marital_name}} portador(a) do RG
                                número {{!worker.person.state_document ? 'SEM RG' : null}}{{worker.person.state_document | formatDocuments}} e do CPF número {{!worker.person.national_document ? 'SEM CPF' : null}}{{worker.person.national_document | formatDocuments}}, residente e
                                domiciliado na {{worker.person.address[0].street}}, {{worker.person.address[0].district}}, {{worker.person.address[0].number}}, {{worker.person.address[0].complement}}, {{worker.person.address[0].zipcode | formatCep}}, na
                                {{worker.person.address[0].city.name}}, {{worker.person.address[0].city.state.name}};
                            </p>
                            <!-- <p style="margin-bottom: 0in">
                                <b>OU</b>
                            </p>
                            <p style="margin-bottom: 0in">
                                <b>(PESSOA JURÍDICA)</b>
                            </p>
                            <p>
                                <b>(RAZÃO SOCIAL).</b>,
                                pessoa jurídica inscrita no CNPJ/MF sob o nº 00.000.000/0001-00,
                                com sede na (rua),
                                (bairro), (número), (completo), (CEP), na (cidade),
                                neste ato representada por seu
                                sócio(a) <b>(NOME COMPLETO)</b>,
                                (nacionalidade), (estado civil), (profissão), portador(a) do RG
                                número 00.000.000-0 e do CPF número 000.000.000.00, residente e
                                domiciliado na (rua), (bairro), (número), (completo), (CEP), na
                                (cidade), (estado), *contatos: e-mail: (___________) e telefone:
                                (00) 00000 0000, doravante denominado simplesmente de <b>PROFISSIONAL
                                PARCEIRO</b>.
                            </p> -->
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 2 </b>
                                – QUALIFICAÇÃO MARY BELEZA
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <b>MARY BELEZA (INTERMEDIADORA): DB CAMPANELLI COMERCIAL LTDA</b>,
                                sociedade empresária limitada, inscrita no CNPJ/ME sob o no
                                11.159.748-0001-58, com sede na Rua Jaci, 3333, Vila Redentora,
                                CEP 15015-810, São José do Rio Preto, SP, doravante denominada
                                simplesmente de <b>MARY BELEZA</b>.
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 3 </b>
                                – TERRITÓRIO DE ATUAÇÃO
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Localização: Cidades da Grande São Paulo atendidas pelo Aplicativo
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 4 </b>
                                – SERVIÇOS A SEREM REALIZADOS
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Diversos entre Manicure, Pedicure, Penteados e Corte de Cabelos, Depilação,
                                Maquiagem, Massagem, Design de Sobrancelhas entre outros que já
                                sejam ou venham a ser oferecidos.
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 5</b>
                                – TAXA DE INTERMEDIAÇÃO
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Remuneração variável de 30% (trinta por cento) a 40% (quarenta por cento), à título
                                de intermediação de negócio, sobre o valor pago pelo Usuário
                                Cliente ao PROFISSIONAL PARCEIRO (“Remuneração MARY BELEZA”).
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 6</b>
                                – FORMA DE PAGAMENTO
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Através de sistema de pagamento eletrônico contratado pela MARY BELEZA para pagamento
                                ao PROFISSIONAL PARCEIRO, atualmente denominado ASAAS.
                            </p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 7 </b>
                                – TABELA DE INFRAÇÕES
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Anexo I</p>
                        </td>
                    </tr>
                    <tr id="border">
                        <td class="p-0">
                            <p class="orange">
                                <b>Item 8</b>
                                – LOCAL E DATA DE ASSINATURA DO PRESENTE
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="my-3">
                                São Paulo - SP, {{moment().format("DD")}} de {{moment().format("MMMM")}} de {{moment().format("YYYY")}}.
                            </p>
                        </td>
                    </tr>
                </table>
            </div>
            <br>
            <p>
                <b>CONSIDERAÇÕES</b>
            </p>
            <p>
                O presente Contrato de Intermediação Digital de Serviços constitui
                um negócio jurídico entre a MARY BELEZA e uma pessoa física ou uma
                pessoa jurídica independente dedicada à prestação de serviços de
                beleza, estética e bem-estar pelo PROFISSIONAL PARCEIRO.
            </p>
            <p>Para
                utilizar os Serviços da MARY BELEZA, o PROFISSIONAL PARCEIRO
                concorda com os termos e condições abaixo, definidos entre as
                partes.
            </p>
            <div class="mb-3">
                <p>
                    <b>1.</b>
                    <u>
                        <b> OBJETO</b>
                    </u>
                </p>
                <p>
                    <b>1.1.</b> O objeto do presente Contrato é a regulamentação da intermediação
                    digital de quaisquer dos serviços de beleza, estética e
                    bem-estar, mencionados no <b>item
                    4 do Quadro Resumo</b>, entre o
                    PROFISSIONAL PARCEIRO devidamente habilitado no Aplicativo e o
                    Usuário Cliente.
                </p>
                <p>
                    <b>1.2.</b> A MARY BELEZA disponibilizará, a relação de serviços desejados
                    pelos Usuários Clientes dentro do Aplicativo MARY BELEZA, desde
                    que esse tenha qualificação comprovada para realiza-los, nos
                    limites do território selecionado pelo PROFISSIONAL PARCEIRO e
                    autorizado pela empresa, no <b>item
                    3 do Quadro Resumo</b>, sem
                    responsabilidade de conseguir trabalhos ou serviços, que este
                    poderá aceitar ou não quando forem oferecidos.
                </p>
                <p>
                    <b>1.3.</b> Para utilização do Aplicativo, o PROFISSIONAL PARCEIRO, deverá baixar
                    o Aplicativo e aderir aos seus Termos de Uso e Política de
                    Privacidade.
                </p>
            </div>
            <div class="mb-3">
                <p>
                    <b>2.</b>
                    <u>
                        <b> REMUNERAÇÃODO SERVIÇO</b>
                    </u>
                </p>
                <p>
                    <b>2.1.</b> A MARY BELEZA fará jus à uma remuneração variável definida no
                    <b>item 5 do Quadro Resumo</b>,
                    à título de intermediação de negócio, sobre o valor pago pelo
                    Usuário Cliente ao PROFISSIONAL PARCEIRO (“Remuneração MARY
                    BELEZA”).
                </p>
            
                <p>
                    <b>2.1.</b> Na hipótese de a MARY BELEZA implantar campanhas de desconto ou
                    vouchers, o valor pago pelo Usuário Cliente ao PROFISSIONAL
                    PARCEIRO não será reduzido proporcionalmente, ficando o desconto
                    concedido como investimento de marketing da MARY BELEZA para
                    conquista de mais clientes.
                </p> 
            </div>
            <div class="mb-3">
                <p>
                    <u>
                        <b>3.</b>
                        <b> FORMA DE PAGAMENTO</b>
                    </u>
                </p>
                <p>
                    <b>3.1.</b> Os Usuários Clientes efetuarão os pagamentos pelos serviços de
                    beleza, estética e bem-estar através de sistema de pagamento
                    eletrônico, que serão encaminhados diretamente para a conta
                    virtual ou bancária do PROFISSIONAL PARCEIRO, descontando-se
                    automaticamente a Remuneração MARY BELEZA, conforme previsto no
                    <b>item 6 do Quadro Resumo</b>.
                </p>
                <p>
                    <b>3.2.</b> O PROFISSIONAL PARCEIRO receberá o valor pago pelo Usuário Cliente
                    em sua conta virtual em até 14 (catorze) dias corridos, após a
                    realização do Serviço de Beleza e Estética, considerando,
                    principalmente, que os valores pagos pelos Usuários Clientes são
                    feitos em cartão de crédito e disponibilizados para a MARY BELEZA
                    pelos meios de pagamento somente 30 (trinta) dias após a
                    realização dos mesmos.
                </p>
                <p>
                    <b>3.3.</b> O PROFISSIONAL PARCEIRO poderá receber seu pagamento antes dos 14
                    (catorze) dias corridos mencionados, desde que peça, através do
                    próprio Aplicativo, a antecipação dos valores aos quais tem
                    direito, que serão, nesse caso, descontados a uma taxa de desconto
                    a ser negociada entre a MARY BELEZA e o meio de pagamento definido.
                    Já que essa taxa poderá variar, para tomar essa decisão de
                    antecipar seu pagamento, o PROFISSIONAL PARCEIRO deverá se
                    informar junto à administração da MARY BELEZA qual é o valor
                    atualizado da referida taxa.
                </p>
                <p>
                    <b>3.4.</b> Desde já a MARY BELEZA, se resguarda o direito de alterar, a qualquer
                    tempo e motivo, a empresa parceira de meios de pagamento, sem
                    prejuízo dos recebimentos pelo PROFISSIONAL PARCEIRO.
                </p>      
            </div>
            <div class="mb-3">
                <p>
                    <b>4.</b>
                    <u>
                        <b> DESCONTOS POR INFRAÇÕES</b>
                    </u>
                </p>
                <p>
                    <b>4.1.</b> Sobre o Pagamento do PROFISSIONAL PARCEIRO poderão ser acrescidas
                    eventuais multas financeiras, nos termos da Tabela de Infrações
                    do ANEXO I, aplicadas por descumprimento do Manual de Atendimento,
                    obrigações contratuais e/ou dos Termos de Uso aderido no
                    Aplicativo.
                </p>
                <p>
                    <b>4.2.</b> O PROFISSIONAL PARCEIRO
                    declara ter lido e concordado a Tabela de Infrações do ANEXO I.
                </p>
                <p>
                    <b>4.3.</b> Os percentuais definidos serão aplicados sobre o valor de referência
                    do Salário Mínimo Nacional, atualizado a cada ano em janeiro pelo
                    Governo Federal. As multas por
                    infrações do PROFISSIONAL PARCEIRO serão convertidas em
                    descontos aos Usuários Cliente, de modo a reverter a insatisfação
                    do Usuário Cliente em relação aos serviços de beleza, estética
                    e bem-estar, garantindo fidelização do mesmo e perenidade do
                    modelo de negócio da MARY BELEZA, de modo a não comportar
                    enriquecimento da MARY BELEZA.
                </p>
            </div>
            <div class="mb-3">
                <p>
                    <b>5. </b>
                    <u>
                        <b>MODIFICAÇÕES</b>
                    </u>
                </p>
                <p>
                    <b>5.1.</b> A MARY BELEZA reserva-se o direito de modificar os termos e condições
                    deste Contrato, a qualquer momento, e estas alterações entrarão
                    em vigor mediante a publicação de uma versão atualizada do
                    presente Contrato e/ou Termos de Uso para aceite no Aplicativo ou
                    por escrito junto à sede da MARY BELEZA.  O PROFISSIONAL PARCEIRO
                    neste ato reconhece e concorda que, ao utilizar os serviços da
                    MARY BELEZA baixar e instalar o Aplicativo, o PROFISSIONAL PARCEIRO
                    está vinculado a quaisquer futuras alterações e aditamentos ao
                    presente Contrato, informações e documentos relacionados.
                </p>
                <p>
                    <b>5.2.</b> O uso do aplicativo com continuidade de uso por parte do PROFISSIONAL
                    PARCEIRO, após qualquer mudança, constituirá um consentimento
                    por parte do PROFISSIONAL PARCEIRO para tal mudança.
                </p>
            </div>
            <div class="mb-3">
                <p>
                    <b>6. </b>
                    <b>RESPONSABILIDADES DO USUÁRIO E DA MARY BELEZA</b>
                </p>
            </div>
            <div>
                <p>
                    <b>6.1. </b>
                    <b>Responsabilidade pelo Uso.</b>
                    Você é exclusivamente responsável pelo uso do Aplicativo e
                    facilidades oferecidas pela MARY BELEZA e deverá respeitar as
                    regras dos Termos de Uso e deste Contrato de Intermediação
                    Digital devidamente assinado entre as partes, bem como a legislação
                    aplicável à MARY BELEZA.
                </p>
                <p>
                    <b>6.2.</b> Ao utilizar o Aplicativo, ainda, você se responsabiliza e se
                    compromete a:
                </p>
            </div>
            <ol type="a">
                <li>
                    <p>
                        Executar diligentemente os serviços de beleza, estética e bem-estar,
                        observando o Padrão MARY BELEZA transmitidos pelas equipes de
                        treinamento e suporte;
                    </p>
                </li>
                <li>
                    <p>
                        Utilizar qualquer material e/ou vestuário orientado pela MARY BELEZA durante
                        a prestação de serviços de beleza, estética e bem-estar;
                    </p>
                </li>
                <li>
                    <p>
                        Comunicar imediatamente a MARY BELEZA sobre qualquer ocorrência que
                        inviabilize ou prejudique a execução dos serviços de beleza,
                        estética e bem-estar agendados ou disponíveis para agendamento;
                    </p>
                </li>
                <li>
                    <p>
                        Utilizar, às suas expensas, somente materiais adquiridos das marcas
                        homologadas pela MARY BELEZA e especificadas nos treinamentos;
                    </p>
                </li>
                <li>
                    <p>
                        Esterilizar, às suas expensas, os materiais utilizados nos serviços de beleza,
                        estética e bem-estar, podendo, caso queira, utilizar da Autoclave
                        disponibilizada pela MARY BELEZA em sua sede, desde que haja
                        disponibilidade do equipamento, a critério único e exclusivo da
                        MARY BELEZA;
                    </p>
                </li>
                <li>
                    <p>
                        Seguir todas as orientações do Padrão MARY BELEZA para atendimento, por
                        categoria de serviço;
                    </p>
                </li>
                <li>
                    <p>
                        Cumprir todas as exigências legais e fiscais decorrentes da execução
                        destes Termos de Uso, no âmbito Federal, Estadual e Municipal,
                        incluindo, mas não se limitando às obrigações tributárias,
                        trabalhistas, previdenciárias, ambientais, dentre outras;
                    </p>
                </li>
                <li>
                    <p>
                        Comparecer impreterivelmente no local definido pelos Usuários Clientes caso
                        tenho aceito algum serviço, respeitando a data e horário do mesmo;
                    </p>
                </li>
                <li>
                    <p>
                        Responder criminalmente sobre furtos, roubos, danos morais e materiais e
                        quaisquer ações penais em que tenha responsabilidade envolvida uma
                        vez que esteja dentro do local de trabalho definido pelo Usuário
                        Cliente. E, isentar a MARY BELEZA de qualquer responsabilidade, uma
                        vez que esta fez apenas a intermediação do contato entre as
                        partes;
                    </p>
                </li>
                <li>
                    <p>
                        Responsabilizar-se sobre qualquer avaria em bens móveis ou imóveis de terceiros ou
                        pertences dos mesmos, estando a MARY BELEZA isenta de assumir
                        qualquer responsabilidade legal e/ ou financeira;
                    </p>
                </li>
                <li>
                    <p>
                        Responder judicialmente por qualquer ação de perjúrio ou ofensa em meios
                        eletrônicos e mídias sociais contra a marca MARY BELEZA;
                    </p>
                </li>
                <li>
                    <p>
                        Você, durante a execução dos serviços de beleza, estética e bem-estar
                        não contatará e garante que não utilizará nenhum dado pessoal do
                        Usuário Cliente para qualquer outro motivo que não seja o da
                        execução de serviços de beleza, estética e bem-estar vinculados
                        ao Aplicativo da MARY BELEZA, sob pena de responder pelos efeitos
                        legais de tal prática. Você, ainda, declara e garante que, para
                        Usuários Clientes, cuja intermediação tenha decorrido de Serviços
                        da MARY BELEZA, fica vedado o oferecimento de serviços
                        desvinculados do Aplicativo, ou seja, de maneira direta e sem a
                        intermediação da MARY BELEZA, bem como e criar trabalhos
                        derivados, baseados nos serviços intermediados. Caso venha a
                        realizar
                        qualquer tentativa de desintermediação, quais sejam, oferecimento
                        direto de serviços aos clientes apresentados pela MARY BELEZA,
                        poderá ser definitivamente excluído da equipe de PROFISSIONAIS
                        PARCEIROS  e sofrer as penalidades por crime de concorrência
                        desleal previstas no art. 195 da lei nº 9.279/96, que estipula a
                        pena de detenção de 3 (três) meses a 1 (um) ano ou multa.  Sem
                        prejuízo, a empresa MARY BELEZA poderá impor multa pecuniária no
                        valor de R$300,00 (trezentos reais), independentemente de
                        interpelação judicial ou extrajudicial, por cliente desviado.
                    </p>
                </li>
            </ol>
            <div class="mb-3">
                <p>
                    <u>
                        <b>7. </b>
                        <b>FORO E LEI APLICÁVEL</b>
                    </u>
                </p>
                <p>
                    <b>7.1.</b> Salvo disposição em contrário prevista neste Contrato, o presente
                    Contrato deve ser exclusivamente regido e interpretado de acordo
                    com as leis do Brasil. Quaisquer disputas e conflitos ou
                    controvérsias decorrentes de ou de alguma forma relacionadas ao
                    Contrato, incluindo com relação à sua validade, sua
                    interpretação ou o seu caráter executório, deverão ser
                    resolvidas no foro de São José do Rio Preto/SP, sede da empresa
                    MARY BELEZA, renunciando a qualquer outro, por mais privilegiado
                    que seja.
                </p>
            </div>
            <p>
                Por estarem assim justos e contratados,
                firmam o presente instrumento, em duas vias de igual teor, juntamente
                com 2 (duas) testemunhas.
            </p>
            <div class="text-center mb-5 mt-3">
                <p class="text-center mb-5">São Paulo, {{moment().format("DD")}} de {{moment().format("MMMM")}} de {{moment().format("YYYY")}}.</p>
                <p class="text-center">Nome:</p>
                <p class="text-center">CNPJ/CPF:</p>
                <p class="text-center mb-5">Parceiro Profissional</p>

                <p class="text-center font-weight-bold">DB CAMPANELLI COMERCIAL LTDA</p>
                <p class="text-center">CNPJ nº 11.159.748-0001-58</p>
                <p class="text-center">MARY BELEZA</p>
            </div>

            <p class="mb-5">
                TESTEMUNHAS:
            </p>

            <div class="d-flex justify-content-between">
                <div>
                    <p>____________________________________________</p>
                    <p>Nome:</p>
                    <p>R.G./C.P.F./MF:</p>
                </div>
                <div>
                    <p>____________________________________________</p>
                    <p>Nome:</p>
                    <p>R.G./C.P.F./MF:</p>
                </div>
            </div>
            <div style="margin-bottom:1200px;"></div>
            <p class="text-center">
                <b>ANEXO I – TABELA DE INFRAÇÕES</b>
            </p>

            <div class="page">
                <!-- <table class="" id="border">
                    <tr>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1">
                                Descrição
                            </div>
                        </th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1">
                                Avisou
                            </div>
                        </th>
                        <th id="border"></th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;">
                                Pontos
                            </div>
                        </th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1">
                                Multa % sobre Salário Mínimo
                            </div>
                        </th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1">
                                Desconto que a cliente irá receber % sobre Salário Mínimo
                            </div>
                        </th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1 text-nowrap">
                                Gravidade
                            </div>
                        </th>
                        <th id="border" class="text-center p-0 m-0">
                            <div style="font-size:15px;" class="p-1">
                                Suspensão
                            </div>
                        </th>
                    </tr>
                    <tr v-for="(item, key) in punishment_rules_filter([1,2,5,6,null])" :key="key" id="border">
                        <td class="m-0 p-0" id="border">
                            <p style="font-size: 15px;" :class="getColorType(item.parent.type)+' p-1'">
                                {{item.parent.description}}
                            </p>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <p style="font-size:15px;" :class="getColorType(item.parent.type)+' p-2 text-center'">
                                {{item.parent.warning ? 'SIM' : 'NÃO'}}
                            </p>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;">{{item.recurrence}}ªvez</b>
                            </div>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;" v-if="item.points">{{item.points}}</b>
                                <b style="font-size:15px;" v-else>-</b>
                            </div>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;" v-if="item.worker_value">{{item.worker_value}}%</b>
                                <b style="font-size:15px;" v-else>-</b>
                            </div>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;" v-if="item.customer_discount">{{item.customer_discount}}%</b>
                                <b style="font-size:15px;" v-else>-</b>
                            </div>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;">{{getGravityTitle(item.gravity_level)}}</b>
                            </div>
                        </td>
                        <td class="m-0 p-0" id="border">
                            <div :class="getColorType(item.parent.type)+' p-1 text-center'">
                                <b style="font-size:15px;" v-if="item.suspension == -1" class="text-nowrap">Encerrar Parceria</b>
                                <b style="font-size:15px;" v-else-if="item.suspension">{{item.suspension}} dias</b>
                                <b style="font-size:15px;" v-else>-</b>
                            </div>
                        </td>
                    </tr>
                </table> -->
                <p class="mt-3"><b>1 -</b> Multa % sobre Salário Mínimo </p>
                <p><b>2 -</b> Desconto que a cliente irá receber % sobre Salário Mínimo </p>
                <div id="divTable" class="mt-3">
                    <div id="headRow">
                        <div id="divCell" class="p-1" style="width:39% !important">Descrição</div>
                        <div id="divCell" class="p-1" style="width:7% !important">Avisou</div>
                        <div id="divCell" class="p-1" style="width:10% !important">Recorrencia</div>
                        <div id="divCell" class="p-1" style="width:7% !important">Pontos</div>
                        <div id="divCell" class="p-1" style="width:8% !important">Multa¹</div>
                        <div id="divCell" class="p-1" style="width:8% !important">Desconto²</div>
                        <div id="divCell" class="p-1" style="width:11% !important">Gravidade</div>
                        <div id="divCell" class="p-1" style="width:10% !important">Suspensão</div>
                    </div>
                    <div id="divRow" :class="getColorType(item.parent.type)" v-for="(item, key) in punishment_rules_filter([1,2,5,6,null])" :key="key">
                        <div id="divCell" class="p-1" style="width:39% !important">{{item.parent.description}}</div>
                        <div id="divCell" class="p-1" style="width:7% !important">{{item.parent.warning ? 'SIM' : 'NÃO'}}</div>
                        <div id="divCell" class="p-1" style="width:10% !important">{{item.recurrence+'ª vez'}}</div>
                        <div id="divCell" class="p-1" style="width:7% !important">{{item.points ? item.points : '-'}}</div>
                        <div id="divCell" class="p-1" style="width:8% !important">{{item.worker_value ? item.worker_value+'%' : '-'}}</div>
                        <div id="divCell" class="p-1" style="width:8% !important">{{item.customer_discount ? item.customer_discount+'%' : '-'}}</div>
                        <div id="divCell" class="p-1" style="width:11% !important">{{getGravityTitle(item.gravity_level)}}</div>
                        <div id="divCell" class="p-1" style="width:10% !important">{{item.suspension == -1 ? 'Encerrar Parceria' : item.suspension ? item.suspension+' dias' : '-'}}</div>
                    </div>
                </div>
                <br>
                <table class="w-100">
                    <tr>
                        <td class="m-0 p-0" id="border">
                            <div class="gray p-1 text-center">
                                <b>O ACÚMULO DE PONTOS GERA A QUEBRA DE PARCERIA</b>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="m-0 p-0" id="border">
                            <div class="gray p-1 text-center">
                                <b>PONTUAÇÃO LIMITE: 30 PONTOS</b>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="m-0 p-0" id="border">
                            <div class="gray p-1 text-center">
                                <b>A CONTAGEM DE PONTOS É FEITA EM PERÍODOS MÓVEIS DE 90 DIAS</b>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Http from '@/api/http'
import moment from 'moment'

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        variant: {
            type: String,
            required: false,
            default: "primary"
        },
        worker_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            worker: null,
            punishment_rules: [],
            punishment_types: [
                { id: 1, title: 'Atraso', color: 'green' },
                { id: 2, title: 'Desistência', color: 'yellow' },
                { id: 5, title: 'Sem uniforme', color: 'gray' },
                { id: 6, title: 'Material Esterelizado', color: 'gray' },
                { id: null, title: 'Edição', color: 'red' },
            ],
            gravity_types: [
                { id: 0, title: 'Leve' },
                { id: 1, title: 'Media' },
                { id: 2, title: 'Média/Grave' },
                { id: 3, title: 'Grave' },
                { id: 4, title: 'Gravissíma' },
            ],
            marital_options: [
                { text: 'Casado(a)', value: 1 },
                { text: 'Solteiro(a)',value: 2 },
                { text: 'Viúvo(a)',value: 3 },
                { text: 'Divorciado(a)',value: 4 },
                { text: 'Separado(a)',value: 5 },
            ],
        }
    },

    methods: {
        moment(...args){
            return moment(...args);
        },

        getWorker(){
            return new Promise((resolve, reject) => {
                Http.get(`worker/${this.worker_id}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },

        getPunishmentRules(){
            return new Promise((resolve, reject) => {
                Http.get(`punishmentRules`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },

        async print() {
            await this.getWorker().then(data => {
                this.worker = data;
            });
            await this.getPunishmentRules().then(data => {
                this.punishment_rules = data;
            });
            this.hide_elements();
            window.print();
            this.restore_elements();
        },

        punishment_rules_filter(types = []){
            if(Array.isArray(types) && types.length > 0){
                return this.punishment_rules.filter(item => types.includes(item.parent.type));
            }else{
                return this.punishment_rules;
            }
        },

        hide_elements() {
            var elements = window.document.querySelectorAll("*");
            var el = null;
            for (el of elements) {
                el.classList.add("noprint-worker-contract-print");
            }

            el = this.$refs.WorkerContractPrint;
            while(el) {
                el.classList.remove("noprint-worker-contract-print");
                el = el.parentElement;
            }

            elements = this.$refs.WorkerContractPrint.querySelectorAll("*");
            for (el of elements) {
                el.classList.remove("noprint-worker-contract-print");
            }
        },

        restore_elements() {
            var elements = window.document.querySelectorAll("*");
            for (var el of elements) {
                el.classList.remove("noprint-worker-contract-print");
            }
        },

        getGravityTitle(type){
            var gravity = this.gravity_types.find(item => item.id === type);
            return gravity.title;
        },

        getColorType(type){
            var punishment = this.punishment_types.find(item => item.id === type);
            return punishment.color;
        },
    },

    computed: {
        marital_name() {
            let marital = this.marital_options.find(marital => marital.value === this.worker.marital);
            return marital?.text
        },
    }
}
</script>

<style>
.WorkerContractPrint {
    display: inline;
}

@media print {
    #divTable {
        display: flex;
        flex-direction: column;
        width: auto;
        border-left: 1px solid #000 !important;
        border-top: 1px solid #000 !important;
    }
    #divRow {
       display: flex;
       width: 100% !important;
       height: 100% !important;
       border-bottom: 1px solid #000 !important;
    }
    #headRow {
        display: flex !important;
        display: -webkit-flex !important;
        width: 100% !important;
    }
    #divCell {
        display: flex !important;
        justify-content: center;
        align-items: stretch;
        font-size: 15px !important;
        border-style: solid !important;
        border-width: 0px 1px 0px 0px !important;
    }
    .gray {
        background-color: #c5c9ca !important;
        -webkit-print-color-adjust: exact !important;
    }

    .green {
        background-color: #699b67 !important;
        -webkit-print-color-adjust: exact !important;
    }

    .yellow {
        background-color: #fff077 !important;
        -webkit-print-color-adjust: exact !important;
    }

    .red {
        background-color: #ff9d95 !important;
        -webkit-print-color-adjust: exact !important;
    }

    .orange {
        background-color: #f4b083 !important;
        -webkit-print-color-adjust: exact;
    }

    p {
        margin: 0px;
    }

    #border {
        border: 1px solid #000 !important;
    }

    .font-all * {
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        text-align: justify;
    }

    .noprint-worker-contract-print
    {
        display: none !important;
    }

    .WorkerContractPrint .logo {
        width: auto;
        height: 80px;
    }
}
</style>
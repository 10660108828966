<template>
    <div class="util-bar sticky-top">
        <div class="py-1 px-3 d-flex justify-content-between align-items-center">
            <div class="title">
                <h1 class="m-0">{{ SetTitle() }}</h1>
                
                <breadcrumb />
            </div>
            <div class="d-flex" v-if="balance && balance_loaded">
                <div class="asaas-value mr-2 font-weight-bold px-3 pt-0" v-b-tooltip.hover.left title="Saldo ASAAS">
                    <img :src="asaas_logo" width="20" class="mr-2"/> {{balance.asaas.current | currency}}
                </div>
                <div class="worker-value ml-2 font-weight-bold px-3 pt-0" v-b-tooltip.hover.left title="Valor pendente para pagamento ASAAS">
                    <img :src="img_notconfirmedworker" width="20" class="mr-2"/> {{balance.asaas.expense_pending | currency}}
                </div>
            </div>
        </div>
        <div class="actions-buttons py-2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ImageLoader from '@/mixins/ImageLoader'

export default {
    mixins: [ImageLoader],
    
    computed: {
        balance() {
            return this.$store.state.financial.balance;
        }
    },
    data() {
        return {
            link: null,
            balance_loaded: false
        }
    },
    components: {
        Breadcrumb
    },
    created() {
        this.balance_loaded = false;
        this.$store.dispatch("financial/getBalance").then(() => {
			this.balance_loaded = true;
		}).catch(() => {
			this.balance_loaded = true;
		});
    },
    methods: {
        SetTitle() {
            if(this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length >= 1)
                return this.$route.meta.breadcrumb.slice(-1)[0].name
        }
    }
}
</script>
<template>
    <div v-if="loaded" id="asaas-receipts">
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="mary-beleza" v-b-modal.add-transaction @click="ClearModel()">
                            <fa icon="plus-circle" class="mr-2" />
                            <span>Criar Cobrança Avulsa</span>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <div class="alert alert-warning form-group" v-for="(item, i) in itemsAlerts" :key="i">
                <table class="table chargeback">
                    <colgroup>
                        <col class="col-xs-2">
                        <col class="col-xs-10">
                    </colgroup>
                    <tr>
                        <th>Criado em: </th>
                        <td><i class="fa fa-calendar-o"></i> {{item.created_at | moment("DD/MM/YYYY HH:MM")}}</td>
                    </tr>
                    <tr>
                        <th>N°: </th>
                        <td>{{item.number}}</td>
                    </tr>
                    <tr>
                        <th>Tipo: </th>
                        <td><span class="asaas-receipt-type" :title="getTypelabel(item).title">{{getTypelabel(item).label}}</span></td>
                    </tr>
                    <tr>
                        <th>Nome: </th>
                        <td>{{item.customer}}</td>
                    </tr>
                    <tr>
                        <th>Valor: </th>
                        <td>
                            <div>
                                {{item.value | currency}}
                                <span v-if="item.original_value && item.value != item.original_value" style="font-size: 10px;"><strong>ORIGINAL</strong><br>{{item.original_value | currency}}</span>
                            </div>
                            <div v-if="item.net_value !== null && item.net_value !== undefined">
                                <strong>TAXA: </strong> 
                                <span v-if="item.fee == 0">{{item.fee | currency}}</span>
                                <span class="text-success" v-else-if="item.fee > 0">{{item.fee * -1 | currency}}</span>
                                <span class="text-danger" v-else >{{item.fee * -1 | currency}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Descrição: </th>
                        <td><span v-html="item.description"></span></td>
                    </tr>
                    <tr>
                        <th>Vencimento: </th>
                        <td><i class="fa fa-calendar-o"></i> {{item.due_date | moment("DD/MM/YYYY")}}</td>
                    </tr>
                    <tr>
                        <th>Status: </th>
                        <td>
                            <template v-if="item.status == 1">
                                <i class="fa fa-clock-o"></i>
                                PENDENTE
                            </template>
                            <template v-else-if="item.status == 4">
                                <i class="fa fa-calendar-times-o"></i>
                                VENCIDO
                            </template>
                            <template v-else-if="item.status == 5">
                                <i class="fa fa-check"></i>
                                <span>CONFIRMADO</span>
                                <div v-if="item.confirmed_date"><i class="fa fa-calendar-o"></i> {{item.payment_date | moment("DD/MM/YYYY")}}</div>
                            </template>
                            <template v-else-if="item.status == 2">
                                <i class="fa fa-check"></i>
                                <span>RECEBIDO</span>
                                <div v-if="item.confirmed_date"><i class="fa fa-calendar-o"></i> {{item.payment_date | moment("DD/MM/YYYY")}}</div>
                            </template>
                            <template v-else-if="item.status == 3">
                                <i class="fa fa-money"></i>
                                <span>RECEBIDO EM DINHEIRO</span>
                                <div v-if="item.confirmed_date"><i class="fa fa-calendar-o"></i> {{item.payment_date | moment("DD/MM/YYYY")}}</div>
                            </template>
                            <template v-else-if="item.status == 8">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO REQUISITADA</span>
                            </template>
                            <template v-else-if="item.status == 9">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>EM DISPUTA PARA DEVOLUÇÃO</span>
                            </template>
                            <template v-else-if="item.status == 10">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO NEGADA AGUARDANDO SALDO</span>
                            </template>
                            <template v-else-if="item.status == 11">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>DEVOLUÇÃO REALIZADA</span>
                            </template>
                            <template v-else-if="item.status == 7">
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>FALHA</span>
                            </template>
                            <template v-else>
                                <i class="fa fa-exclamation-triangle"></i>
                                <span>STATUS DESCONHECIDO</span>
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
            
            <panel title="Filtros" icon="filter">
                <b-row class="no-print">
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Criado em</label>
                        <v-date-picker v-model="filter_period_created_at" :model-config="modelConfig" is-range clas>
                            <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input v-if="inputValue.start || inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                                <b-form-input v-else v-on="inputEvents.start" />
                            </template>
                        </v-date-picker>
                    </b-col>
                    
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Situação</label>
                        <select class="form-control" v-model="filter_status">
                            <option :value="null">Todos</option>
                            <option :value="1">Pendente</option>
                            <option :value="5">Confirmado</option>
                            <option :value="2">Recebido</option>
                            <option :value="4">Vencido</option>
                            <option :value="11">Devolução Realizada</option>
                        </select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Vencimento</label>
                        <v-date-picker v-model="filter_period_due_date" is-range :model-config="modelConfig">
                            <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input v-if="inputValue.start || inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                                <b-form-input v-else v-on="inputEvents.start" />
                            </template>
                        </v-date-picker>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-center form-group">
                        <b-button variant="outline-mary-beleza" @click="CleanFilters">
                            <b-icon class="mr-2" icon="funnel" />
                            <span>Limpar Filtros</span>
                        </b-button>
                    </b-col>
                </b-row>
            </panel>

            <panel>
                <div class="row form-group">
                    <div class="col-md-12">
                        <rich-table class="asaasPaymentsRichTable" ref="asaasPaymentsRichTable"
                                    v-on:input="ChangeTableConfig"
                                    :numbers="table_numbers"
                                    :loading="table_loading"
                                    :cols="table_cols" 
                                    font-size='0.8rem'>
                            <template v-if="items.length > 0">
                                <tr v-for="(item, key) in items" :key="key">
                                    <td style="width: 100px">
                                        <span class="d-block"><img :src="img_calendar" width="12" /> {{item.created_at | moment("DD/MM/YYYY")}}</span>
                                        <span class="d-block"><img :src="img_time" width="12" /> {{item.created_at | moment("HH:mm")}}</span>
                                    </td>
                                    <td class="text-center">{{item.number}}</td>
                                    <td>
                                        <span v-b-tooltip :title="getTypelabel(item).title">
                                            <b-badge :variant='getTypelabel(item).variant'>{{getTypelabel(item).label}}</b-badge>
                                        </span>
                                    </td>
                                    <td>{{item.customer}}</td>
                                    <td>
                                        <div>
                                            {{item.value | currency}}
                                            <span v-if="item.original_value && item.value != item.original_value" style="font-size: 10px;"><strong>ORIGINAL</strong><br>{{item.original_value | currency}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="item.fee != 0">
                                            <span v-if="item.fee == 0">{{item.fee | currency}}</span>
                                            <span class="text-success" v-else-if="item.fee < 0">{{item.fee * -1 | currency}}</span>
                                            <span class="text-danger" v-else >{{item.fee * -1 | currency}}</span>
                                        </div>
                                    </td>
                                    <td class="description">
                                        <div :class="{text: true, collapsed: item.collapsed}">
                                            <span v-if="item.short_description" v-html="item.short_description"></span>
                                            <span v-else v-html="item.description"></span>

                                            <b-badge class="ml-2 c-pointer" variant="light" v-if="(item.short_description || item.description) && (item.short_description ? item.short_description.length : item.description.length) > description_max_len" @click="item.collapsed = !item.collapsed">
                                                <fa :icon="['fa', item.collapsed ? 'angle-double-down': 'angle-double-up']" />
                                            </b-badge>
                                        </div>
                                    </td>
                                    <td>
                                        <a style="padding: 4px 8px;" class="btn btn-success" target="_blank" :href="item.url" v-b-tooltip.hover="'Visualizar cobrança'" v-if="item.url" variant="success" size="sm">
                                            <b-icon icon="eye" font-scale="1" />
                                        </a>
                                    </td>
                                    <td>
                                        <img :src="img_calendar" width="12" /> {{item.due_date | moment("DD/MM/YYYY")}}
                                    </td>
                                    <td>
                                        <template v-if="item.status == 1">
                                            <b-badge variant="primary">PENDENTE</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 4">
                                            <b-badge variant="danger">VENCIDO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 5">
                                            <b-badge variant="success">CONFIRMADO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 2">
                                            <b-badge variant="info">RECEBIDO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 3">
                                            <b-badge variant="success">RECEBIDO EM DINHEIRO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 8">
                                            <b-badge variant="warning">DEVOLUÇÃO REQUISITADA</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 9">
                                            <b-badge variant="warning">EM DISPUTA PARA DEVOLUÇÃO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 10">
                                            <b-badge variant="danger">DEVOLUÇÃO NEGADA AGUARDANDO SALDO</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 11">
                                            <b-badge variant="success">DEVOLUÇÃO REALIZADA</b-badge>
                                        </template>
                                        <template v-else-if="item.status == 7">
                                            <b-badge variant="danger">FALHA</b-badge>
                                        </template>
                                        <template v-else>
                                            <b-badge variant="light">STATUS DESCONHECIDO</b-badge>
                                        </template>
                                        <div v-if="item.confirmed_date"><img :src="img_calendar" width="12" /> {{item.confirmed_date | moment("DD/MM/YYYY")}}</div>
                                    </td>
                                    <td>
                                        <b-button variant="info" size="sm" v-if="(item.status == 5 || item.status == 2 || item.status == 3)" v-b-tooltip.hover="'Estornar cobrança'" @click="RefundAsaasReceipt(item)">
                                            <b-icon icon="arrow-counterclockwise" font-scale="1" />
                                        </b-button>  
                                    </td>
                                    <td>
                                        <b-button v-b-tooltip.hover="'Editar cobrança'" v-if="(item.status == 1 || item.status == 4) && item.avulse && false" variant="primary" size="sm" v-b-modal.add-transaction @click="ModifyAsaasReceipt(item)">
                                            <b-icon icon="pen" font-scale="1" />
                                        </b-button>                                   
                                    </td>
                                    <td>
                                        <b-button v-b-tooltip.hover="'Cancelar cobrança'" v-if="(item.status == 1 || item.status == 4)" variant="danger" size="sm" @click="RemoveAsaasReceipt(item)">
                                            <b-icon icon="trash" font-scale="1" />
                                        </b-button>
                                    </td>
                                </tr>
                            </template>
                            <tr v-else class="odd zero_records">
                                <td :colspan="table_cols.length">Nenhum registro encontrado</td>
                            </tr>
                        </rich-table>
                    </div>
                </div>
            </panel>

            <b-modal cancel-title="Fechar" ok-title="Salvar" id="add-transaction" title="Cobrança Avulsa" size="lg" @ok="SaveAsaasReceipt">
                <template v-if="true">
                    <customer-selection class="form-group" v-model="customer"></customer-selection>
                    <prepaidcard-selection class="form-group" v-if="customer" v-model="model.prepaidcard_id" :prepaidcards="customer.prepaidcards"></prepaidcard-selection>
                    <schedule-selection class="form-group" v-if="customer" v-model="schedule" :customer_id="customer.id" @input="DefaultServiceDescription"></schedule-selection>
                    <div class="row form-group">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <label>Valor</label>
                                    <money class="form-control" v-model="model.value" v-bind="money"></money>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Vencimento</label>
                            <v-date-picker
                                :attributes="vdatepicker_attributes"
                                class="col-md-12"
                                inputClass="form-control"
                                v-model="model.due_date"
                                mode="single"
                                show-caps
                                :model-config="modelConfig"
                                input-placeholder="Selecione o período">
                            </v-date-picker>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <label for="">Descrição dos Serviços</label>
                            <textarea v-limit='description_limit' class="form-control" v-model="model.description" rows="10"></textarea>
                            <div class="text-right">{{model.description ? model.description.length : 0}}/{{description_limit}}</div>
                        </div>
                    </div>
                </template>
                <div class="text-center" v-else>
                    <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                    <span>CARREGANDO...</span>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import RichTable from '@/components/RichTable'
//import FilterStorage from '@/mixins/FilterStorage'
import TodayVDatePickerHighlighter from '@/mixins/TodayVDatePickerHighlighter'
import moment from 'moment'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import CustomerSelection from "@/components/CustomerSelection"
import PrepaidcardSelection from "@/components/PrepaidcardSelection"
import ScheduleSelection from "@/components/ScheduleSelection"
import ImageLoader from '@/mixins/ImageLoader'

import ResponsesTest from '@/mixins/ResponsesTest'

export default {
    mixins: [
        TodayVDatePickerHighlighter,
        ResponsesTest,
        ImageLoader
    ],
    components: {
        Panel,
        RichTable,
        UtilBar,
        CustomerSelection,
        PrepaidcardSelection,
        ScheduleSelection
    },
    computed: {
        filter_period_due_date: {
            get() {
                return this.filters.period_due_date;
            },
            set(value) {
                if (_isEqual(this.filters.period_due_date, value)) {
                    return;
                }

                this.filters.period_due_date = value;
                this.LoadData();
            }
        },
        filter_period_created_at: {
            get() {
                return this.filters.period_created_at;
            },
            set(value) {
                if (_isEqual(this.filters.period_created_at, value)) {
                    return;
                }

                this.filters.period_created_at = value;
                this.LoadData();
            }
        },
        filter_period_receipt: {
            get() {
                return this.filters.period_receipt;
            },
            set(value) {
                if (_isEqual(this.filters.period_receipt, value)) {
                    return;
                }

                this.filters.period_receipt = value;
                this.LoadData();
            }
        },
        filter_status: {
            get() {
                return this.filters.status;
            },
            set(value) {
                if (_isEqual(this.filters.status, value)) {
                    return;
                }

                this.filters.status = value;
                this.LoadData();
            }
        },
        filter_billing_type: {
            get() {
                return this.filters.billing_type;
            },
            set(value) {
                if (_isEqual(this.filters.billing_type, value)) {
                    return;
                }

                this.filters.billing_type = value;
                this.LoadData();
            }
        },
        filter_type: {
            get() {
                return this.filters.type;
            },
            set(value) {
                if (_isEqual(this.filters.type, value)) {
                    return;
                }

                this.filters.type = value;
                this.LoadData();
            }
        }
    },
    data() {
        return {
            loaded: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            description_max_len: 125,
            table_loading: false,
            filters: {
                period_due_date: null,
                period_created_at: null,
                status: 1,
                billing_type: '',
                period_receipt: null,
                type: -1
            },
            table_numbers: {
                size: 10,
                total: 0,
                found: 0,
                footer: null
            },
            table_cols: [
                { id: 'created_at', text: 'Criado em', class: "receipt_created_at" },
                { text: "Nº", class: "receipt_number" },
                { text: "Tipo", class: "receipt_type" },
                { text: "Nome", class: "receipt_name" },
                { text: 'Valor', class: "receipt_amount" },
                { text: 'Taxas', class: "receipt_fees" },
                { text: 'Descrição', class: "receipt_description" },
                { text: '', class: "" },
                { text: 'Vencimento', class: "receipt_due_date" },
                { text: 'Status', class: "receipt_status" },
                { class: "receipt_controls" },
                { class: "receipt_controls" },
                { class: "receipt_controls" }
            ],
            table_config: {
                search: '',
                sort_column: "due_date",
                sort_order: "desc",
                limit: 10,
                offset: 0
            },
            initRichtable: false,
            items: [],
            itemsAlerts: [],
            asaasReceiptTypes: [
                { id: -1, text: "Todos" },
                { id: 0, text: "Serviço" },
                { id: 1, text: "Cobrança Avulsa" }
            ],
            short_description_limit: 80,
            description_limit: 500,
            customer: null,
            schedule: null,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            model: {
                id: null,
                person_id: null,
                avulse: true,
                prepaidcard_id: null,
                schedule_id: null,
                value: 0,
                due_date: null,
                description: "",
                short_description: ""
            }
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.table_loading = true;
            var params = _clone(_merge(this.filters, this.table_config));
            if (this.filters.period_due_date) {
                params.due_date_start = this.filters.period_due_date.start;
                params.due_date_end = this.filters.period_due_date.end;
            }

            if (this.filters.period_created_at) {
                params.created_at_start = this.filters.period_created_at.start;
                params.created_at_end = this.filters.period_created_at.end;
            }

            if (this.filters.period_receipt) {
                params.payment_date_start = this.filters.period_receipt.start;
                params.payment_date_end = this.filters.period_receipt.end;
            }
            
            this.$store.dispatch('financial/getAsaasReceipts', params).then((response) => {
                this.table_loading = false;
                var data = response;

                this.items = data.items ? data.items : [];
                this.itemsAlerts = this.items.filter(i => i.status == 8 || i.status == 9 || i.status == 10);

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : null;

                this.loaded = true;
            }).catch(() => {
                this.items = [];
                this.table_loading = false;
            });
        },
        moment(...args){
            return moment(...args);
        },
        ChangeTableConfig(value) {
            this.table_config = value;

            if (!this.initRichtable) {
                this.initRichtable = true;
                return;
            }

            this.LoadData();
        },
        CleanFilters() {
            this.$set(this, "filters", {
                period_due_date: null,
                period_created_at: null,
                status: 1,
                billing_type: '',
                period_receipt: null,
                type: -1
            });

            this.$refs.asaasPaymentsRichTable.CleanFilters();
        },
        getTypelabel(item) {
            var label = "S";
            var title = "Serviço";
            var variant = 'primary';

            if (item.avulse) {
                label = "#";
                title = "Cobrança Avulsa";
                variant = 'success';
            }

            return {
                label,
                title,
                variant
            }
        },
        ClearModel() {
            this.model = {
                id: null,
                id_reference: null,
                value: 0,
                due_date: null,
                description: "",
                short_description: "",
                prepaidcard_id: null,
                schedule_id: null,
                person_id: null
            };
        },
        ModifyAsaasReceipt(item) {
            console.log(item);
        },
        RefundAsaasReceipt(item) {
            this.$swal({
                title: "Estornar Cobrança",
                text: "Informe o motivo para o estorno da cobrança. Esta ação não poderá ser desfeita",
                icon: "warning",
                input: "textarea",
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: "Confirmar",
                denyButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.table_loading = true;
                    this.$store.dispatch('financial/refundAsaasReceipts', item.id, {
                        motive: result.value
                    }).then(() => {
                        this.LoadData();
                    }).catch(() => {
                        this.table_loading = false;
                    });
                }
            });
        },
        RemoveAsaasReceipt(item) {
            this.$swal({
                title: "Cancelar Cobrança",
                text: "Deseja realmente cancelar a cobrança? Esta ação não poderá ser desfeita.",
                icon: "question",
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: "Sim",
                denyButtonText: "Não"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.table_loading = true;
                    this.$store.dispatch('financial/deleteAsaasReceipts', item.id).then(() => {
                        this.LoadData();
                    }).catch(() => {
                        this.table_loading = false;
                    });
                }
            });
        },
        Validate() {
            if (!this.customer || !this.customer.id) {
                this.$swal("", "A seleção do cliente é obrigatória", "error");
                return false;
            }

            if (!this.model.due_date) {
                this.$swal("", "A seleção do vencimento é obrigatória", "error");
                return false;
            }

            if (!this.model.prepaidcard_id) {
                this.$swal("", "A seleção do cartão é obrigatória", "error");
                return false;
            }
            
            return true;
        },
        DefaultServiceDescription() {
            if (!this.schedule || !this.customer || (this.model.description != "" && this.model.description != null)) {
                return;
            }

            var description = `Recebimento referente ao serviço Nº ${this.schedule.number} para a data ${moment(this.schedule.service_date).format("DD/MM/YYYY")} com o cliente ${this.customer.name}.`;
            this.model.description = description;
        },
        SaveAsaasReceipt(e) {
            if (!this.Validate()) {
                if (e) 
                    e.preventDefault();

                return;
            }

            this.table_loading = true;

            var data = _cloneDeep(this.model);
            data.person_id = this.customer.id;
            data.due_date = data.due_date ? moment(data.due_date).format("YYYY-MM-DD") : null;
            data.schedule_id = this.schedule ? this.schedule.id : null;
            this.$store.dispatch('financial/saveAsaasReceipts', data).then(() => {
                this.LoadData();
            }).catch(() => {
                this.table_loading = false;
            });
        }
    }
}
</script>

<style scoped>
</style>

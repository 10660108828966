<template>
    <div id="sidebar-container" :class="[menu_collapsed ? 'sidebar-collapsed' : 'sidebar-expanded']" class="d-none d-md-block"><!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
        <!-- Bootstrap List Group -->
        <ul class="list-group">

            <!-- Menu with submenu -->
            <router-link to="/" class="list-group-item list-group-item-action flex-column align-items-start">
                <fa icon="tachometer-alt" class="fa-fw mr-3" />
                <span :class="{ 'd-none': menu_collapsed }" class="menu-collapsed">Dashboard</span>
            </router-link>
            
            <template v-for="(menu_group, index) in menu.items">
                <template v-if="!menu_group.subitems || menu_group.subitems.length == 0">
                    <router-link v-if="!menu_group.external" :to="menu_group.route" class="list-group-item list-group-item-action flex-column align-items-start" :key="'item' + index" v-b-toggle="'menu_' + index">
                        <fa :icon="menu_group.icons" class="fa-fw mr-3" />
                        <span :class="{ 'd-none': menu_collapsed }" class="menu-collapsed">{{menu_group.desc}}</span>
                    </router-link>
                    <a v-else :target="menu_group.target" :href="menu_group.route" class="list-group-item list-group-item-action flex-column align-items-start" :key="'item' + index" v-b-toggle="'menu_' + index">
                        <fa :icon="menu_group.icons" class="fa-fw mr-3" />
                        <span :class="{ 'd-none': menu_collapsed }" class="menu-collapsed">{{menu_group.desc}}</span>
                    </a>
                </template>
                <template v-else>
                    <a :key="'toggle' + index" v-b-toggle="'menu_' + index" @click="MenuToggle()" data-toggle="collapse" class="c-pointer list-group-item list-group-item-action flex-column align-items-start">
                        <fa :icon="menu_group.icons" class="fa-fw mr-3" />
                        <span :class="{ 'd-none': menu_collapsed }" class="menu-collapsed">{{menu_group.desc}}</span>
                        <span :class="{ 'd-none': menu_collapsed }" class="submenu-icon ml-auto">
                            <fa icon="angle-right" class="fa-fw mr-3" />
                        </span>
                    </a> 

                    <!-- Submenu content -->
                    <b-collapse :key="'collapse' + index" :id="'menu_' + index" class="sidebar-submenu" accordion="main-menu" :class="{ 'd-none': menu_collapsed }">
                        <template v-for="(menu_item, item_index) in menu_group.subitems">
                            <router-link :key="item_index" :to="menu_item.route" class="list-group-item list-group-item-action" v-if="!menu_item.external">
                                <span v-if="menu_item.route == '/profissionais/mb'" class="menu-collapsed font-weight-bold">{{menu_item.desc}}</span>
                                <span v-else class="menu-collapsed">{{menu_item.desc}}</span>
                            </router-link>
                            <a :key="item_index" v-else :target="menu_item.target" :href="menu_item.route" class="list-group-item list-group-item-action">
                                <span v-if="menu_item.route == '/profissionais/mb'" class="menu-collapsed font-weight-bold">{{menu_item.desc}}</span>
                                <span v-else class="menu-collapsed">{{menu_item.desc}}</span>
                            </a>
                        </template>
                    </b-collapse>
                </template>
            </template>
            
        </ul><!-- List Group END-->
    </div>
    <!-- /#wrapper -->
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters('system', [
            'menu_collapsed', 'menu'
        ])
    },
    methods: {
        MenuToggle() {
            if(this.menu_collapsed)
                this.$store.dispatch('system/toggle_menu')
        }
    }

}
</script>

<style scoped>
</style>
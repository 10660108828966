const state = {
    data: []
};

const getters = {
    loadStorageTableConfig: state => storage_name => { 
        var table = state.data.find(table => table.storage_name == storage_name);
        return table ? table.table_config: null;
    }
};

const actions = {
    saveTableConfig({ commit }, data) {
        commit("SET_SAVE_RICH_TABLE", data)
    },
};

const mutations = {
    SET_SAVE_RICH_TABLE(state, data) {
        var table_index = state.data.findIndex(table => table.storage_name == data.storage_name);
        if(table_index == -1){
            state.data.push({storage_name: data.storage_name, table_config: data.table_config});
        }else{
            state.data[table_index] = data.table_config;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


<template>
    <div class="comemorative mr-2" v-if="img">
        <img :src="img" width="40" class="vibrate-1" id="comemorative-date" />
        <b-tooltip target="comemorative-date" variant="light" placement="left" ><span v-html="subtitle"></span></b-tooltip>
    </div>
</template>

<script>
import moment from 'moment' // eslint-disable-line

export default {
    data() {
        return {
            img: null,
            subtitle: null
        }
    },
    created() {
        var now = moment();
        var comemorative_dates = [
            // { // Proclamação da República
            //     date: moment().set({'date': 15, 'month': 10}), 
            //     img: require('@/assets/images/special-dates/proclamacao-da-republica.png'),
            //     subtitle: '<strong>15 de Novembro</strong> Proclamação da República do Brasil'},
            // { // Dia da Bandeira
            //     date: moment().set({'date': 19, 'month': 10}), 
            //     img: require('@/assets/images/special-dates/dia-da-bandeira.png'),
            //     subtitle: '<strong>19 de Novembro</strong> Dia da Bandeira'
            // }, 
        ]

        for(var dates in comemorative_dates) {
            if(now <= comemorative_dates[dates].date && now >= comemorative_dates[dates].date.subtract(3, 'days')) {
                this.img = comemorative_dates[dates].img
                this.subtitle = comemorative_dates[dates].subtitle
            }
        }
    }
}
</script>
<template>
    <div>
        <util-bar></util-bar>
        <div class="pr-3 pl-3 pt-3">
            <bonus-rules></bonus-rules>
            <b-row>
                <b-col>
                    <panel title="Profissionais">
                        <b-row class="form-group">
                            <b-col md="4">
                                <label>Competência</label>
                                <b-row>
                                    <b-col>
                                        <typeable-date v-model="period_start"/>
                                    </b-col>
                                    <span class="align-self-center">-</span>
                                    <b-col>
                                        <typeable-date v-model="period_end"/>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-table-simple responsive striped small hover class="p-0">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th style="width: 15%;">Competência</b-th>
                                    <b-th style="width: 20%;">Profissional</b-th>
                                    <b-th style="width: 60%;">Valor</b-th>
                                    <b-th style="width: 5%;"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <template v-if="bonus.length > 0">                    
                                    <b-tr v-for="(item, i) in bonus" :key="i">
                                        <b-td class="text-left" >{{item.competence | moment("MM/YYYY")}}</b-td>
                                        <b-td class="text-left" >{{item.person.name}}</b-td>
                                        <b-td class="text-left" >{{item.value | currency}}</b-td>
                                        <b-td class="text-left" @click="() => item_selected = item"><medal v-b-modal.bonus-schedules class="medal" :type="GetType(item.type)" size="sm"></medal></b-td>
                                    </b-tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="4" class="text-center py-3">Nenhum registro encontrado</td>
                                    </tr>
                                </template>
                            </b-tbody>
                        </b-table-simple>
                    </panel>
                    <b-modal id="bonus-schedules" title="Serviços realizados" size="lg" ok-only ok-title="Fechar">
                        <template v-if="item_selected">
                            <b-row align-h="center">
                                <b-col class="text-center">
                                    <b-form-group label="Profissional" label-size="md" label-class="font-weight-bold pt-0">
                                        {{item_selected.person.name}}
                                    </b-form-group>
                                </b-col>
                                <b-col class="text-center">
                                    <b-form-group label="Bonificação" label-size="md" label-class="font-weight-bold pt-0">
                                        <medal class="medal" :type="GetType(item_selected.type)" size="sm"></medal>
                                    </b-form-group>
                                </b-col>
                                <b-col class="text-center">
                                    <b-form-group label="Competência" label-size="md" label-class="font-weight-bold pt-0">
                                        {{item_selected.competence | moment("MM/YYYY")}}
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-table-simple responsive small hover class="p-0">
                                <b-thead head-variant="light">
                                    <b-tr>
                                        <b-th style="width: 20%;">Data</b-th>
                                        <b-th style="width: 40%;">Cliente</b-th>
                                        <b-th style="width: 15%;">Valor</b-th>
                                        <b-th style="width: 15%;">Bônus</b-th>
                                        <b-th style="width: 10%;"></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <template v-if="item_selected.schedules && item_selected.schedules.length > 0">                    
                                        <b-tr v-for="(item, i) in item_selected.schedules" :key="i">
                                            <b-td class="text-left">{{item.service_date | moment("DD/MM/YYYY")}}</b-td>
                                            <b-td class="text-left">{{item.customer}}</b-td>
                                            <b-td class="text-left">{{item.value | currency}}</b-td>
                                            <b-td class="text-left">{{item.bonus | currency}}</b-td>
                                            <b-td class="text-left">
                                                <a target="_blank" :href="`/servicos/servico/${item.id}`">Visualizar</a>
                                            </b-td>
                                        </b-tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="4" class="text-center py-3">Nenhum registro encontrado</td>
                                        </tr>
                                    </template>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                    </b-modal>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import UtilBar from '@/components/UtilBar'
import Panel from "@/components/Panel"
import moment from 'moment'
import TypeableDate from '@/components/TypeableDate'
import Medal from '@/components/Medal'
import BonusRules from '@/components/BonusRules'

export default {
    components: {
        UtilBar,
        Panel,
        TypeableDate,
        Medal,
        BonusRules
    },
    computed: {
        period_start: {
            get() {
                return this.filters.period_start;
            },
            set(value) {
                this.filters.period_start = value;
                this.LoadData();
            }
        },
        period_end: {
            get() {
                return this.filters.period_end;
            },
            set(value) {
                this.filters.period_end = value;
                this.LoadData();
            }
        }
    },
    data() {
        return {
            types: [  ],
            loaded: false,
            filters: {
                period_start: moment().subtract(1, "month").format("YYYY-MM-DD"),
                period_end: moment().format("YYYY-MM-DD")
            },
            item_selected: null,
            bonus: []
        };
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$store.dispatch("worker/bonus", this.filters).then((response) => {
                this.loaded = true;
                this.bonus = response;
            });
        },
        GetType(type) {
            switch(type) {
                case 1:
                    return 'bronze';
                case 2:
                    return 'silver';
                case 3:
                    return 'gold';
                case 4:
                    return 'ruby';
            }

            return '';
        }
    }
}
</script>

<style scoped>
    .medal {
        cursor: pointer !important;
        transition: all 0.2s ease;
    }

    .medal:hover {
        transform: scale(1.05, 1.05);
    }
</style>
<template>
    <loader-mary-beleza v-if="!loaded" :show="!loaded"/>
    <div v-else>
        <util-bar>
            <div v-if="has_permission('price-table-edit')">
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <template v-if="!editing">
                            <b-button v-if="!changed" v-b-toggle.sidebar-right class="btn btn-mary-beleza mr-2">
                                <fa icon="filter" class="mr-2" /> FILTRAR TABELA
                            </b-button>
                            <b-button-group>
                                <b-button class="btn btn-outline-mary-beleza" @click="Save()" v-if="changed">
                                    <fa :icon="['far', 'save']" /> SALVAR
                                </b-button>
                                <b-button class="btn btn-mary-beleza" @click="editing = true">
                                    <fa icon="pen" class="mr-2" /> ATUALIZAR TABELA DE PREÇOS
                                </b-button>
                            </b-button-group>
                            <b-alert v-if="changed" class="mt-3" variant="warning" show>Há alterações na tabela de preços mas elas ainda não estão salvas. Se deseja mantê-las, clique em salvar.</b-alert>
                        </template>
                        <template v-else>
                            <b-button-group>
                                <b-button class="btn btn-outline-mary-beleza" @click="editing = false">
                                    <fa icon="undo" class="mr-2" />Voltar
                                </b-button>
                                <b-button class="btn btn-mary-beleza" @click="Generate()">
                                    <fa icon="plus-circle" class="mr-2" /> GERAR TABELA
                                </b-button>
                            </b-button-group>
                        </template>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3" v-if="!editing" id="scrollspy-nested">
            <panel title="Tabela de Preços" icon="bookmark" icon-lib="far">
                <b-row>
                    <b-sidebar id="sidebar-right" title="Serviços" right backdrop shadow width="540px">
                        <div class="px-3 py-2">
                            <b-navbar class="flex-column pricetable-nav" toggleable="lg" :class="{ changed }">
                                <b-nav pills vertical>
                                    <b-nav-item :class="{active: !selected}" @click="selected = null">Tabela Completa</b-nav-item>
                                    <template v-for="(item, i) in items">
                                        <b-nav-item class="root" :key="'item-'+i" @click="() => { if (item.children == 0) { selected = item } }">{{item.name}}
                                            <b-nav pills vertical :key="i + 'a'">
                                                <b-nav-item style="white-space: nowrap;" v-for="(service, j) in item.children" :key="j" class="ml-3 my-1 nav-item-service" :class="{active: selected == service, root: service.children.length > 0}" @click="() => { if (service.children == 0) { selected = service } }">
                                                    {{service.name}}
                                                    <template v-if="checkAlerts(service).alerts">
                                                        <div v-if="checkAlerts(service).increase >= checkAlerts(service).discounts" class="increase"></div>
                                                        <div v-else class="discount"></div>
                                                    </template>
                                                    <b-nav pills vertical :key="i + 'b'">
                                                        <b-nav-item v-for="(subservice, k) in service.children" :key="k" class="ml-3 my-1 nav-item-service" :class="{active: selected == subservice}" @click="() => { if (subservice.children == 0) { selected = subservice } }">
                                                            {{subservice.name}} 
                                                            <template v-if="checkAlerts(subservice).alerts">
                                                                <div v-if="checkAlerts(subservice).increase >= checkAlerts(subservice).discounts" class="increase"></div>
                                                                <div v-else class="discount"></div>
                                                            </template>
                                                        </b-nav-item>
                                                    </b-nav>
                                                </b-nav-item>
                                            </b-nav>
                                        </b-nav-item>
                                    </template>
                                </b-nav>
                            </b-navbar>
                        </div>
                    </b-sidebar>
                    <b-col>
                        <b-overlay :show="loadingData" rounded="sm">
                            <div class="price-table-content">
                                <div v-for="(service, j) in services" :key="j" style="overflow-x:auto;">
                                    <h2 v-if="service.parent">{{service.parent.name}}</h2>
                                    <h4>{{service.name}}</h4>
                                    <div class="prices-alerts form-group" v-html="checkAlerts(service).alerts"></div>
                                    <b-table-simple responsive hover class="pricetable-view" >
                                        <colgroup>
                                            <col style="width: 15%" />
                                            <col v-for="(weekday_data, weekday) in weekdays" :key="weekday"/>
                                        </colgroup>
                                        <b-thead>
                                            <b-tr>
                                                <b-th class="text-center"></b-th>
                                                <b-th style="white-space: nowrap;" v-for="(weekday_data, weekday) in weekdays" :key="weekday">{{weekday_data.text}}</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(person_type, i) in person_types" :key="i">
                                                <b-td>{{person_type.text}}</b-td>
                                                <template v-if="person_type.key == 'CUSTOMER'">
                                                    <b-td v-for="(weekday_data, weekday) in weekdays" :key="weekday" :class="{ 'text-primary font-weight-bold': getDiscount(person_type.key, service.id, weekday_data.key) > 0, 'text-warning font-weight-bold': getDiscount(person_type.key, service.id, weekday_data.key) < 0 }">{{model[`${person_type.key}_SERVICE_${service.id}_${weekday_data.key}`] | currency}}</b-td>
                                                </template>
                                                <template v-else>
                                                    <b-td v-for="(weekday_data, weekday) in weekdays" :key="weekday" :class="{ 'text-primary font-weight-bold': getDiscount(person_type.key, service.id, weekday_data.key) > 0, 'text-warning font-weight-bold': getDiscount(person_type.key, service.id, weekday_data.key) < 0 }">{{model[`${person_type.key}_SERVICE_${service.id}_${weekday_data.key}`] | currency}}</b-td>
                                                </template>
                                            </b-tr>
                                            <tr>
                                                <b-td>Lucro</b-td>
                                                <b-td style="white-space: nowrap;" v-for="(weekday_data, weekday) in weekdays" :key="weekday" :class="{ 'table-wrapper text-success font-weight-bold': Profit(service.id, weekday_data.key) > 0, 'text-danger font-weight-bold': Profit(service.id, weekday_data.key) <= 0, }">{{Profit(service.id, weekday_data.key) | currency}} ({{ProfitPercentage(service.id, weekday_data.key) | percentage}})</b-td>
                                            </tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </div>
                        </b-overlay>
                    </b-col>
                </b-row>
            </panel>
        </div>
        <div class="pr-3 pl-3 pt-3" v-else>
            <panel title="Geral" icon="bookmark" icon-lib="far">
                <b-row class='form-group'>
                    <b-col class="inline d-flex" sm="6" md="4" lg="3">
                        <money class="form-control form-control-lg mr-2" v-bind="percentage" v-model="overall_value"></money>
                        <b-button size="md" variant="primary" @click="ApplyOverallValues">Aplicar</b-button>
                    </b-col>
                    <b-col class="text-right" sm="6" md="8" lg="9">
                        <b-button size="md" variant="outline-danger" @click="ResetTableValues">Descartar alterações</b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Acréscimos/Descontos - Quantidade de Horas" icon="bookmark" icon-lib="far">
                <b-row>
                    <b-col>
                        <b-table-simple hover responsive striped class="mb-5" v-for="(item, j) in model['DISCOUNT_PERCENT_PER_HOURS_COUNT']" :key="j">
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-left">
                                        Quantidade:
                                    </b-th>
                                    <b-th class="text-right" colspan="100%">
                                        <b-button-group>
                                            <b-button @click="REMOVE_DISCOUNT_PERCENT_PER_HOURS_COUNT(item)" size='sm' variant='danger'><b-icon font-scale="2" icon="trash" aria-hidden="true"></b-icon></b-button>
                                        </b-button-group>
                                    </b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-center">
                                        <b-input-group style="width: 200px;">
                                            <b-form-input type="number" v-model="item.START"></b-form-input><span style="padding: 0 5px;"> à </span><b-form-input type="number" v-model="item.END"></b-form-input>
                                            <b-input-group-append>
                                                <b-icon class="ml-2" font-scale="2" icon="alarm" aria-hidden="true"></b-icon>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-th>
                                    <th></th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(person_type, i) in person_types" :key="i">
                                    <b-td>{{person_type.text}}</b-td>
                                    <template>
                                        <b-td><money class="form-control" v-bind="percentage" v-model="item[person_type.key]" /></b-td>
                                    </template>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button @click="ADD_DISCOUNT_PERCENT_PER_HOURS_COUNT()" size='sm' variant='primary' block><b-icon style="vertical-align: middle;" font-scale="2" icon="plus" aria-hidden="true"></b-icon> Adicionar nova faixa</b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Acréscimos/Descontos - Horas de Antecedência" icon="bookmark" icon-lib="far">
                <b-row>
                    <b-col>
                        <b-table-simple hover responsive striped class="mb-5" v-for="(item, j) in model['DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE']" :key="j">
                            <colgroup>
                                <col style="width: 15%" />
                                <col style="width: 10%" v-for="(weekday_data, weekday) in weekdays" :key="weekday"/>
                            </colgroup>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-left">
                                        Antecedência:
                                    </b-th>
                                    <b-th class="text-right" colspan="100%">
                                        <b-button-group>
                                            <b-button @click="REMOVE_DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE(item)" size='sm' variant='danger'><b-icon font-scale="2" icon="trash" aria-hidden="true"></b-icon></b-button>
                                        </b-button-group>
                                    </b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-center">
                                        <b-input-group style="width: 200px;">
                                            <b-form-input type="number" v-model="item.START"></b-form-input><span style="padding: 0 5px;"> à </span><b-form-input type="number" v-model="item.END"></b-form-input>
                                            <b-input-group-append>
                                                <b-icon class="ml-2" font-scale="2" icon="alarm" aria-hidden="true"></b-icon>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-th>
                                    <b-th style="white-space: nowrap; font-size: 12px; font-weight: normal" v-for="(weekday_data, weekday) in weekdays" :key="weekday">{{weekday_data.text}}</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(person_type, i) in person_types" :key="i">
                                    <b-td>{{person_type.text}}</b-td>
                                    <template>
                                        <b-td v-for="(weekday_data, weekday) in weekdays" :key="weekday"><money class="form-control" v-bind="percentage" v-model="item[person_type.key][weekday_data.key]"  /></b-td>
                                    </template>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button @click="ADD_DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE()" size='sm' variant='primary' block><b-icon style="vertical-align: middle;" font-scale="2" icon="plus" aria-hidden="true"></b-icon> Adicionar nova faixa</b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel v-for="(item, index) in items" :key="index" :title="item.name" icon="bookmark" icon-lib="far">
                <b-tabs v-model="item.tab" class="services-tabs">
                    <b-tab v-for="(service, index) in item.children_flatten" :key="index">
                        <template #title>
                            <template><small style="vertical-align: top; color: #8c51a1; font-weight: bold;" v-if="service.parent">{{service.parent.name}}</small></template> {{service.name}}
                        </template>
                        <b-row class="form-group" v-if="item.tab == index">
                            <b-col sm="12" md="12" lg="12" style="overflow-x:auto;">
                                <div class="price-table-content">
                                    <b-table-simple hover responsive striped class="pricetable-edit">
                                        <b-thead head-variant="marybeleza">
                                            <b-tr>
                                                <b-th style="width: 60%"></b-th>
                                                <b-th style="width: 20%" v-for="(person_type, i) in person_types" :key="i">{{person_type.text}}</b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="align-middle">Preço do serviço <strong>{{service.name}}</strong></b-th>
                                                <b-th v-for="(person_type, i) in person_types" :key="i" style="vertical-align: top;">
                                                    <template v-if="person_type.key == 'CUSTOMER'">
                                                        <money class="form-control" v-bind="money" :value="GetValue(`${person_type.key}_SERVICE_${service.id}`, person_type.key)" @input="(v) => SetValue(`${person_type.key}_SERVICE_${service.id}`, v, person_type.key, service.id)"></money>
                                                    </template>
                                                    <template v-else>
                                                        <money class="form-control" v-bind="percentage" :value="GetValue(`${person_type.key}_SERVICE_${service.id}_PERCENT`, person_type.key)" @input="(v) => SetValue(`${person_type.key}_SERVICE_${service.id}_PERCENT`, v, person_type.key, service.id)"></money>
                                                        <small>{{WorkerPrice(service.id) | currency}}</small>
                                                    </template>
                                                </b-th>
                                            </b-tr>
                                        </b-thead>
                                    </b-table-simple>
                                    <b-table-simple hover responsive striped class="mb-5">
                                        <colgroup>
                                            <col style="width: 15%" />
                                            <col style="width: 10%" v-for="(weekday_data, weekday) in weekdays" :key="weekday"/>
                                        </colgroup>
                                        <b-thead>
                                            <b-tr>
                                                <b-th colspan="8" class="text-center">Acréscimos/Descontos - <strong>{{service.name}}</strong></b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="text-center"></b-th>
                                                <b-th style="white-space: nowrap; font-size: 12px; font-weight: normal" v-for="(weekday_data, weekday) in weekdays" :key="weekday">{{weekday_data.text}}</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(person_type, i) in person_types" :key="i">
                                                <b-td>{{person_type.text}}</b-td>
                                                <template>
                                                    <b-td v-for="(weekday_data, weekday) in weekdays" :key="weekday"><money class="form-control" v-bind="money" :value="GetValue(`${person_type.key}_SERVICE_${service.id}_${weekday_data.key}_DISCOUNT`)" @input="(v) => SetValue(`${person_type.key}_SERVICE_${service.id}_${weekday_data.key}_DISCOUNT`, v, person_type.key, service.id)"/></b-td>
                                                </template>
                                            </b-tr>
                                            <tr class="profit">
                                                <b-td>Lucro</b-td>
                                                <b-td style="white-space: nowrap;" v-for="(weekday_data, weekday) in weekdays" :key="weekday" :class="{ 'text-success font-weight-bold': Profit(service.id, weekday_data.key) > 0, 'text-danger font-weight-bold': Profit(service.id, weekday_data.key) <= 0, }">{{Profit(service.id, weekday_data.key) | currency}} ({{ProfitPercentage(service.id, weekday_data.key) | percentage}})</b-td>
                                            </tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

export default {
    data() {
        return {
            loaded: false,
            changed: false,
            show: true,
            items: [],
            loadingData: false,
            editing: false,
            selected: null,
            services_flatten: [],
            overall_value: 0,
            reset_table: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            },
            person_types: [
                { key: 'CUSTOMER', text: 'Cliente' },
                { key: 'WORKER', text: 'Profissional' },
            ],
            weekdays: [
                { key: 'SUNDAY', text: 'Domingo/Feriado' },
                { key: 'MONDAY', text: 'Segunda-Feira' },
                { key: 'TUESDAY', text: 'Terça-Feira' },
                { key: 'WEDNESDAY', text: 'Quarta-Feira' },
                { key: 'THURSDAY', text: 'Quinta-Feira' },
                { key: 'FRIDAY', text: 'Sexta-Feira' },
                { key: 'SATURDAY', text: 'Sábado' }
            ],
            model: {}
        }
    },
    components: {
        Panel, 
        UtilBar,
    },
    computed: {
        services() {
            var services = this.services_flatten;
            if (this.selected) {
                services = services.filter(s => s == this.selected);
            }

            return services;
        },
        ...mapGetters('auth', [
            'has_permission'
        ]),
    },


    mounted() {
        this.LoadData();
    },

    methods: {
        ADD_DISCOUNT_PERCENT_PER_HOURS_COUNT() {
            if (!this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT) {
                this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT = [];
            }

            var item = {};
            this.$set(item, "START", "");
            this.$set(item, "END", "");
            for (var person_type of this.person_types) {
                this.$set(item, person_type.key, 0);
            }

            this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT.push(item);
        },
        REMOVE_DISCOUNT_PERCENT_PER_HOURS_COUNT(item) {
            if (!this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT) {
                this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT = [];
            }
            
            this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT = this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT = this.model.DISCOUNT_PERCENT_PER_HOURS_COUNT.filter((i) => i != item);
        },
        ADD_DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE() {
            if (!this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE) {
                this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE = [];
            }

            var item = {};
            this.$set(item, "START", "");
            this.$set(item, "END", "");
            for (var person_type of this.person_types) {
                this.$set(item, person_type.key, {});

                for (var weekday of this.weekdays) {
                    this.$set(item[person_type.key], weekday.key, 0);
                }
            }

            this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE.push(item);
        },
        REMOVE_DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE(item) {
            if (!this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE) {
                this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE = [];
            }
            
            this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE = this.model.DISCOUNT_PERCENT_PER_HOURS_ANTECEDENCE.filter((i) => i != item);
        },
        Prepare(pricetable) {
            for (var prop in pricetable) {
                this.$set(this.model, prop, pricetable[prop]);
            }

            for (var item of this.items) {
                var flatten = this.Flattten(item.children);
                this.$set(item, "children_flatten", flatten);
                this.$set(item, "tab", 0);
            }

            this.reset_table = _clone(this.model);
        },
        GetValue(key) {
            var value = this.model[key];
            return value;
        },
        SetValue(key, value, person_type, service_id) {
            var customer = 0;
            var percent = 0;
            var worker = 0;
            if (person_type == 'WORKER' && key.match(/_PERCENT$/i)) {
                if (key.match(/^WORKER_SERVICE_\d+_PERCENT$/i)) {
                    customer = this.CustomerPrice(service_id);
                    percent = !isNaN(value) ? parseFloat(value) : 0;
                    worker = customer * (percent / 100);
                    this.model[`WORKER_SERVICE_${service_id}`] = worker;
                }
                else {
                    percent = !isNaN(value) ? parseFloat(value) / 100 : 0;
                    worker = this.WorkerPrice(service_id);
                    worker = worker * percent;
                    var _key = key.replace(/_PERCENT$/i, "");
                    this.model[_key] = worker;
                }
            }

            this.model[key] = value;
        },
        CustomerPrice(service_id) {
            var key = `CUSTOMER_SERVICE_${service_id}`;
            var value = !isNaN(this.model[key]) ? parseFloat(this.model[key]) : 0;
            return value;
        },
        WorkerPrice(service_id) {
            var key = `WORKER_SERVICE_${service_id}`;
            var value = !isNaN(this.model[key]) ? parseFloat(this.model[key]) : 0;
            return value;
        },
        Flattten(items, parent = null) {
            var result = [];
            for (var item of items) {
                if (item.children.length > 0) {
                    result = result.concat(this.Flattten(item.children, item));
                }
                else {
                    this.$set(item, "parent", parent);
                    result.push(item);
                }
            }

            return result;
        },
        LoadData() {
            this.loadingData = true;

            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('service/getServices', params).then(data => {
                this.items = data ? data : [];
                this.services_flatten = this.Flattten(this.items);
                this.$store.dispatch('financial/getPriceTable').then((pricetable) => {
                    this.Prepare(pricetable);
                    this.loaded = true;
                    this.loadingData = false;
                    this.changed = false;
                });
            });
        },

        Profit(service_id, weekday) {
            var profit = 0;
            var worker = 0;
            var customer = 0;

            if (this.editing) {
                worker = this.WorkerPrice(service_id);
                customer = this.CustomerPrice(service_id);

                var worker_discount = this.model[`WORKER_SERVICE_${service_id}_${weekday}_DISCOUNT`];
                var customer_discount = this.model[`CUSTOMER_SERVICE_${service_id}_${weekday}_DISCOUNT`];

                worker = worker + worker_discount;
                customer = customer + customer_discount;
            }
            else {
                worker = this.model[`WORKER_SERVICE_${service_id}_${weekday}`];
                customer = this.model[`CUSTOMER_SERVICE_${service_id}_${weekday}`];
            }
            
            worker = worker && !isNaN(worker) ? parseFloat(worker) : 0;
            customer = customer && !isNaN(customer) ? parseFloat(customer) : 0;
            profit = customer - worker;

            return profit;
        },

        ProfitPercentage(service_id, weekday) {
            var customer = 0;
            
            if (this.editing) {
                customer = this.CustomerPrice(service_id);

                var customer_discount = this.model[`CUSTOMER_SERVICE_${service_id}_${weekday}_DISCOUNT`];

                customer = customer + customer_discount;
            }
            else {
                customer = this.model[`CUSTOMER_SERVICE_${service_id}_${weekday}`];
            }
            
            customer = customer && !isNaN(customer) ? parseFloat(customer) : 0;

            return customer > 0 ? (this.Profit(service_id, weekday) / customer) * 100 : 0;
        },

        Generate() {
            this.editing = false;
            this.loadingData = true;
            this.$store.dispatch('financial/generatePriceTable', this.model).then(data => {
                this.changed = true;
                this.model = data;
                this.loadingData = false;
            }).catch(() => {
                this.loadingData = false;
            });
        },

        Save() {
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                icon: "warning",
                title: "Salvar Alterações",
                text: "Deseja reamente salvar as alterações da tabela de preço?"
            }).then((result) => {
                if (result.value) {
                    swal.fire({
                        title: "Salvando",
                        didOpen() {
                            swal.showLoading();
                        }
                    })
                    this.$store.dispatch('financial/savePriceTable', this.model).then(data => {
                        swal.fire("Sucesso ao salvar!", "Salvo com sucesso!", "success");
                        this.changed = false;
                        this.model = data;
                    }).catch(() => {
                        swal.fire("Falha", "Falha ao salvar valores da tabela de preço.", "error");
                    });
                }
            });
        },

        ApplyOverallValues() {
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                icon: "warning",
                title: "Aplicar",
                html: `Deseja reamente aplicar <b>${this.overall_value}%</b> em todos os valores de cliente em todos os serviços?`
            }).then((result) => {
                if (result.value) {
                    this.items.forEach(item => {
                        item.children_flatten.forEach(service => {
                            var value = this.GetValue(`CUSTOMER_SERVICE_${service.id}`, 'CUSTOMER');
                            var discount = ((value * this.overall_value) / 100);
                            this.SetValue(`CUSTOMER_SERVICE_${service.id}`, (value + discount), 'CUSTOMER', service.id);
                        });
                    });
                }
            });
        },

        ResetTableValues() {
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                icon: "warning",
                title: "Descartar",
                text: `Deseja realmente descartar todos os valores alterados?`
            }).then((result) => {
                if (result.value) {
                    this.model = _clone(this.reset_table);
                }
            });
        },

        checkAlerts(service) {
            var increase = 0;
            var discounts = 0;
            var alerts = [];
            for (var person_type of this.person_types) {
                for (var weekday of this.weekdays) {
                    var value = this.getDiscount(person_type.key, service.id, weekday.key);
                    if (value != 0) {
                        if (value > 0) {
                            alerts.push(`<div class='text-primary'>* ${person_type.text} - neste serviço há um acréscimo de ${this.$options.filters.currency(value)} - <strong>${weekday.text}</strong></div>`);
                            increase++;
                        }
                        else {
                            alerts.push(`<div class='text-warning'>* ${person_type.text} - neste serviço há um desconto de ${this.$options.filters.currency(value)} - <strong>${weekday.text}</strong></div>`);
                            discounts++;
                        }
                    }
                }
            }

            return {
                alerts: alerts.join(""),
                discounts,
                increase
            };
        },

        getDiscount(person_type, service_id, weekday) {
            var key = `${person_type}_SERVICE_${service_id}_${weekday}_DISCOUNT`;
            var value = this.model[key] && !isNaN(this.model[key]) ? parseFloat(this.model[key]) : 0;
            return value;
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .increase, .discount {
        display: block;
        border-radius: 1000px;
        width: 12px;
        height: 12px;
        background-color: #000;
        border: 2px solid #fff;
        position: static;
        float: right;
        margin-top: 5px;
    }

    .increase {
        background-color: #007bff;
    }

    .discount {
        background-color: #f7bf4e;
    }

    .pricetable-edit {
        margin: 0px;
        thead {
            > tr:first-child {
                > th {
                    text-align: center;
                    border: none;
                    background: white;
                    background-color: white;
                    color: #000;
                }
            }
            
        }
    }

    .pricetable-nav {
        position: sticky;
        top: 120px;
        cursor: default;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

        * {
            color: #9051a0;
            cursor: inherit;
        }

        a {
            color: inherit;
            cursor: pointer;
        }

        .active, .active a {
            background-color: #9051a0;
            border-radius: 10px;
            color: #fff;
        }

        .root, .root > a {
            cursor: default;
        }
    }

    .pricetable-nav.changed {
        top: 180px;
    }

    .pricetable-view *, input {
        font-size: 12px;
    }

    .profit {
        font-size: 12px;
    }

    .price-table-content {
        overflow: auto;
    }

    @media screen and (min-width: 768px) {
        .price-table-content {
            max-width: 450px;
            min-width: 100%;
        }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (min-width: 1000px) {
        .price-table-content {
            max-width: 650px;
        }
    }

    .nav-item-service {
        border-bottom: 1px dotted; 
        border-left: 1px dotted;
        border-radius: 10px;
    }
</style>

<style scoped>
    .services-tabs >>> .nav-tabs {
        background-color: #f868a9;
    }

    .services-tabs >>> .nav-item {
        padding: 2px;
    }

    .services-tabs >>> a {
        color: #fff;
    }
</style>
<template>
<div id="customer-media">
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <label>Tipo de Cliente:</label>
                        <b-form-select v-model="filters.type" :options="type_options"></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col sm="12" md="8" lg="8">
                        <label>Cliente:</label>
                        <b-form-input v-model="filters.customer" placeholder="Nome do Cliente" />
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <label>Visualização:</label>
                        <b-form-checkbox v-model="filters.visualization" name="check-button" switch>
                            Agrupada
                        </b-form-checkbox>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col sm="12" md="4" lg="4">
                        <label>Ativo nos últimos (x) dias:</label>
                        <b-form-input v-model="filters.days" placeholder="Dias" />
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col sm="12" md="4" lg="4" class="pt-4">
                        <b-button variant="primary" block class="mt-2">
                            <b-icon icon="arrow-repeat" font-scale="1" class="mr-2" /> Recalcular</b-button>
                    </b-col>
                </b-row>
            </panel>

            <panel title="Localização" icon="map-marked-alt" icon-lib="fa">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <g-map :markers="locations"></g-map>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import moment from 'moment' // eslint-disable-line
import GMap from "@/components/GMap"
import UtilBar from "@/components/UtilBar"

export default {
    components: {
        Panel,
        GMap,
        UtilBar
    },
    data() {
        return {
            show: true,
            loadingData: false,
            filters: {
                visualization: false,
                type: '',
                customer: '',
                days: ''
            },
            type_options: [{
                    value: '',
                    text: 'Todos'
                },
                {
                    value: 1,
                    text: 'Pessoa Física'
                },
                {
                    value: 2,
                    text: 'Pessoa Jurídica'
                }
            ],
            locations: [{
                    position: {
                        lat: -23.5602918,
                        lng: -46.6354601,
                        title: "Camile"
                    }
                },]
        }
    },
    created() {},
    mounted() {
        this.geolocate();
    },
    methods: {
        addMarker(lat, lng) {
            /* if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng()
                };
                this.markers.push({
                    position: marker
                });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
            } */
            this.locations.push({
                    position: {lat: lat, lng: lng, title: "Minha localização"}
            });
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.addMarker(position.coords.latitude, position.coords.longitude);
            });
        }
    }
}
</script>

<style>

</style>

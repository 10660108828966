import Vue from 'vue' 
import Http from '@/api/http';
import EmployeeDomain from '@/domain/employee'

const state = {
    employees: [],
    employee: new EmployeeDomain(),
    permissions: null,
    isUpdate: false,
    is_update: false,
    errors: {}
};

const getters = {
    getPermissionModule: (state) => (module) => {
        return state.employee[`module_${module}`];
    },
    IsUpdate: (state) => {
        return state.is_update
    },
};

const actions = {

    loadInsertForm({ commit, dispatch }) {
        commit('SET_EMPLOYEE_ERROR', {});
        dispatch("IsUpdate", false);
        commit('SET_EMPLOYEE', new EmployeeDomain);
        return new Promise((resolve, reject) => {
            Http.get('employee/permissions').then(response => {
                commit('SET_PERMISSIONS', response.data);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    loadEditForm({ commit, dispatch }, id) {
        dispatch("IsUpdate", true);
        commit('SET_EMPLOYEE_ERROR', {});
        return new Promise((resolve, reject) => {
            Http.get('employee/' + id).then(response => {
                commit('SET_EMPLOYEE', response.data.employee)
                commit('SET_PERMISSIONS', response.data.permissions)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    IsUpdate({ commit }, is_update) {
        commit("EMPLOYEE_IS_UPDATE", is_update)
    },

    updatePermission({ commit }, {module, new_value} ) {
        commit('UPDATE_PERMISSION', {module, new_value})
    },

    getEmployees({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('employee', {params: params}).then(response => {
                commit('SET_EMPLOYEES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createEmployee({ state }) {
        return new Promise((resolve, reject) => {
            Http.post('employee', state.employee).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },
    
    deleteEmployee({commit}, employee) {
        return new Promise((resolve, reject) => {
            Http.delete('employee/' + employee.id).then(response => {
                commit('DELETE_EMPLOYEE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateEmployee({commit, state}) {
        return new Promise((resolve, reject) => {
            Http.put('employee/' + state.employee.id, state.employee).then(response => {
                commit('UPDATE_EMPLOYEE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    // * PHONE =============================
    createPhone({ commit, state }, index) {
        let phone = state.employee.person.phones[index]
        phone['person_id'] = state.employee.person.id

        return new Promise((resolve, reject) => {
            Http.post('phone', phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    updatePhone({ commit, state },index) {
        let phone = state.employee.person.phones[index]
        return new Promise((resolve, reject) => {
            Http.put(`phone/${phone.id}`, phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    deletePhone({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`phone/${state.employee.person.phones[index].id}`).then(response => {
                commit('SET_PHONE_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },
    // * ===========================================

};

const mutations = {

    EMPLOYEE_IS_UPDATE(state, is_update) {
        state.is_update = is_update
    },

    SET_EMPLOYEES(state, employees) {
        state.employees = employees;
    },

    SET_EMPLOYEE(state, employee) {
        state.employee = employee;
    },
    
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },

    UPDATE_PERMISSION(state, {module, new_value}) {
        state.employee[`module_${module}`] = new_value;
    },

    CREATE_EMPLOYEE(state, employee) {
        state.employees.push({employee});
    },

    UPDATE_EMPLOYEE(state, employee) {
        Vue.set(state.employee, employee);
    },

    DELETE_EMPLOYEE(state, employee) {
        state.employee = employee
    },

    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_EMPLOYEE_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },
    
    //PERSON
    SET_EMPLOYEE_NAME(state, value){
        state.employee.person.name = value
    },

    SET_EMPLOYEE_EMAIL(state, value){
        state.employee.person.email = value
    },

    SET_EMPLOYEE_PASSWORD(state, value){
        state.employee.person.password = value
    },

    SET_EMPLOYEE_IMAGE(state, value){
        state.employee.person.image = value
    },

    SET_EMPLOYEE_BIRTH(state, value){
        state.employee.person.birth = value
    },

    SET_EMPLOYEE_NATIONAL_DOCUMENT(state, value){
        state.employee.person.national_document = value
    },

    SET_EMPLOYEE_HIDE_FINANCIAL(state, value) {
        state.employee.person.hide_financial = value;
    },

    SET_EMPLOYEE_STATE_DOCUMENT(state, value){
        state.employee.person.state_document = value
    },

    SET_EMPLOYEE_TYPE(state, value){
        state.employee.person.type = value
    },

    SET_EMPLOYEE_GENDER(state, value){
        state.employee.person.gender = value
    },
    
    // * PHONE
    SET_PHONE_ADD(state){
        state.employee.person.phones.push({phone_number: '', description: '', is_personal: false, is_whatsapp: false})
    },
    
    SET_PHONE_NUMBER(state, data){
        for (var item in state.employee.person.phones) {
            if(item == data.index){
                state.employee.person.phones[item].phone_number = data.value
            }
        }
    },
    SET_PHONE_DESCRIPTION(state, data){
        for (var item in state.employee.person.phones) {
            if(item == data.index){
                state.employee.person.phones[item].description = data.value
            }
        }
    },
    SET_PHONE_IS_PERSONAL(state, data){
        for (var item in state.employee.person.phones) {
            if(item == data.index){
                state.employee.person.phones[item].is_personal = data.phone.is_personal ? true : false
            }
        }
    },
    SET_PHONE_IS_WHATSAPP(state, data){
        for (var item in state.employee.person.phones) {
            if(item == data.index){
                state.employee.person.phones[item].is_whatsapp = data.phone.is_whatsapp ? true : false
            }
        }
    },
    SET_UPDATE_PHONE(state, phone_update){
        state.employee.person.phones[phone_update.index] = phone_update.data
    },

    SET_PHONE_DELETE(state, index){
        state.employee.person.phones.splice(index, 1);
    },

    //ADDRESS
    SET_ZIPCODE(state, value) {
        state.employee.person.address.zipcode = value;
    },

    SET_STREET(state, value) {
        state.employee.person.address.street = value;
    },

    SET_NUMBER(state, value) {
        state.employee.person.address.number = value;
    },

    SET_COMPLEMENT(state, value) {
        state.employee.person.address.complement = value;
    },

    SET_REFERENCE(state, value) {
        state.employee.person.address.reference = value;
    },

    SET_ZONE(state, value) {
        state.employee.person.address.zone = value;
    },

    SET_DISTRICT(state, value) {
        state.employee.person.address.district = value;
    },

    SET_CITY_ID(state, value) {
        state.employee.person.address.city_id = value;
    },

    SET_STATE_ID(state, value) {
        if(state.employee.person.address.city.state.id != value){
            state.employee.person.address.city_id = null;
            state.employee.person.address.city = {
                id: null,
                state_id: null,
                name: '',
                enabled: 0,
                state: {
                id: value,
                name: '',
                acronym: ''
                }
            }
        }
    },

    SET_CITY_NAME(state, value) {
        state.employee.person.address.city_name = value;
    },

    SET_EMPLOYEE_ID(state, value){
        state.employee.id = value
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
<template>
    <panel title="Preferências" icon='' icon-lib="fa">
        <b-row class="form-group">
            <b-col sm="12" md="4" lg="4">
                <label class="mr-1">Prefere que o profissional seja do sexo:</label>
                <button-group :readonly="!edit && !insert" v-model="gender_preference" :options="gender_preferences"></button-group>
            </b-col>
        </b-row>
        <b-row v-if="!insert && has_permission('services-edit')">
            <b-col sm="12" md="12" lg="12" class="form-group">
                <b-button v-if="!edit" variant="mary-beleza" size="sm" @click="$emit('edit')">
                    <fa icon="pen" class="mr-2" /> Editar
                </b-button>
                <template v-else>
                    <b-button variant="outline-mary-beleza" size="sm" @click="$emit('cancel')" class="mr-2">
                        <fa icon="ban" class="mr-2" /> Cancelar
                    </b-button>
                    <b-button variant="mary-beleza" size="sm" @click="$emit('save')">
                        <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                    </b-button>
                </template>
            </b-col>
        </b-row>
    </panel>
</template>

<script>
import Panel from "@/components/Panel";
import ButtonGroup from '@/components/ButtonGroup';
import { mapGetters } from 'vuex'

export default {
    props: {
        edit: {
            type: [Boolean],
            default: false
        },
        insert: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        ...mapGetters('auth', [ 
            'has_permission' 
        ]),
        gender_preference: {
            get() {
                return this.$store.state.service.schedule.gender_preference
            },
            set(value) {
                this.$store.commit('service/SET_GENDER_PREFERENCE', value)
            }
        }
    },
    components: {
        Panel,
        ButtonGroup
    },
    data() {
        return {
            gender_preferences: [
                { id: 'female', text: "Feminino" },
                { id: 'male', text: "Masculino" },
                { id: null, text: "Sem Preferência" }
            ]
        };
    }
}
</script>
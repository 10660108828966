import Vue from 'vue' 
import Http from '@/api/http';
import WorkerDomain from '@/domain/worker'
import FiltersDomain from '@/domain/filters/worker'
import _cloneDeep from 'lodash/cloneDeep'
import Constants from '@/constants'

const state = {
    is_update: false,
    ranking: [],
    bonus: [],
    bonus_details: null,
    open_form: {
        isUpdate: false,
        personalInformation: false,
        habilities: false,
        additional_information: false,
        personAddress: false,
        contact: false,
        experiences: false,
        schooling: false,
        bankAccount: false,
        workerFiles: false,
        interview: false,
        training: false,
        restriction: false,
    },
    is_stage: false,
    index_stage: 0,
    stage_form: [
        'personalInformation', 
        'habilities',
        'additional_information', 
        'personAddress', 
        'contact', 
        'experiences', 
        'schooling', 
        'workerFiles',
        'interview',
    ],
    workers: [],
    habilities: [],
    habilities_all: [],
    experience: {},
    worker: new WorkerDomain(),
    workerDb: null,
    loading: false,
    errors: {},
    filters: {
        pre_registration: new FiltersDomain([Constants.STATUS_DISAPPROVED, Constants.STATUS_PRE_REGISTRATION, Constants.STATUS_APPROVED, Constants.STATUS_WAITING_CHECKING]),
        general_registration: new FiltersDomain([Constants.STATUS_NOT_SCHEDULED_TECHNICAL, Constants.STATUS_SCHEDULED_TECHNICAL]),
        approved_registration: new FiltersDomain([Constants.STATUS_NOT_SCHEDULED_TRAINING, Constants.STATUS_SCHEDULED_TRAINING]),
        mb_registration: new FiltersDomain([Constants.STATUS_PROFESSIONAL_MB])
    },
    historical: {
        filters: {
            service_date: null
        }
    }
};

const getters = {
    getWorker: (state) => (id_worker) => {
        return state.workers.items.find(worker => worker.id === id_worker)
    },
    IsUpdate: (state) => {
        return state.is_update
    },
    OpenForm: (state) => {
        return state.open_form
    },
    IsStage: (state) => {
        return state.is_stage
    },
    WorkerHability: (state) => (id) => {
        return state.worker.worker_habilities.find(hability => hability.hability_id == id)
    }
};

const actions = {

    // * WORKER FORM ============================= 
    loadEditForm({ commit, dispatch }, id) {
        commit('LOADING')
        dispatch("IsUpdate", true);
        dispatch("OpenForm", 'none')
        commit('SET_WORKER', new WorkerDomain);
        Http.get(`worker/${id}`).then(response => {
            // Passa o status para o IS_STAGE verificar o tipo de edição
            commit('LOADING')
            commit('IS_STAGE', response.data.status)
            commit('SET_WORKER', response.data)
        }).catch(() => {
            commit('LOADING')
        })
    },
    
    loadInsertForm({ commit, dispatch }) {
        dispatch("IsStage", 0);
        dispatch("IsUpdate", false);
        dispatch("OpenForm", 'all');
        commit('SET_WORKER', new WorkerDomain);
    },

    CancelEdit({ commit, dispatch, state }) {
        commit('SET_WORKER', state.workerDb)
        dispatch('OpenForm', 'none')
    },

    OpenForm({ commit }, form_name) {
        commit("WORKER_OPEN_FORM", form_name)
    },

    IsUpdate({ commit }, is_update) {
        commit("WORKER_IS_UPDATE", is_update)
    },

    IsStage({ commit }, is_stage) {
        commit("IS_STAGE", is_stage)
    },
    // * ========================================

    // * WORKER LIST =============================
    getList({ commit }, params) {
        return new Promise((resolve, reject) => {
            params.habilities = params.habilities.map(hability => hability.id)
            Http.get('worker', {params: params}).then(response => {
                commit('SET_WORKERS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getWorkers(_, params) {
        return new Promise((resolve, reject) => {
            Http.get('worker', {params: params}).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // * =========================================

    // * HABILITIES LIST =============================
    getHabilities({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('worker/habilities').then(response => {
                commit('SET_HABILITIES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getHabilitiesAll({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('worker/habilities/all').then(response => {
                commit('SET_HABILITIES_ALL', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // * =========================================

    // * WORKER BY ID =============================
    getWorkerById({ commit }, id) {
        commit('SET_WORKER', new WorkerDomain);
        return new Promise((resolve, reject) => {
            Http.get('worker/' + id).then(response => {
                commit('SET_WORKER', response.data)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    changePasswordWorkerById(_, id) {
        return new Promise((resolve, reject) => {
            Http.get(`person/${id}/changePassword`).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteWorkerById({commit}, id) {
        return new Promise((resolve, reject) => {
            Http.delete(`worker/${id}`).then(response => {
                commit('SET_DELETE_WORKER', id)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    contactWorkerById({commit}, id) {
        return new Promise((resolve, reject) => {
            Http.post(`workers/${id}/contact`).then(response => {
                commit('SET_CONTACT_WORKER', id)
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        });
    },
    // * =========================================

    // * CREATE WORKER ========================
    createWorker({commit, state}) {
        commit('SET_CLEAR_ERRORS')
        state.worker.status = Constants.STATUS_APPROVED
        return new Promise((resolve, reject) => {
            Http.post('worker', state.worker).then(response => {
                resolve(response.data)
            }).catch(error => {
                commit('SET_ADD_ERROR', error.response.data)
                reject(error.response.data)
            })
        });
    },
    // * =====================================

    // * UPDATE WORKER (WORKER, WORKER_DETAIL, PERSON, HABILITY) =============
    updateWorker({commit, state}, from = null) {
        let worker = _cloneDeep(state.worker)
        delete worker.experiences
        delete worker.person.address
        delete worker.person.image
        delete worker.detail.resume

        if(worker.detail.children_quantity == ''){
            worker.detail.children_quantity = null
        }

        if(worker.detail.children_minor_quantity == ''){
            worker.detail.children_minor_quantity = null
        }
        
        worker['from'] = from

        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            Http.put(`worker/${state.worker.id}`, worker).then(response => {
                commit('SET_UPDATE_WORKER', response.data.worker)
                if(!state.is_stage){commit("WORKER_CLOSE_FORM")}
                resolve(response.data)
            }).catch(error => {
                commit('SET_ADD_ERROR', error.response.data)
                reject(error.response.data)
            })
        })
    },
    // * ======================================

    // * UPDATE WORKER SCHEDULING =============
    updateScheduling({commit}, data) {
        return new Promise((resolve, reject) => {
            Http.put(`worker/${data.worker_id}/scheduling`, data.scheduling).then(response => {
                commit('SET_SCHEDULING_DETAILS', data)
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },
    // * ======================================
    
    // * EXPERIENCE (WORKER_EXPERIENCE) =============
    createExperience({commit, state}, index) {
        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            let experience = state.worker.experiences[index]
            experience['worker_id'] = state.worker.id

            Http.post(`workerExperience`, experience).then(response => {
                var experience_update = { index: index, data: response.data.experience }
                commit('SET_UPDATE_EXPERIENCE', experience_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    updateExperience({commit, state}, index) {
        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            let experience = state.worker.experiences[index]
            Http.put(`workerExperience/${experience.id}`, experience).then(response => {
                var experience_update = { index: index, data: response.data.experience }
                commit('SET_UPDATE_EXPERIENCE', experience_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data )
            })
        })
    },

    deleteExperience({commit, state}, index) {
        return new Promise((resolve, reject) => {
            Http.delete(`workerExperience/${state.worker.experiences[index].id}`).then(response => {
                commit('SET_DELETE_EXPERIENCE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },
    // * ======================================


    // * ADDRESS (ADDRESS) =============
    createAddress({commit, state}, index) {
        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            let address = state.worker.person.address[index]
            address.person_id = state.worker.person.id
            Http.post(`address`, address).then(response => {
                if(response.data.address != null) {
                    var address_update = { index: index, data: response.data.address }
                    commit('SET_UPDATE_ADDRESS', address_update)
                }
                resolve(response.data)
                commit("WORKER_CLOSE_FORM")
            
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    updateAddress({commit, state}, index) {
        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            let address = state.worker.person.address[index]
            Http.put(`address/${address.id}`, address).then(response => {
                var address_update = { index: index, data: response.data.address }
                commit('SET_UPDATE_ADDRESS', address_update)
                commit("WORKER_CLOSE_FORM")
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },
    // * ======================================


    // * PHONE =============================
    createPhone({ commit, state }, index) {
        commit('SET_CLEAR_ERRORS')
        let phone = state.worker.person.phones[index]
        phone['person_id'] = state.worker.person.id

        return new Promise((resolve, reject) => {
            Http.post('phone', phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    updatePhone({ commit, state },index) {
        commit('SET_CLEAR_ERRORS')
        let phone = state.worker.person.phones[index]
        return new Promise((resolve, reject) => {
            Http.put(`phone/${phone.id}`, phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    deletePhone({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`phone/${state.worker.person.phones[index].id}`).then(response => {
                commit('SET_PHONE_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },
    // * ===========================================

    // * APPROVE OR FAIL WORKER ========================
    changeStatusWorker({commit}, data) {
        return new Promise((resolve, reject) => {
            Http.post(`worker/${data.worker_id}/changeStatusWorker`, data).then(response => {
                commit('SET_STATUS', data.status);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        });
    },
    // * =====================================

    // * BLOCKED WORKER ========================
    blockedWorkerById(_, data) {
        return new Promise((resolve, reject) => {
            Http.get(`person/${data.worker_id}/blocked`, {params: data}).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // * =====================================
    ranking({commit}, params) {
        return new Promise((resolve, reject) => {
            Http.get(`worker/ranking`, { params }).then(response => {
                commit('SET_RANKING', response.data);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    bonus({commit}, params) {
        return new Promise((resolve, reject) => {
            Http.get(`worker/bonus-list`, { params }).then(response => {
                commit('SET_BONUS', response.data);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    bonusDetails({commit}, params) {
        return new Promise((resolve, reject) => {
            Http.get(`worker/bonus`, { params }).then(response => {
                commit('SET_BONUS_DETAILS', response.data);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            });
        })
    }
};

const mutations = {
    SET_BONUS_DETAILS(state, value) {
        state.bonus_details = value;
    },
    // * FORM
    STAGE_NEXT(state) {
        state.index_stage += 1

        // Testa para ver se o index do estagio é igual ao length da lista de estagio,
        // que quer dizer que o é o estagio final então ele muda o is_stage para false
        // e fecha todas as edições do formulário.
        if(state.index_stage === state.stage_form.length + 1){
            state.is_stage = false
            this.commit('worker/WORKER_CLOSE_FORM')
        }else{
            // Pega o nome do formulário do estagio atual para mostrar
            this.commit('worker/WORKER_OPEN_FORM', state.stage_form[state.index_stage -1])
            // Apos mostrar o estagio na tela é acrescentado 1 para o proximo estagio
            
        }
    },

    STAGE_PREVIOUS(state) {
        state.index_stage -= 1

        // Testa para ver se o index do estagio é igual ao length da lista de estagio,
        // que quer dizer que o é o estagio final então ele muda o is_stage para false
        // e fecha todas as edições do formulário.
        if(state.index_stage === state.stage_form.length){
            state.is_stage = false
            this.commit('worker/WORKER_CLOSE_FORM')
        }else{
            // Pega o nome do formulário do estagio atual para mostrar
            this.commit('worker/WORKER_OPEN_FORM', state.stage_form[state.index_stage - 1])
            // Apos mostrar o estagio na tela é acrescentado 1 para o proximo estagio
        }
    },

    IS_STAGE(state, status) {
        // Verifica se o status é igual 1 onde quer dizer que é um pré cadastro, se for
        // passa o is_stage para true e inicia o estagio em 0(no inicio), e faz um 
        // STAGE_NEXT para mostrar na tela
        if(status == Constants.STATUS_APPROVED){
            state.is_stage = true
            state.index_stage = 0
            this.commit('worker/STAGE_NEXT')
        }else{
            // Adiciona false ao is_stage caso não for status 1, pois se a pessoa não chegar ao
            // fim da edição por estagio o is_stage acaba ficando como true, assim toda a vez ele
            // testa para ver.
            state.is_stage = false
        }
    },

    LOADING(state) {
        state.loading = !state.loading
    },

    WORKER_OPEN_FORM(state, form_name) {
        state.workerDb = _cloneDeep(state.worker)
        Object.keys(state.open_form).map((item) => {
            state.open_form[item] =  false
        });

        if(form_name == 'none') {
            return;
        } else if(form_name == 'all') {
            Object.keys(state.open_form).map((item) => {
                state.open_form[item] =  true
            });
        } else {
            state.open_form[form_name] = true;
        }
    },

    WORKER_CLOSE_FORM(state) {
        Object.keys(state.open_form).map((item) => {
            state.open_form[item] =  false
        });
    },

    WORKER_IS_UPDATE(state, is_update) {
        state.is_update = is_update
    },
    // * =============================

    // * WORKER

    SET_WORKERS(state, worker) {
        state.workers = worker;
    },

    SET_DELETE_WORKER(state, worker_id) {
        let index = state.workers.items.findIndex(worker => worker.id == worker_id);
        state.workers.items.splice(index, 1);
    },
    
    SET_WORKER(state, worker) {
        if(worker.person.address.length === 0){
            let model = new WorkerDomain()
            worker.person.address[0] = model.person.address[0]
        }
        Object.assign(state.worker, worker);
        state.workerDb = _cloneDeep(state.worker)
    },
    // * =============================


    // * WORKER, DETAIL, PERSON ===========
    SET_UPDATE_WORKER(state, worker) {
        Vue.set(state.worker, worker);
        state.worker.person.image = worker.person.image
        state.worker.detail.resume = worker.detail.resume
    },
    // * ================================


    // * ADDRESS ===========
    SET_UPDATE_ADDRESS(state, address_update) {
        state.worker.person.address[address_update.index] = address_update.data
    },
    // * ================================

    // * EXPERIENCE ===========
    SET_CREATE_EXPERIENCE(state, experience_update) {
        state.worker.experiences[experience_update.index] = experience_update.data
    },

    SET_UPDATE_EXPERIENCE(state, experience_update) {
        state.worker.experiences[experience_update.index] = experience_update.data
    },

    SET_DELETE_EXPERIENCE(state, index) {
        state.worker.experiences.splice(index, 1)
    },
    // * ================================


    // * PHONE ==========================
    SET_UPDATE_PHONE(state, phone_update){
        state.worker.person.phones[phone_update.index] = phone_update.data
    },

    SET_PHONE_DELETE(state, index){
        state.worker.person.phones.splice(index, 1);
    },
    // * ================================


    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_ADD_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },
    // * =============================
    

    // * WORKER
    SET_FUNCTION_HABILITY_OBS(state, value){
        state.worker.function_hability_obs = value
    },
    SET_INTERVIEWER(state, value){
        state.worker.interviewer = value
    },
    SET_INTERVIEWER_DETAILS(state, value){
        state.worker.interview_details = value
    },
    SET_WORK_NUMBER(state, value){
        state.worker.detail.work_number = value
    },
    SET_WORK_NUMBER_SERIE(state, value){
        state.worker.detail.work_number_serie = value
    },
    SET_MARITAL(state, value){
        state.worker.marital = value
    },
    SET_TRAINING(state, value){
        state.worker.training = value
    },
    SET_TRAINING_DT(state, value){
        state.worker.training_dt = value
    },
    SET_PROFESSIONAL_TYPE(state, value){
        state.worker.professional_type = value
    },
    SET_STATUS(state, value){
        state.worker.status = value
    },
    SET_WHO_INSERTED(state, value){
        state.worker.who_inserted = value
    },
    SET_RESTRICTION_SICK_PEOPLE(state, value){
        state.worker.restriction_sick_people = value
    },
    SET_RESTRICTION_CHILDREN(state, value){
        state.worker.restriction_children = value
    },
    SET_RESTRICTION_ELDERLY(state, value){
        state.worker.restriction_elderly = value
    },
    SET_BEHAVIORAL_GRADE(state, value){
        state.worker.behavioral_grade = value
    },
    SET_TECHNICAL_GRADE(state, value){
        state.worker.technical_grade = value
    },
    SET_MOTHER_NAME(state, value){
        state.worker.mother_name = value
    },
    SET_BANK_THIRD_PARTY(state, value){
        state.worker.bank_third_party = value
    },
    SET_BANK_THIRD_PARTY_NAME(state, value){
        state.worker.bank_third_party_name = value
    },
    SET_BANK_THIRD_PARTY_BIRTH(state, value){
        state.worker.bank_third_party_birth = value
    },
    SET_BANK_THIRD_PARTY_CPF(state, value){
        state.worker.bank_third_party_cpf = value
    },
    SET_WORKER_DOC_FILENAME(state, value){
        state.worker.worker_doc_filename = value
    },
    SET_ACTIVED_ASAAS(state, value){
        state.worker.actived_asaas = value
    },  
    SET_BANK_CHANGED(state, value){
        state.worker.bank_changed = value
    },
    SET_DENIED_LEVEL(state, value){
        state.worker.denied_level = value
    },
    SET_DENIED_REASON(state, value){
        state.worker.denied_reason = value
    },
    SET_RANK(state, value){
        state.worker.rank = value
    },
    SET_BANK_AGENCY(state, value){
        state.worker.bank_agency = value
    }, 
    SET_BANK_ACCOUNT(state, value){
        state.worker.bank_account = value
    }, 
    SET_BANK_ACCOUNT_TYPE(state, value){
        state.worker.bank_account_type = value
    }, 
    SET_BANK_OPERATION(state, value){
        state.worker.bank_operation = value
    }, 
    SET_BANK_ACCOUNT_DIGIT(state, value){
        state.worker.bank_account_digit = value
    }, 
    SET_BANK_AGENCY_DIGIT(state, value){
        state.worker.bank_agency_digit = value
    }, 
    SET_BANK_ID(state, value){
        state.worker.bank_id = value
    },
    SET_DISAPPROVAL_REASON(state, value){
        state.worker.disapproval_reason = value
    },
    SET_CONTACT_WORKER(state, value){
        let index = state.workers.items.findIndex(item => item.id == value);
        state.workers.items[index].number_of_contacts += 1;
    },
    // * =============================
    
    // * PERSON
    SET_NAME(state, value){
        state.worker.person.name = value
    },
    SET_EMAIL(state, value){
        state.worker.person.email = value
    },
    SET_PASSWORD(state, value){
        state.worker.person.password = value
    },
    SET_IMAGE(state, value){
        state.worker.person.image = value
    },
    SET_BIRTH(state, value){
        state.worker.person.birth = value
    },
    SET_NATIONAL_DOCUMENT(state, value){
        state.worker.person.national_document = value
    },
    SET_STATE_DOCUMENT(state, value){
        state.worker.person.state_document = value
    },
    SET_TYPE(state, value){
        state.worker.person.type = value
    },
    SET_GENDER(state, value){
        state.worker.person.gender = value
    },
    SET_RESTRICTION(state, value){
        state.worker.person.restriction = value
    },
    SET_RESTRICT_REASON(state, value){
        state.worker.person.restrict_reason = value
    },
    SET_RESTRICTION_LEVEL(state, value){
        state.worker.person.restriction_level = value
    },
    SET_EXISTENCE(state, value){
        state.worker.person.existence = value
    },
    SET_WHO_EXISTENCE(state, value){
        state.worker.person.who_existence = value
    },
    // * =============================

    // * EXPERIENCES
    SET_EXPERIENCE_ADD(state){
        state.worker.experiences.push({
            place: null, //string
            office: null, // ? cargo - post? | string 
            admission: null, //date
            dismissal: null,  //date
            contact: null, //string
            phone: null, //string
            professional_allegations: null, //text
            checking: null, //text
            address: null, // ? endereço - neighborhood? | string
        })
    },

    SET_EDIT_EXPERIENCE(state, index){
        state.experience = _cloneDeep(state.worker.experiences[index])
    },

    CANCEL_EDIT_EXPERIENCE(state, index){
        state.worker.experiences[index] = state.experience
        state.experience = {}
    },

    SET_PLACE(state, data){
        state.worker.experiences[data.index].place = data.value
    },
    SET_OFFICE(state, data){
        state.worker.experiences[data.index].office = data.value
    },
    SET_ADMISSION(state, data){
        state.worker.experiences[data.index].admission = data.value
    },
    SET_DISMISSAL(state, data){
        state.worker.experiences[data.index].dismissal = data.value
    },
    SET_CONTACT(state, data){
        state.worker.experiences[data.index].contact = data.value
    },
    SET_PHONE(state, data){
        state.worker.experiences[data.index].phone = data.value
    },
    SET_PROFESSIONAL_ALLEGATIONS(state, data){
        state.worker.experiences[data.index].professional_allegations = data.value
    },
    SET_ADDRESS(state, data){
        state.worker.experiences[data.index].address = data.value
    },
    SET_CHECKING(state, data){
        state.worker.experiences[data.index].checking = data.value
    },
    // * =========================


    // * DETAIL
    SET_SCHEDULING_DETAILS(state, data){
        let index = state.workers.items.findIndex(item => item.id == data.worker_id);
        state.workers.items[index].status = data.status
        state.workers.items[index].contact_scheduling_technical = data.scheduling.contact_scheduling_technical
        state.workers.items[index].scheduling_technical = data.scheduling.scheduling_technical
        state.workers.items[index].scheduling_training = data.scheduling.scheduling_training
        state.workers.items[index].contact_scheduling_training = data.scheduling.contact_scheduling_training
    },
    SET_INTERVIEW_DETAILS(state, value){
        state.worker.detail.interview_details = value
    },
    SET_HABILITIES_OBSERVATION(state, value){
        state.worker.detail.habilities_observation = value
    },
    SET_DRIVERLICENSE(state, value){
        state.worker.detail.driverlicense = value
    },
    SET_DRIVERLICENSETYPE(state, value){
        state.worker.detail.driverlicensetype = value
    },
    SET_SMOKE(state, value){
        state.worker.detail.smoke = value
    },
    SET_DRINK(state, value){
        state.worker.detail.drink = value
    },
    SET_READ(state, value){
        state.worker.detail.read = value
    },
    SET_WRITE(state, value){
        state.worker.detail.write = value
    },
    SET_SCHOOLING(state, value){
        state.worker.detail.schooling = value
    },
    SET_GRADUATED_AT(state, value){
        state.worker.detail.graduated_at = value
    },
    SET_EXTENSION_CURSES(state, value){
        state.worker.detail.extension_curses = value
    },
    SET_CHILDREN(state, value){
        state.worker.detail.children = value
    },
    SET_CHILDREN_QUANTITY(state, value){
        state.worker.detail.children_quantity = value
    },
    SET_CHILDREN_MINOR(state, value){
        state.worker.detail.children_minor = value
    },
    SET_CHILDREN_MINOR_QUANTITY(state, value){
        state.worker.detail.children_minor_quantity = value
    },
    SET_CHILDREN_WHO(state, value){
        state.worker.detail.children_who = value
    },
    SET_FAMILY_DRUGS(state, value){
        state.worker.detail.family_drugs = value
    },
    SET_FAMILY_DRUGS_DETAIL(state, value){
        state.worker.detail.family_drugs_detail = value
    },
    SET_FAMILY_JUSTICE(state, value){
        state.worker.detail.family_justice = value
    },
    SET_FAMILY_JUSTICE_DETAIL(state, value){
        state.worker.detail.family_justice_detail = value
    },
    SET_WORKER_HEALTH(state, value){
        state.worker.detail.worker_health = value
    },
    SET_WORKER_HEALTH_DETAIL(state, value){
        state.worker.detail.worker_health_detail = value
    },
    SET_FAMILY_HEALTH(state, value){
        state.worker.detail.family_health = value
    },
    SET_FAMILY_HEALTH_DETAIL(state, value){
        state.worker.detail.family_health_detail = value
    },
    SET_SMOKE_DRINK_DETAIL(state, value){
        state.worker.detail.smoke_drink_detail = value
    },
    SET_ACCEPT_HOME_CARE(state, value){
        state.worker.detail.accept_home_care = value
    },
    SET_INSTAGRAM(state, value){
        state.worker.detail.instagram = value
    },
    SET_FACEBOOK(state, value){
        state.worker.detail.facebook = value
    },
    SET_HAS_MEI(state, value){
        state.worker.detail.has_mei = value
    },
    SET_HAS_VEHICLE(state, value){
        state.worker.detail.has_vehicle = value
    },
    SET_HAS_VEHICLE_WHICH(state, value){
        state.worker.detail.has_vehicle_which = value
    },
    SET_ANOTHER_BEAUTY_APP(state, value){
        state.worker.detail.another_beauty_app = value
    },
    SET_RESUME(state, value){
        state.worker.detail.resume = value
    },
    SET_PROFESSIONAL_REFERENCES(state, value){
        state.worker.detail.professional_references = value
    },
    // * ==============================


    // * PHONE ========================
    SET_PHONE_ADD(state){
        state.worker.person.phones.push({phone_number: '', description: '', is_personal: false, is_whatsapp: false})
    },
    SET_PHONE_NUMBER(state, data){
        state.worker.person.phones[data.index].phone_number = data.value
    },
    SET_PHONE_DESCRIPTION(state, data){
        state.worker.person.phones[data.index].description = data.value
    },
    SET_PHONE_IS_PERSONAL(state, data){
        state.worker.person.phones[data.index].is_personal = data.phone.is_personal
    },
    SET_PHONE_IS_WHATSAPP(state, data){
        state.worker.person.phones[data.index].is_whatsapp = data.phone.is_whatsapp
    },

    // * ADDRESS ========================
    SET_ADDRESS_NAME(state, data){
        state.worker.person.address[data.index].name = data.value
    },
    SET_ZIPCODE(state, data){
        state.worker.person.address[data.index].zipcode = data.value
    },
    SET_STREET(state, data){
        state.worker.person.address[data.index].street = data.value
    },
    SET_NUMBER(state, data){
        state.worker.person.address[data.index].number = data.value
    },
    SET_REFERENCE(state, data){
        state.worker.person.address[data.index].reference = data.value
    },
    SET_ZONE(state, data){
        state.worker.person.address[data.index].zone = data.value
    },
    SET_DISTRICT(state, data){
        state.worker.person.address[data.index].district = data.value
    },
    SET_COMPLEMENT(state, data){
        state.worker.person.address[data.index].complement = data.value
    },
    SET_STATE_ID(state, data){
        if(!state.worker.person.address[data.index] || !state.worker.person.address[data.index].city || !state.worker.person.address[data.index].city.state || state.worker.person.address[data.index].city.state.id != data.value){
            if (!state.worker.person.address[data.index]) {
                data.index = 0;
                state.worker.person.address = [{}];
            }

            state.worker.person.address[data.index].city_id = null
            state.worker.person.address[data.index].city = {
                id: null,
                state_id: null,
                name: '',
                enabled: 0,
                state: {
                  id: data.value,
                  name: '',
                  acronym: ''
                }
            }
        }
    },
    SET_CITY_ID(state, data){
        state.worker.person.address[data.index].city_id = data.value
    },
    // * ==============================


    // * HABILITIES
    SET_HABILITIES(state, value){
        state.habilities = value
    },
    SET_HABILITIES_ALL(state, value){
        state.habilities_all = value
    },
    // * ==============================


    // * SELF HABILITIES
    SET_WORKER_HABILITIES(state, value){
        let index = state.worker.worker_habilities.findIndex(item => item.hability_id == value.hability_id);
        if(index < 0) {
            var habilities = state.worker.worker_habilities;
            habilities.push({ 
                hability_id: value.hability_id, 
                parent_id: value.parent_id, 
                self_evaluation_grade: null,
                mb_grade: null,
                time_experience: null,
                obs: null
             });

            state.worker.worker_habilities = habilities;
        } else if (!value.only_check) {
            if(value.parent_id){
                state.worker.worker_habilities.splice(index, 1);
            }else{ 
                state.worker.worker_habilities.splice(index, 1);
                do {
                    let index_parent = state.worker.worker_habilities.findIndex(item => item.parent_id == value.hability_id)
                    if(index_parent > -1){
                        state.worker.worker_habilities.splice(index_parent, 1)
                    }
                } while (state.worker.worker_habilities.find(item => item.parent_id == value.hability_id))
            }
        }
    },

    SET_SELF_EVALUATION_GRADE(state, data){
        state.worker.worker_habilities.forEach(item => {
            if(item.hability_id == data.id){
                item['self_evaluation_grade'] = data.grade
            }
        })
    },

    SET_MB_GRADE(state, data){
        state.worker.worker_habilities.forEach(item => {
            if(item.hability_id == data.id){
                item['mb_grade'] = data.grade
            }
        })
    },

    SET_TIME_EXPERIENCE(state, data){
        state.worker.worker_habilities.forEach(item => {
            if(item.hability_id == data.id){
                item['time_experience'] = data.time_experience
            }
        })
    },

    SET_OBS_HABILITY(state, data){
        state.worker.worker_habilities.forEach(item => {
            if(item.hability_id == data.id){
                item['obs'] = data.obs
            }
        })
    },

    // * FILTERS
    SET_CLEAN_FILTERS(state, data){
        //console.log('modulo js '+ data.module)
        localStorage.removeItem(`persist_page_${data.module}`);
        localStorage.removeItem(`persist_search_${data.module}`);
        state.filters[data.module] = new FiltersDomain(data.status)
    },

    SET_CLEAN_FILTERS_HISTORICAL(state){
        state.historical.filters = {
            service_date: null
        }
    },
    // * =========================

    SET_RANKING(state, value) {
        state.ranking = value;
    },

    SET_BONUS(state, value) {
        state.bonus = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

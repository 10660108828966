import Vue from 'vue' 
import Http from '@/api/http'

const state = {
    user: {
        id: '',
        name: '',
        type: '',
        employee: {
            permissions: []
        }
    },
    logout: false
};

const getters = {
    user: state => {
        return state.user
    },
    has_permission: state => permission => state.user.employee.permissions.includes(permission)
};

const actions = {
    login({ commit }, params) {
        localStorage.setItem('vuex', null);
        return new Promise((resolve, reject) => {
            Http.post('auth/login', params).then(response => {
                let user = response.data.user
                user.token = response.data.token
                commit('SET_USER', response.data.user)
                Vue.prototype.router.push('/');
                resolve()
            }).catch(error => {
                reject(error.response.data.error)
            })
        })
    },
    logout({ commit }) {
        Vue.prototype.router.replace('/login');
        commit('LOGOUT')
        localStorage.setItem('vuex', null);
    },
};

const mutations = {

    SET_USER (state, obj) {
        state.user = obj
    },
    UPDATE_TOKEN(state, token) {
        state.user.token = token
    },
    LOGOUT(state) {
        state.user = null;
        state.logout = true;
        location.reload()
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
<template>
<div>
    <panel title="FINANCEIRO" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label>Exibir nos relatórios:</label>
                            <b-form-checkbox v-model="hide_financial" :value="0" :unchecked-value="1" switch>
                                {{hide_financial ? "Não" : "Sim" }}
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"

export default {

    components: {
        Panel,
    },

    data() {
        return {
            password_eye: false,
        }
    },

    methods: {
        passwordVisibility() {
            this.password_eye = !this.password_eye;
        },
    },

    computed: {
        hide_financial: {
            get() {
                return this.$store.state.employee.employee.person.hide_financial;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_HIDE_FINANCIAL', value)
            }
        },

        password: {
            get() {
                return this.$store.state.employee.employee.person.password;
            },
            set(value) {
                this.$store.commit('employee/SET_EMPLOYEE_PASSWORD', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.employee.errors;
            },
        },
    }

}
</script>

<style>

</style>

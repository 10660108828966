<template>
    <div>
        <util-bar></util-bar>
        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Data do Serviço - Período</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filter_date_start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filter_date_end"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col>
                        <label>Com nota fiscal:</label>
                        <button-group :options="options" v-model="filter_invoices"></button-group>
                    </b-col>
                    <b-col v-if="[null, 1].includes(filter_invoices)" sm="12" md="4" lg="4" class="form-group">
                        <label>Situação: <b-icon icon="patch-question-fll" class="text-mary-beleza" id="status"></b-icon></label>
                        <b-form-select v-model="filter_status" :options="status_options"></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4">
                        <label></label>
                        <p>
                            <b-button block class="mt-2 my-2" variant="outline-danger" @click="CleanFilters">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>
            </panel>
            <panel v-if="has_permission('asaas-invoices-edit')" title="NFS-e" icon="file" :class="invoices_show_select ? 'sticky-nfs-e' : null">
                <b-row>
                    <b-col v-if="!invoices_show_select" sm="12" md="4" lg="4">
                        <b-button class="ml-2" variant="primary" @click="$store.commit('financial/SET_INVOICES_SHOW_SELECT')" v-b-tooltip="'Selecionar mais de um pagamento para gerar NFS-e'">Multiplas NFS-e</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="invoices_show_select">
                    <b-col sm="12" md="3" lg="3">
                        <label>Quantidades de notas: </label>
                        {{ invoices_count }}
                    </b-col>
                    <b-col sm="12" md="5" lg="5">
                        <label>Valor selecionado: </label>
                        {{ invoices_values | currency }}
                    </b-col>
                    <b-col v-if="invoices_count > 0" sm="12" md="2" lg="2">
                        <b-button block variant="primary" @click="ScheduleInvoices(null)">Gerar Notas</b-button>
                    </b-col>
                    <b-col sm="12" :md="invoices_count > 0 ? 2 : 4" :lg="invoices_count > 0 ? 2 : 4" class="text-right">
                        <b-button block variant="outline-danger" @click="$store.commit('financial/SET_INVOICES_SHOW_SELECT')">Cancelar</b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Painel">
                <b-row class="form-group">
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table
                                    ref="invoicesRichTable"
                                    v-on:input="ChangeTableConfig"
                                    :numbers="table_numbers"
                                    :loading="loadingData"
                                    :cols="table_cols"
                                    searchPlaceholder="Número NFS-e | Serviço"
                                    class="b-table-service-list">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, i) in items" :key="i">
                                            <td style="width: 18%">
                                                <span>{{item.service_date | moment("DD/MM/YYYY")}}</span>
                                            </td>
                                            <td style="width: 10%">{{item.number}}</td>
                                            <td style="width: 22%">
                                                {{item.customer.person.name}}
                                                <p style="font-size:14px" class="m-0 text-muted">Valor recebido: {{CalcValueTransactionsByType(item.transactions, 1) | currency}}</p>
                                            </td>
                                            <td style="width: 22%">
                                                {{item.worker.person.name}}
                                                <p style="font-size:14px" class="m-0 text-muted">Valor pago: {{CalcValueTransactionsByType(item.transactions, 0) | currency}}</p>
                                            </td>
                                            <td style="width: 12%">
                                                <p class="m-0">{{CalcValueTransactions(item.transactions) | currency}}</p>
                                            </td>
                                            <td style="width: 10%">
                                                <span v-if="item.invoice.length > 0">
                                                    <b-badge class="m-0" pill :variant="InvoiceGetStatus(item.invoice[0].status).variant" :id="'invoice-'+ i">{{InvoiceGetStatus(item.invoice[0].status).text}}</b-badge>
                                                    <b-tooltip v-if="item.invoice[0].rps_serie && item.invoice[0].rps_number" :target="'invoice-'+ i" placement="bottom">
                                                        <p class="m-0">Serie: {{item.invoice[0].rps_serie}}</p>
                                                        <p class="m-0">Número: {{item.invoice[0].number}}</p>
                                                    </b-tooltip>
                                                </span>
                                                <b v-else class="text-danger">-</b>
                                            </td>
                                            <td style="width: 10%">
                                                <span v-if="invoices_show_select && item.invoice.length <= 0 && has_permission('asaas-invoices-edit')">
                                                    <b-form-checkbox :checked="!!InvoicesHasSelected(item)" @change="$store.commit('financial/SET_INVOICES_SELECTED', item)"/>
                                                </span>
                                                <span v-else-if="item.invoice.length <= 0">
                                                    <b-button size="sm" v-if="has_permission('asaas-invoices-edit')" class="mt-2 ml-2 text-nowrap" variant="primary" v-b-tooltip="'Gerar NFS-e'" @click="ScheduleInvoices(item)">
                                                        <b-icon icon="plus" scale="1.1"/>Emitir
                                                    </b-button>
                                                </span>
                                                <span v-if="item.invoice.length > 0">
                                                    <p class="m-0 d-flex">
                                                        <a v-if="item.invoice[0].pdf_url" :href="item.invoice[0].pdf_url" target="_blank">
                                                            <b-button class="mt-2 ml-2" size="sm" v-b-tooltip="'Baixar PDF'" variant="primary">
                                                                <b-icon icon="file-earmark-richtext-fill" scale="1.1"/>
                                                            </b-button>
                                                        </a>
                                                        <a v-if="item.invoice[0].xml_url" :href="item.invoice[0].xml_url" target="_blank">
                                                            <b-button class="mt-2 ml-2" size="sm" variant="primary" v-b-tooltip="'Baixar XML'">
                                                                <b-icon icon="file-earmark-code-fill" scale="1.1"/>
                                                            </b-button>
                                                        </a>
                                                        <b-button v-if="[CONSTANTS.STATUS_SCHEDULED, CONSTANTS.STATUS_SYNCHRONIZED, CONSTANTS.STATUS_AUTHORIZED, CONSTANTS.STATUS_INVOICE_UNKNOW].includes(item.invoice[0].status)" @click="cancelScheduleInvoices(item)" class="pt-0 mt-2 ml-2" size="sm" variant="danger" v-b-tooltip="'Cancelar emisão de NFS-e'">
                                                            <b-icon icon="file-earmark-excel-fill" scale="1.1"/>
                                                        </b-button>
                                                    </p>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else class="odd zero_records">
                                        <td :colspan="table_cols.length" class="text-center">Nenhum registro encontrado</td>
                                    </tr>
                                </rich-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable';
import ImageLoader from '@/mixins/ImageLoader'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import TypeableDate from '@/components/TypeableDate'
import ButtonGroup from '@/components/ButtonGroup'
import Constants from '@/constants'

export default {
    mixins: [ ImageLoader ],

    computed: {
        ...mapGetters('auth', [ 
            'has_permission' 
        ]),
        CONSTANTS() {
            return Constants;
        },
        filters: {
            get() {
                return this.$store.state.financial.filters_asaas_invoices;
            }
        },
        filter_invoices: {
            get() {
                return this.filters.invoice;
            },
            set(value) {
                if (value === this.filters.invoice) {
                    return;
                }

                this.filters.invoice = value;
                this.LoadData();
            }
        },
        filter_status: {
            get() {
                return this.filters.invoice_status;
            },
            set(value) {
                if (value === this.filters.invoice_status) {
                    return;
                }

                this.filters.invoice_status = value;
                this.LoadData();
            }
        },
        filter_date_start: {
            get() {
                return this.filters.service_date.start;
            },
            set(value) {
                if (value === this.filters.service_date.start) {
                    return;
                }

                this.filters.service_date.start = value;
                this.LoadData();
            }
        },
        filter_date_end: {
            get() {
                return this.filters.service_date.end;
            },
            set(value) {
                if (value === this.filters.service_date.end) {
                    return;
                }

                this.filters.service_date.end = value;
                this.LoadData();
            }
        },
        invoices_selected: {
            get() {
                return this.$store.state.financial.invoices_selected;
            },
        },
        invoices_show_select: {
            get() {
                return this.$store.state.financial.invoices_show_select;
            },
        },
        invoices_count: {
            get() {
                return this.$store.state.financial.invoices_selected.length;
            },
        },
        invoices_values: {
            get() {
                return this.invoices_selected.reduce(function(total, item){ 
                    var expanse = item.transactions.find(item => item.type == 0);
                    var recipe = item.transactions.find(item => item.type == 1);
                    return total + (recipe.value - expanse.value)
                },0);
            },
        },
        table_cols() {
            var cols = [
                { id: 'service_date', text: 'Data do serviço', class: 'cols_service_date' },
                { id: 'number', text: 'Número', class: 'cols_number' },
                { id: null, text: 'Cliente', class: 'cols_customer' },
                { id: null, text: 'Profissional', class: 'cols_worker' }, 
                { id: null, text: 'Valor da nota', class: 'cols_value' },
                { id: null, text: 'status', class: 'cols_status' },
                { id: null, text: 'Ações', class: 'cols_actions' },
            ];
            return cols;
        },
    },
    components: {
        Panel, 
        RichTable,
        UtilBar,
        TypeableDate,
        ButtonGroup
    },
    data() {
        return {
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            table_config: {
                search: '',
                sort_column: "due_date",
                sort_order: "ASC",
                limit: 10,
                offset: 0
            },
            loadingData: false,
            items: [],
            status: null,
            options: [
                { id: null, text: "Todas" },
                { id: 1, text: "Sim" },
                { id: 0, text: "Não" },
            ],
            status_options: [
                { value: null, text: "Todos" },
                { value: Constants.STATUS_SCHEDULED, text: "Agendada", variant: "primary" },
                { value: Constants.STATUS_SYNCHRONIZED, text: "Processando Emissão", variant: "warning" },
                { value: Constants.STATUS_AUTHORIZED, text: "Autorizada",variant: "success" },
                { value: Constants.STATUS_CANCELED, text: "Cancelada", variant: "danger" },
                { value: Constants.STATUS_PROCESSING_CANCELLATION, text: "Processando cancelamento", variant: "secondary" },
                { value: Constants.STATUS_CANCELLATION_DENIED, text: "Cancelamento negado", variant: "danger" },
                { value: Constants.STATUS_INVOICE_UNKNOW, text: "Desconhecido", variant: "light" },
                { value: Constants.STATUS_ERROR, text: "Em Erro", variant: "danger" },
            ],
        }
    },
    methods: {
        LoadData() {
            this.loadingData = true;
            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('financial/getScheduleInvoices', params).then((response) => {
                this.loadingData = false;
                var data = response;

                this.items = data.items ? data.items : [];

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : null;
            }).catch(() => {
                this.items = [];
                this.loadingData = false;
            });
        },
        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },
        moment(...args){
            return this.$moment(...args);
        },
        ScheduleInvoices(item) {
            this.$swal({
                title: 'Gerar NFS-e',
                text: "Deseja realmente gerar NFS-e? Esta ação não poderá ser desfeita.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
                icon: "question",
            }).then((result) => {
                if (result.value) {
                    this.loadingData = true;
                    this.$store.dispatch("financial/saveScheduleInvoices", item).then(data => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Sucesso!',
                            text: data.success,
                            icon: 'success',
                            padding: 20,
                        })
                        if(this.invoices_show_select){
                            this.$store.commit('financial/SET_INVOICES_SHOW_SELECT');
                        }
                        this.LoadData();
                    }).catch(error => {
                        this.loadingData = false;
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            });
        },
        CleanFilters() {
            this.$store.commit('financial/SET_CLEAN_FILTERS_ASAAS_INVOICES');
            this.$refs["invoicesRichTable"].CleanFilters();
        },
        InvoicesHasSelected(transaction){
            return this.invoices_selected.find(item => item.id == transaction.id);
        },
        cancelScheduleInvoices(item) {
            this.$swal({
                title: 'Cancelar',
                html: `Deseja realmente cancelar a NFS-e do agendamento de Nº <b>${item.number}</b>? Esta ação não poderá ser desfeita.`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
                icon: "question",
            }).then((result) => {
                if (result.value) {
                    this.loadingData = true;
                    this.$store.dispatch("financial/cancelScheduleInvoices", item.invoice[0].id).then(data => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Successo!',
                            text: data.success,
                            icon: 'success',
                            padding: 20,
                        })
                        this.LoadData();
                    }).catch(error => {
                        this.loadingData = false;
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            });
        },
        CalcValueTransactions(transactions){
            let expanse = transactions.find(item => item.type == 0);
            let recipe = transactions.find(item => item.type == 1);
            return recipe.value - expanse.value;
        },
        CalcValueTransactionsByType(transactions, type){
            let transaction = transactions.find(item => item.type == type);
            return transaction.value;
        },
        InvoiceGetStatus(value){
            let status = this.status_options.find(item => item.value == value);
            return status;
        }
    }
}
</script>

<style>

.sticky-nfs-e {
    top: 80px; 
    position: sticky; 
    z-index: 10;
}

</style>
<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="mary-beleza" @click="SaveSchedule">
                            <fa :icon="['far', 'save']" class="mr-2" />Salvar
                        </b-button>
                        <b-button variant="outline-mary-beleza" class="ml-2" @click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <loader-mary-beleza :show="loading" rounded="sm"/>
            <panel title="Serviço" icon='address-card' icon-lib="far">
                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="4">
                        <b-row>
                            <b-col sm="4" md="12" lg="12" class="form-group">
                                <label>Serviço:</label>
                                <services-select v-model="schedule_service_id"></services-select>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" v-if="schedule_service_id">
                        <b-row>
                            <b-col sm="4" md="12" lg="12" class="form-group">
                                <label>Data do serviço:</label>
                                <div>
                                    <v-date-picker :min-date='new Date()' :minute-increment="30" :model-config="modelConfig" v-model="schedule_service_date" mode="dateTime" is24hr>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div>
                                                <input
                                                class="form-control"
                                                :value="inputValue"
                                                v-on="inputEvents"/>
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" v-if="schedule_service_date">
                        <b-row>
                            <b-col sm="4" md="12" lg="12" class="form-group">
                                <template>
                                    <label>Valor cliente:</label>
                                    <money class="form-control" v-model="schedule_customer_value" v-bind="money"></money>
                                </template>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" v-if="schedule_service_date">
                        <b-row>
                            <b-col sm="4" md="12" lg="12" class="form-group">
                                <template>
                                    <label>Valor profissional:</label>
                                    <money class="form-control" v-model="schedule_worker_value" v-bind="money"></money>
                                </template>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" v-if="schedule_service_date && worker && false">
                        <b-row>
                            <b-col sm="6" md="12" lg="12" class="form-group">
                                <template>
                                    <label>
                                        Profissional deve realizar check-ins? 
                                        <help-icon title="Info" class="ml-2" name="okay" scale="1.5">
                                            <b>Caso SIM:</b>
                                            <p>O serviço será criado com o status de aceito e a profissional deverá realizar os check-ins no andamento do serviço.</p>
                                            <b>Caso NÃO:</b>
                                            <p>O serviço será criado, e os check-ins seram realizados automaticamente pelo sistema e a profissional deverá realizar somente o check-in de finalização do serviço.</p>
                                        </help-icon>
                                    </label>
                                    <button-group :options="options" v-model="schedule_checkins"></button-group>
                                </template>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>

            <div>
                <b-row>
                    <b-col sm="12" md="12" lg="6" class="form-group mt-5">
                        <b-card style="height:100%;">
                            <template #header>
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 font-weight-bold">Cliente</h6>
                                    <div v-if="customer">
                                        <span>
                                            <b-icon id="button_remove_customer" class="zoom-1-2 ml-2" variant="mary-beleza" scale="2.1" icon="x" @click="RemoveCustomer"></b-icon>
                                            <b-tooltip target="button_remove_customer" placement="top">
                                                Remover cliente
                                            </b-tooltip>
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <div v-if="customer">
                                <b-row class="justify-content-center">
                                    <b-avatar size="140" class="bg-mary-beleza-gradient" style="margin-top:-120px">
                                        <b-avatar :src="customer.person.image" size="96%">
                                        </b-avatar>
                                    </b-avatar>
                                </b-row>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <label>Nome:</label> <a style="color: #000" class="link-open" target="_blank" :href="'/clientes/cadastro/' + customer.id">{{ customer.person.name }}</a>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col sm="12" md="12" lg="12">
                                        <b-icon icon="at" variant="danger" scale="1.2" class="mr-1"></b-icon>
                                        <label>E-mail:</label>
                                        <div>
                                            <p v-if="!customer.person.email">
                                                <strong class="text-danger">-</strong>
                                            </p>
                                            <p v-else>{{ customer.person.email }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <!-- <img :src="img_service_phone" width="18" :id="'worker-phone'" class="mr-2" /> -->
                                        <label>Telefones:</label>
                                        <div v-if="customer.person.phones.length > 0">
                                            <b-row>
                                                <b-col sm="4" md="4" lg="6" xl="4" v-for="(phone, index) in customer.person.phones" :key="index" class="mb-1">
                                                    {{ phone.phone_number | formatPhones }}
                                                    <b-badge v-if="phone.is_personal" variant="primary" class="mr-1" style="font-size:11px;">Pessoal</b-badge>
                                                    <b-badge v-if="phone.is_whatsapp" variant="success" style="font-size:11px;">WhatsApp</b-badge>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div v-else>
                                            <strong class="text-danger">-</strong>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else class="text-center">
                                <h2 style="margin-top:10%;" class="font-italic">Sem Cliente</h2>
                                <div>
                                    <b-icon @click="ShowModalAddCustomer" icon="plus-circle-fill" scale="4" variant="mary-beleza" id="add_customer" class="m-5 zoom-1-2"></b-icon>
                                    <b-tooltip target="add_customer" placement="top">
                                        Adicionar cliente
                                    </b-tooltip>
                                </div>
                            </div>
                        </b-card>
                    </b-col>

                    <b-col v-if="(schedule_service_id && schedule_service_date) || schedule_worker_id" sm="12" md="12" lg="6" class="form-group mt-5">
                        <b-card style="height:100%;">
                            <template #header>
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 font-weight-bold">Profissional</h6>
                                    <div v-if="worker">
                                        <span>
                                            <b-icon id="button_remove_worker" class="zoom-1-2 ml-2" variant="mary-beleza" scale="2.1" icon="x" @click="RemoveWorker"></b-icon>
                                            <b-tooltip target="button_remove_worker" placement="top">
                                                Remover profissional
                                            </b-tooltip>
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <div v-if="worker">
                                <b-row class="justify-content-center">
                                    <b-avatar size="140" class="bg-mary-beleza-gradient" style="margin-top:-120px">
                                        <b-avatar :src="worker.person.image" size="96%">
                                        </b-avatar>
                                    </b-avatar>
                                </b-row>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <label>Nome:</label> <a style="color: #000" class="link-open" target="_blank" :href="'/profissionais/historico/' + worker.id">{{ worker.person.name }}</a>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col sm="12" md="12" lg="12">
                                        <b-icon icon="at" variant="danger" scale="1.2" class="mr-1"></b-icon>
                                        <label>E-mail:</label>
                                        <div>
                                            <p v-if="!worker.person.email">
                                                <strong class="text-danger">-</strong>
                                            </p>
                                            <p v-else>{{ worker.person.email }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="form-group">
                                        <!-- <img :src="img_service_phone" width="18" :id="'worker-phone'" class="mr-2" /> -->
                                        <label>Telefones:</label>
                                        <div v-if="worker.person.phones.length > 0">
                                            <b-row>
                                                <b-col sm="4" md="4" lg="6" xl="4" v-for="(phone, index) in worker.person.phones" :key="index" class="mb-1">
                                                    {{ phone.phone_number | formatPhones }}
                                                    <b-badge v-if="phone.is_personal" variant="primary" class="mr-1" style="font-size:11px;">Pessoal</b-badge>
                                                    <b-badge v-if="phone.is_whatsapp" variant="success" style="font-size:11px;">WhatsApp</b-badge>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div v-else>
                                            <strong class="text-danger">-</strong>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else class="text-center">
                                <h2 style="margin-top:10%;" class="font-italic">Sem profissional</h2>
                                <div>
                                    <b-icon @click="ShowModalAddWorker" icon="plus-circle-fill" scale="4" variant="mary-beleza" id="add_worker" class="m-5 zoom-1-2"></b-icon>
                                    <b-tooltip target="add_worker" placement="top">
                                        Adicionar profissional
                                    </b-tooltip>
                                </div>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </div>

            <b-overlay>
                <schedule-customer-preferences insert></schedule-customer-preferences>
            </b-overlay>
        
            <panel title="Endereço" icon='map-marked-alt' icon-lib="fa" v-if="customer">
                <b-row>
                    <b-col sm="12" md="4" lg="4" v-for="(address, key) in customer.person.address" :key="key">
                        <b-card @click="schedule_address_id = address.id">
                            <div>
                                <b-iconstack v-if="schedule_address_id == address.id" font-scale="1.2">
                                    <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                    <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                </b-iconstack>
                                <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                            </div>
                            <div>
                                <b-icon icon="play-fill"/> {{address.street}}, <b>{{address.number}}</b>
                                <p class="m-0">
                                    <b-icon icon="play-fill"/>
                                    {{address.district}}
                                </p>
                                <p class="m-0">
                                    <b>
                                        <b-icon icon="play-fill"/>
                                        {{address.city.name}}/{{address.city.state.acronym}}
                                    </b>
                                </p>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Pagamento" icon='credit-card' icon-lib="fa" v-if="customer">
                <b-row class="form-group">
                    <b-col>
                        <b-form-group label="Forma de Pagamento">
                            <select class="form-control" v-model="payment_type">
                                <option :value="0">Cartão de Crédito</option>
                                <option :value="1">PIX</option>
                            </select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template v-if="payment_type == 0">
                    <b-row v-if="customer.person.prepaidcards && customer.person.prepaidcards.length > 0" class="form-group">
                        <b-col sm="6" md="4" lg="4" v-for="(prepaidcard, key) in customer.person.prepaidcards" :key="key">
                            <b-card @click="schedule_customer_prepaidcard_id = prepaidcard.id">
                                <div>
                                    <b-iconstack v-if="schedule_customer_prepaidcard_id == prepaidcard.id" font-scale="1.2">
                                        <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                        <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                    </b-iconstack>
                                    <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                                </div>
                                <div class="justify-content-between d-flex">
                                    <div>
                                        **** **** **** {{prepaidcard.number}}
                                    </div>
                                    <div>
                                        {{prepaidcard.due_date | moment("MM/YYYY")}}
                                    </div>
                                </div>
                                <p class="font-weight-bold m-0">{{prepaidcard.flag | cardFlag}}</p>
                            </b-card>
                        </b-col>
                    </b-row>
                    <div v-else class="alert alert-warning">Nenhum cartão de crédito encontrado</div>
                </template>
            </panel>

            <b-modal id="modal-add-worker" hide-footer centered size="lg" title="Adicionar profissional" ref="modal-add-worker" no-stacking>
                <rich-table class="m-4" ref="addWorkerRichTable" v-on:input="ChangeWorkerTableConfig" :numbers="worker_table.table_numbers" :loading="worker_table.loading" :cols="worker_table.table_cols">
                    <template v-if="worker_table.items && worker_table.items.length > 0">
                        <tr v-for="(item, key) in worker_table.items" :key="key" @click="schedule_worker_id = item.id">
                            <td width="40%">{{item.name}} <b-badge v-if="item.distance">{{item.distance}} km</b-badge></td>
                            <td width="40%">{{item.phone | formatPhones}}</td>
                            <td width="15%" class="text-center">
                                <b-button v-if="item.phone" v-b-tooltip title="Enviar Mensagem de Contato" @click="WhatsAppLink(item.phone, item.name)" size="sm" variant="success"><fa :icon="['fab', 'whatsapp']" size="lg" /></b-button>
                            </td>
                            <td width="5%" class="text-center">
                                <b-iconstack v-if="schedule_worker_id == item.id" font-scale="1.2">
                                    <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                    <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                </b-iconstack>
                                <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="worker_table.table_cols.length" class="text-center py-3">Nenhuma profissional livre no momento!</td>
                        </tr>
                    </template>
                </rich-table>
                <div>
                    <b-button v-if="schedule_worker_id != null" variant="primary" size="md" class="float-right" @click="SelectedWorker">
                        Confirmar
                    </b-button>
                </div>
            </b-modal>

            <b-modal id="modal-add-customer" hide-footer centered size="lg" title="Adicionar cliente" ref="modal-add-customer" no-stacking>
                <rich-table class="m-4" ref="addCustomerRichTable" v-on:input="ChangeCustomerTableConfig" :numbers="customer_table.table_numbers" :loading="customer_table.loading" :cols="customer_table.table_cols">
                    <template v-if="customer_table.items && customer_table.items.length > 0">
                        <tr v-for="(item, key) in customer_table.items" :key="key" @click="schedule_customer_id = item.id">
                            <td width="40%">{{item.name}}</td>
                            <td width="40%">{{item.phone | formatPhones}}</td>
                            <td width="15%" class="text-center">
                                <b-button v-if="item.phone" v-b-tooltip title="Enviar Mensagem de Contato" @click="WhatsAppLink(item.phone, item.name)" size="sm" variant="success"><fa :icon="['fab', 'whatsapp']" size="lg" /></b-button>
                            </td>
                            <td width="5%" class="text-center">
                                <b-iconstack v-if="schedule_customer_id == item.id" font-scale="1.2">
                                    <b-icon stacked icon="circle-fill" scale="0.6" variant="mary-beleza"></b-icon>
                                    <b-icon stacked icon="circle" variant="mary-beleza"></b-icon>
                                </b-iconstack>
                                <b-icon v-else variant="mary-beleza" icon="circle" font-scale="1.2" />
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="customer_table.table_cols.length" class="text-center py-3">Nenhuma cliente encontrado!</td>
                        </tr>
                    </template>
                </rich-table>
                <div>
                    <b-button variant="outline-mary-beleza" size="md" class="float-left">
                        Voltar
                    </b-button>
                    <b-button v-if="schedule_customer_id != null" variant="primary" size="md" class="float-right" @click="SelectedCustomer">
                        Confirmar
                    </b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import RichTable from '@/components/RichTable'
import UtilBar from '@/components/UtilBar'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import ButtonGroup from '@/components/ButtonGroup'
import HelpIcon from '@/components/HelpIcon'
import moment from 'moment'
import ScheduleCustomerPreferences from "@/components/ScheduleCustomerPreferences"
import ServicesSelect from '@/components/ServicesSelect';

export default {
    
    components: {
        Panel,
        RichTable,
        UtilBar,
        ButtonGroup,
        HelpIcon,
        ScheduleCustomerPreferences,
        ServicesSelect
    },

    data() {
        return {
            loading: false,
            worker: null,
            customer: null,
            worker_table: {
                loading: false,
                filters: null,
                items: [],
                table_numbers: {
                    size: 1,
                    total: 0,
                    found: 0,
                    footer: null
                },
                table_cols: [
                    { id: 'name', text: 'Nome', class: 'cols_name' },
                    { id: null, text: 'Telefone(s)', class: 'cols_phones' },
                    { text: '', class: '' },
                    { text: '', class: '' },
                ]
            },
            customer_table: {
                loading: false,
                filters: null,
                items: [],
                table_numbers: {
                    size: 1,
                    total: 0,
                    found: 0,
                    footer: null
                },
                table_cols: [
                    { id: 'name', text: 'Nome', class: 'cols_name' },
                    { id: null, text: 'Telefone(s)', class: 'cols_phones' },
                    { text: '', class: '' },
                    { text: '', class: '' },
                ]
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD HH:mm:ss',
            },
            options: [
                { id: 1, text: "Sim" },
                { id: 0, text: "Não" },
            ],
        }
    },

    created() {
        this.$store.dispatch(`service/getServices`);
        this.$store.commit('service/SET_CLEAN_SCHEDULE');

        this.schedule_checkins = 0;

        if (this.$route.query.customer_id || this.$route.query.worker_id) {
            this.schedule_customer_id = this.$route.query.customer_id;
            this.schedule_worker_id = this.$route.query.worker_id;
        }

        if(this.schedule_customer_id){
            this.SelectedCustomer();
        }

        if(this.schedule_worker_id){
            this.SelectedWorker();
        }
    },

    methods: {

        moment(...args){
            return moment(...args);
        },

        ShowModalAddWorker(){
            this.$refs['modal-add-worker'].show()
        },

        ShowModalAddCustomer(){
            this.$refs['modal-add-customer'].show()
        },

        ChangeWorkerTableConfig(value) {
            this.worker_table.table_config = value
            this.WorkersLoadData();
        },

        ChangeCustomerTableConfig(value) {
            this.customer_table.table_config = value
            this.CustomersLoadData();
        },

        WorkersLoadData() {
            this.worker_table.loading = true;
            var service = this.services_all ? this.services_all.filter(s => s.id == this.schedule_service_id)[0] : null;
            var data = {
                service_date: this.schedule_service_date,
                service_id: this.schedule_service_id,
                customer_id: this.schedule_customer_id,
                hability: service ? service.hability_id : null,
                status: 6
            }
            var params = _clone(_merge(this.worker_table.filters, data, this.worker_table.table_config));
            this.$store.dispatch('service/getWorkersNotSchedule', params).then(data => {
                this.worker_table.loading = false;

                this.worker_table.items = data.items ? data.items : [];

                this.worker_table.table_numbers.size = data.size ? data.size : 0;
                this.worker_table.table_numbers.found = data.found ? data.found : 0;
                this.worker_table.table_numbers.total = data.total ? data.total : 0;
                this.worker_table.table_numbers.footer = data.footer ? data.footer : 0;

            });
        },

        CustomersLoadData() {
            this.customer_table.loading = true;
            var params = _clone(_merge(this.customer_table.filters, this.customer_table.table_config));
            this.$store.dispatch('service/getCustomers', params).then(data => {
                this.customer_table.loading = false;

                this.customer_table.items = data.items ? data.items : [];

                this.customer_table.table_numbers.size = data.size ? data.size : 0;
                this.customer_table.table_numbers.found = data.found ? data.found : 0;
                this.customer_table.table_numbers.total = data.total ? data.total : 0;
                this.customer_table.table_numbers.footer = data.footer ? data.footer : 0;

            });
        },

        SelectedWorker(){
            this.$store.dispatch(`service/getWorkerById`, this.schedule_worker_id).then(data => {
                this.worker = data
                this.$refs['modal-add-worker'].hide()
            });
        },

        SelectedCustomer(){
            this.$store.dispatch(`service/getCustomerById`, this.schedule_customer_id).then(data => {
                this.customer = data
                this.$refs['modal-add-customer'].hide()
            });
        },

        WhatsAppLink(phones, name) {
            let array_phones = phones.split(" | ");
            let inputOptions = [];

            array_phones.forEach(phone => {
                if (phone.length >= 10 && phone.length <= 11) { // Com DDD
                    inputOptions.push(phone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3"));
                }else if (phone.length >= 8 && phone.length <= 9) { // Sem DDD
                    inputOptions.push(phone.replace(/(\d{4,5})(\d{4})/, "$1-$2"));
                }
            });

            this.$swal.fire({
                title: 'Selecione o telefone',
                input: 'select',
                inputOptions: inputOptions,
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                inputValidator: (index) => {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = 'https://api.whatsapp.com/send?phone=55'+inputOptions[index].replace(/[^\d]/g, "")+'&text=Olá, '+name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
        },

        GetPriceTable(){
            this.loading = true;
            var params = {
                date: moment(this.schedule_service_date).format("YYYY-MM-DD"),
                service_id: this.schedule_service_id
            }
            
            this.$store.dispatch(`service/getPriceTable`, params).then(data => {
                this.loading = false;
                this.schedule_customer_value = data.CUSTOMER;
                this.schedule_worker_value = data.WORKER;
            }).catch(() => {
                this.loading = false;
            });
        },

        RemoveWorker() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover a(o) profissional?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.schedule_worker_id = null;
                    this.worker = null
                }
            })
        },

        RemoveCustomer() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Remover`,
                html: `Deseja realmente remover a(o) cliente?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.schedule_customer_id = null;
                    this.customer = null;
                    this.schedule_address_id = null;
                    this.schedule_customer_prepaidcard_id = null;
                }
            })
        },

        SaveSchedule(){

            if(!this.schedule_service_id){
                this.$swal({ title: "Nescessário escolher um serviço!", icon: "error" });
                return;
            }
            if(!this.schedule_service_date){
                this.$swal({ title: "Nescessário escolher uma data!", icon: "error" });
                return;
            }
            if(!this.schedule_customer_id){
                this.$swal({ title: "Nescessário escolher um cliente!", icon: "error" });
                return;
            }
            if(!this.schedule_address_id){
                this.$swal({ title: "Nescessário escolher um endereço!", icon: "error" });
                return;
            }

            if (this.payment_type == 0) { 
                if(!this.schedule_customer_prepaidcard_id){
                    this.$swal({ title: "Nescessário escolher um cartão de crédito!", icon: "error" });
                    return;
                }
            }

            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Deseja realmente criar um serviço?`,
                html: this.worker ?
                        `<p><b>Obs:</b> Será criado um serviço para a(o) cliente <b>${this.customer.person.name}</b> e a(o) profissional <b>${this.worker.person.name}</b>!</p>`
                        + `<p>Se a(o) profissional não precisar realizar os check-ins já será realizado a cobrança do cliente no valor do serviço!</p>` 
                    :
                        `<p><b>Obs:</b> Será criado um serviço para a(o) cliente <b>${this.customer.person.name}</b>!</p>`
                        + `<p>O serviço será criado com status de oportunidade!</p>`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.dispatch(`service/createSchedule`).then(() => {
                        this.$swal({ title: "Agendamento criado com sucesso!", icon: "success" });
                        this.$store.commit('service/SET_CLEAN_SCHEDULE')
                        this.loading = false;
                        this.$router.push("/servicos");
                    }).catch(error => {
                        this.loading = false;
                        this.$swal({ title: error, icon: "error" });
                    });
                }
                
            })
        }
    },

    computed: {

        schedule: {
            get() {
                return this.$store.state.service.schedule;
            }
        },

        schedule_customer_id: {
            get() {
                return this.schedule.customer_id;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_CUSTOMER_ID', value)
            }
        },

        schedule_worker_id: {
            get() {
                return this.schedule.worker_id;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_WORKER_ID', value)
            }
        },

        payment_type: {
            get() {
                return this.schedule.payment_type;
            },
            set(value) {
                this.$store.commit('service/SET_PAYMENT_TYPE', value)
            }
        },

        schedule_service_id: {
            get() {
                let service_id = this.schedule.service_id;
                return service_id;
            },
            set(value) {
                if(this.worker){
                    let service = this.services_all.find(item => item.id == value);
                    let has_hability = this.worker.worker_habilities.find(item => item.hability_id == service.hability_id);
                    if(!has_hability){
                        this.$swal({
                            showConfirmButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            title: `Remover`,
                            html: `Deseja realmente alterar o serviço? Profissional escolhido(a) não possui habilidade para realizar o serviço escolhido e será removido(a).`,
                            icon: 'warning',
                            padding: 20,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$store.commit('service/SET_SCHEDULE_SERVICE_ID', value)
                                this.worker = null;
                            }
                        })
                    }else{
                        this.$store.commit('service/SET_SCHEDULE_SERVICE_ID', value)
                    }
                }else{
                    this.$store.commit('service/SET_SCHEDULE_SERVICE_ID', value)
                }
                if(this.schedule_service_date){
                    this.GetPriceTable();
                }
            }
        },

        schedule_address_id: {
            get() {
                return this.schedule.address_id;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_ADDRESS_ID', value)
            }
        },

        schedule_customer_prepaidcard_id: {
            get() {
                return this.schedule.customer_prepaidcard_id;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_CUSTOMER_PREPAIDCARD_ID', value)
            }
        },

        schedule_service_date: {
            get() {
                return this.schedule.service_date;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_SERVICE_DATE', value)
                if(this.schedule_service_id){
                    this.GetPriceTable();
                }
            }
        },

        schedule_customer_value: {
            get() {
                return this.schedule.customer_value ? this.schedule.customer_value : 0;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_CUSTOMER_VALUE', value)
            }
        },

        schedule_worker_value: {
            get() {
                return this.schedule.worker_value ? this.schedule.worker_value : 0;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_WORKER_VALUE', value)
            }
        },

        schedule_checkins: {
            get() {
                return this.schedule.checkins;
            },
            set(value) {
                this.$store.commit('service/SET_SCHEDULE_CHECKINS', value)
            }
        },

        services_all: {
            get(){
                return this.$store.state.service.services;
            }
        }

    }
}
</script>
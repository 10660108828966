<template>
    <loader-mary-beleza v-if="!loaded" :show="!loaded"/>
    <div v-else>
        <util-bar></util-bar>

        <div class="pr-3 pl-3 pt-3">
            <b-row v-if="has_permission('configs-financial', 'configs-ranking', 'configs-bonus', 'configs-punishment', 'configs-services')" class="form-group" >
                <b-col offset="10" md="2">
                    <b-button variant="primary" size="sm" @click="Save()" block>Salvar</b-button>
                </b-col>
            </b-row>
            <b-row align-v="stretch">
                <b-col md="12" lg="6" v-if="has_permission('configs-ranking')">
                    <panel title="Ranking" class="pb-2">
                        <b-row class="form-group">
                            <b-col md="12">
                                <b-form-group label-cols-md="9"
                                              label-size="sm"
                                              label="Máximo de horas de trabalho possível por dia:"
                                              label-align-sm="right"
                                              class="mb-0"
                                >
                                    <money class="form-control" v-model="model.ranking_max_hours_per_day" v-bind="hour"></money>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12">
                                <b-form-group label-cols-md="9"
                                              label-size="sm"
                                              label="Peso ponderado de horas trabalhadas:"
                                              label-align-sm="right"
                                              class="mb-0"
                                >
                                    <b-form-input type="number" v-model="model.ranking_weight_hours"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12">
                                <b-form-group label-cols-md="9"
                                              label-size="sm"
                                              label="Peso ponderado do porcentual de confirmação:"
                                              label-align-sm="right"
                                              class="mb-0"
                                >
                                    <b-form-input type="number" v-model="model.ranking_weight_percent_taxconfirmation"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12">
                                <b-form-group label-cols-md="9"
                                              label-size="sm"
                                              label="Peso ponderado das notas:"
                                              label-align-sm="right"
                                              class="mb-0"
                                >
                                    <b-form-input type="number" v-model="model.ranking_weight_grades"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <br>
                        <br>
                    </panel>
                </b-col>
                <b-col md="12" lg="6" v-if="has_permission('configs-bonus')">
                    <panel title="Bonificação">
                        <b-tabs>
                            <b-tab v-for="(type, i) in bonification_types" :key="i" :title="type.label">
                                <div style="padding: 4px;"></div>
                                <b-row class="form-group">
                                    <b-col lg="5">
                                        <medal v-if="type.key != 'common'" class="medal" :type="type.key" size="lg">{{type.label}}</medal>
                                    </b-col>
                                    <b-col lg="7">
                                        <template v-if="type.key != 'common'">
                                            <b-row class="form-group">
                                                <b-col md="12">
                                                    <b-form-group label-cols-md="8"
                                                                  label-size="sm"
                                                                  label="Horas de serviço:"
                                                                  label-align-sm="right"
                                                                  class="mb-0"
                                                    >
                                                        <b-form-input type="number" v-model="model[`bonification_${type.key}_service_hours`]"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="form-group">
                                                <b-col md="12">
                                                    <b-form-group label-cols-md="8"
                                                                  label-size="sm"
                                                                  label="Confirmação:"
                                                                  label-align-sm="right"
                                                                  class="mb-0"
                                                    >
                                                        <money class="form-control" v-model="model[`bonification_${type.key}_taxconfirmation`]" v-bind="percentage"></money>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="form-group">
                                                <b-col md="12">
                                                    <b-form-group label-cols-md="8"
                                                                  label-size="sm"
                                                                  label="Nota média:"
                                                                  label-align-sm="right"
                                                                  class="mb-0"
                                                    >
                                                        <b-form-input type="number" v-model="model[`bonification_${type.key}_average_grade`]"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="form-group">
                                                <b-col md="12">
                                                    <b-form-group label-cols-md="8"
                                                                  label-size="sm"
                                                                  label="Porcentagem do bônus:"
                                                                  label-align-sm="right"
                                                                  class="mb-0"
                                                    >
                                                        <money class="form-control" v-model="model[`bonification_${type.key}_percentage`]" v-bind="percentage"></money>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </template>
                                        <b-row class="form-group">
                                            <b-col md="12">
                                                <b-form-group label-cols-md="8"
                                                              label-size="sm"
                                                              label="Serviços visualizados após:"
                                                              label-align-sm="right"
                                                              description="Minutos"
                                                              class="mb-0"
                                                >
                                                    <input class="form-control" type="text" v-model="model[`minutes_${type.key}_service_view`]">
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </panel>
                </b-col>
            </b-row>
            <b-row v-if="has_permission('configs-punishment') && model.punishments.length > 0">
                <b-col md="12">
                    <panel title="Penalidades">
                        <b-row class="form-group justify-content-between">
                            <b-col sm="4" md="4" lg="4">
                                 <b-form-group label-cols-md="4"
                                                label-size="sm"
                                                label="Salário mínino:"
                                                label-align-sm="left"
                                                class="mb-0"
                                >
                                    <money class="form-control" v-model="model.basic_salary" v-bind="money"></money>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3" md="3" lg="3">
                                 <b-button block variant="primary" size="sm" @click="ResetPunishments">Desfazer alterações</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" md="12" lg="12">
                                <div v-for="(punishmentRule, pr) in model.punishments" :key="pr">
                                    <div class="p-2 mb-1" :style="'background-color: ' + GetColorType(punishmentRule.type)">
                                        <span v-b-toggle="'collapse-punishment-'+pr">
                                            <span class="justify-content-between d-flex">
                                                <h6>{{ punishmentRule.description }}</h6>
                                                <b-icon scale="1.5" class="mr-4 mt-3 when-opened" icon="x-circle"></b-icon>
                                                <b-icon scale="1.5" class="mr-4 mt-3 when-closed" icon="pencil-square"></b-icon>
                                            </span> 
                                        </span>
                                        <span v-for="(punishment, p) in punishmentRule.children" :key="p">
                                            <p style="font-size: 13px" class="m-0 p-0"><strong>Multa para o Profissional:</strong> {{ punishment.worker_value }}% ({{ CalculateMoneyPercentage(punishment.worker_value) | currency }}) | <strong>Desconto para o Cliente:</strong> {{ punishment.customer_discount }}% ({{ CalculateMoneyPercentage(punishment.customer_discount) | currency }}) | <strong>Taxa Adicional do Cliente:</strong> {{ punishment.customer_tax }}% ({{ CalculateMoneyPercentage(punishment.customer_tax) | currency }}) | <strong>Pontuação:</strong> {{ punishment.points }} pts | <strong>Cobrar valor integral?</strong> {{ punishment.full_amount ? 'Sim' : 'Não' }}</p>
                                            <b-collapse :id="'collapse-punishment-'+pr">
                                                <b-card class="p-0 m-0">
                                                    <b-row class="p-0 m-0">
                                                        <b v-b-tooltip="'Reincidência'" class="bg-mary-beleza rounded-circle text-center align-self-center text-white" style="height:25px; width:25px">{{punishment.recurrence}}</b>
                                                        <b-col sm="11" md="11" lg="11">
                                                            <b-row>
                                                                <b-col sm="12" md="12" lg="5" v-if="[null,1,2,5,6].includes(punishmentRule.type)">
                                                                    <b-badge variant="primary">Profissional</b-badge>
                                                                    <b-row>
                                                                        <b-col v-if="[null,1,2,5,6].includes(punishmentRule.type)" sm="5" md="5" lg="5">
                                                                            <label>Valor profissional:</label>
                                                                            <money class="form-control mb-1" :disabled="punishment.full_amount" v-model="punishment.worker_value" v-bind="percentage_punishment"></money>
                                                                            <money class="form-control" :disabled="punishment.full_amount" :value="CalculateMoneyPercentage(punishment.worker_value)" @blur.native="CalculatePercentageMoney($event, pr, p, 'worker_value')" v-bind="money"></money>
                                                                        </b-col>
                                                                        <b-col sm="3" md="3" lg="3">
                                                                            <div v-if="[null,1,2,5,6].includes(punishmentRule.type)">
                                                                                <label>Pontos:</label>
                                                                                <b-form-input type="number" v-model="punishment.points" placeholder="0"></b-form-input>
                                                                            </div>
                                                                        </b-col>
                                                                        <b-col v-if="[null,1,2,5,6].includes(punishmentRule.type)" sm="3" md="3" lg="3">
                                                                            <label>Suspensão:</label>
                                                                            <b-form-input v-if="punishment.suspension != -1" type="number" v-model="punishment.suspension" placeholder="0"></b-form-input>
                                                                            <b-form-checkbox v-if="[null,1,2,5,6].includes(punishmentRule.type)" :checked="punishment.suspension == -1" @change="punishment.suspension = punishment.suspension == -1 ? null : -1" class="mt-2">
                                                                                Permanente
                                                                            </b-form-checkbox>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-col>
                                                                <b-col sm="12" md="12" lg="2" v-if="[null,1,2,5,6].includes(punishmentRule.type)">
                                                                    <hr class="border-left-punishment">
                                                                </b-col>
                                                                <b-col>
                                                                    <b-badge variant="primary">Cliente</b-badge>
                                                                    <b-row>
                                                                        <b-col v-if="[null,1,2,5,6].includes(punishmentRule.type)" sm="7" md="7" lg="7">
                                                                            <label>Desconto cliente:</label>
                                                                            <money class="form-control mb-1" v-model="punishment.customer_discount" v-bind="percentage_punishment"></money>
                                                                            <money class="form-control" :value="CalculateMoneyPercentage(punishment.customer_discount)" @blur.native="CalculatePercentageMoney($event, pr, p, 'customer_discount')" v-bind="money"></money>
                                                                        </b-col>
                                                                        <b-col v-if="[3,4].includes(punishmentRule.type)" sm="5" md="5" lg="5">
                                                                            <label>Taxa cliente:</label>
                                                                            <money class="form-control mb-1" :disabled="punishment.full_amount" v-model="punishment.customer_tax" v-bind="percentage_punishment"></money>
                                                                            <money class="form-control" :disabled="punishment.full_amount" :value="CalculateMoneyPercentage(punishment.customer_tax)" @blur.native="CalculatePercentageMoney($event, pr, p, 'customer_tax')" v-bind="money"></money>
                                                                        </b-col>
                                                                        <b-col sm="2" md="2" lg="2">
                                                                            <b-form-checkbox v-if="[3,4].includes(punishmentRule.type)" class="mt-2" v-model="punishment.full_amount">
                                                                                Valor total
                                                                            </b-form-checkbox>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-collapse>
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </panel>
                </b-col>
            </b-row>
            <b-row v-if="has_permission('configs-services')">
                <b-col md="12">
                    <panel title="Serviços">
                        <b-row class="form-group">
                            <b-col class="text-right">
                                <b-button variant="primary" class="mr-2" size="sm" @click="SelectAllServices()">Selecionar Todos</b-button>
                                <b-button variant="warning" size="sm" v-b-modal.services-zipcodes>Remoção de serviços por CEP</b-button>
                            </b-col>
                        </b-row>
                        <b-modal id="services-zipcodes" @shown="LoadDataServiceZipCodes()" title="Faixas de CEP" size="lg" ok-only ok-title='Fechar'>
                            <panel>
                                <b-row>
                                    <b-col sm="12" md="12" lg="12">
                                        <b-alert variant="warning" show class="small">
                                            <b-icon icon="exclamation-triangle-fill" class="mr-2"></b-icon><strong>Atenção!</strong> Ao informar uma faixa de CEP você estará BLOQUEANDO a exibição do serviço selecionado para a faixa de CEP informada, ou seja, alguém que more em um CEP listado abaixo não verá no Aplicativo o serviço informado.
                                        </b-alert>
                                    </b-col>
                                </b-row>

                                <b-form-group>
                                    <label>Serviço</label>
                                    <services-select v-model="newzipcode.service_id" name='zipcode_service_id' data-vv-as="Serviço" v-validate="'required'"></services-select>
                                    <span class="text-danger" v-if="errors.has('zipcode_service_id')">{{errors.first('zipcode_service_id')}}</span>
                                </b-form-group>
                                <b-form-group>
                                    <b-row>
                                        <b-col md="6">
                                            <label>CEP inicial</label>
                                            <the-mask :mask="'#####-###'" class="form-control" v-model="newzipcode.start" placeholder="CEP" name='zipcode_service_start' data-vv-as="CEP Inicial" v-validate="'required'" />
                                            <span class="text-danger" v-if="errors.has('zipcode_service_start')">{{errors.first('zipcode_service_start')}}</span>
                                        </b-col>
                                        <b-col md="6">
                                            <label>CEP final</label>
                                            <the-mask :mask="'#####-###'" class="form-control" v-model="newzipcode.end" placeholder="CEP" name='zipcode_service_end' data-vv-as="CEP Final" v-validate="'required'" />
                                            <span class="text-danger" v-if="errors.has('zipcode_service_end')">{{errors.first('zipcode_service_end')}}</span>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                                <div class="text-center">
                                    <b-button variant="success" type="button" @click="AddServiceZipCodes()"><b-icon icon="plus-circle" /> Adicionar</b-button>
                                </div>
                            </panel>
                            <b-overlay :show="!loaded_service_zipcodes">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Serviço</th>
                                            <th>CEP Inicial</th>
                                            <th>CEP Final</th>
                                            <th style="width: 1%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="services_zipcodes && services_zipcodes.length > 0">
                                            <tr v-for="(item, i) in services_zipcodes" :key="i">
                                                <td class="align-middle">{{item.service.name}}</td>
                                                <td class="align-middle">{{item.start | zipcode}}</td>
                                                <td class="align-middle">{{item.end | zipcode}}</td>
                                                <td class="align-middle"><b-button type="button" variant="danger" size="sm" @click="DeleteServiceZipCode(item.id)"><b-icon icon="trash" font-scale="1" /></b-button></td>
                                            </tr>
                                        </template>
                                        <tr v-else class="text-center">
                                            <td colspan="4">Nenhum registro encontrado</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-overlay>
                        </b-modal>
                        <b-row>
                            <b-col v-for="(service, i) in Services" :key="i" sm="12" md="12" lg="6">
                                <b-card class="service-card pr-0" tag="article" :class="{ 'icon-service': !!service.image_url }" :style="`${service.image_url ? `background-size: 200px; background-image: url(${service.image_url})` : ''}`">
                                    <b-card-title class='service-title'>
                                        <span v-for="(title, i) in (service.name ? service.name.split('/') : [])" :key="i">
                                            <div class="service-subitem" :style="{ width: (15*i)+'px' }"></div> <span style="vertical-align: middle; width: calc(100% - 100px);display: inline-block;">{{title}}</span>
                                        </span>
                                    </b-card-title>
                                    <b-form-checkbox
                                        switch
                                        v-model="model.services"
                                        :value="service.id"
                                    >
                                        {{model.services && model.services.filter(s => s == service.id).length > 0 ? "Habilitado" : "Desabilitado" }}                             
                                    </b-form-checkbox>
                                </b-card>
                            </b-col>
                        </b-row>
                    </panel>
                </b-col>
            </b-row>
            <b-row v-if="has_permission('configs-financial')">
                <b-col md="12">
                    <panel title="Financeiro">
                        <b-row class="form-group">
                            <b-col md="12" lg="6">
                                <label for="days_to_pay_worker">Quantidade de dias para pagamento das profissionais:</label>
                                <b-form-input type="number" id="days_to_pay_worker" v-model="model.days_to_pay_worker" trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="4" lg="4">
                                <label for="invoice_retain_iss">Reter ISS:</label>
                                <b-form-input id="invoice_retain_iss" v-model="model.invoice_retain_iss" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_iss">ISS:</label>
                                <b-form-input id="invoice_iss" v-model="model.invoice_iss" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_cofins">COFINS:</label>
                                <b-form-input id="invoice_cofins" v-model="model.invoice_cofins" trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="4" lg="4">
                                <label for="invoice_csll">CSLL:</label>
                                <b-form-input id="invoice_csll" v-model="model.invoice_csll" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_inss">INSS:</label>
                                <b-form-input id="invoice_inss" v-model="model.invoice_inss" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_ir">IR:</label>
                                <b-form-input id="invoice_ir" v-model="model.invoice_ir" trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="4" lg="4">
                                <label for="invoice_pis">PIS:</label>
                                <b-form-input id="invoice_pis" v-model="model.invoice_pis" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_municipal_service_id">ID serviço municipal:</label>
                                <b-form-input id="invoice_municipal_service_id" v-model="model.invoice_municipal_service_id" trim></b-form-input>
                            </b-col>
                            <b-col md="4" lg="4">
                                <label for="invoice_municipal_service_code">Código serviço municipal:</label>
                                <b-form-input id="invoice_municipal_service_code" v-model="model.invoice_municipal_service_code" trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12" lg="12">
                                <label for="invoice_municipal_service_name">Nome serviço municipal:</label>
                                <b-form-input id="invoice_municipal_service_name" v-model="model.invoice_municipal_service_name" trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12" lg="12">
                                <label for="invoice_obs">Observação nota:</label>
                                <b-form-textarea id="invoice_obs" v-model="model.invoice_obs" rows="4" max-rows="6" ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="form-group">
                            <b-col md="12" lg="12">
                                <label for="invoice_description">Descrição nota:</label>
                                <b-form-textarea id="invoice_description" v-model="model.invoice_description" rows="4" max-rows="6" ></b-form-textarea>
                            </b-col>
                        </b-row>
                    </panel>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import ServicesSelect from '@/components/ServicesSelect';
import UtilBar from '@/components/UtilBar'
import Medal from '@/components/Medal'
import swal from 'sweetalert2'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        Panel,
        UtilBar,
        Medal,
        ServicesSelect
    },
    computed: {
        ...mapGetters('auth', [
            'has_permission'
        ]),

        Services: {
            get() {
                var services = this.services;
                var flatten = (items, prefix = "") => {
                    var result = [];
                    for (var item of items) {
                        if (item.children && item.children.length > 0) {
                            result = result.concat(flatten(item.children, (prefix ? prefix + " / " : "") + item.name));
                        }
                        else {
                            item.name = prefix+ " / " +item.name;
                            result.push(item);
                        }
                    }

                    return result;
                };
                services = flatten(services);

                return services;
            }
        },

    },
    data() {
        return {
            services: [],
            loaded: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            hour: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: 'H',
                precision: 0,
                masked: false
            },
            day: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: 'D',
                precision: 0,
                masked: false
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            },
            percentage_punishment: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 5,
                masked: false
            },
            bonification_types: [
                { key: 'common', label: "Comum" },
                { key: 'bronze', label: "Bronze" },
                { key: 'silver', label: "Prata" },
                { key: 'gold', label: "Ouro" },
                { key: 'ruby', label: "Rubi" }
            ],
            loaded_service_zipcodes: false,
            services_zipcodes: [],
            newzipcode: {
                service_id: null,
                start: null,
                end: null
            },
            model: {
                ranking_max_hours_per_day: null,
                ranking_weight_hours: null,
                ranking_weight_percent_taxconfirmation: null,
                ranking_weight_grades: null,
                bonification_bronze_service_hours: null,
                bonification_bronze_taxconfirmation: null,
                bonification_bronze_average_grade: null,
                bonification_bronze_percentage: null,
                bonification_silver_service_hours: null,
                bonification_silver_taxconfirmation: null,
                bonification_silver_average_grade: null,
                bonification_silver_percentage: null,
                bonification_gold_service_hours: null,
                bonification_gold_taxconfirmation: null,
                bonification_gold_average_grade: null,
                bonification_gold_percentage: null,
                bonification_ruby_service_hours: null,
                bonification_ruby_taxconfirmation: null,
                bonification_ruby_average_grade: null,
                bonification_ruby_percentage: null,
                days_bronze_service_view: 0,
                days_silver_service_view: 0,
                days_gold_service_view: 0,
                days_ruby_service_view: 0,
                days_to_pay_worker: 0,
                tax_antecipation: 0,
                invoice_obs: null,
                invoice_description: null,
                invoice_retain_iss: null,
                invoice_iss: null,
                invoice_cofins: null,
                invoice_csll: null,
                invoice_inss: null,
                invoice_ir: null,
                invoice_pis: null,
                invoice_municipal_service_id: null,
                invoice_municipal_service_code: null,
                invoice_municipal_service_name: null,
                services: [],
                punishments: [],
            },
            punishments_backup: [],
            punishment_types: [
                { id: 1, title: 'Atraso', color: '#b1e4af' },
                { id: 2, title: 'Desistência', color: '#fff49f' },
                { id: 3, title: 'Cancelamento', color: '#fbc6c2' },
                { id: 4, title: 'Edição', color: '#fbc6c2' },
                { id: 5, title: 'Sem uniforme', color: '#fbc6c2' },
                { id: 6, title: 'Material Esterelizado', color: '#fbc6c2' },
                { id: null, title: 'Edição', color: '#fbc6c2' },
            ]
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        SelectAllServices() {
            this.model.services = this.Services.map(s => s.id);
        },
        LoadData() {
            this.$store.dispatch('configurations/all').then((response) => {
                this.loaded = true;
                this.model = response;
                this.punishments_backup = _cloneDeep(this.model.punishments);
                this.services = response && response.all_services ? response.all_services : [];
            });
        },
        LoadDataServiceZipCodes() {
            this.newzipcode = {
                service_id: null,
                start: null,
                end: null
            };
            this.loaded_service_zipcodes = false;
            this.$store.dispatch('configurations/getServicesZipCodes').then((response) => {
                this.services_zipcodes = response;
                this.loaded_service_zipcodes = true;
            });
        },
        Save() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Salvar Configurações`,
                html: `Deseja realmente salvar as configurações? Essa ação não poderá ser desfeita.`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        title: 'Salvando...',
                        icon: 'info',
                        padding: 20,
                        didOpen() {
                            swal.showLoading()
                        }
                    });

                    this.$store.dispatch('configurations/save', this.model).then((response) => {
                        this.model = response;
                        this.services = response && response.all_services ? response.all_services : [];
                        this.$swal({
                            title: "Salvo com sucesso",
                            icon: "success"
                        });
                    });
                }
            });
        },

        CalculateMoneyPercentage(value){
            return ((this.model.basic_salary * value) / 100)
        },

        CalculatePercentageMoney(event, pr, p, field){
            var replace = event.target.value.replace(/[^0-9,.]*/, '');
            replace = replace.replace('.', '');
            replace = replace.replace(',', '.');
            var value = parseFloat(replace);
           
            if(value){
                var percent = Math.abs((((this.model.basic_salary - value) - this.model.basic_salary) / this.model.basic_salary) * 100);
                this.model.punishments[pr].children[p][field] = percent ? percent : 0;
            }
        },

        ResetPunishments(){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterações`,
                html: `Deseja realmente desfazer as alterações nas penalidades?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.model.punishments =  _cloneDeep(this.punishments_backup);
                }
            });
        },

        GetColorType(type){
            var punishment = this.punishment_types.find(item => item.id === type);
            return punishment.color;
        },
        AddServiceZipCodes() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                this.loaded_service_zipcodes = false;
                this.$store.dispatch('configurations/addServiceZipCode', this.newzipcode).then(() => {
                    this.LoadDataServiceZipCodes();
                });
            });
        },
        DeleteServiceZipCode(id) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Alterações`,
                html: `Deseja realmente remover a faxia?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loaded_service_zipcodes = false;
                    this.$store.dispatch('configurations/deleteServiceZipCode', id).then(() => {
                        this.LoadDataServiceZipCodes();
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
    .service-card {
        height: 140px;
        margin-bottom: 15px;
        font-size: 11px;
        padding: 10px;
    }

    .service-card .card-title {
        font-size: 13px;
    }

    .service-card.icon-service {
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: 40px;
        background-position-x: right;
        background-position-y: center;
    }

    /* * >>> input {
        height: 30px;
    } */

    .medal {
        width: 100px;
        margin: auto;
        display: block;
        text-align: center;
        margin-bottom: 40px;
    }

    .medal >>> * {
        font-size: 20px !important;
    }

    .punishment-card {
        margin-bottom: 15px;
        font-size: 11px;
        padding: 10px;
    }

    .punishment-card .card-title {
        font-size: 13px;
    }

    .service-title {
        border-left: 1px dashed #888;
        padding-left: 8px;
        width: calc(100% - 120px);
    }

    .service-title > span {
        display: block;
        margin-bottom: 4px;
    }

    .service-subitem {
        display: inline-block;
        height: 1px;
        border-top: 1px dashed #888;
        vertical-align: middle;
    }

    @media (min-width: 992px) {
        .border-left-punishment {
            border: none;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            height: 80%;
            width: 1px;
        }
    }

    .when-closed, .when-opened {
        transition: opacity 0.3s, visibility 0.3s;
    }

    .collapsed .when-opened {
        opacity: 0;
        display: none;
    }

    .collapsed .when-closed {
        opacity: 1;
        display: block;
    }

    .not-collapsed .when-opened {
        opacity: 1;
        display: block;
    }

    .not-collapsed .when-closed {
        opacity: 0;
        display: none;
    }
</style>
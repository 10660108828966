<template>
    <div>
        <util-bar></util-bar>
        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Data do Serviço - Período</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filter_due_date_start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filter_due_date_end"/>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Situação: <b-icon icon="patch-question-fll" class="text-mary-beleza" id="status"></b-icon></label>
                        <b-form-select v-model="filter_status" :options="status_options"></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4">
                        <label></label>
                        <p>
                            <b-button block class="mt-2 my-2" variant="outline-danger" @click="CleanFilters">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Painel">
                <b-row class="form-group">
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table
                                    ref="paymentsRichTable"
                                    :default_perPage="100"
                                    v-on:input="ChangeTableConfig"
                                    :numbers="table_numbers"
                                    :loading="loadingData"
                                    :cols="table_cols"
                                    font-size='0.8rem'
                                    class="b-table-service-list">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, i) in items" :key="i">
                                            <td style="width: 10%">
                                                <span v-if="item.due_date">{{item.due_date | moment("DD/MM/YYYY")}}</span>
                                                <span v-else class="font-weight-bold text-danger">-</span>
                                            </td>
                                            <td style="width: 20%">{{item.description}}</td>
                                            <td style="width: 18%">{{item.worker}}</td>
                                            <td style="width: 14%">{{item.service_date ? item.service_date : '-' | moment("DD/MM/YYYY")}}</td>
                                            <td style="width: 16%">{{item.customer}}</td>
                                            <td style="width: 10%">{{item.value | currency}}</td>
                                            <td style="width: 2%">
                                                <a style="padding: 4px 8px;" class="btn btn-success" target="_blank" :href="item.url" v-b-tooltip.hover="'Visualizar Pagamento'" v-if="item.url" variant="success" size="sm">
                                                    <b-icon icon="eye" font-scale="1" />
                                                </a>
                                            </td>
                                            <td style="width: 10%; white-space: nowrap;" class="text-center">
                                                <template v-if="item.status == CONSTANTS.STATUS_PENDING || item.status == CONSTANTS.STATUS_PROCESSING">
                                                    <template v-if="(!item.due_date || moment(item.due_date) > moment()) && item.status == CONSTANTS.STATUS_PENDING">
                                                        <button class="btn btn-primary m-1" @click="AnticipateAsaasPayment(item)" :disabled="loadingData">
                                                            <fa icon="clock" class="fa-fw mr-3" /> Antecipar pagamento
                                                        </button><br>
                                                        <button class="btn btn-danger" @click="CancelAsaasPayment(item)" :disabled="loadingData">
                                                            <fa icon="trash" class="fa-fw mr-3" /> Cancelar pagamento
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <fa icon="clock" class="fa-fw mr-3" /> Em processamento
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item.status == CONSTANTS.STATUS_RECEIVED || item.status == CONSTANTS.STATUS_RECEIVED || item.status == CONSTANTS.STATUS_RECEIVED_IN_CASH || item.status == CONSTANTS.STATUS_CONFIRMED">
                                                        <b-icon scale="1.2" variant="success" icon="check-circle-fill"  class="ml-3"></b-icon>
                                                        <span> Pago</span>
                                                        <div v-if="item.url">
                                                            <a :href="item.url" target="_blank">
                                                                <i class="fa fa-ticket"></i> Comprovante
                                                            </a>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.status == CONSTANTS.STATUS_FAIL">
                                                        <button type="button" class="btn btn-danger" v-b-tooltip.hover="item.status_obs" :disabled="loadingData" @click="ReprocessAsaasPayment(item)">
                                                            <fa icon="exclamation-triangle" class="fa-fw mr-3" /> Reprocessar falha
                                                        </button>
                                                    </template>
                                                    <template v-else-if="item.status == CONSTANTS.STATUS_DRAFT">
                                                        <b-icon scale="1.2" variant="warning" icon="bookmarks-fill"  class="ml-3"></b-icon>
                                                        <span> Previsto</span>
                                                    </template>
                                                    <template v-else-if="item.status == CONSTANTS.STATUS_CANCELED_TRA">
                                                        <b-icon scale="1.2" variant="danger" icon="trash"  class="ml-3"></b-icon>
                                                        <span> Cancelado</span>
                                                    </template>
                                                    <template v-else>
                                                        <b-icon scale="1.2" variant="warning" icon="check-circle-fill"  class="ml-3"></b-icon>
                                                        <span> Desconhecido</span>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else class="odd zero_records">
                                        <td :colspan="table_cols.length" class="text-center">Nenhum registro encontrado</td>
                                    </tr>
                                </rich-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable';
import ImageLoader from '@/mixins/ImageLoader'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import TypeableDate from '@/components/TypeableDate'
import Constants from '@/constants'

export default {
    mixins: [ ImageLoader ],

    computed: {
        ...mapGetters('auth', [ 
            'has_permission' 
        ]),
        CONSTANTS() {
            return Constants;
        },
        filters: {
            get() {
                return this.$store.state.financial.filters_asaas_payment;
            }
        },
        filter_status: {
            get() {
                return this.filters.status;
            },
            set(value) {
                if (value === this.filters.status) {
                    return;
                }

                this.filters.status = value;
                this.LoadData();
            }
        },
        filter_due_date_start: {
            get() {
                return this.filters.due_date_start;
            },
            set(value) {
                if (value === this.filters.due_date_start) {
                    return;
                }

                this.filters.due_date_start = value;
                this.LoadData();
            }
        },
        filter_due_date_end: {
            get() {
                return this.filters.due_date_end;
            },
            set(value) {
                if (value === this.filters.due_date_end) {
                    return;
                }

                this.filters.due_date_end = value;
                this.LoadData();
            }
        },
        table_cols() {
            var cols = [
                { id: 'due_date', text: 'Vencimento', class: 'cols_due_date' },
                { id: null, text: 'Descrição', class: 'cols_description' },
                { id: 'worker', text: 'Profissional', class: 'cols_worker' },
                { id: 'service_date', text: 'Data do serviço', class: 'cols_service_date' },
                { id: 'customer', text: 'Cliente', class: 'cols_customer' },
                { id: 'value', text: 'Valor', class: 'cols_value' },
                { id: null, text: '', class: '' },
                { id: null, text: '', class: 'cols_status' }
            ];
            return cols;
        },
        status_options() {
            return [
                { value: null, text: "Todos" },
                { value: Constants.STATUS_DRAFT, text: 'Previsto' },
                { value: Constants.STATUS_PENDING, text: "Pendente" },
                { value: Constants.STATUS_RECEIVED, text: "Pago" },
                { value: Constants.STATUS_FAIL, text: "Falha" },
                { value: Constants.STATUS_CANCELED_TRA, text: "Cancelado" }
            ];
        }
    },
    components: {
        Panel, 
        RichTable,
        UtilBar,
        TypeableDate
    },
    data() {
        return {
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            table_config: {
                search: '',
                sort_column: "due_date",
                sort_order: "ASC",
                limit: 100,
                offset: 0
            },
            loadingData: false,
            items: [],
            status: null,
        }
    },
    methods: {
        LoadData() {
            this.loadingData = true;
            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('financial/getAsaasPayments', params).then((response) => {
                this.loadingData = false;
                var data = response;

                this.items = data.items ? data.items : [];

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : null;
            }).catch(() => {
                this.items = [];
                this.loadingData = false;
            });
        },
        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },
        moment(...args){
            return this.$moment(...args);
        },
        AnticipateAsaasPayment(item) {
            this.$swal({
                title: 'Antecipar Pagamento',
                text: "Deseja realmente antecipar o pagamento? Esta ação não poderá ser desfeita.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
                icon: "question",
            }).then((result) => {
                if (result.value) {
                    this.loadingData = true;
                    this.$store.dispatch("financial/anticipateAsaasPayment", item.id).then(() => {
                        this.LoadData();
                    }).catch(() => {
                        this.loadingData = false;
                    });
                }
            });
        },
        CancelAsaasPayment(item) {
            this.$swal({
                title: "Cancelar Pagamento",
                text: "Informe o motivo para o cancelamento do pagamento. Esta ação não poderá ser desfeita",
                icon: "warning",
                input: "textarea",
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: "Confirmar",
                denyButtonText: "Cancelar"
            }).then((result) => {
                if (result && result.isConfirmed) {
                    this.table_loading = true;
                    this.loadingData = true;
                    debugger
                    this.$store.dispatch("financial/cancelAsaasPayment", { id: item.id, motive: result.value}).then(() => {
                        this.LoadData();
                    }).catch(() => {
                        this.loadingData = false;
                    });
                }
            });
        },
        ReprocessAsaasPayment(item) {
            this.$swal({
                title: 'Reprocessar Pagamento com falha',
                html: "Deseja realmente reprocessar o pagamento? Esta ação não poderá ser desfeita tenha certeza de ter verificado e entendido o motivo "+(item.status_obs ? "<strong>"+item.status_obs+"</strong> " : "")+"da falha.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
                icon: "question",
            }).then((result) => {
                if (result.value) {
                    this.loadingData = true;
                    this.$store.dispatch("financial/reprocessAsaasPayment", item.id).then(() => {
                        this.LoadData();
                    }).catch(() => {
                        this.loadingData = false;
                    });
                }
            });
        },
        CleanFilters() {
            this.$store.commit('financial/SET_CLEAN_FILTERS_ASAAS_PAYMENTS');
            this.$refs["paymentsRichTable"].CleanFilters();
        },
    }
}
</script>

<style scoped>
    .b-table-service-list, .b-table-service-list * {
        font-size: 0.8rem !important;
    }
</style>
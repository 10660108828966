<template>
  <div>
    <util-bar>
      <div v-if="has_permission('worker-edit')">
        <b-row>
          <b-col sm="12" md="12" lg="12">
            <router-link to="/profissionais/cadastro" class="btn btn-mary-beleza">
              <fa icon="plus-circle" class="mr-2" />Adicionar
            </router-link>
          </b-col>
        </b-row>
      </div>
    </util-bar>

    <div class="pr-3 pl-3 pt-3">
      <panel title="Filtros" icon="filter">
        <b-row class="form-group" align-h="between">
          <b-col sm="12" md="4" lg="4">
            <label>Status:</label>
            <b-form-select v-model="filters.status" :options="options_status"></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Atende a domicílio:</label>
            <b-form-select v-model="filters.accept_home_care" :options="options"></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Possui veículo:</label>
            <b-form-select v-model="filters.has_vehicle" :options="options"></b-form-select>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <label></label>
            <p>
              <b-button
                class="mt-2 ml-2"
                variant="outline-danger"
                @click="CleanFilters"
              >Limpar Filtros</b-button>
            </p>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="2" lg="2">
            <label>Com MEI:</label>
            <b-form-select v-model="filters.has_mei" :options="options"></b-form-select>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <label>Já trabalha com aplicativo de beleza:</label>
            <b-form-select v-model="filters.another_beauty_app" :options="options"></b-form-select>
          </b-col>
          <b-col sm="12" md="6" lg="6">
            <label>Data de cadastro:</label>
            <typeable-date v-model="filters.created_at" />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="9" lg="9">
            <label>Habilidades:</label>
            <multiselect
              v-model="filters.habilities"
              :options="habilities_all"
              :multiple="true"
              placeholder="Selecione..."
              selectedLabel="Selecionado"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para remover"
              track-by="id"
              label="name"
            >
              <template slot="noResult">Nenhum resultado encontrado</template>
              <template slot="noOptions">Nenhum resultado encontrado</template>
            </multiselect>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Importação:</label>
            <b-form-select v-model="filters.imported_from" :options="options_imported_from"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col>
            <div role="group">
              <label>Como ficou sabendo da existência da Mary Beleza:</label>
              <template>
                <b-form-select v-model="existence" :options="existence_options"></b-form-select>
              </template>
            </div>
          </b-col>
          <b-col sm="4" v-if="existence == 15">
            <div role="group">
              <label>Qual outro:</label>
              <template>
                <b-form-input v-model="filters.who_existence" placeholder="Qual outro"></b-form-input>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="3" lg="3">
            <div role="group">
              <label>Zona:</label>
              <b-form-select v-model="filters.zone" :options="zone_options">
                  <template #first>
                      <b-form-select-option :value="null">Selecione...</b-form-select-option>
                  </template>
              </b-form-select>
            </div>  
          </b-col>
        </b-row>
      </panel>

      <panel title="Lista de Pré-cadastros">
        <b-row class="form-group">
          <b-col md="12 text-center">
            <!-- INSIRA O CONTEÚDO DO PAINEL AQUI -->
            <b-row class="form-group">
              <b-col sm="12" md="12" lg="12">
                <rich-table
                  ref="pre_registrationRichTable"
                  v-on:input="ChangeTableConfig"
                  :numbers="table_numbers"
                  :loading="loadingData"
                  :cols="table_cols"
                  :vueComponent="vue_module"
                >
                  <template v-if="items && items.length > 0">
                    <tr
                      v-for="(item, key) in items"
                      v-bind:key="key"
                      :class="item.page + (item.selected ? ' selected' : '')"
                    >
                      <td width="30%">{{ item.name }}</td>
                      <td width="30%">{{ item.email }}</td>
                      <td width="15%">{{ item.phone | formatPhones }}</td>
                      <td width="10%">
                        <b-badge
                          v-if="
                            item.status == constants.STATUS_PRE_REGISTRATION
                          "
                          variant="mary-beleza"
                        >Pré-cadastro</b-badge>
                        <b-badge
                          v-else-if="
                            item.status == constants.STATUS_DISAPPROVED
                          "
                          variant="danger"
                          v-b-tooltip="item.worker.disapproval_reason"
                        >Reprovado</b-badge>
                        <b-badge
                          v-else-if="item.status == constants.STATUS_APPROVED"
                          variant="success"
                        >Aprovado</b-badge>
                        <b-badge
                          v-else-if="
                            item.status == constants.STATUS_WAITING_CHECKING
                          "
                          variant="info"
                        >Aguardando Checagem</b-badge>
                      </td>
                      <td>
                        <b-button
                          v-if="item.restriction"
                          variant="danger"
                          size="sm"
                          v-b-tooltip
                          :title="item.restrict_reason"
                        >
                          <b-icon icon="hand-thumbs-down" style="color: #D5F5E3;" font-scale="1.5" />
                        </b-button>

                        <b-button v-else variant="success" size="sm">
                          <b-icon icon="hand-thumbs-up" style="color: #F2D7D5;" font-scale="1.5" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="item.phone"
                          v-b-tooltip
                          title="Copiar Mensagem"
                          @click="OpenContactModal(item)"
                          size="sm"
                          variant="success"
                        >
                          <fa :icon="['fab', 'whatsapp']" size="lg" />
                        </b-button>
                      </td>
                      <td>
                        <b-button
                          v-if="item.phone"
                          v-b-tooltip
                          title="WhatsApp Web"
                          @click="WhatsAppLink(item.phone)"
                          size="sm"
                          variant="primary"
                          ><fa :icon="['fab', 'whatsapp']" size="lg"
                        /></b-button>
                      </td>
                      <td width="7%" class="text-center">
                        <b-button
                          v-if="has_permission('worker-edit')"
                          variant="primary"
                          size="sm"
                          v-b-tooltip
                          @click="contactWorkerById(item)"
                          title="Informar tentativa de contato."
                        >
                          <b-icon icon="telephone-plus-fill" font-scale="1" />
                          {{
                          item.number_of_contacts
                          ? item.number_of_contacts
                          : 0
                          }}
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="
                            (item.status == constants.STATUS_APPROVED ||
                              item.status == constants.STATUS_DISAPPROVED ||
                              item.status ==
                                constants.STATUS_WAITING_CHECKING) &&
                              has_permission('worker-edit')
                          "
                          variant="primary"
                          size="sm"
                          @click="
                            $router.push({
                              path: '/profissionais/cadastro/' + item.id
                            })
                          "
                          v-b-tooltip
                          title="Editar Ficha"
                        >
                          <b-icon icon="pen" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="has_permission('worker-view')"
                          variant="warning"
                          size="sm"
                          @click="
                            $router.push({
                              path: '/profissionais/pre-cadastro/' + item.id
                            })
                          "
                          v-b-tooltip
                          title="Visualizar Ficha"
                        >
                          <b-icon icon="binoculars" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="has_permission('worker-delete')"
                          variant="danger"
                          size="sm"
                          @click="deleteWorker(item)"
                          v-b-tooltip
                          title="Apagar Ficha"
                        >
                          <b-icon icon="trash" font-scale="1" />
                        </b-button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="table_cols.length"
                        class="text-center py-3"
                      >Nenhum registro encontrado</td>
                    </tr>
                  </template>
                </rich-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </panel>
      <b-modal
        title="Informações de Contato"
        size="normal"
        ref="contact-modal"
        hide-footer
        scrollable
      >
        <template>
          <div class="row">
            <div class="col-md-12">
              <b-button
                class="mb-2"
                block
                variant="dark"
                @click.stop.prevent="CopyToClipboard('contact-phone-text')"
              >Copiar Contato</b-button>
              <b-form-input
                id="contact-phone-text"
                class="p-1"
                plaintext
                :value="'55'+currentItem.phone"
                readonly
              ></b-form-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <b-button
                class="mb-2"
                block
                variant="dark"
                @click.stop.prevent="CopyToClipboard('contact-message-text')"
              >Copiar Mensagem</b-button>
              <b-form-textarea
                id="contact-message-text"
                class="p-4"
                plaintext
                :value="contact_message"
                readonly
                no-resize
                wrap
                no-auto-shrink
                rows="15"
              ></b-form-textarea>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <b-button class="float-right" variant="success" @click="CloseContactModal()">Ok</b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel";
import RichTable from "@/components/RichTable";
import store from "@/store";
import _clone from "lodash/clone";
import _merge from "lodash/merge";
import UtilBar from "@/components/UtilBar";
import Constants from "@/constants";
import Multiselect from "vue-multiselect";
import TypeableDate from "@/components/TypeableDate";
import { mapGetters } from "vuex";

export default {
  components: {
    Panel,
    RichTable,
    UtilBar,
    Multiselect,
    TypeableDate
  },
  data() {
    return {
      vue_module: 'pre_registration',
      show: true,
      currentItem: {},
      items: [],
      table_numbers: {
        size: 1,
        total: 0,
        found: 0,
        footer: null
      },
      loadingData: false,

      options_status: [
        {
          value: [
            Constants.STATUS_DISAPPROVED,
            Constants.STATUS_PRE_REGISTRATION,
            Constants.STATUS_APPROVED,
            Constants.STATUS_WAITING_CHECKING
          ],
          text: "Todos"
        },
        { value: Constants.STATUS_PRE_REGISTRATION, text: "Pré-cadastro" },
        { value: Constants.STATUS_APPROVED, text: "Aprovados" },
        { value: Constants.STATUS_DISAPPROVED, text: "Reprovados" },
        {
          value: Constants.STATUS_WAITING_CHECKING,
          text: "Aguardando Checagem"
        }
      ],
      options: [
        { value: 0, text: "Não" },
        { value: 1, text: "Sim" },
        { value: null, text: "Todos" }
      ],
      options_imported_from: [
        // { value: Constants.STATUS_MARYBELEZASITE, text: "Site Mary Beleza" },
        {
          value: Constants.STATUS_RECRUTAMENTOBELEZA,
          text: "Recrutamento Beleza"
        },
        { value: Constants.STATUS_INFOJOBS, text: "InfoJobs" },
        { value: Constants.STATUS_SISTEMA, text: "Sistema" },
        { value: "facebook", text: "Facebook" },
        { value: null, text: "Todos" }
      ],
      existence_options: [
        { value: null, text: "Selecione", disabled: true },
        { value: 1, text: "Bloguerias" },
        { value: 2, text: "Cartão de Visita" },
        { value: 3, text: "E-mail Marketing" },
        { value: 4, text: "Facebook" },
        { value: 5, text: "Google" },
        { value: 6, text: "Instagram" },
        { value: 7, text: "Indicação de Amigos" },
        { value: 8, text: "Jornal" },
        { value: 9, text: "Outdoor" },
        { value: 10, text: "Panfletos de Propaganda" },
        { value: 11, text: "Revistas" },
        { value: 12, text: "SMS" },
        { value: 13, text: "WhatsApp" },
        { value: 14, text: "", disabled: true },
        { value: 15, text: "Outros" }
      ],
      zone_options: [
        {
          text: 'Sem Nenhuma',
          value: -1
        },
        {
          text: 'Centro',
          value: 0
        },
        {
          text: 'Norte',
          value: 1
        },
        {
          text: 'Sul',
          value: 2
        }, 
        {
          text: 'Leste',
          value: 3
        },
        {
          text: 'Oeste',
          value: 4
        }
      ],
      contact_message: "Prezada(o) profissional, somos da empresa MARY BELEZA - Atendimento de beleza via aplicativo. Você se cadastrou através do Facebook ou da Infojobs e por isso temos o seu contato. Estamos buscando profissionais da beleza para trabalharem em um aplicativo onde o atendimento será feito em domicílio como: manicure, cabelos, massagens, maquiagem, limpeza de pele, etc.  Através do nosso aplicativo você pode ter mais liberdade, horários mais flexíveis e ainda aumentar a sua renda!!! Caso você tenha interesse responda SIM aqui no WhatsApp e nos fale quais as áreas que você atua e quanto tempo tem de profissão. Caso queira conhecer melhor a nossa empresa acesse o site: www.marybeleza.com.br  Grata Equipe Mary Beleza"
    };
  },

  mounted() {
    this.LoadData();
    this.$store.dispatch("worker/getHabilitiesAll");
  },

  computed: {
    ...mapGetters("auth", ["has_permission"]),

    habilities_all: {
      get() {
        return this.$store.state.worker.habilities_all;
      }
    },

    filters: {
      get() {
        return this.$store.state.worker.filters.pre_registration;
      }
    },

    existence: {
      get() {
        return this.filters.existence;
      },
      set(value) {
        this.filters.existence = value;
        this.filters.who_existence = null;
      }
    },

    table_cols() {
      let cols = [
        {
          id: "name",
          text: "Nome",
          class: "cols_name"
        },
        {
          id: "email",
          text: "E-mail",
          class: "cols_email"
        },
        {
          id: "phone",
          text: "Telefones",
          class: "cols_phone"
        },
        {
          id: "status",
          text: "Status",
          class: "cols_status"
        },
        {
          id: "restriction",
          text: "Restrição",
          class: "cols_restriction"
        },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" }
      ];
      return cols;
    },
    constants() {
      return Constants;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.LoadData();
      },
      deep: true
    }
  },
  methods: {
    makeToast(message, variant = null) {
      this.$bvToast.toast(message, {
        title: 'Mensagem',
        variant: variant,
        solid: true,
        autoHideDelay: 1000
      })
    },
    CopyToClipboard(fieldId) {
      let copy = document.querySelector("#" + fieldId);
      copy.setAttribute("type", "text");
      copy.select();

      try {
        var successful = document.execCommand("copy");
        if(successful)
          this.makeToast('Copiado com sucesso', 'success')
        else
          this.makeToast('Não foi possível copiar', 'danger')

      } catch (err) {
        this.makeToast('Erro ao copiar', 'danger')
      }
    },
    OpenContactModal(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
      this.$refs["contact-modal"].show();
    },
    CloseContactModal() {
      this.currentItem = {};
      this.$refs["contact-modal"].hide();
    },
    LoadData() {
      this.loadingData = true;

      var params = _clone(_merge(this.filters, this.table_config));
      store.dispatch("worker/getList", params).then(data => {
        this.loadingData = false;

        this.items = data.items ? data.items : [];

        this.table_numbers.size = data.size ? data.size : 0;
        this.table_numbers.found = data.found ? data.found : 0;
        this.table_numbers.total = data.total ? data.total : 0;
        this.table_numbers.footer = data.footer ? data.footer : 0;
      });
    },
    deleteWorker(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Apagar`,
        html: `Deseja realmente apagar o(a) profissional <strong>${worker.name}</strong>?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("worker/deleteWorkerById", worker.id)
            .then(response => {
              this.sweealert2("success", response.success);
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    },

    sweealert2(icon, text) {
      this.$swal({
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        text: text,
        icon: icon,
        padding: 20
      });
    },

    CleanFilters() {
      this.$store.commit("worker/SET_CLEAN_FILTERS", {
        module: this.vue_module,
        status: [
          Constants.STATUS_DISAPPROVED,
          Constants.STATUS_PRE_REGISTRATION,
          Constants.STATUS_APPROVED,
          Constants.STATUS_WAITING_CHECKING
        ]
      });
      this.$refs["pre_registrationRichTable"].CleanFilters();
    },

    ChangeTableConfig(value) {
      this.table_config = value;
      this.LoadData();
    },

    WhatsAppLink(phones) {
      let array_phones = phones.split(" | ");
      let inputOptions = [];

      array_phones.forEach(phone => {
        if (phone.length >= 10 && phone.length <= 11) {
          // Com DDD
          inputOptions.push(
            phone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3")
          );
        } else if (phone.length >= 8 && phone.length <= 9) {
          // Sem DDD
          inputOptions.push(phone.replace(/(\d{4,5})(\d{4})/, "$1-$2"));
        }
      });

      this.$swal.fire({
        title: "Selecione o telefone",
        input: "select",
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: "Enviar",
        cancelButtonText: "Cancelar",
        inputValidator: index => {
          let a = document.createElement("a");
          a.target = "_blank";
          a.href =
            "https://api.whatsapp.com/send?phone=55" +
            inputOptions[index].replace(/[^\d]/g, "") +
            "&text=" +
            this.contact_message;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    },

    contactWorkerById(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Contato`,
        html: `Deseja realmente adicionar uma tentativa de contato com a(o) profissional <strong>${worker.name}</strong>?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            title: "Processando...",
            icon: "info",
            padding: 20
          });

          this.$store
            .dispatch("worker/contactWorkerById", worker.id)
            .then(data => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: data.success,
                icon: "success",
                padding: 20
              });
            })
            .catch(errors => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: errors.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    }
  }
};
</script>

<style>
</style>

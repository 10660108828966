<template>
    <div v-if="loaded">
        <util-bar></util-bar>
        <div class="pr-3 pl-3 pt-3">
            <b-row>
                <b-col>
                    <panel title="Profissionais">
                        <b-row class="form-group">
                            <b-col md="4">
                                <label>Período</label>
                                <b-row>
                                    <b-col>
                                        <typeable-date v-model="period_start"/>
                                    </b-col>
                                    <span class="align-self-center">-</span>
                                    <b-col>
                                        <typeable-date v-model="period_end"/>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-table-simple responsive striped small hover class="p-0">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>#</b-th>
                                    <b-th>Profissional</b-th>
                                    <b-th>Horas Trabalhadas no período</b-th>
                                    <b-th>Dias no período</b-th>
                                    <b-th>Taxa de Confirmação</b-th>
                                    <b-th>Nota média</b-th>
                                    <b-th>Pontuação</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>  
                                <template v-if="ranking.length > 0">                  
                                    <b-tr v-for="(worker, i) in ranking" :key="i">
                                        <b-td>{{i + 1}}</b-td>
                                        <b-td>{{worker.person.name}}</b-td>
                                        <b-td>{{worker.H}}</b-td>
                                        <b-td>{{worker.ND}}</b-td>
                                        <b-td>{{worker.PC * 100 | percentage}}</b-td>
                                        <b-td>{{worker.NM}}</b-td>
                                        <b-td>{{worker.POINTS | million}}</b-td>
                                    </b-tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="7" class="text-center py-3">Nenhum registro encontrado</td>
                                    </tr>
                                </template>
                            </b-tbody>
                        </b-table-simple>
                    </panel>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import UtilBar from '@/components/UtilBar'
import moment from 'moment'
import TypeableDate from '@/components/TypeableDate'
//import swal from 'sweetalert2'

export default {
    components: {
        UtilBar,
        Panel,
        TypeableDate
    },
    computed: {
        period_start: {
            get() {
                return this.filters.period_start;
            },
            set(value) {
                this.filters.period_start = value;
                this.LoadData();
            }
        },
        period_end: {
            get() {
                return this.filters.period_end;
            },
            set(value) {
                this.filters.period_end = value;
                this.LoadData();
            }
        }
    },
    data() {
        return {
            loaded: false,
            filters: {
                period_start: moment().subtract(1, "month").format("YYYY-MM-DD"),
                period_end: moment().format("YYYY-MM-DD")
            },
            ranking: []
        };
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$store.dispatch("worker/ranking", this.filters).then((response) => {
                this.loaded = true;
                this.ranking = response;
            });
        }
    }
}
</script>
<template>
    <div>
        <util-bar>
            <div v-if="!loadingData">
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button v-if="has_permission('worker-edit') && [constants.STATUS_DROPOUT].includes($store.state.worker.worker.status)" variant="success" class="mr-2" @click="newStatusWorker(constants.STATUS_PROFESSIONAL_MB)">
                            <b-iconstack font-scale="1.3" class="mr-2">
                                <b-icon stacked icon="patch-check"></b-icon>
                            </b-iconstack>
                            Tornar Profissional MB
                        </b-button>

                        <b-button variant="outline-mary-beleza" v-on:click="$router.push({ path: '/profissionais/pre-cadastro'})">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <b-overlay :show="loadingData" rounded="sm">
                <panel title="Informações Pessoais" icon='user-circle' icon-lib="far">
                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Nome:</label>
                            <div>
                                {{ $store.state.worker.worker.person.name }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>E-mail:</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.person.email">
                                                -
                                </p>
                                <p v-else>{{ $store.state.worker.worker.person.email }}</p>
                            </div>
                        </b-col>

                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Telefones:</label>
                            
                            <div v-if="$store.state.worker.worker.person.phones.length > 0">
                                <div v-for="(phone, index) in $store.state.worker.worker.person.phones" :key="index" class="mb-1">
                                    - {{ phone.phone_number | formatPhones }} <small v-if="phone.description">({{ phone.description }})</small><br />
                                    <b-badge v-if="phone.is_personal" variant="primary" class="mr-2">Pessoal</b-badge>
                                    <b-badge v-if="phone.is_whatsapp" variant="success">WhatsApp</b-badge>
                                </div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </b-col>
                    </b-row>
                    <b-alert v-if="$store.state.worker.worker.status == constants.STATUS_DISAPPROVED" class="text-dark" variant="warning" show><strong class="text-danger">Reprovado: </strong>{{ $store.state.worker.worker.disapproval_reason }}</b-alert>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm" v-if="$store.state.worker.worker.person.address[0].zipcode">
                <panel title="Endereço" icon='map-marked-alt' icon-lib="fa">
                    <b-row>
                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label>CEP:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].zipcode | formatCep }}
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Endereço:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].street }}
                            </div>
                        </b-col>

                        <b-col sm="12" md="3" lg="3" class="form-group">
                            <label>Número:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].number }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Bairro:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].district }}
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Complemento:</label>
                            <div>
                                {{ $store.state.worker.worker.person.address[0].complement != null ? $store.state.worker.worker.person.address[0].complement : '-' }}
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Cidade:</label>
                            <div>
                                <template v-if="$store.state.worker.worker.person.address[0] && $store.state.worker.worker.person.address[0].city">{{ $store.state.worker.worker.person.address[0].city.name }}</template>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Estado:</label>
                            <div>
                                <template v-if="$store.state.worker.worker.person.address[0] && $store.state.worker.worker.person.address[0].city && $store.state.worker.worker.person.address[0].city.state">{{ $store.state.worker.worker.person.address[0].city.state.name }}</template>
                            </div>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>

            <b-overlay :show="loadingData" rounded="sm">
                <panel title="Informações Adicionais" icon='address-card' icon-lib="far">
                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Estaria disposto a fazer atendimentos em domicílio?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.accept_home_care">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.accept_home_care"><b-badge variant="success">Sim</b-badge></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Você tem empresa como MEI (Micro Empreendedor Individual)?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.has_mei">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.has_mei"><b-badge variant="success">Sim</b-badge></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Possui veículo de locomoção próprio?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.has_vehicle">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.has_vehicle"><b-badge variant="success">Sim</b-badge> <small v-if="$store.state.worker.worker.detail.has_vehicle_which">{{ $store.state.worker.worker.detail.has_vehicle_which }}</small></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>

                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Você já trabalha com algum aplicativo de beleza?</label>
                            <div>
                                <p v-if="!$store.state.worker.worker.detail.another_beauty_app">-</p>
                                <h5 v-else-if="$store.state.worker.worker.detail.another_beauty_app"><b-badge variant="success">Sim</b-badge> <small>{{ $store.state.worker.worker.detail.another_beauty_app }}</small></h5>
                                <h5 v-else><b-badge variant="danger">Não</b-badge></h5>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="6" lg="6" class="form-group">
                            <label>Facebook:</label>
                            <div>
                                <span v-if="!$store.state.worker.worker.detail.facebook">-</span>
                                <span v-else-if="$store.state.worker.worker.detail.facebook">{{$store.state.worker.worker.detail.facebook}}</span>
                            </div>
                        </b-col>
                        
                        <b-col sm="12" md="¨6" lg="6" class="form-group">
                            <label>Instagram:</label>
                            <div>
                                <span v-if="!$store.state.worker.worker.detail.instagram">-</span>
                                <span v-else-if="$store.state.worker.worker.detail.instagram">{{$store.state.worker.worker.detail.instagram}}</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Áreas de Atuação:</label>
                            <div>
                                <h5 v-if="$store.state.worker.worker.worker_habilities.length > 0">
                                    <b-badge v-for="(hability, index) in $store.state.worker.worker.worker_habilities" :key="index" variant="mary-beleza" class="mr-2">{{habilityName(hability.hability_id)}}</b-badge> 
                                </h5>
                                <span v-else>Nenhuma Área de Atuação Informada</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="form-group">
                            <label>Referências profissionais</label>
                            <div>
                                <span v-if="$store.state.worker.worker.detail.professional_references" v-html="$store.state.worker.worker.detail.professional_references"></span>
                                <p v-else>Nenhuma referência informada</p>
                            </div>
                        </b-col>
                    </b-row>
                </panel>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import store from '@/store'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import swal from 'sweetalert2'

import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {
            loadingData: true
        }
    },
    components: {
        Panel,
        UtilBar
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        async LoadData() {
            await store.dispatch('worker/getWorkerById', this.$route.params.id).then(() => {
                this.loadingData = false;
            });
        },
        newStatusWorker(status){
            var self= this;
            swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Profissional Mary Beleza`,
                html: `Deseja realmente ${status == Constants.STATUS_PROFESSIONAL_MB ? 'tornar' : 'retirar'} o(a) profissional <strong>${this.worker.person.name}</strong> ${status == Constants.STATUS_PROFESSIONAL_MB ? 'como profissional MB' : 'da lista de profissionais MB'}?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    swal.fire({
                        title: "Salvando",
                        didOpen() {
                            swal.showLoading();
                        }
                    })
                    var data = {
                        worker_id: self.worker.id,
                        status: status,
                        from: 'status_worker'
                    }

                    this.$store.dispatch('worker/changeStatusWorker', data).then(response => {
                        this.sweealert2('success', response.success)
                    }).catch(error => {
                        swal.fire({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        habilityName(id) {
            let habilitie = this.habilities.find(hability => hability.id === id)
            return habilitie?.name
        },

        sweealert2(icon, text) {
            swal.fire({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20
            })
        }
    },

    computed: {
        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        worker: {
            get() {
                return this.$store.state.worker.worker;
            },
        },

        loading: {
            get() {
                return this.$store.state.worker.loading;
            },
        },

        habilities: {
            get() {
                return this.$store.state.worker.habilities_all;
            },
        },
    }
}
</script>

<style lang="scss">
label {
    font-weight: bold;
}
</style>
<template>
    <div>
        <ul>
            <li>
                <b-form-checkbox v-model="parent_checked" name="check-button" switch @change="CheckChildren">
                    {{group.description}}
                </b-form-checkbox>
                <ul>
                    <li v-for="(item, key) in items" :key="key">

                        <b-form-checkbox v-model="item.checked" name="check-button" switch @input="UpdateValues">
                            {{item.description}}
                        </b-form-checkbox>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        group: {
            type: Object,
            required: true
        },
        user_permissions: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            items: this.group.children.slice()
        }
    },
    computed: {
        parent_checked: { 
            get() {
                return this.items.filter((item) => ! item.checked).length == 0
            },
            set() {
                // do nothing
            }
        }
    },
    beforeMount() {
        this.ProcessPermissions();
    },
    methods: {
        ProcessPermissions() {
            let user_permissions = this.user_permissions
            this.items.map((item) => {
                this.$set(item, "checked", (item.value & user_permissions) > 0)
            })
        },
        UpdateValues() {
            let total = this.items.reduce((acc, item) => {
                return acc + (item.checked ? item.value : 0)
            }, 0)
            this.$emit("update:user_permissions", total)
        },
        CheckChildren(value) {
            this.items.map((item) => {
                item.checked = value
            })  
        }
    }
}
</script>

<style scoped>
    ul {
        padding-left: 10px;
    }

    li {
        list-style: none;
    }

    input[type="checkbox"] {
        margin: 0 5px;
    }
    /* .children {
        padd
    } */
</style>
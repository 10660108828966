import Http from '@/api/http';
import ScheduleDomain from '@/domain/schedule'
import ServicesFilters from '@/domain/filters/services'

const state = {
    schedules: [],
    punishment_rules: [],
    schedule: new ScheduleDomain(),
    services: [],
    filters: {
        schedules: new ServicesFilters(),
        schedules_problems: new ServicesFilters(),
        promotions: []
    },
    errors: {}
};

const actions = {
    // Agendamentos
    getSchedules({ commit }, params) {
        return new Promise((resolve, reject) => {
            if(params.services != null){ params.services = params.services.map(service => service.id)}
            Http.get('schedule', {params: params}).then(response => {
                commit('SET_SCHEDULES', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    pix(_, id) {
        return new Promise((resolve, reject) => {
            Http.get('schedule/'+id+"/pix").then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    searchSchedules({ commit }, params) {
        return new Promise((resolve, reject) => {
            if(params.services != null){ params.services = params.services.map(service => service.id)}
            Http.get('schedule/search', {params: params}).then(response => {
                commit('SET_SCHEDULES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Tipos de serviço - Unhas, Pés
    getServices({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('service').then(response => {
                commit('SET_SERVICES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Busca uma determinada schedule
    getSchedule({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.get(`schedule/${id}`).then(response => {
                commit('SET_SCHEDULE', response.data);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Apaga uma determinada schedule
    deleteSchedule({ state }, id) {
        return new Promise((resolve, reject) => {
            Http.delete(`schedule/${id}`).then(response => {
                state.schedules
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Profissionais sem serviço em andamento
    getWorkersNotSchedule({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('/worker/alterSchedule', {params: params}).then(response => {
                commit('SET_SCHEDULES')
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Adiciona um profissionais ao agendamento
    addScheduleWorker({ state, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/accept`, data).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Profissionais sem serviço em andamento
    updateScheduleWorker({ state, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/worker`, data).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updatePrepaidcard({state, dispatch}, customer_prepaidcard_id) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/prepaidcard`, { customer_prepaidcard_id }).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateSchedulePaymentType({ state, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/payment_type`, data).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updatePreferences({ state, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/preferences`, data).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Remove a profissional do serviço
    removeScheduleWorker({ state, dispatch }, punishment_opt) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/quit`, {punishment_opt}).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Update nos dados do agendamento
    updateSchedule({ dispatch, state }, punishment_opt) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}`, Object.assign(state.schedule, punishment_opt)).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Cancela um determinado agendamento
    cancelSchedule({ dispatch, state }, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/cancel`, data).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateAddress({commit, state}) {
        commit('SET_CLEAR_ERRORS')
        return new Promise((resolve, reject) => {
            let address = state.schedule.address
            Http.put(`/schedule/${state.schedule.id}/address`, address).then(response => {
                commit('SET_ADDRESS', response.data.address)
                resolve(response.data)
            }).catch(error => {
                commit('SET_ERRORS', error.response.data)
                reject(error.response.data)
            })
        })
    },

    resolvedProblem({commit, state}, index) {
        return new Promise((resolve, reject) => {
            Http.put(`/schedule/${state.schedule.id}/problem/${state.schedule.schedule_problems[index].id}`).then(response => {
                commit('SET_SCHEDULE_PROBLEM', index)
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    newPunishment({state}, punishment) {
        return new Promise((resolve, reject) => {
            Http.post(`/punishmentRules/${punishment.id}/schedule/${state.schedule.id}`).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    createProblemSchedule({state}, data) {
        return new Promise((resolve, reject) => {
            Http.post(`/schedule/${state.schedule.id}/problem`, data).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    // Busca uma determinada schedule
    getPunishmentRules({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get(`punishmentRules`).then(response => {
                commit('SET_PUNISHMENT_RULES', response.data);
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Repete a cobrança do cliente
    repeatTransaction({state, dispatch}) {
        return new Promise((resolve, reject) => {
            Http.post(`schedule/${state.schedule.id}/transaction`).then(response => {
                dispatch('getSchedule', state.schedule.id);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            });
        })
    },

    getPriceTable(_, params){
        return new Promise((resolve, reject) => {
            Http.get(`financial/pricetable/price`, {params: params}).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error.response.data)
            });
        })
    },

    getCustomers(_, params) {
        return new Promise((resolve, reject) => {
            Http.get('customer', {params: params}).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    getCustomerById(_, id) {
        return new Promise((resolve, reject) => {
            Http.get(`customer/${id}`).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    getWorkerById(_, id) {
        return new Promise((resolve, reject) => {
            Http.get(`worker/${id}`).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error.response.data)
            })
        })
    },

    createSchedule({ state }, services = []) {
        return new Promise((resolve, reject) => {
            Http.post(`schedule`, {
                ...state.schedule, services
            }).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error.response.data.error)
            })
        })
    },
    checkin(_, params) {
        return new Promise((resolve, reject) => {
            var formdata = new FormData();
            formdata.append("type", params.type);

            if (params.photo) {
                formdata.append("photo", params.photo);
            }
            
            if (params.other_payment_type) {
                formdata.append("other_payment_type", 1);
            }

            Http.post(`schedule/${params.schedule_id}/worker/${params.worker_id}/checkin`, formdata).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error.response.data.error)
            })
        })
    }
};

const mutations = {

    SET_CLEAN_FILTERS(state) {
        state.filters.schedules = new ServicesFilters();
    },

    SET_SCHEDULE_PROBLEMS_CLEAN_FILTERS(state) {
        state.filters.schedules_problems = new ServicesFilters();
    },

    SET_SCHEDULES(state, data) {
        state.schedules = data;
    },

    SET_SCHEDULE(state, data) {
        state.schedule = new ScheduleDomain();
        state.schedule = data;
    },

    SET_CLEAN_SCHEDULE(state) {
        state.schedule = new ScheduleDomain();
    },

    SET_PUNISHMENT_RULES(state, data) {
        state.punishment_rules = data;
    },

    SET_SERVICES(state, data) {
        state.services = [];
        options(data);
        function options(opt){
            opt.forEach(element => {
                state.services.push({"name": element.name, "id": element.id, "hability_id": element.hability_id});
                if(element.children){
                    options(element.children);
                }
            });
        }
    },

    SET_PAYMENT_TYPE(state, value) {
        state.schedule.payment_type = value
    },

    SET_CUSTOMER_VALUE(state, value){
        state.schedule.customer_value = value
    },

    SET_WORKER_VALUE(state, value){
        state.schedule.worker_value = value
    },

    SET_STATUS(state, value){
        state.schedule.status = value
    },

    SET_SERVICE_DATE(state, value){
        state.schedule.service_date = value
    },

    SET_GENDER_PREFERENCE(state, value) {
        state.schedule.gender_preference = value
    },


    // HORÁRIOS
    SET_ADD_HOUR(state){
        state.schedule.hours.push({hour: "00:00"})
    },
    SET_DELETE_HOUR(state, index) {
        state.schedule.hours.splice(index, 1)
    },
    SET_HOUR(state, data){
        state.schedule.hours[data.index].hour = data.value
    },



    // ENDEREÇO
    SET_ADDRESS(state, value) {
        state.schedule.address = value;
    },

    SET_ZIPCODE(state, value) {
        state.schedule.address.zipcode = value;
    },

    SET_STREET(state, value) {
        state.schedule.address.street = value;
    },

    SET_NUMBER(state, value) {
        state.schedule.address.number = value;
    },

    SET_COMPLEMENT(state, value) {
        state.schedule.address.complement = value;
    },

    SET_REFERENCE(state, value) {
        state.schedule.address.reference = value;
    },

    SET_ZONE(state, value) {
        state.schedule.address.zone = value;
    },

    SET_DISTRICT(state, value) {
        state.schedule.address.district = value;
    },

    SET_CITY_ID(state, value) {
        state.schedule.address.city_id = value;
    },

    SET_ADDRESS_STATE(state, value) {
        state.schedule.state = value;
    },

    SET_ERRORS(state, value){
        state.errors = value;
    },

    SET_CLEAR_ERRORS(state){
        state.errors = {};
    },

    SET_SCHEDULE_PROBLEM(state, index){
        state.schedule.schedule_problems[index].status = 0;
    },

    SET_SCHEDULE_CUSTOMER_ID(state, value){
        state.schedule.customer_id = value;
    },

    SET_SCHEDULE_WORKER_ID(state, value){
        state.schedule.worker_id = value;
    },

    SET_SCHEDULE_SERVICE_ID(state, value){
        state.schedule.service_id = value;
    },

    SET_SCHEDULE_ADDRESS_ID(state, value){
        state.schedule.address_id = value;
    },

    SET_SCHEDULE_CUSTOMER_PREPAIDCARD_ID(state, value){
        state.schedule.customer_prepaidcard_id = value;
    },

    SET_SCHEDULE_SERVICE_DATE(state, value){
        state.schedule.service_date = value;
    },

    SET_SCHEDULE_CUSTOMER_VALUE(state, value){
        state.schedule.customer_value = value;
    },

    SET_SCHEDULE_WORKER_VALUE(state, value){
        state.schedule.worker_value = value;
    },

    SET_SCHEDULE_CHECKINS(state, value){
        state.schedule.checkins = value;
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
    <div>
        <util-bar>
            <b-row>
                <b-col v-if="has_permission('faq-edit')" sm="12" md='12' lg="12">
                    <b-button variant="mary-beleza" size="md" class="mr-2" @click="CreateFaq">
                        <fa icon="plus-circle" class="mr-2" /> Adicionar
                    </b-button>
                </b-col>
            </b-row>
        </util-bar>
        <div class="pr-3 pl-3 pt-3 w-100">
            <panel title="Filtros" icon="filter">
                <b-row class="form-group" align-h="between">
                    <b-col sm="12" md="8" lg="8">
                        <label>Busca por palavras:</label>
                        <b-form-input v-model="filters.search" placeholder="Busca" trim></b-form-input>
                    </b-col>
                    <b-col sm="12" md="4" lg="4">
                        <label>APP:</label>
                        <b-form-select v-model="filters.app" :options="options"></b-form-select>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Clientes" icon="handshake" iconLib="far">
                <b-card v-for="(item, i) in FilterItemsFaq('customer')" :key="i" class="mb-1">
                    <b-row>
                        <b-col sm="12" md="10" lg="10">
                            <p class="m-0 p-0" :id="'copy-customer-question-'+i">
                                <b>{{item.question}}</b>
                            </p>
                            <p class="m-0 p-0" :id="'copy-customer-answer-'+i">{{item.answer}}</p>
                            <p class="m-0 p-0 text-muted" style="font-size:12px">
                                {{item.created_at | moment("DD/MM/YYYY HH:mm") }}
                                <fa v-if="item.app" class="ml-2" :icon="['fas', 'mobile']" color="green" v-b-tooltip="'Ativo no APP'"/>
                            </p>
                        </b-col>
                        <b-col sm="12" md="2" lg="2" class="text-right align-self-center">
                            <b-button @click="CopyText('copy-customer', i)" v-b-tooltip="'Copiar'" class="ml-1 rounded-circle p-0" variant="outline-mary-beleza" style="height: 32px; width: 32px">
                                <b-icon class="align-self-center" icon="clipboard"></b-icon>
                            </b-button>
                            <b-button v-if="has_permission('faq-delete')" @click="DeleteFaq(item)" v-b-tooltip="'Apagar'" class="ml-1 rounded-circle p-0" variant="outline-danger" style="height: 32px; width: 32px">
                                <b-icon class="align-self-center" icon="trash" scale="1"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                <p v-if="FilterItemsFaq('customer').length == 0" class="justify-content-center d-flex">Nenhum registro encontrado...</p>
            </panel>
            <panel title="Profissionais" icon="female" iconLib="fas">
                <b-card v-for="(item, i) in FilterItemsFaq('worker')" :key="i" class="mb-1">
                    <b-row>
                        <b-col sm="12" md="10" lg="10">
                            <p class="m-0 p-0" :id="'copy-worker-question-'+i">
                                <b>{{item.question}}</b>
                            </p>
                            <p class="m-0 p-0" :id="'copy-worker-answer-'+i">{{item.answer}}</p>
                            <p class="m-0 p-0 text-muted" style="font-size:12px">
                                {{item.created_at | moment("DD/MM/YYYY HH:mm") }}
                                <fa v-if="item.app" class="ml-2" :icon="['fas', 'mobile']" color="green" v-b-tooltip="'Ativo no APP'"/>
                            </p>
                        </b-col>
                        <b-col sm="12" md="2" lg="2" class="text-right align-self-center">
                            <b-button @click="CopyText('copy-worker', i)" v-b-tooltip="'Copiar'" class="ml-1 rounded-circle p-0" variant="outline-mary-beleza" style="height: 32px; width: 32px">
                                <b-icon class="align-self-center" icon="clipboard" scale="0.8"></b-icon>
                            </b-button>
                            <b-button v-if="has_permission('faq-delete')" @click="DeleteFaq(item)" v-b-tooltip="'Apagar'" class="ml-1 rounded-circle p-0" variant="outline-danger" style="height: 32px; width: 32px">
                                <b-icon class="align-self-center" icon="trash" scale="1"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                <p v-if="FilterItemsFaq('worker').length == 0" class="justify-content-center d-flex">Nenhum registro encontrado...</p>
            </panel>
        </div>
    </div>
</template>

<script>
import Http from '@/api/http'
import Panel from '@/components/Panel'
import Constants from '@/constants'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'

export default {
    components: {
        Panel,
        UtilBar,
    },

    data() {
        return {
            filters: {
                search: null,
                app: null,
                type: 'employee'
            },
            items: [],
            model: {
                question: null,
                answer: null,
                app: true,
                type: 'worker'
            },
            options: [
                { value: 0, text: "Não" },
                { value: 1, text: "Sim" },
                { value: null, text: "Todos" },
            ],
        }
    },

    created() {
        this.LoadData();
    },

    watch: {
        'filters': {
            handler: function () {
                this.LoadData();
            },
            deep: true
        },
    },

    methods: {
        LoadData() {
            Http.get('faq', {params: this.filters}).then(response => {
                this.items = response.data
            });
        },

        FilterItemsFaq(type){
            return this.items.filter(item => item.type == type);
        },

        CopyText(initial, key){
            var question = document.getElementById(initial+'-question-'+key).innerText;
            var answer = document.getElementById(initial+'-answer-'+key).innerText;
            
            const el = document.createElement('textarea');
            el.value = '*'+question+'* '+answer;
            document.body.appendChild(el);
            el.select();
            try {
                document.execCommand('copy');
                this.Sweealert2('success', 'Pergunta copiada com sucesso!');
            }
            catch (e) {
                this.Sweealert2('error', 'Não foi possível copiar!');
            }
            finally {
                document.body.removeChild(el);
            }
        },

        CreateFaq() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Salvar',
                cancelButtonText: 'Voltar',
                title: `Nova Pergunta frequente`,
                html: `<label class="mt-3">Pergunta: </label>`
                    + `<textarea class="form-control form-control-sm" rows="4" id="swal-textarea-1"></textarea>`
                    + `<label class="mt-3">Resposta: </label>`
                    + `<textarea class="form-control form-control-sm" rows="4" id="swal-textarea-2"></textarea>`
                    + `<label class="mt-3">Para quem? </label>`
                    + `<select class="form-control form-control-lg mb-3" id="swal-select">
                        <option value="customer" selected>Cliente</option>
                        <option value="worker">Profissional</option>
                        </select>`
                    + `<label class="mt-3">App? </label>`
                    + `<div><input class="form-control form-control-sm" type="checkbox" id="swal-checkbox" checked></div>`,
                padding: 20,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    var textarea_1 = document.getElementById("swal-textarea-1");
                    var textarea_2 = document.getElementById("swal-textarea-2");
                    var select = document.getElementById("swal-select");
                    var checkbox = document.getElementById("swal-checkbox");

                    if(textarea_1.value === ""){
                        this.$swal.showValidationMessage(`Por favor, ensira uma perguta!`)
                        return false;
                    }

                    if(textarea_2.value === ""){
                        this.$swal.showValidationMessage(`Por favor, ensira uma resposta!`)
                        return false;
                    }

                    return [
                        textarea_1.value,
                        textarea_2.value,
                        select.value,
                        checkbox.checked
                    ]
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    var data = {
                        question: result.value[0],
                        answer: result.value[1],
                        type: result.value[2],
                        app: result.value[3],
                    }
                    Http.post('faq', data).then(response => {
                        this.Sweealert2('success', response.data.success);
                        this.LoadData();
                    }).catch(() => {})
                }
            })
        },

        DeleteFaq(item){     
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Apagar`,
                html: `Deseja realmente apagar pergunta da(o) ${item.type == Constants.PERSON_TYPE_CUSTOMER ? 'cliente' : 'profissional'}?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {  
                    Http.delete(`faq/${item.id}`).then(response => {
                        this.Sweealert2('success', response.data.success);
                        this.LoadData();
                    }).catch(error => {
                        this.Sweealert2('error', error.response.data.error);
                    })
                }
            })
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        },
    },

    computed: {

        ...mapGetters('auth', [
            'has_permission'
        ]),

        search: {
            get(){
                return this.filters.search;
            },
            set(value){
                this.filters.search = value;
                this.LoadData();
            }
        },

        constants(){
            return Constants
        },
    },

}
</script>

<style scoped>
.card-body {
    padding: 8px !important;
}
</style>
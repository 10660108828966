import moment from 'moment'
export default {
    data() {
        return {
            vdatepicker_attributes: []
        }
    },
    mounted() {
        this.vdatepicker_attributes = [
            {
                dates: moment().toDate(),
                bar: {
                    backgroundColor: 'blue',
                    opacity: 1
                },
                popover: {
                    label: "HOJE",
                    hideIndicator: false
                }
            }
        ]
        
    }
}
<template>
<div>
    <gmap-map :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height: 500px">'

        <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :title="m.position.title" :icon="require('@/assets/images/marker-mb.png')" :clickable="true" @click="toCenter(m.position.lat, m.position.lng)"></gmap-marker>
    </gmap-map>
</div>
</template>

<script>
export default {
    props: {
        /* center: {
            type: Object,
        }, */
        zoom: {
            type: Number,
            default: 15
        },
        clustered: {
            type: Boolean,
            default: false
        },
        items: {
            type: Object,
            default: null
        },
        markers: {
            type: Array,
        }
    },

    data() {
        return {
            center: {},
            // zoom: 15,
        }
    },

    created() {
        this.GetCenterCoordinates();
    },

    methods: {
        toCenter(lat, lng) {
            this.center.lat = lat;
            this.center.lng = lng;
            this.zoom = 17;
        },

        GetCenterCoordinates() {
            var num_coords = this.markers.length;
            var X = 0.0;
            var Y = 0.0;
            var Z = 0.0;
            
            for (let i = 0; i < num_coords; i++) {
                var lat = this.markers[i].position.lat * Math.PI / 180;
                var lon = this.markers[i].position.lng * Math.PI / 180;
                var a = Math.cos(lat) * Math.cos(lon);
                var b = Math.cos(lat) * Math.sin(lon);
                var c = Math.sin(lat);

                X += a;
                Y += b;
                Z += c;
            }

            X /= num_coords;
            Y /= num_coords;
            Z /= num_coords;

            lon = Math.atan2(Y, X);
            var hyp = Math.sqrt(X * X + Y * Y);
            lat = Math.atan2(Z, hyp);

            var finalLat = lat * 180 / Math.PI;
            var finalLng = lon * 180 / Math.PI;

            this.center = {lat: finalLat, lng: finalLng};
        }
    }
}
</script>

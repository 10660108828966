<template>
    <div id="c-subtitle">
        <button class="btn btn-outline-mary-beleza pull-right btn-block" v-b-toggle.modal-subtitles @click="open = true">
            <fa icon="bars" class="mr-3" />
            <span>Legendas</span>
        </button>

        <b-sidebar id="modal-subtitles" title="Legendas" width="750px" right shadow backdrop class="text-left">
            <div class="p-3">
                <b-table-simple hover small responsive class="bg-white">
                    <b-tr v-for="(item, index) in items" :key="index">
                        <b-td class="text-center">
                            <b-button v-if="item.type && item.type == 'btn'" size="sm" :variant="item.variant" @click="Delete(item)">
                                <b-icon :icon="item.icon" />
                            </b-button>

                            <b-badge v-if="item.type && item.type == 'badge'" pill :variant="item.variant" :class="item.class">{{item.title}}</b-badge>

                            <fa v-if="item.fa_icon && !item.type" :icon="item.icon" :class="item.class" :style="{'color': (item.color ? item.color : 'auto'), 'background-color': item.backgroundColor ? item.backgroundColor : 'auto'}" />
                            
                            <b-icon v-if="item.icon && !item.type" :icon="item.icon" :class="item.class" :style="{'color': (item.color ? item.color : 'auto'), 'background-color': item.backgroundColor ? item.backgroundColor : 'auto'}" />
                            
                            <img width="25" v-if="item.image" :src="GetImage(item.image)">
                        </b-td>
                        <b-td class="align-middle">{{item.description}}</b-td>
                    </b-tr>
                </b-table-simple>
            </div>
        </b-sidebar>
    </div>
</template>


<script>

    import ImageLoader from '@/mixins/ImageLoader'
    
    export default {
        mixins: [ ImageLoader ],
        data() {
            return {
                open: false
            }
        },
        props: {
            items: {
                required: true,
                type: Array
            },
            size: {
                required: false,
                type: String,
                default: 'medium'
            },
            fontSize: {
                required: false,
                type: Number,
                default: null
            },
            verticalAlign: {
                required: false,
                type: String,
                default: null
            }
        },
        methods: {
            GetImage(property_name){
                return this[property_name]
            }
        }
    }
</script>


<style scoped>
    .table tr td {
        border: 1px lightgrey solid !important;
        margin: 0;
        padding: 3px 10px;
    }
    .table .icon {
        text-align: center;
        margin-top: 3px;
    }
    .modal-container {
        overflow: auto;
        max-height: 400px;
    }
</style>
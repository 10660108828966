import Http from '@/api/http';
import moment from 'moment';
import FiltersDomain from '@/domain/filters/notification'
import Constants from '@/constants'

const state = {
    push_notifications: [],
    push_notification: {
        persons: [],
        title: null,
        message: null,
        min_sent_date: null,
        type: Constants.TYPE_TEXT_NOTIFICATION
    },
    filters: new FiltersDomain()
};

const actions = {

    getPersons({ commit }, params) {
        if(params.cities){
            params.cities = params.cities.map(city => city.value)
        }
        if(params.existences){
            params.existences = params.existences.map(existence => existence.value)
        }
        if(params.status){
            params.status = params.status.map(status => status.value)
        }
        return new Promise((resolve, reject) => {
            Http.get('pushNotification/persons', {params: params}).then(response => {
                commit('SET_PERSONS', response.data)
                commit('SET_RESET_PUSH_NOTIFICATION', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getPushNotifications({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('pushNotification', {params: params}).then(response => {
                commit('SET_PUSH_NOTIFICATIONS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getPushNotification(_, push_notification_id) {
        return new Promise((resolve, reject) => {
            Http.get(`pushNotification/${push_notification_id}`).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createPushNotifications({ state }, { image, filters }) {
        const formData = new FormData()
        if(image != null && image != ''){
            formData.append('image', image, 'img');
        }
        for(var key in state.push_notification) {
            if(key != 'persons'){
                if(state.push_notification[key]){
                    formData.append(key, state.push_notification[key]);
                }
            }
        }

        if (filters) {
            formData.append("filters", JSON.stringify(filters));
        }
        
        return new Promise((resolve, reject) => {
            Http.post('pushNotification', formData).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    deletePushNotification({ commit }, push_notification_id) {
        return new Promise((resolve, reject) => {
            Http.delete(`pushNotification/${push_notification_id}`).then(response => {
                commit('SET_REMOVE_PUSH_NOTIFICATION', push_notification_id)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

};

const mutations = {

    SET_PERSONS(state, data) {
        state.push_notification.persons = data.items ? data.items : data;
    },
    SET_PUSH_NOTIFICATIONS(state, data) {
        state.push_notifications = data.items ? data.items : data;
    },
    SET_TITLE(state, value) {
        state.push_notification.title = value;
    },
    SET_MESSAGE(state, value) {
        state.push_notification.message = value;
    },
    SET_MIN_SENT_DATE(state, value) {
        state.push_notification.min_sent_date = value;
    },
    SET_RESET_PUSH_NOTIFICATION(state) {
        state.push_notification.title = null;
        state.push_notification.message = null;
        state.push_notification.min_sent_date = null;
    },
    SET_REMOVE_PERSON(state, person_id) {
        let index = state.push_notification.persons.findIndex(person => person.id == person_id);
        state.push_notification.persons.splice(index, 1);
    },
    SET_REMOVE_PUSH_NOTIFICATION(state, push_notification_id) {
        let index = state.push_notifications.findIndex(push_notification => push_notification.id == push_notification_id);
        state.push_notifications[index].deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
    },
    SET_CLEAN_FILTERS(state) {
        state.filters = new FiltersDomain();
    }
    
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
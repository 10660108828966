<template>
    <div id="login2" class="d-flex justify-content-center align-items-center">
        <div class="login-content fadeInDown">
            <b-form @submit.prevent="doLogin" v-if="!logout">
                <img src="@/assets/images/logo/medium/logo-mb-colorido-degrade.png" class="logo mb-4" />
                
                <b-row class="px-10 py-2">
                    <b-col sm="12" md="12" lg="12" class="input-icon">
                        <fa :icon="['far', 'user']" class="icon-left" />
                        <input type="text" class="form-control h-auto form-control-solid py-3 px-8" v-model="model.email" autocomplete="off" placeholder="E-mail" />
                    </b-col>
                </b-row>
                <b-row class="px-10 py-2">
                    <b-col sm="12" md="12" lg="12" class="input-icon">
                        <b-icon icon="key" font-scale="1" class="icon-left" rotate="45" />
                        <fa :icon="['far', passwordIcon]" class="icon-right c-pointer" @click="hidePassword = !hidePassword" />
                        <input :type="passwordType" class="form-control h-auto form-control-solid py-3 px-8" v-model="model.password" autocomplete="off" placeholder="Senha" />
                    </b-col>
                </b-row>
                <b-row class="px-10 py-2">
                    <b-col sm="12" md="12" lg="12">
                        <b-button type="submit" block size="lg" variant="mary-beleza-gradient">
                            <span v-if="!loading">Login</span>
                            <span v-else><b-icon icon="arrow-repeat" class="mr-2" animation="spin-pulse" font-scale="1.4"></b-icon>Entrando...</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <h1 v-else>
                Até logo...
            </h1>
        </div>
    </div>
</template>

<script>
import store from '@/store'

export default {
    data() {
        return {
            model: {
                email: '',
                password: '',
                type: 'employee'
            },
            hidePassword: true,
            loading: false,
        }
    },
    computed: {
        passwordType() {
            return this.hidePassword ? 'password' : 'text'
        },
        passwordIcon() {
            return this.hidePassword ? 'eye' : 'eye-slash'
        },
        logout: {
            get(){
                return store.state.auth.logout;
            }
        }
    },
    methods: {
        doLogin() {
            this.loading = true;
            store.dispatch("auth/login", this.model).then(() => {
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.sweealert2('error', error)
            })
        }, 

        sweealert2(icon, text) {
            this.$swal({
                title: 'Oops...',
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#fff'
            })
        },
    }
}
</script>

<style lang="scss">
.ponto-animacao {
  animation: go-back 1s infinite alternate;
}
@keyframes go-back {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateYY(0);
    }
}
</style>
import Vue from 'vue' 
import CustomerDomain from '@/domain/customer'
import Http from '@/api/http'

const state = {
    customers: [],
    customer: new CustomerDomain(),
    errors: {}
};

const getters = {
    
};

const actions = {

    getCustomers({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('customer', {params: params}).then(response => {
                commit('SET_CUSTOMERS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getCustomerById({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.get(`customer/${id}`).then(response => {
                commit('SET_CUSTOMER', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createCustomer({commit, state}) {
        return new Promise((resolve, reject) => {
            Http.post('customer', state.customer).then(response => {
                resolve(response.data)
            }).catch(error => {
                commit('SET_ADD_ERROR', error.response.data)
                reject(error)
            })
        });
    },

    updateCustomer({commit, state}) {
        return new Promise((resolve, reject) => {
            Http.put(`customer/${state.customer.id}`, state.customer).then(response => {
                commit('UPDATE_CUSTOMER', response.data.customer)
                resolve(response.data)
            }).catch(error => {
                commit('SET_ADD_ERROR', error.response.data)
                reject(error)
            })
        })
    },

    deleteCustomer({commit}, customer) {
        return new Promise((resolve, reject) => {
            Http.delete('customer/' + customer.id_customer).then(response => {
                commit('DELETE_CUSTOMER', customer.id_customer)
                resolve(response.data)
            }).catch(error => {
                commit('SET_ADD_ERROR', error.response.data)
                reject(error)
            })
        })
    },

    searchZipcode({commit, state}, index) {
        let zipcode = state.customer.person.address[index].zipcode.replace(/[^\d]+/g,'');
        return new Promise((resolve, reject) => {
            Http.get(`/zipcode/${zipcode}`).then(response => {
                let data = {index:index, address: response.data}
                commit('SET_ADDRESS_ZIPCODE_SEARCH', data)
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data.error)
            });
        })
    },

    searchLocation({commit, state}, index) {
        var address = state.customer.person.address[index]
        return new Promise((resolve, reject) => {
            Http.post('/geolocation', address).then(response => {
                let data = {index:index, location: response.data}
                commit('SET_ADDRESS_LOCATION_SEARCH', data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            });
        })
    },

    createPhone({ commit, state }, index) {
        let phone = state.customer.person.phones[index]
        phone['person_id'] = state.customer.person.id

        return new Promise((resolve, reject) => {
            Http.post('phone', phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    updatePhone({ commit, state },index) {
        let phone = state.customer.person.phones[index]
        return new Promise((resolve, reject) => {
            Http.put(`phone/${phone.id}`, phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    deletePhone({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`phone/${state.customer.person.phones[index].id}`).then(response => {
                commit('SET_PHONE_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    createAddress({ commit, state }, index) {
        let address = state.customer.person.address[index]
        address['person_id'] = state.customer.person.id

        return new Promise((resolve, reject) => {
            Http.post('address', address).then(response => {
                var address_update = { index: index, data: response.data.address }
                commit('SET_UPDATE_ADDRESS', address_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    updateAddress({ commit, state },index) {
        let address = state.customer.person.address[index]
        return new Promise((resolve, reject) => {
            Http.put(`address/${address.id}`, address).then(response => {
                var address_update = { index: index, data: response.data.address }
                commit('SET_UPDATE_ADDRESS', address_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    deleteAddress({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`address/${state.customer.person.address[index].id}`).then(response => {
                commit('SET_ADDRESS_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error.response.data)
            })
        })
    },

    changePasswordCustomerById(_, id) {
        return new Promise((resolve, reject) => {
            Http.get(`person/${id}/changePassword`).then(response => {
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    blockedCustomerById({commit}, id) {
        return new Promise((resolve, reject) => {
            Http.get(`person/${id}/blocked`).then(response => {
                commit('SET_BLOCKED_CUSTOMER', id)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
};

const mutations = {

    SET_CUSTOMER(state, customer) {
        state.customer = customer;
    },
    SET_CUSTOMERS(state, customer) {
        state.customers = customer;
    },
    UPDATE_CUSTOMER(state, customer) {
        Vue.set(state.customer, customer);
    },
    DELETE_CUSTOMER(state, customer) {
        state.customers = state.customers.filter(stateCustomer => stateCustomer.id != customer.id);
    },
    SET_BLOCKED_CUSTOMER(state, customer_id){
        let index = state.customers.items.findIndex(customer => customer.id == customer_id);
        state.customers.items[index].blocked = state.customers.items[index].blocked ? 0 : 1;
    },
    

    // ! CUSTOMER
    SET_NAME(state, value){
        state.customer.person.name = value
    },
    SET_EMAIL(state, value){
        state.customer.person.email = value
    },
    SET_NATIONAL_DOCUMENT(state, value){
        state.customer.person.national_document = value
    },
    SET_STATE_DOCUMENT(state, value){
        state.customer.person.state_document = value
    },
    SET_BIRTH(state, value){
        state.customer.person.birth = value
    },
    SET_EXISTENCE(state, value){
        state.customer.person.existence = value
    },
    SET_WHO_EXISTENCE(state, value){
        state.customer.person.who_existence = value
    },
    SET_OBSERVATIONS(state, value){
        state.customer.observations = value
    },
    SET_AFTER_SALES(state, value){
        state.customer.after_sales = value
    },
    SET_PARTNER(state, value) {
        state.customer.partner = value
    },
    SET_RESTRICTION(state, value){
        state.customer.person.restriction = value
    },
    SET_RESTRICT_REASON(state, value){
        state.customer.person.restrict_reason = value
    },
    SET_RESTRICTION_LEVEL(state, value){
        state.customer.person.restriction_level = value
    },

    // ! PHONE
    SET_PHONE_ADD(state){
        state.customer.person.phones.push({phone_number: '', description: '', sms: false, is_whatsapp: false})
    },
    SET_UPDATE_PHONE(state, phone_update){
        state.customer.person.phones[phone_update.index] = phone_update.data
    },
    SET_PHONE_DELETE(state, index){
        state.customer.person.phones.splice(index, 1);
    },
    SET_PHONE_NUMBER(state, data){
        state.customer.person.phones[data.index].phone_number = data.value
    },
    SET_PHONE_DESCRIPTION(state, data){
        state.customer.person.phones[data.index].description = data.value
    },
    SET_PHONE_SMS(state, data){
        state.customer.person.phones[data.index].sms = data.phone.sms
    },
    SET_PHONE_WHATSAPP(state, data){
        state.customer.person.phones[data.index].is_whatsapp = data.phone.is_whatsapp
    },



    // ! ADDRESS
    SET_ADDRESS_ADD(state){
        state.customer.person.address.push({
            name: '', 
            zipcode: '', 
            street: '', 
            number: null, 
            complement: '', 
            district: '', 
            reference: '', 
            zone: null, 
            default: false,
            city_id: null, 
            city: {
                id: null,
                state_id: null,
                name: '',
                enabled: 0,
                state: {
                  id: null,
                  name: '',
                  acronym: ''
                }
            }
        })
    },
    SET_UPDATE_ADDRESS(state, address_update){
        state.customer.person.address[address_update.index] = address_update.data
    },
    SET_ADDRESS_DELETE(state, index){
        state.customer.person.address.splice(index, 1);
    },
    SET_ADDRESS_NAME(state, data){
        state.customer.person.address[data.index].name = data.value
    },
    SET_ADDRESS_ZIPCODE(state, data){
        state.customer.person.address[data.index].zipcode = data.value
    },
    SET_ADDRESS_STREET(state, data){
        state.customer.person.address[data.index].street = data.value
    },
    SET_ADDRESS_NUMBER(state, data){
        state.customer.person.address[data.index].number = data.value
    },
    SET_ADDRESS_COMPLEMENT(state, data){
        state.customer.person.address[data.index].complement = data.value
    },
    SET_ADDRESS_DISTRICT(state, data){
        state.customer.person.address[data.index].district = data.value
    },
    SET_ADDRESS_REFERENCE(state, data){
        state.customer.person.address[data.index].reference = data.value
    },
    SET_ADDRESS_CITY_ID(state, data){
        state.customer.person.address[data.index].city_id = data.value
    },
    SET_ADDRESS_STATE_ID(state, data){
        if(state.customer.person.address[data.index].city.state.id != data.value){
            state.customer.person.address[data.index].city_id = null
            state.customer.person.address[data.index].city = {
                id: null,
                state_id: null,
                name: '',
                enabled: 0,
                state: {
                  id: data.value,
                  name: '',
                  acronym: ''
                }
            }
        }
    },
    SET_ADDRESS_ZONE(state, data){
        state.customer.person.address[data.index].zone = data.value
    },
    SET_ADDRESS_DEFAULT(state, data){
        for (var item in state.customer.person.address) {
            Vue.set(state.customer.person.address[item], "default", false);
        }
        
        Vue.set(state.customer.person.address[data.index], "default", data.value);
    },
    SET_ADDRESS_ZIPCODE_SEARCH(state, data){
        state.customer.person.address[data.index].street = data.address.street
        state.customer.person.address[data.index].district = data.address.district
        state.customer.person.address[data.index].city_id = data.address.city_id
        state.customer.person.address[data.index].city.id = data.address.city_id
        state.customer.person.address[data.index].city.name = data.address.city_name
        state.customer.person.address[data.index].city.state.id = data.address.state_id
        state.customer.person.address[data.index].city.state.name = data.address.state_name
        state.customer.person.address[data.index].zone = data.address.zone
    },
    SET_ADDRESS_LOCATION_SEARCH(state, data){
        state.customer.person.address[data.index].latitude = data.location.lat
        state.customer.person.address[data.index].longitude = data.location.lng
    },


    // ERRORS
    SET_CLEAR_ERROR(state){
        state.errors = {}
    },

    SET_ADD_ERROR(state, value){
        state.errors = value
    },
    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
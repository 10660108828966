<template>
    <b-row v-if="loaded">
        <b-col md="12" sm="12" lg="12">
            <b-row>
                <b-col sm="12" md="12" lg="12" class="form-group">
                    <label>O que você deseja pagar?</label>
                    <multiselect placeholder="Selecione" selectedLabel="Selecionado" track-by="id" label="name" selectLabel="Pressione enter para selecionar" deselectLabel="Pressione enter para remover" v-model="category_selected" :options="categories_flatten" >
                        <template slot="noResult">
                            Nenhum resultado encontrado
                        </template>
                        <template slot="noOptions">
                            Nenhum resultado encontrado
                        </template>
                    </multiselect>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12" md="6" lg="6" class="form-group">
                    <label>Descrição</label>
                    <input type="text" maxlength="50" class="form-control" v-model="value.description" />
                </b-col>
                <b-col sm="12" md="6" lg="6" class="form-group">
                    <label>Valor</label>
                    <money class="form-control" v-bind="money" v-model="value.value"></money>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12" md="6" lg="6" class="form-group">
                    <label for="competence">Competência</label>
                    <month-picker block v-model="value.competence"></month-picker>
                </b-col>
                <b-col sm="12" md="6" lg="6" class="form-group">
                    <label class="block">Data de pagamento</label>
                    <v-date-picker
                        :attributes="vdatepicker_attributes"
                        inputClass="form-control"
                        v-model="payment_date_internal"
                        mode="single"
                        is-expanded
                        is-inline
                        input-placeholder="Selecione o dia">
                    </v-date-picker>
                </b-col>
            </b-row>
            <div class="row form-group">
                <div class="col-md-12">
                    <div class="btn-group" style="width: 100%">
                        <button :style="`width: ${btnClose ? '50%' : '100%'}`" class="btn btn-primary" @click="Save()">Salvar</button>
                        <button v-if="btnClose" style="width: 50%" class="btn btn-default">{{btnCloseLabel}}</button>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
    <div class="text-center" v-else>
        <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
        <span>CARREGANDO...</span>
    </div>
</template>

<script>
import TodayVDatePickerHighlighter from '@/mixins/TodayVDatePickerHighlighter'
import MonthPicker from "@/components/MonthPicker"
import moment from 'moment'
import swal from 'sweetalert2'
import Multiselect from 'vue-multiselect'

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Custo fixo"
        },
        value: {
            type: Object,
            required: true
        },
        btnClose: {
            type: Boolean,
            default: false
        },
        btnCloseLabel: {
            type: String,
            default: "Fechar"
        }
    },
    components: {
        MonthPicker,
        Multiselect
    },
    mixins: [TodayVDatePickerHighlighter],
    computed: {
        categories_flatten() {
            var flatten = (categories, parent) => {
                var items = [];
                for (var category of categories) {
                    var item = {};
                    item.id = category.id;
                    item.name = (parent ? parent.name + " - " : "") + category.name;
                    if (category.children.length == 0) {
                        items.push(item)
                    }
                    items = items.concat(flatten(category.children, item));
                }

                return items;
            };

            return flatten(this.categories, null);
        },
        category_selected: {
            get() {
                var category = undefined;
                if (this.value && this.value.id_category) {
                    category = this.categories_flatten.filter(c => c.id == this.value.id_category)[0];
                }

                return category;
            },
            set(value) {
                if (this.value) {
                    this.value.id_category = value.id;
                }
            }
        },
        competence_internal: {
            get() {
                if (!this.value.competence) {
                    return null;
                }

                return this.value.competence.replace(/(\d*)-(\d*)/, "$2/$1");
            },
            set(value) {
                value = value ? value.replace(/(\d*)\/(\d*)/, "$2-$1") : null;
                this.value.competence = value;
            }
        },
        payment_date_internal: {
            get() {
                if (!this.value.payment_date) {
                    return null;
                }

                return moment(this.value.payment_date).toDate();
            },
            set(value) {
                this.value.payment_date = value ? moment(value).format("YYYY-MM-DD") : null;
            }
        }
    },
    data() {
        return {
            loaded: false,
            categories: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },
    mounted() {
        if(this.value && this.value.payment_date) {
            this.payment_date_internal = this.value.payment_date
        }
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.loaded = false;
            this.$store.dispatch("financial/getExpensesFixedCategories").then((response) => {
                this.loaded = true;
                this.categories = response;
            });
        },
        Save() {
            this.loaded = false;
            this.$store.dispatch("financial/saveExpensesFixedValue", this.value).then((response) => {
                this.loaded = true;
                swal.fire("Sucesso ao salvar!", "Salvo com sucesso!", "success");
                this.$emit("AddSuccessful", response)
            }).catch((err) => {
                console.log(err);
                this.loaded = true;
                swal.fire("Falha ao salvar!", "Falha ao salvar custo fixo!", "error");
            });
        },
        Close() {
            this.$emit("close");
        }
    }
}
</script>

<style scoped>
    .block {
        display: block;
    }
    .form-control {
        width: 100%;
        z-index: 0;
    }
</style>

<template>
    <div class="emoji-container" :style="{ 'justify-content': emojiAlign }">
        <template v-if="!onlyActived || (Value >= 1 && Value <= 5)">
            <div class="emoji" v-b-tooltip v-for="(emoji, i) in emojis" :key="i" @click="setGrade(i + 1)" :class="{ active: Value == (i + 1), hide: onlyActived && Value != (i + 1), onlyActived: onlyActived, emojiRemoveHidden: emojiRemoveHidden, readonly: readonly}" :title="Titles[i]">
                <img :style="{ width: emojiWidth, height: emojiHeight }" v-if="emoji.id == 'hate'" :src="emoji_horrivel" alt="">
                <img :style="{ width: emojiWidth, height: emojiHeight }" v-else-if="emoji.id == 'disappointed'" :src="emoji_ruim" alt="">
                <img :style="{ width: emojiWidth, height: emojiHeight }" v-else-if="emoji.id == 'natural'" :src="emoji_medio" alt="">
                <img :style="{ width: emojiWidth, height: emojiHeight }" v-else-if="emoji.id == 'good'" :src="emoji_bom" alt="">
                <img :style="{ width: emojiWidth, height: emojiHeight }" v-else-if="emoji.id == 'excellent'" :src="emoji_otimo" alt="">
                <label><slot name="label" :label="Labels[i]" :emoji="emoji.id" :rating="i+1">{{Labels[i]}}</slot></label>
            </div>
        </template>
        <template v-else>
            <div class="emoji active">
                <b-icon icon="x-circle-fill" scale="1.50" variant="danger"></b-icon>
            </div>
        </template>
    </div>
</template>

<script>
import ImageLoader from "@/mixins/ImageLoader";
export default {
    mixins: [ImageLoader],
    props: {
        emojiWidth: {
            type: [Number, String],
            default: "32px"
        },
        emojiHeight: {
            type: [Number, String],
            default: "32px"
        },
        emojiAlign: {
            type: String,
            default: "space-around"
        },
        emojiRemoveHidden: {
            type: Boolean,
            default: true
        },
        title: {
            type: [String, Array],
            default: ""
        },
        labels: {
          type: Array,
          required: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },
        onlyActived: {
            type: Boolean,
            default: false
        },
        value: {
          required: false,
        }
    },
    computed: {
        Titles() {
            var titles = [];
            for (var i = 0; i < this.emojis.length; i++) {
                var title = "";
                if (Array.isArray(this.title)) {
                    title = this.emojiTitle[i] ? this.title[i] : "";
                }
                else if (this.title && (i+1) == this.value) {
                    title = this.title;
                }
                
                titles.push(title);
            }

            return titles;
        },
        Labels() {
            var labels = [
                "Péssimo",
                "Ruim",
                "Regular",
                "Bom",
                "Ótimo"
            ];

            if (this.labels) {
                for (var i = 0; i < this.labels.length; i++) {
                    labels[i] = this.labels[i];
                }
            }

            return labels;
        },
        Value: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    data() {
        return {
            emojis: [
                { id: "hate" },
                { id: "disappointed" },
                { id: "natural" },
                { id: "good" },
                { id: "excellent" }
            ]
        };
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {

        },
        setGrade(grade) {
            if (!this.readonly) {
                this.Value = grade;
            }
        }
    }
};
</script>

<style scoped>
    .emoji-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 4px 0;
    }

    .emoji-container .emoji.hide {
        transition: none;
        visibility: hidden;
    }

    .emoji-container .emoji.emojiRemoveHidden.hide {
        transition: none;
        display: none;
    }

    .emoji-container .emoji.onlyActived.active {
        transition: none;
        order: 0 !important;
    }

    .emoji-container .emoji {
        cursor: pointer;
        opacity: 0.3;
        text-align: center;
        transition: all 0.2s ease;
        order: 1;
    }

    .emoji-container .emoji.active {
        opacity: 1;
    }

    .emoji-container .emoji.readonly {
        cursor: initial;
    }

    .emoji-container .emoji img {
        display: block;
        margin: auto;
    }

    .emoji-container .emoji label {
        font-size: 12px;
        font-weight: normal;
        min-width: 70px;
    }
</style>
<template>
  <div>
    <util-bar></util-bar>
    <div class="pr-3 pl-3 pt-3">
      <panel title="Filtros" icon="filter">
        <b-row class="form-group">
          <b-col sm="12" md="4" lg="4">
            <label>Status: </label>
            <b-form-select
              v-model="filters.status"
              :options="options_status"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Atende a domicílio: </label>
            <b-form-select
              v-model="filters.accept_home_care"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Possui veículo: </label>
            <b-form-select
              v-model="filters.has_vehicle"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <label></label>
            <p>
              <b-button
                class="mt-2 ml-2"
                variant="outline-danger"
                @click="CleanFilters"
                >Limpar Filtros</b-button
              >
            </p>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="2" lg="2">
            <label>Com MEI: </label>
            <b-form-select
              v-model="filters.has_mei"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <label>Já trabalha com aplicativo de beleza: </label>
            <b-form-select
              v-model="filters.another_beauty_app"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="6" lg="6">
            <label>Data de cadastro: </label>
            <typeable-date v-model="filters.created_at" />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="9" lg="9">
            <label>Habilidades:</label>
            <multiselect
              v-model="filters.habilities"
              :options="habilities_all"
              :multiple="true"
              placeholder="Selecione..."
              selectedLabel="Selecionado"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para remover"
              track-by="id"
              label="name"
            >
              <template slot="noResult">
                Nenhum resultado encontrado
              </template>
              <template slot="noOptions">
                Nenhum resultado encontrado
              </template>
            </multiselect>
          </b-col>
          <b-col sm="12" md="3" lg="3">
            <label>Importação: </label>
            <b-form-select
              v-model="filters.imported_from"
              :options="options_imported_from"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col>
            <div role="group">
              <label>Como ficou sabendo da existência da Mary Beleza:</label>
              <template>
                <b-form-select
                  v-model="existence"
                  :options="existence_options"
                ></b-form-select>
              </template>
            </div>
          </b-col>
          <b-col sm="4" v-if="existence == 15">
            <div role="group">
              <label>Qual outro:</label>
              <template>
                <b-form-input
                  v-model="filters.who_existence"
                  placeholder="Qual outro"
                ></b-form-input>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="12" md="3" lg="2">
            <label>Treinamento: </label>
            <b-input-group>
              <v-date-picker v-model="filters.scheduling_training" is-range :model-config="modelConfig">
                  <template v-slot="{ inputValue, inputEvents }">
                      <b-form-input v-if="inputValue.start && inputValue.end" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
                      <b-form-input v-else v-on="inputEvents.start" />
                  </template>
              </v-date-picker>
            </b-input-group>
          </b-col>
          <b-col sm="12" md="3" lg="2">
            <label>Agendado: </label>
            <b-form-select
              v-model="filters.scheduling_training_hability"
              :options="options"
            ></b-form-select>
          </b-col>
          <b-col sm="12" md="3" lg="2">
            <label>Bloqueado(a): </label>
            <b-form-select
              v-model="filters.blocked"
              :options="options"
            ></b-form-select>
          </b-col>
        </b-row>
      </panel>

      <panel title="Painel">
        <b-row class="form-group">
          <b-col md="12 text-center">
            <!-- INSIRA O CONTEÚDO DO PAINEL AQUI -->
            <div class="form-group" v-if="filters.scheduling_training">
              <worker-register-print
                title="Imprimir Fichas"
                :date-summary="filters.scheduling_training"
                v-if="
                  items && items.filter(w => w.scheduling_training).length > 0
                "
                :workers="items"
              >
                Imprimir Fichas</worker-register-print
              >
            </div>

            <b-row class="form-group">
              <b-col sm="12" md="12" lg="12">
                <rich-table
                  ref="workerRichTable"
                  v-on:input="ChangeTableConfig"
                  :numbers="table_numbers"
                  :loading="loadingData"
                  :cols="table_cols"
                  :vueComponent="vue_module"
                >
                  <template v-if="items && items.length > 0">
                    <tr
                      v-for="(item, key) in items"
                      v-bind:key="key"
                      :class="item.page + (item.selected ? ' selected' : '')"
                    >
                      <td width="20%">{{ item.name }}</td>
                      <td width="20%">{{ item.email }}</td>
                      <td width="20%">{{ item.phone | formatPhones }}</td>

                      <!-- <td width="45%">
                        <div v-if="!item.scheduling_training">
                          <p
                            style="font-size: 12px!important"
             
                          >
                            <b>{{ item.scheduling_training }}</b
                            >:
                            {{
                              item.scheduling_training
                                | moment("DD/MM/YYYY HH:mm")
                            }}
                          </p>
                        </div>
                      </td> -->

                      <!-- <b-tooltip
                            :target="'scheduling_training' + key"
                            class="mr-2"
                            variant="mary-beleza-light"
                            placement="bottom"
                          >
                            <b-icon icon="clock-history" class="mr-2"></b-icon>
                            <strong>{{
                              moment(item.scheduling_training).format("HH:mm")
                            }}</strong>
                          </b-tooltip> -->

                      <td width="15%">
                        <span
                          v-if="item.scheduling_training"
                          :id="'scheduling_training' + key"
                          ><b-icon icon="clock-history" class="mr-2"></b-icon
                          >{{ item.scheduling_training | moment("DD/MM/YYYY") }}
                        </span>
                      </td>
                      <td>
                        <b-button
                          v-if="item.restriction"
                          variant="danger"
                          size="sm"
                          v-b-tooltip
                          :title="item.restrict_reason"
                        >
                          <b-icon
                            icon="hand-thumbs-down"
                            style="color: #D5F5E3;"
                            font-scale="1.5"
                          />
                        </b-button>

                        <b-button v-else variant="success" size="sm">
                          <b-icon
                            icon="hand-thumbs-up"
                            style="color: #F2D7D5;"
                            font-scale="1.5"
                          />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="
                            item.blocked == 0 &&
                              has_permission('worker-blocked')
                          "
                          variant="warning"
                          size="sm"
                          @click="blockedWorker(item)"
                          v-b-tooltip
                          :title="item.blocked_reason"
                        >
                          <b-icon icon="unlock" font-scale="1" />
                        </b-button>

                        <b-button
                          v-else-if="
                            item.blocked == 1 &&
                              has_permission('worker-blocked')
                          "
                          variant="danger"
                          size="sm"
                          @click="blockedWorker(item)"
                          v-b-tooltip
                          :title="item.blocked_reason"
                        >
                          <b-icon icon="lock" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          variant="info"
                          size="sm"
                          @click="changePassword(item)"
                          v-b-tooltip
                          title="Solicitar Criação de Senha Para o App"
                        >
                          <b-icon icon="key-fill" font-scale="1" />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <div v-if="has_permission('worker-edit')">
                          <b-button
                            v-if="
                              item.status ==
                                constants.STATUS_NOT_SCHEDULED_TRAINING
                            "
                            v-b-modal.modal-scheduling
                            @click="openCreateScheduling(item)"
                            variant="warning"
                            size="sm"
                            v-b-tooltip
                            title="Agendar treinamento"
                          >
                            <b-iconstack font-scale="1.5">
                              <b-icon stacked icon="calendar"></b-icon>
                              <b-icon
                                stacked
                                shift-v="-1"
                                scale="0.5"
                                icon="telephone-forward"
                              ></b-icon>
                            </b-iconstack>
                          </b-button>
                          <b-button
                            v-else
                            v-b-modal.modal-scheduling
                            @click="openUpdateScheduling(item)"
                            variant="warning"
                            size="sm"
                            v-b-tooltip
                            title="Alterar agendamento"
                          >
                            <b-iconstack font-scale="1.5">
                              <b-icon stacked icon="calendar"></b-icon>
                              <b-icon
                                stacked
                                shift-v="-1"
                                scale="0.5"
                                icon="pen"
                              ></b-icon>
                            </b-iconstack>
                          </b-button>
                        </div>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="
                            $router.push({
                              path: '/profissionais/cadastro/' + item.id
                            })
                          "
                          v-b-tooltip
                          :title="
                            has_permission('worker-edit')
                              ? 'Editar'
                              : 'Visualizar'
                          "
                        >
                          <b-icon
                            v-if="has_permission('worker-edit')"
                            icon="pen"
                            font-scale="1"
                          />
                          <b-icon
                            v-else
                            icon="journal-medical"
                            font-scale="1"
                          />
                        </b-button>
                      </td>
                      <td width="5%" class="text-center">
                        <b-button
                          v-if="has_permission('worker-delete')"
                          variant="danger"
                          size="sm"
                          @click="deleteWorker(item)"
                          v-b-tooltip
                          title="Apagar Ficha"
                        >
                          <b-icon icon="trash" font-scale="1" />
                        </b-button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td :colspan="table_cols.length" class="text-center py-3">
                        Nenhum registro encontrado
                      </td>
                    </tr>
                  </template>
                </rich-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="modal-scheduling"
          centered
          size="lg"
          ref="modal-scheduling"
          hide-header
        >
          <div>
            <span
              >Agendar data de treinamento para a profissional
              <b>{{ worker_modal.name }}</b></span
            >
            <b-row class="text-center justify-content-md-center">
              <b-col md="5" class="mt-4">
                <p><label>Ligação:</label></p>
                <v-date-picker
                  :class="{
                    'border border-danger': error.contact_scheduling_training
                  }"
                  :model-config="modelConfig"
                  v-model="contact_scheduling_training"
                  mode="dateTime"
                  is24hr
                ></v-date-picker>
                <div
                  v-if="error.contact_scheduling_training"
                  class="text-danger"
                >
                  Obrigatório!
                </div>
              </b-col>
              <b-col md="5" class="mt-4">
                <p><label>Treinamento:</label></p>
                <v-date-picker
                  :class="{ 'border border-danger': error.scheduling_training }"
                  :model-config="modelConfig"
                  v-model="scheduling_training"
                  mode="dateTime"
                  is24hr
                ></v-date-picker>
                <div v-if="error.scheduling_training" class="text-danger">
                  Obrigatório!
                </div>
                <p class="mt-2" v-if="scheduling_training">
                  Tem <strong>{{ count_schedules }}</strong> agendados para esse
                  dia.
                </p>
              </b-col>
            </b-row>
          </div>
          <template #modal-footer>
            <b-button @click="cancelScheduling" variant="outline-mary-beleza">
              Cancelar
            </b-button>
            <b-button
              v-if="edit_modal"
              @click="updateScheduling"
              variant="mary-beleza"
            >
              Salvar
            </b-button>
            <b-button v-else @click="saveScheduling" variant="mary-beleza">
              Salvar
            </b-button>
          </template>
        </b-modal>
      </panel>
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel";
import Http from "@/api/http";
import moment from "moment";
import RichTable from "@/components/RichTable";
import store from "@/store";
import _clone from "lodash/clone";
import _merge from "lodash/merge";
import UtilBar from "@/components/UtilBar";
import Constants from "@/constants";
import Multiselect from "vue-multiselect";
import TypeableDate from "@/components/TypeableDate";
import { mapGetters } from "vuex";
import WorkerRegisterPrint from "@/components/WorkerRegisterPrint";

export default {
  components: {
    Panel,
    RichTable,
    UtilBar,
    Multiselect,
    TypeableDate,
    WorkerRegisterPrint
  },

  data() {
    return {
      vue_module: 'approved_registration',
      show: true,
      scheduling_training: "",
      contact_scheduling_training: "",
      worker_modal: {},
      items: [],
      table_numbers: {
        size: 1,
        total: 0,
        found: 0,
        footer: null
      },
      loadingData: false,
      options_status: [
        {
          value: [
            Constants.STATUS_NOT_SCHEDULED_TRAINING,
            Constants.STATUS_SCHEDULED_TRAINING
          ],
          text: "Todos"
        },
        {
          value: Constants.STATUS_NOT_SCHEDULED_TRAINING,
          text: "Não Agendados"
        },
        { value: Constants.STATUS_SCHEDULED_TRAINING, text: "Agendados" }
      ],
      error: {
        contact_scheduling_technical: null,
        scheduling_technical: null
      },
      edit_modal: false,
      options: [
        { value: 0, text: "Não" },
        { value: 1, text: "Sim" },
        { value: null, text: "Todos" }
      ],
      options_imported_from: [
         // { value: Constants.STATUS_MARYBELEZASITE, text: "Site Mary Beleza" },
        { value: Constants.STATUS_RECRUTAMENTOBELEZA, text: "Recrutamento Beleza" },
        { value: Constants.STATUS_INFOJOBS, text: "InfoJobs" },
        { value: Constants.STATUS_SISTEMA, text: "Sistema" },
        { value: "facebook", text: "Facebook" },
        { value: null, text: "Todos" }
      ],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm"
      },
      count_schedules: 0,
      existence_options: [
        { value: null, text: "Selecione", disabled: true },
        { value: 1, text: "Bloguerias" },
        { value: 2, text: "Cartão de Visita" },
        { value: 3, text: "E-mail Marketing" },
        { value: 4, text: "Facebook" },
        { value: 5, text: "Google" },
        { value: 6, text: "Instagram" },
        { value: 7, text: "Indicação de Amigos" },
        { value: 8, text: "Jornal" },
        { value: 9, text: "Outdoor" },
        { value: 10, text: "Panfletos de Propaganda" },
        { value: 11, text: "Revistas" },
        { value: 12, text: "SMS" },
        { value: 13, text: "WhatsApp" },
        { value: 14, text: "", disabled: true },
        { value: 15, text: "Outros" }
      ]
    };
  },

  mounted() {
    this.LoadData();
    this.$store.dispatch("worker/getHabilitiesAll");
  },

  computed: {
    ...mapGetters("auth", ["has_permission"]),

    habilities_all: {
      get() {
        return this.$store.state.worker.habilities_all;
      }
    },

    filters: {
      get() {
        return this.$store.state.worker.filters.approved_registration;
      }
    },

    existence: {
      get() {
        return this.filters.existence;
      },
      set(value) {
        this.filters.existence = value;
        this.filters.who_existence = null;
      }
    },

    table_cols() {
      let cols = [
        {
          id: "name",
          text: "Nome",
          class: "cols_name"
        },
        {
          id: "email",
          text: "E-mail",
          class: "cols_email"
        },
        {
          id: "phone",
          text: "Telefones",
          class: "cols_phone"
        },
        {
          id: "scheduling_technical",
          text: "Agendado",
          class: "cols_scheduling_technical"
        },
        {
          id: "restriction",
          text: "Restrição",
          class: "cols_restriction"
        },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" },
        { text: "", class: "" }
      ];
      return cols;
    },

    constants() {
      return Constants;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.LoadData();
      },
      deep: true
    },
    scheduling_training: {
      handler: function() {
        this.SchedulingCount();
      },
      deep: true
    }
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },

    LoadData() {
      this.loadingData = true;

      var params = _clone(_merge(this.filters, this.table_config));
      store.dispatch("worker/getList", params).then(data => {
        this.loadingData = false;

        this.items = data.items ? data.items : [];

        this.table_numbers.size = data.size ? data.size : 0;
        this.table_numbers.found = data.found ? data.found : 0;
        this.table_numbers.total = data.total ? data.total : 0;
        this.table_numbers.footer = data.footer ? data.footer : 0;
      });
    },

    deleteWorker(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Apagar`,
        html: `Deseja realmente apagar o(a) profissional <strong>${worker.name}</strong>?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("worker/deleteWorkerById", worker.id)
            .then(response => {
              this.Sweealert2("success", response.success);
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    },

    changePassword(worker) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        title: `Criação de Senha`,
        html: `Deseja realmente enviar um e-mail para o(a) cliente <strong>${worker.name}</strong> criar ou alterar sua senha de acesso ao App?`,
        icon: "warning",
        padding: 20
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            title: "Processando...",
            icon: "info",
            padding: 20
          });

          this.$store
            .dispatch("worker/changePasswordWorkerById", worker.id)
            .then(response => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: response.success,
                icon: "success",
                padding: 20
              });
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });
        }
      });
    },

    CleanFilters() {
      this.$store.commit("worker/SET_CLEAN_FILTERS", {
        module: this.vue_module,
        status: [
          Constants.STATUS_NOT_SCHEDULED_TRAINING,
          Constants.STATUS_SCHEDULED_TRAINING
        ]
      });
      this.$refs["workerRichTable"].CleanFilters();
    },

    ChangeTableConfig(value) {
      this.table_config = value;
      this.LoadData();
    },

    saveScheduling() {
      if (this.contact_scheduling_training == "") {
        this.error.contact_scheduling_training = true;
        return;
      }
      if (this.scheduling_training == "") {
        this.error.scheduling_training = true;
        return;
      }
      this.$refs["modal-scheduling"].hide();
      var data = {
        worker_id: this.worker_modal.id,
        scheduling: {
          status: Constants.STATUS_SCHEDULED_TRAINING,
          scheduling_training: this.scheduling_training,
          contact_scheduling_training: this.contact_scheduling_training
        }
      };
      this.$store
        .dispatch("worker/updateScheduling", data)
        .then(response => {
          this.Sweealert2("success", response.success);
        })
        .catch(error => {
          this.Sweealert2("error", error.error);
        });
    },

    updateScheduling() {
      if (this.contact_scheduling_training == "") {
        this.error.contact_scheduling_training = true;
        return;
      }
      if (this.scheduling_training == "") {
        this.error.scheduling_training = true;
        return;
      }
      this.$refs["modal-scheduling"].hide();
      var data = {
        worker_id: this.worker_modal.id,
        scheduling: {
          scheduling_training: this.scheduling_training,
          contact_scheduling_training: this.contact_scheduling_training
        }
      };
      this.$store
        .dispatch("worker/updateScheduling", data)
        .then(response => {
          this.Sweealert2("success", response.success);
        })
        .catch(error => {
          this.Sweealert2("error", error.error);
        });
    },

    cancelScheduling() {
      this.$refs["modal-scheduling"].hide();
    },

    openCreateScheduling(worker) {
      this.edit_modal = false;
      this.scheduling_training = "";
      this.contact_scheduling_training = "";
      this.error = {
        contact_scheduling_training: false,
        scheduling_training: false
      };
      this.worker_modal = worker;
      this.$refs["modal-scheduling"].show();
    },

    openUpdateScheduling(worker) {
      this.edit_modal = true;
      this.worker_modal = worker;
      this.error = {
        contact_scheduling_training: false,
        scheduling_training: false
      };
      this.scheduling_training = worker.scheduling_training;
      this.contact_scheduling_training = worker.contact_scheduling_training;
      this.$refs["modal-scheduling"].show();
    },

    Sweealert2(icon, text) {
      this.$swal({
        showConfirmButton: true,
        timer: 3000,
        timerProgressBar: true,
        text: text,
        icon: icon,
        padding: 20
      });
    },

    blockedWorker(worker) {
      var input = worker.blocked == 0 ? "textarea" : null;
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        input: input,
        title: `${
          worker.blocked == 0 ? "Bloquear" : "Desbloquear"
        } Profissinal`,
        html: `Deseja realmente ${
          worker.blocked == 0 ? "BLOQUEAR" : "DESBLOQUEAR"
        } o(a) profissional <strong>${worker.name}</strong>? ${
          worker.blocked == 0
            ? `<hr/><p class="mt-3">Motivo do bloqueio: </p>`
            : ""
        }`,

        icon: "warning",
        padding: 20,
        preConfirm: reason => {
          if (reason === null || reason === "") {
            this.$swal.showValidationMessage(
              `Por favor, informe um motivo para o bloqueio do(a) profissional!`
            );
          }
          return [reason];
        }
      }).then(result => {
        if (result.isConfirmed) {
          var data = {
            worker_id: worker.id,
            reason: result.value[0]
          };

          this.$swal({
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            title: "Processando...",
            icon: "info",
            padding: 20
          });

          this.$store
            .dispatch("worker/blockedWorkerById", data)
            .then(response => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: response.success,
                icon: "success",
                padding: 20
              });

              this.LoadData();
            })
            .catch(error => {
              this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                title: "Atenção!",
                text: error.response.data.error,
                icon: "error",
                padding: 20
              });
            });

        
        }
      });
    },

    // blockedWorker(worker) {
    //   this.$swal({
    //     showConfirmButton: true,
    //     showCancelButton: true,
    //     confirmButtonText: "Sim",
    //     cancelButtonText: "Não",
    //     title: `${
    //       worker.blocked == 0 ? "Bloquear" : "Desbloquear"
    //     } Profissinal`,
    //     html: `Deseja realmente ${
    //       worker.blocked == 0 ? "BLOQUEAR" : "DESBLOQUEAR"
    //     } o(a) profissional <strong>${worker.name}</strong>?`,
    //     icon: "warning",
    //     padding: 20
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       this.$swal({
    //         showConfirmButton: false,
    //         timer: 10000,
    //         timerProgressBar: true,
    //         title: "Processando...",
    //         icon: "info",
    //         padding: 20
    //       });

    //       this.$store
    //         .dispatch("worker/blockedWorkerById", worker.id)
    //         .then(response => {
    //           this.$swal({
    //             showConfirmButton: true,
    //             timer: 3000,
    //             timerProgressBar: true,
    //             title: "Atenção!",
    //             text: response.success,
    //             icon: "success",
    //             padding: 20
    //           });

    //           this.LoadData();
    //         })
    //         .catch(error => {
    //           this.$swal({
    //             showConfirmButton: true,
    //             timer: 3000,
    //             timerProgressBar: true,
    //             title: "Atenção!",
    //             text: error.response.data.error,
    //             icon: "error",
    //             padding: 20
    //           });
    //         });
    //     }
    //   });
    // },

    SchedulingCount() {
      var params = {
        offset: 0,
        limit: 1,
        sort_order: "asc",
        sort_column: "name",
        status: 5,
        scheduling_training: moment(this.scheduling_training).format(
          "YYYY-MM-DD"
        )
      };
      Http.get("worker", { params }).then(response => {
        this.count_schedules = response.data.total;
      });
    }
  }
};
</script>

<style>
</style>

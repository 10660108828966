export default class Worker {

    constructor() {
        
        this.function_hability_obs = '' // ? text
        this.interviewer = '' //string
        this.marital = null //integer
        this.training = false //boolean 
        this.training_dt = null //date
        this.status = 0 // ? back-end? integer
        this.behavioral_grade = null //integer
        this.technical_grade = null //integer
        this.mother_name = "" // ! parte do asaas no form da mary help | string
        this.bank_third_party = null //integer
        this.bank_third_party_name = '' //string
        this.bank_third_party_birth = null //date
        this.bank_third_party_cpf = '' //string
        this.worker_doc_filename = '' // ! parte do asaas no form da mary help | string
        this.actived_asaas = 1 // ? | integer
        this.bank_changed = 1 // ? | integer
        this.denied_level = 1 // ? back-end? | integer
        this.denied_reason = '' // ? back-end? | text
        this.rank = 2 //integer
        this.bank_agency = '' //string
        this.bank_account = '' //string
        this.bank_account_type = '' //char
        this.bank_operation = '' //string
        this.bank_account_digit = '' //string
        this.bank_agency_digit = '' //string
        this.bank_id = null
        this.disapproval_reason = null
        this.person = { 
            name: '', //string 
            email: '', //string
            image: null, //string
            birth: null, //date
            password: '', //string
            national_document: '', //string 
            state_document: '', //string
            restriction: 0, // ? back-end? | boolean
            restriction_level: 5, // ? back-end? | integer
            restrict_reason: '', // ? back-end? | text
            type: 'worker', // enum['worker', 'customer', 'franchise', 'employee']
            gender: '', // enum['male', 'female']
            existence: null, // ? integer
            who_existence: null, // ? text
            phones: [
                /* {
                    number: '', 
                    description: '', 
                    is_personal: null, 
                    is_whatsapp: null
                } */
            ],
            address: [
                {
                    name: '', //string
                    street: '', //string
                    number: '', //integer
                    district: '', //string
                    zipcode: null, //string
                    zone: null, //integer
                    reference: '', //string
                    default: 0, //boolean
                    latitude: 0, //float
                    longitude: 0, //float
                    complement: '', //string
                    city_id: null, //integer
                    city: {
                        id: null,
                        state_id: null,
                        name: "",
                        enabled: 0,
                        state: {
                          id: null,
                          name: "",
                          acronym: ''
                        }
                    }
                }
            ]
        }
        this.detail = {
            interview_details: null, //text
            work_number: null, //string
            work_number_serie: null, //string
            driverlicense: null, //string
            driverlicensetype: null, //string
            smoke: false, //boolean
            drink: false, //boolean
            read: false, //boolean
            write: false, //boolean
            schooling: null, //integer
            graduated_at: null, //date
            extension_curses: '', //text
            children: false, //boolean
            children_quantity: null, //integer
            children_minor: false, //boolean
            children_minor_quantity: null, //integer
            children_who: '', //text
            family_drugs: false, //boolean
            family_drugs_detail: '', //text
            family_justice: false, //boolean
            family_justice_detail: '', //text
            worker_health: false, //boolean
            worker_health_detail: '', //text
            family_health: false, //boolean
            family_health_detail: '', //text
            smoke_drink_detail: '', //text
            accept_home_care: false, //boolean
            has_mei: false, //boolean
            has_vehicle: false, //boolean
            has_vehicle_which: '', //string
            instagram: null, //string
            facebook: null, //string
            another_beauty_app: false, //boolean
            resume: null, //string
            professional_references: null, //string
            habilities_observation: null, //string
        }
        this.experiences = [
            /* {
                place: '', //string
                office: '', // ? cargo - post? | string 
                admission: null, //date
                dismissal: null,  //date
                contact: '', //string
                phone: '', //string
                professional_allegations: '', //text
                checking: '', //text
                address: '', // ? endereço - neighborhood? | string
            } */
        ]
        this.worker_habilities = []
    }
}
<template>
    <div class="cash-flow-row">
        <tr class="text-center" :class="{'totalizer': totalizer, 'header-line': headerLine}" :level="item.level">
            <td class="name" :style="'padding-left: ' + (item.level - 0.5 ) + '%'">
                <span v-if="totalizer || !item.has_children">
                    <b-icon v-if="item.loading" icon="arrow-repeat" animation="spin" class="mr-2" /> {{item.name}}
                </span>
                <button v-else class="btn btn-link" @click.stop="OpenCloseItem()">
                    <b-icon v-if="item.loading" icon="arrow-repeat" animation="spin" class="mr-2" /> {{item.name}}
                </button>
            </td>
            <td class="month" v-for="(value, v_index) in item.values" :key='v_index'>
                <span v-if="headerLine">{{value}}</span>
                <span v-else-if="totalizer" :class="{'positive': value >= 0, 'negative': value < 0}">{{value | currency(false)}}</span>
                <template v-else-if="!item.has_children">
                    <template v-if="value === null">
                        <!-- <span :class="{notClickable: !HasPermission('FinancialExpensesFixed')}">
                            <i v-if="HasPermission('FinancialExpensesFixed')" @click="EditItem(item.key, v_index, 0)" class="fa fa-plus-circle"/>
                            <span v-else>-</span>
                        </span> -->
                        <span>
                            <b-icon icon="plus-circle" />
                        </span>
                    </template>
                    <template v-else>
                        <span v-if="item.editable" class="editable" @click="EditItem(item.key, v_index, 1)">{{value | currency(false)}}</span>
                        <span v-else>{{value | currency(false)}}</span>
                    </template>
                </template>
                <button v-else class="btn btn-link" @click.stop="OpenCloseItem()">
                    <template v-if="expense">
                        {{value * -1 | currency(false)}}
                    </template>
                    <template v-else>{{value | currency(false)}}</template>
                </button>
            </td>
        </tr>
        <template v-if="item.children && item.show_children">
            <cash-flow-line v-on:OpenEditPopup="OpenEditPopup" v-on:LoadData="LoadData" v-for="(child, index) in item.children" :key="index" :item="child"></cash-flow-line>
        </template>
    </div>
</template>

<script>
// import PermissionManager from '@/mixins/PermissionManager'

export default {
    name: 'CashFlowLine',
    // mixins: [PermissionManager],
    props: {
        item: {
            type: Object,

        },
        expense: {
            type: Boolean,
            default: false
        },
        headerLine: {
            type: Boolean,
            default: false
        },
        totalizer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        OpenCloseItem() {
            this.$set(this.item, "loading", true);
            
            if(this.item.show_children) {
                this.$set(this.item, "loading", false);
                this.item.show_children = false
                this.item.children = null;
            } else {
                if(this.item.children && Object.keys(this.item.children).length > 0) {
                    this.$set(this.item, "loading", false);
                    this.item.show_children = true;
                } else {
                    this.LoadData(this.item);
                }
            }
        },
        LoadData(parent) {
            this.$set(parent, "loading", true);
            var promise = new Promise((resolve, reject) => {
                this.$emit("LoadData", parent, resolve, reject);
            });
            promise.then((item) => {
                this.$set(item, "loading", false);
            }).catch((item) => {
                this.$set(item, "loading", false);
            });
        },
        EditItem(key, month, has_items) {
            this.OpenEditPopup({ key, month, has_items, category_name: this.item.name })
        },
        OpenEditPopup({key, month, has_items, category_name}) {
            this.$emit("OpenEditPopup", { key, month, has_items, category_name })
        },
    }
}
</script>

<style scoped>
.btn.btn-link {
    color: black;
    margin: 0;
    padding: 0;
}

.positive {
    font-weight: bold;
    color: green;
}

.negative {
    font-weight: bold;
    color: red;
}

* {
    text-transform: uppercase;
}

.name {
    width: 20%;
    word-wrap: break-word;
    text-align: left;
}

.month {
    width: 10px;
    padding-right: 5px !important;
    text-align: right;
}

.name, .month {
    /* display: inline-block; */
    border: 1px lightgrey solid;
    border-collapse: collapse;
}

.name > span, .name > button, .month > span, .month > button {
    font-size: 0.9em;
}

tr[level="0"] td {
    background-color: rgb(243, 165, 223);
}
tr[level="1"] td {
    background-color: rgb(250, 201, 246);
}
tr[level="2"] td {
    background-color: rgb(255, 215, 250);
}
tr[level="3"] td {
    background-color: rgb(252, 230, 250);
}

tr.totalizer td {
    background-color:lightgrey;
}

.month .b-icon {
    text-align: center;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.editable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header-line {
    text-align: center;
}

.header-line span {
    font-weight: bold;
    font-size: 14px;
}

.notClickable, .notClickable * {
    cursor: initial !important;
}

.cash-flow-row {
    display: contents;
}
</style>
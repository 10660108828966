<template>
    <div>
        <util-bar></util-bar>
        <div class="pr-3 pl-3 pt-3">
            <panel class="text-center" title="Importação InfoJobs" icon="upload">
                <div class="align-components-center">
                    <b-row class="mb-2">
                        <b-col>
                            <b-button block size="lg" variant="mary-beleza" @click="$refs.FileRef.click()">Selecionar arquivo</b-button>
                            <span v-if="file" class="d-flex file-text">Arquivo: {{ file.name }}</span>
                            <input ref="FileRef" type="file" id="file" @change="selectFromFileImport" style="display: none;" accept=".xlsx" />
                        </b-col>
                    </b-row>
                    <b-row v-if="file" class="mb-2">
                        <b-col>
                            <b-button block size="lg" variant="primary" @click="importWorkersInfoJobs">Importar</b-button>
                        </b-col>
                    </b-row>
                    <b-row v-if="upload" class="mb-2">
                        <b-col>
                            <b-progress :max="max" height="2rem">
                                <b-progress-bar :value="progress" variant="success" striped :animated="loading">
                                    <span>Progresso: <strong>{{ progress.toFixed(2) }} / {{ max }}</strong></span>
                                </b-progress-bar>
                            </b-progress>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-alert :show="completed" :variant="result.errors > 0 ? 'danger' : 'primary'">
                                <div class="text-left">
                                    <b-row>
                                        <b-col>
                                            <strong>Total: </strong>{{ result.rows }}
                                        </b-col>
                                        <b-col>
                                            <strong>Já registrados: </strong>{{ result.exists }}
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <strong>Novos registros: </strong>{{ result.success }}
                                        </b-col>
                                        <b-col>
                                            <strong>Falhas: </strong>{{ result.errors }}
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-alert>
                        </b-col>
                    </b-row>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import UtilBar from '@/components/UtilBar'
import Http from '@/api/http'

export default {
    
    components: {
        Panel,
        UtilBar,
    },

    data() {
      return {
        file: null, 
        progress: 0.00,
        max: 100,
        upload: false,
        loading: false,
        completed: false,
        result: {
            rows: 0,
            exists: 0,
            success: 0,
            errors: 0
        }
      }
    },

    methods: {

        selectFromFileImport(e){
            this.upload = false
            this.completed = false
            this.file = e.target.files[0]
        },

        importWorkersInfoJobs() {
            this.upload = true
            this.loading = true
            const formData = new FormData()
            formData.append('file', this.file)
            this.file = null
            Http.post('importfrominfojobs', formData, {
                onUploadProgress: (event) => {
                    this.progress = Math.round(
                        (event.loaded * 100.00) / event.total
                    );
                }
            }).then(response => {
                this.result = response.data
                this.completed = true
                document.getElementById("file").value = null;
            }).catch(error => {
                this.sweealert2('error', error.response.data.error)
            })
            this.loading = false
        },

        sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    },
}

</script>

<style>
.align-components-center {
    margin-left: 25%;
    margin-right: 25%;
}

.file-text {
    color: #808080;
    font-size: 14px;
}
</style>
<template>
    <div>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12" class="d-flex justify-content-between mt-2">
                        <div>
                            <b-button v-if="worker.status == constants.STATUS_SCHEDULED_TRAINING && has_permission('worker-edit')" @click="newStatusWorker(constants.STATUS_PROFESSIONAL_MB)" variant="success" class="mr-2">
                                <b-iconstack font-scale="1.3" class="mr-2">
                                    <b-icon stacked icon="patch-check"></b-icon>
                                </b-iconstack>
                                Tornar profissional MB
                            </b-button>

                            <template v-else-if="worker.status == constants.STATUS_PROFESSIONAL_MB && has_permission('worker-edit')">
                                <b-button variant="danger" class="mr-2" @click="newStatusWorker(constants.STATUS_SCHEDULED_TRAINING)">
                                    <b-iconstack font-scale="1.3" class="mr-2">
                                        <b-icon stacked icon="patch-minus"></b-icon>
                                    </b-iconstack>
                                    Retirar das profissionais MB
                                </b-button>
                                <b-button variant="secondary" class="mr-2" @click="newStatusWorker(constants.STATUS_DROPOUT)">
                                    <b-iconstack font-scale="1.3" class="mr-2">
                                        <b-icon stacked icon="power"></b-icon>
                                    </b-iconstack>
                                    Colocar para Desistência
                                </b-button>
                            </template>
                            <span v-else-if="[constants.STATUS_SCHEDULED_TRAINING, constants.STATUS_NOT_SCHEDULED_TRAINING, constants.STATUS_SCHEDULED_TRAINING, constants.STATUS_PROFESSIONAL_MB].includes(worker.status)" class="icon-mb">
                                <b-iconstack id="tooltip-1" variant="success" v-if="worker.status == constants.STATUS_PROFESSIONAL_MB" font-scale="2.2" class="mr-3">
                                    <b-icon stacked icon="patch-check"></b-icon>
                                    <b-tooltip target="tooltip-1" title="Profissional MB"></b-tooltip>
                                </b-iconstack>
                                <b-iconstack id="tooltip-2" v-else variant="danger" font-scale="2.2" class="mr-3">
                                    <b-icon stacked icon="patch-minus"></b-icon>
                                    <b-tooltip target="tooltip-2" title="Profissional não MB"></b-tooltip>
                                </b-iconstack>
                            </span>

                            <b-button  v-if='! IsUpdate' v-on:click="verifyForm" variant="mary-beleza" class="mr-2">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>

                            <b-button variant="outline-mary-beleza" v-on:click="IsStage ? $router.push({ path: '/profissionais/pre-cadastro'}) : $router.go(-1)">
                                <fa icon="undo" class="mr-2" />Voltar
                            </b-button>

                            <b-button v-if="has_permission('worker-edit') && [constants.STATUS_NOT_SCHEDULED_TRAINING, constants.STATUS_SCHEDULED_TRAINING, constants.STATUS_NOT_SCHEDULED_TECHNICAL, constants.STATUS_SCHEDULED_TECHNICAL].includes(worker.status)" variant="danger" class="ml-2" @click="ApproveOrFail">
                                <fa :icon="['far', 'thumbs-down']" class="mr-2" />Reprovar Cadastro
                            </b-button>
                        </div>
                        <help-icon v-if="worker.status" class="space-left mr-4 mt-1" title="Info" name="okay" scale="1.7">
                                <span v-if="worker.status == constants.STATUS_APPROVED">
                                    <b>Aguardando Checagem</b>
                                    <p>Deve possuir uma <b>Avaliação Pessoal</b> maior que <b>4</b> e pelo menos uma experiência sem checagem (se houver alguma experiência).</p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_APPROVED">
                                    <b>Cadastro Geral</b>
                                    <p>Deve ter uma <b>Avaliação Pessoal</b> maior que <b>4</b> e todas as experiências com checagem (se houver alguma experiência).</p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_NOT_SCHEDULED_TECHNICAL">
                                    <p>Para prosseguir com o cadastro, deve-se agendar uma data para a <b>Avaliação Técnica</b> clicando em:
                                        <b-button variant="warning" size="sm">
                                            <b-iconstack font-scale="1.5">
                                                <b-icon stacked icon="calendar"></b-icon>
                                                <b-icon stacked shift-v="-1" scale="0.5" icon="telephone-forward"></b-icon>
                                            </b-iconstack>
                                        </b-button>
                                        na listagem.
                                    </p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_SCHEDULED_TECHNICAL">
                                    <b>Cadastro Aprovados</b>
                                    <p>Para prosseguir com o cadastro, ele deve possuir uma <b>Avaliação Pessoal</b> maior que <b>3</b> e uma <b>Avaliação Técnica</b> maior que <b>4</b>, inserido no painel <b>Entrevista</b>.</p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_NOT_SCHEDULED_TRAINING">Cadastro Aprovado para Profissionais MB
                                    <p>Deve possuir uma data agendada para o <b>Treinamento</b> clicando em:
                                        <b-button variant="warning" size="sm">
                                            <b-iconstack font-scale="1.5">
                                                <b-icon stacked icon="calendar"></b-icon>
                                                <b-icon stacked shift-v="-1" scale="0.5" icon="telephone-forward"></b-icon>
                                            </b-iconstack>
                                        </b-button>
                                    </p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_SCHEDULED_TRAINING">
                                    <b>Profissionais MB</b>
                                    <p>Para tornar profissional Mary Beleza, deve-se possuir um banco cadastrado para receber e clicar no botão: 
                                        <b-button variant="success">
                                            <b-iconstack font-scale="1.3" class="mr-2">
                                                <b-icon stacked icon="patch-check"></b-icon>
                                            </b-iconstack>
                                            Tornar profissional MB
                                        </b-button>
                                    </p>
                                </span>
                                <span v-if="worker.status == constants.STATUS_PROFESSIONAL_MB">
                                    <b>Cadastro Aprovados</b>
                                    <p>Para tirar a profissional da listagem Mary Beleza deve-se clicar no botão: 
                                        <b-button variant="danger" class="d-flex">
                                            <b-iconstack font-scale="1.3" class="mr-2">
                                                <b-icon stacked icon="patch-minus"></b-icon>
                                            </b-iconstack>
                                            Retirar das profissionais MB
                                        </b-button>
                                    </p>
                                </span>
                            </help-icon>
                    </b-col>
                </b-row>
            </div>
        </util-bar>
        
        <loader-mary-beleza :show="loading || loading_save"></loader-mary-beleza>
        
        <div class="pr-3 pl-3 pt-3">

            <template v-if='IsUpdate && IsStage && !loading'>
                <panel>
                    <b-row>
                        <b-col sm="12" md="12" lg="12">
                            <h5><strong>Profissional:</strong> {{$store.state.worker.worker.person.name}}<span v-if="$store.state.worker.worker.person.national_document"> / <strong>CPF:</strong> {{$store.state.worker.worker.person.national_document | formatDocuments }}</span></h5>
                        </b-col>
                    </b-row>
                </panel>

                <personal-information v-if="OpenForm.personalInformation"/>
                <habilities v-if="OpenForm.habilities"/>
                <worker-files v-if="OpenForm.workerFiles"/>
                <contacts v-if="OpenForm.contact"/>
                <experiences v-if="OpenForm.experiences"/>
                <school-training v-if="OpenForm.schooling"/>
                <person-address v-if="OpenForm.personAddress" :validate-region="false"/>
                <complementary v-if="OpenForm.additional_information"/>
                <interview v-if="OpenForm.interview"/>
            </template>
            <template v-else-if='IsUpdate && !loading'>
                    <personal-information/>
                    <habilities/>
                    <worker-files/>
                    <contacts/>
                    <bank/>
                    <experiences/>
                    <school-training/>
                    <person-address :validate-region="false"/>
                    <complementary/>
                    <interview ref="evaluation"/>
                    <training/>
                    <restriction/>
            </template>
            <template v-else-if="!loading">
                    <personal-information/>
                    <habilities/>
                    <contacts></contacts>
                    <person-address :validate-region="false"/>
                    <bank/>
                    <experiences/>
                    <school-training/>
                    <complementary/>
                    <interview/>
                    <restriction/>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PersonalInformation from '@/views/Worker/forms/PersonalInformation'
import Contacts from '@/views/Worker/forms/Contacts'
import Bank from '@/views/Worker/forms/Bank'
import Interview from '@/views/Worker/forms/Interview'
import Experiences from '@/views/Worker/forms/Experiences'
import SchoolTraining from '@/views/Worker/forms/SchoolTraining'
import PersonAddress from '@/views/Worker/forms/PersonAddress'
import Complementary from '@/views/Worker/forms/Complementary'
import Training from '@/views/Worker/forms/Training'
import Habilities from '@/views/Worker/forms/Habilities'
import WorkerFiles from '@/views/Worker/forms/WorkerFiles'
import Restriction from '@/views/Worker/forms/Restriction'
import UtilBar from '@/components/UtilBar'
import Constants from '@/constants'
import Panel from "@/components/Panel"
import HelpIcon from '@/components/HelpIcon';
import swal from 'sweetalert2'

export default {
    components: {
        PersonalInformation,
        Contacts,
        Bank,
        Interview,
        Experiences,
        SchoolTraining,
        PersonAddress,
        Complementary,
        Training,
        Habilities,
        WorkerFiles,
        Restriction,
        UtilBar,
        Panel,
        HelpIcon
    },

    data() {
        return {
            loading_save: false,
            loaderImages: [
                'icon-nails', 
                'icon-hair-stylist', 
                'icon-massage', 
                'icon-lipstick'
            ]
        }
    },

    created() {
        this.$store.commit('worker/SET_ADD_ERROR', {});

        if (this.$route.params.id) {
            this.$store.dispatch("worker/loadEditForm", this.$route.params.id);
        } else {
            this.$store.dispatch("worker/loadInsertForm");
        }

        this.$store.dispatch('worker/getHabilities');
        this.$store.dispatch('worker/getHabilitiesAll');
    },
    methods: {

        newStatusWorker(status){
            var message = `Deseja realmente ${status == Constants.STATUS_PROFESSIONAL_MB ? 'tornar' : 'retirar'} o(a) profissional <strong>${this.worker.person.name}</strong> ${status == Constants.STATUS_PROFESSIONAL_MB ? 'como profissional MB' : 'da lista de profissionais MB'}?`;
            if (status == Constants.STATUS_PROFESSIONAL_MB) {
                if (!this.training) {
                    message = 'A profissional não está com a opção Participou do Treinamento corretamente, deseja continuar?';
                }
            }

            var swal_config = {
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Profissional Mary Beleza`,
                html: message,
                icon: 'warning',
                padding: 20
            };

            this.$swal.showValidationMessage(
              `Por favor, informe um motivo para o bloqueio do(a) profissional!`
            );

            if (status == Constants.STATUS_DROPOUT) {
                swal_config.input = "textarea";
                swal_config.inputLabel = "Motivo";
                swal_config.preConfirm = (reason) => {
                    if (reason === null || reason === "") {
                        swal.showValidationMessage("Motivo da desistência é obrigatório.");
                    }
                };
            }

            swal.fire(swal_config).then((result) => {
                if (result.isConfirmed) {
                    if (status == Constants.STATUS_PROFESSIONAL_MB) {
                        this.training = 1;
                    }

                    swal.fire({
                        title: "Salvando",
                        didOpen() {
                            swal.showLoading();
                        }
                    })

                    var data = {
                        worker_id: this.worker.id,
                        status: status,
                        status_motive: result.value,
                        from: 'status_worker'
                    }
                    
                    this.$store.dispatch('worker/changeStatusWorker', data).then(response => {
                        this.sweealert2('success', response.success)
                    
                    }).catch(error => {
                            swal.fire({
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                title: 'Atenção!',
                                text: error.response.data.error,
                                icon: 'error',
                                padding: 20,
                            })
                        });
                    }
            })
        },

        createWorker() {
            this.$store.dispatch('worker/createWorker').then(response => {
                this.sweealert2('success', response.success)
                this.$router.push('/profissionais/pre-cadastro');
                this.loading_save = false
            }).catch(error => {
                this.addError(error)
                this.loading_save = false
                this.sweealert2Toast('error', error[Object.keys(error)[0]])
            });
        },

        addError(error) {
            this.$store.commit('worker/SET_ADD_ERROR', error)
        },

        verifyForm() {
            //document.location.href = "#name"
            this.loading_save = true
            var worker = this.$store.state.worker.worker;
            var error = {};
            worker.person.name === '' || worker.person.name === null ? error = {
                name: ['O campo nome é obrigatório']
            } : null
            // worker.person.email === '' || worker.person.email === null ? error = Object.assign(error, {
            //     email: ['O campo e-mail é obrigatório']
            // }) : null
            
            // worker.person.national_document === '' || worker.person.national_document === null ? error = Object.assign(error, {
            //     national_document: ['O campo CPF é obrigatório']
            // }) : null

            if (Object.keys(error).length === 0) {
                this.$store.commit('worker/SET_ADD_ERROR', {})
                this.loading_save = false
                this.createWorker()
            } else {
                this.loading_save = false
                this.$store.commit('worker/SET_ADD_ERROR', error)
                for(var item in error){
                    this.sweealert2Toast('error', error[item])
                }
            }
        },

        sweealert2(icon, text) {
            swal.fire({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20
            })
        },

        sweealert2Toast(icon, text) {
            var bgColor = '#dbf6e9';

            if(icon == 'error')
                bgColor = '#f4c8c8'

            swal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: bgColor
            })
        },

        RandomImages() {
            return require('@/assets/images/icons/' + this.loaderImages[Math.floor(Math.random() * this.loaderImages.length)] + '.png');
        },

        ApproveOrFail() {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Reprovar Pré-cadastro`,
                html: `Deseja realmente Reprovar o pré-cadastro do(a) profissional <strong>${this.worker.person.name}</strong>?<hr/><p>Por favor informe o motivo:</p>`,
                icon: 'warning',
                padding: 20,
                showLoaderOnConfirm: true,
                preConfirm: (disapproval_reason) => {
                    // Validação do textarea.
                    if(disapproval_reason === null || disapproval_reason === ""){
                        this.$swal.showValidationMessage(`Obrigatório informar motivo da reprovação!`)
                    } 
                    return disapproval_reason
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                   var data = {
                        worker_id: this.worker.id,
                        disapproval_reason: result.value,
                        status: Constants.STATUS_DISAPPROVED,
                        from: 'status_worker'
                    }
                    this.$store.dispatch('worker/changeStatusWorker', data).then(response => {
                        this.sweealert2('success', response.success);
                        this.$router.push('/profissionais/aprovados');
                    }).catch(error => {
                        return this.$swal.showValidationMessage(`${error.response.data.error}`)
                    });
                }
            })
        },
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        loading: {
            get() {
                return this.$store.state.worker.loading;
            },
        },

        worker: {
            get() {
                return this.$store.state.worker.worker;
            },
        },

        training: {
            get() {
                return this.$store.state.worker.worker.training;
            },
            set(value) {
                this.$store.commit('worker/SET_TRAINING', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    },
}
</script>

<style>
.icon-mb {
    vertical-align: bottom;
}
</style>
